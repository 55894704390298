import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  removeProject,
  userInvitesProjectRequest,
} from '../../../../store/project/actions';
import { saveInvites } from '../../../../store/task/action';
import FormInputField from '../../../formInputField';
import { getItem } from '../../../../utilities/utils';

const InvitesModal = ({
  showModal,
  setShowModal,
  dispatchSaveInvitesRequest,
  dispatchGetProjectInviteUser,
  dispatchRemoveProject,
}) => {
  const { invitedUser, projects, projectId } = useSelector(
    (state) => state.project
  );
  const {isInvitesAdded} = useSelector((state)=> state.task)
  const [projectName, setProjectName] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [btnEnable, setBtnEnable] = useState(false);


  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    };
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (selectedProjectId) {
      dispatchGetProjectInviteUser(selectedProjectId);
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (selectedProjectId) {
      setProjectName(
        projects.find((project) => project.id === Number(projectId)).name
      );
    }
  }, []);

  const handleDataSubmit = (data) => {
    const finalPayload = {
      projectId: selectedProjectId,
      email: data.email,
      notes: 'This project urgent basis',
    };
    setBtnEnable(true);
    dispatchSaveInvitesRequest(finalPayload);
  };

  const handleRemoveProject = (id) => {
    dispatchRemoveProject(id);
  };
  useEffect(() => {
    if (JSON.parse(getItem('selectedProject'))) {
      setSelectedProjectId(JSON.parse(getItem('selectedProject')).id);
    }
  }, []);

  useEffect(() => {
    if (projectId) {
      setSelectedProjectId(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if(isInvitesAdded)
    {
      setBtnEnable(false)
    }
  }, [isInvitesAdded])
 
  return (
    <div className={`modal ${showModal ? 'show' : 'hide'}`}>
      <div className="modal-dialog modal-lg modal-dialog-centered max-w-5xl">
        <div className="modal-content">
          <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
            <h5 className="text-xl font-medium leading-normal text-gray-800">
              Invite pepole to {projectName !== '' && projectName}
            </h5>
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="btn-close box-content"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body relative p-5">
            <form onSubmit={handleSubmit(handleDataSubmit)}>
              <div className="mb-4">
                <FormInputField
                  label={'E-mail'}
                  type={'email'}
                  fieldName={'email'}
                  register={register}
                  errors={errors}
                  isRequired={true}
                  pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}
                  placeholder={'email'}
                />
              </div>
              <div className="flex justify-between">
                <p className="text-center mt-3">
                  Your teammates will get an e-mail that give them access to
                  your project
                </p>
                <button className="rounded-lg mt-0 btn-primary w-fit mr-3 ml-auto" disabled={btnEnable}>
                  Invite
                </button>
              </div>
            </form>
            <div>
              <div className="mb-2">
                <span className="font-semibold">Already Invites:</span>
                <div className="max-h-[250px] overflow-auto">
                  <table className="min-w-full border mt-2">
                    <thead className="border-b">
                      <tr className="text-left">
                        <th className="table-h">Email</th>
                        <th className="table-h">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invitedUser &&
                        invitedUser.map((user, idx) => (
                          <tr className="border-b" key={idx}>
                            <td className="table-data">{user.email}</td>
                            <td className="table-data">
                              {user.inviteStatus ? (
                                <i
                                  className={`fa-solid ${
                                    user.inviteStatus === 1
                                      ? 'fa-check text-green-500 border-green-500'
                                      : 'fas fa-user-slash  text-red-500 border-red-500'
                                  }  status-icon`}
                                ></i>
                              ) : (
                                <i
                                  className={
                                    'fa-solid fa fa-trash text-red-500 border-red-500 cursor-pointer'
                                  }
                                  onClick={() => handleRemoveProject(user.id)}
                                ></i>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSaveInvitesRequest: saveInvites,
  dispatchGetProjectInviteUser: userInvitesProjectRequest,
  dispatchRemoveProject: removeProject,
};
export default connect(null, mapDispatchToProps)(InvitesModal);
