import React, { useState } from 'react';
import CustomAvatar from '../../../../common/customAvatar';
import Avatar from 'react-avatar';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { deleteSubTask } from '../../../../../store/task/action';
import DeleteModal from '../../../../common/deleteModal';

const LinkIssuesTask = ({ singleTask, dispatchDeleteSubTask }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState(null);

  const { trackingStatuses } = useSelector((state) => state.task);
  const navigate = useNavigate();

  const onClicktoView = ({ taskId }) => {
    navigate(`/task/${taskId}`);
  };

  const onDeleteTask = () => {
    if (deletedId) {
      dispatchDeleteSubTask({ id: deletedId, isPermanent: true });
      setShowDeleteModal(false);
      setDeletedId(null);
    }
  };
  const handleDeleteLinkedTask = (id) => {
    setShowDeleteModal(true);
    setDeletedId(id);
  };

  return (
    <>
      {singleTask.taskRelation && !!singleTask.taskRelation.length && (
        <>
          <div className="linked-issues-card mb-4 cursor-pointer">
            {singleTask.taskRelation.map((item, idx, arr) => (
              <div
                className={`flex items-center justify-between p-2 issue-card ${
                  idx + 1 < arr.length ? 'border-b' : ''
                }`}
                key={idx}
                onDoubleClick={() =>
                  onClicktoView({
                    taskId: item.id,
                  })
                }
              >
                <div>
                  <Link to={`/task/${item.id}`}>{item.title}</Link>
                </div>
                <div className="flex items-center">
                  <span>
                    <img
                      src="../../assets/img/medium.svg"
                      className="w-[20px] h-[20px] mr-2"
                      alt=""
                    />
                  </span>

                  {item.assignedId ? (
                    <CustomAvatar id={item.assignedId} idPrefix={`pre_${item.assignedId}`} />
                  ) : (
                    <div className="user">
                      <div className="avatar">
                        <Avatar
                          round={true}
                          title="Unassigned"
                          name="Un Assigned"
                        />
                      </div>
                    </div>
                  )}

                  {trackingStatuses.map((task, index) => {
                    return task.id === item.trackingStatusId ? (
                      <div
                        className="ml-2 flex-items cursor-pointer border border-[#0052cc] rounded-[5px] p-1 text-[#0052cc]"
                        key={idx + index}
                      >
                        {task.name}
                      </div>
                    ) : (
                      ''
                    );
                  })}

                  <div className="dropdown inline-block relative">
                    <img
                      src="../../assets/img/dots-vertical.svg"
                      alt=""
                      className="w-7 h-7 rotate-0 m-0 cursor-pointer"
                    />

                    <ul
                      id="dropdown-menu"
                      className="dropdown-menu absolute hidden pt-1 bg-white shadow rounded py-2 px-4 mb-2 whitespace-nowrap right-0 left-align"
                    >
                      <li className="text-red-500">
                        <button
                          type="button"
                          onClick={() =>
                            handleDeleteLinkedTask(item.taskRelationId)
                          }
                        >
                          <i
                            className="fa fa-trash mr-2 cursor-pointer w-[18px]"
                            aria-hidden="true"
                          ></i>
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {showDeleteModal && (
        <DeleteModal
          DeleteShowModal={showDeleteModal}
          setDeleteShowModal={(val) => setShowDeleteModal(val)}
          handleDelete={onDeleteTask}
          data={{ name: 'Linked Task' }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchDeleteSubTask: deleteSubTask,
};
export default connect(null, mapDispatchToProps)(LinkIssuesTask);
