import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  deleteProjectRequest,
  flowStatusList,
  getAllProjectRequest,
  getProjectScrum,
  saveTag,
  saveTaskPriority,
  saveTaskType,
  saveTrackingStatus,
  updateProjectRequest,
} from '../../../store/project/actions';
import { successMsg } from '../../../utilities/utils';
import ToastMessage from '../../ToastContainer';
import DeleteModal from '../../common/deleteModal';
import { useForm } from 'react-hook-form';
import FormInputField from '../../formInputField';
import Table from '../../common/table';
import { resetState } from '../../../store/project/projectSlice';
import UserRole from './components/UserRole';
import { useNavigate } from 'react-router-dom';
import FormSelector from '../../formSelector';

import TackingStatusTable from '../../common/table/trackingStatus/TackingStatusTable';
import EmailTemplate from './components/EmailTemplate';
import { getEmailTemplateRequest } from '../../../store/emailTemplate/actions';
import IconPicker from './components/IconPicker';
import TaskPriorityTable from '../../common/table/taskPriorityTable';
import { emailResetState } from '../../../store/emailSettings/emailSettingsSlice';
import Loader from '../../loader';
import { useDispatch } from 'react-redux';

const Projects = ({
  dispatchResetStateRequest,
  dispatchGetProjectScrumRequest,
  dispatchDeleteProjectRequest,
  dispatchUpdateProjectRequest,
  dispatchSaveTaskTypeRequest,
  dispatchSaveTaskPriorityRequest,
  dispatchSaveTrackingStatusRequest,
  dispatchSaveTagRequest,
  dispatchGetFlowStatus,
  dispatchGetProjectRequest,
  dispatchGetEmailTemplateRequest,
  dispatchResetEmailAlertsRequest,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const {
    register: registerTaskType,
    formState: { errors: errorsTaskType },
    handleSubmit: handleTaskTypeSubmit,
    reset: resetTaskType,
  } = useForm();
  const {
    register: registerTaskPriority,
    formState: { errors: errorsTaskPriority },
    handleSubmit: handleTaskPrioritySubmit,
    reset: resetTaskPriority,
  } = useForm();
  const {
    register: registerTrackingStatus,
    formState: { errors: errorsTrackingStatus },
    handleSubmit: handleTrackingStatusSubmit,
    reset: resetTrackingStatus,
  } = useForm();
  const {
    register: registerTags,
    formState: { errors: errorsTags },
    handleSubmit: handleTagsSubmit,
    reset: resetTags,
  } = useForm();

  const {
    loading,
    projects,
    message,
    allTaskType,
    allTaskPriority,
    allTrackingStatus,
    singleProject,
    isProjectUpdated,
    allTags,
    isTrackingStatusAdded,
    isTaskPriorityAdded,
    isTaskTypeAdded,
    isTaskTypeUpdated,
    isTaskPriorityUpdated,
    isTrackingStatusUpdated,
    isDeleteTrackingStatus,
    isDeleteTaskType,
    isDeleteTaskPriority,
    isUserRoleChanged,
    isUserDeleted,
    users,
    projectId,
    flowStatus,
  } = useSelector((state) => state.project);
  const { emailMessage, isEmailAlerts } = useSelector(
    (state) => state.emailSubscription
  );
  const { isGetSingleTaskFailed } = useSelector((state) => state.common);
  const [DeleteShowModal, setDeleteShowModal] = useState(false);
  const navigate = useNavigate();
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [chooseStandardStatus, setChooseStandardStatus] = useState([]);
  const [selectIcon, setSelectIcon] = useState('');
  const [colorCode, setColorCode] = useState('#000000');
  const dispatch = useDispatch();
  let modalId;
  let title = '';

  useEffect(() => {
    if (!!flowStatus && !!flowStatus.length) {
      setChooseStandardStatus(flowStatus);
    }
  }, [flowStatus]);

  useEffect(() => {
    if (isGetSingleTaskFailed) {
      navigate('/board');
    }
  }, [isGetSingleTaskFailed]);

  const handleDelete = () => {
    if (title.id) {
      dispatchDeleteProjectRequest(title.id);
      setDeleteShowModal(false);
    }
  };

  useEffect(() => {
    if (projects.length && selectedProjectId) {
      dispatchGetProjectScrumRequest(selectedProjectId);
    }
  }, [projects, selectedProjectId]);

  useEffect(() => {
    if (projectId) {
      setSelectedProjectId(projectId);
    }
  }, [projectId]);

  const handleDataSubmit = (data) => {
    let finalData;
    if (data.projectTypeId) {
      finalData = {
        id: selectedProjectId,
        data: [
          {
            path: '/name',
            op: 'replace',
            value: data.name,
          },
          {
            path: '/projectTypeId',
            op: 'replace',
            value: data.projectTypeId,
          },
        ],
      };
    } else {
      finalData = {
        id: selectedProjectId,
        data: [
          {
            path: '/name',
            op: 'replace',
            value: data.name,
          },
        ],
      };
    }

    dispatchUpdateProjectRequest(finalData);
  };
  const handleTaskType = (data) => {
    const finalPayload = {
      name: data.name,
      orderIndex: 1,
      projectId: selectedProjectId,
    };
    dispatchSaveTaskTypeRequest(finalPayload);
    resetTaskType({ name: '' });
  };

  const handleTaskPriority = (data) => {
    const finalPayload = {
      name: data.name,
      description: 'Testing',
      projectId: selectedProjectId,
      priorityColor: data.priorityColor,
      icon: selectIcon ? selectIcon : '',
    };
    dispatchSaveTaskPriorityRequest(finalPayload);
    resetTaskPriority({ name: '' });
  };

  const handleTrackingStatus = (data) => {
    const finalPayload = {
      name: data.name,
      description: 'Testing',
      orderIndex: 1,
      projectId: selectedProjectId,
      flowStatusId: data.flowStatusId,
    };
    dispatchSaveTrackingStatusRequest(finalPayload);
    resetTrackingStatus({ name: '' });
  };

  const handleTagsStatus = (data) => {
    const finalPayload = {
      name: data.name,
      projectId: selectedProjectId,
    };
    dispatchSaveTagRequest(finalPayload);
    resetTags({ name: '' });
  };
  useEffect(() => {
    if (Object.keys(singleProject).length !== 0 && selectedProjectId) {
      reset(singleProject);
      if (singleProject.projectTypeId) {
        setValue('projectTypeId', singleProject.projectTypeId.toString());
      }
    }
  }, [singleProject, selectedProjectId]);

  useEffect(() => {
    if (
      isTrackingStatusAdded ||
      isTaskPriorityAdded ||
      isTaskTypeAdded ||
      isTaskTypeUpdated ||
      isTaskPriorityUpdated ||
      isTrackingStatusUpdated ||
      isDeleteTrackingStatus ||
      isDeleteTaskType ||
      isDeleteTaskPriority ||
      isProjectUpdated ||
      isUserRoleChanged ||
      isUserDeleted
    ) {
      successMsg(message);
    }
    dispatchResetStateRequest();
  }, [
    isTrackingStatusAdded,
    isTaskPriorityAdded,
    isTaskTypeAdded,
    isTaskTypeUpdated,
    isTaskPriorityUpdated,
    isTrackingStatusUpdated,
    isDeleteTrackingStatus,
    isDeleteTaskType,
    isDeleteTaskPriority,
    isProjectUpdated,
    isUserRoleChanged,
    isUserDeleted,
  ]);

  useEffect(() => {
    if (isEmailAlerts) {
      successMsg(emailMessage);
      dispatchResetEmailAlertsRequest();
    }
  }, [isEmailAlerts]);

  useEffect(() => {
    if (isProjectUpdated) {
      dispatchGetProjectRequest({dispatch});
    }
  }, [isProjectUpdated]);

  const handleStandardTrackingStatus = () => {
    if (projectId) {
      dispatchGetFlowStatus(projectId);
    }
  };

  const handleEmailTemplate = () => {
    if (projectId) {
      dispatchGetEmailTemplateRequest(projectId);
    }
  };

  const onIconChange = (icon) => {
    setSelectIcon(icon);
  };

  return (
    <>
      <Loader isVisible={loading} />
      <ToastMessage />
      <div className="projects relative">
        <div>
          <div className="mt-5">
            <ul
              className="nav nav-tabs flex overflow-auto list-none"
              id="tabs-tab"
              role="tablist"
            >
              <li className="nav-item whitespace-nowrap" role="presentation">
                <a
                  href="#tabs-home"
                  className={`nav-link ${
                    projectId && modalId === 'Project' ? '' : 'active'
                  }`}
                  id="tabs-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-home"
                  role="tab"
                  aria-controls="tabs-home"
                  aria-selected="true"
                >
                  Project
                </a>
              </li>
              <li className="nav-item whitespace-nowrap" role="presentation">
                <a
                  href="#tabs-task-type"
                  className={`nav-link ${
                    projectId && modalId === 'Project' ? 'active' : ''
                  }`}
                  id="tabs-task-type-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-task-type"
                  role="tab"
                  aria-controls="tabs-task-type"
                  aria-selected="false"
                >
                  Task Type
                </a>
              </li>
              <li className="nav-item whitespace-nowrap" role="presentation">
                <a
                  href="#tabs-task-priority"
                  className="nav-link"
                  id="tabs-task-priority-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-task-priority"
                  role="tab"
                  aria-controls="tabs-task-priority"
                  aria-selected="false"
                >
                  Task Priority
                </a>
              </li>
              <li
                className="nav-item whitespace-nowrap"
                role="presentation"
                onClick={() => handleStandardTrackingStatus()}
              >
                <a
                  href="#tabs-tracking-status"
                  className="nav-link"
                  id="tabs-tracking-status-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-tracking-status"
                  role="tab"
                  aria-controls="tabs-tracking-status"
                  aria-selected="false"
                >
                  Tracking Status
                </a>
              </li>
              <li className="nav-item whitespace-nowrap" role="presentation">
                <a
                  href="#tabs-tags"
                  className="nav-link"
                  id="tabs-tags-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-tags"
                  role="tab"
                  aria-controls="tabs-tags"
                  aria-selected="false"
                >
                  Tags
                </a>
              </li>
              <li className="nav-item whitespace-nowrap" role="presentation">
                <a
                  href="#tabs-users"
                  className="nav-link"
                  id="tabs-users-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-users"
                  role="tab"
                  aria-controls="tabs-users"
                  aria-selected="false"
                >
                  Users
                </a>
              </li>
              <li
                className="nav-item whitespace-nowrap"
                role="presentation"
                onClick={() => handleEmailTemplate()}
              >
                <a
                  href="#tabs-email-template"
                  className="nav-link"
                  id="tabs-email-template-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-email-template"
                  role="tab"
                  aria-controls="tabs-email-template"
                  aria-selected="false"
                >
                  Email Template
                </a>
              </li>
            </ul>
          </div>
          <div
            className="tab-content border border-gray-300 px-5 pt-5 pb-3 h-[auto]"
            id="tabs-tabContent"
          >
            <div
              className={`tab-pane fade ${
                projectId && modalId === 'Project' ? '' : 'show active'
              } `}
              id="tabs-home"
              role="tabpanel"
              aria-labelledby="tabs-home-tab"
            >
              <form
                key={1}
                onSubmit={handleSubmit(handleDataSubmit)}
                className="mb-4"
              >
                <div className="lg:w-1/2 flex">
                  <div className="w-full flex">
                    <FormInputField
                      label={''}
                      type={'text'}
                      fieldName={'name'}
                      register={register}
                      errors={errors}
                      isRequired={true}
                      placeholder={'Enter project name'}
                    />
                    <div className="flex items-center ml-5">
                      <input
                        {...register('projectTypeId')}
                        type="radio"
                        value={'1'}
                      />
                      <label className="ml-3">Sprint</label>
                    </div>
                    <div className="flex items-center ml-5">
                      <input
                        {...register('projectTypeId')}
                        type="radio"
                        value={'2'}
                      />
                      <label className="ml-3">Kanban</label>
                    </div>
                  </div>
                  <div className="ml-4 ">
                    <button className="border rounded-lg btn-primary w-fit ml-auto mr-0 mt-0">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className={`tab-pane fade ${
                projectId && modalId === 'Project' ? 'show active' : ''
              }`}
              id="tabs-task-type"
              role="tabpanel"
              aria-labelledby="tabs-task-type-tab"
            >
              <form
                onSubmit={handleTaskTypeSubmit(handleTaskType)}
                className="mb-4"
              >
                <div className="lg:w-1/2 flex items-baseline">
                  <div className="w-full">
                    <FormInputField
                      label={''}
                      type={'text'}
                      fieldName={'name'}
                      register={registerTaskType}
                      errors={errorsTaskType}
                      isRequired={true}
                      placeholder={'Please Enter Task type'}
                    />
                  </div>
                  <div className="mt-4 ml-4">
                    <button className="border rounded-lg mt-0 btn-primary w-fit mr-0 ml-auto">
                      Save
                    </button>
                  </div>
                </div>
              </form>
              {!!allTaskType && !!allTaskType.length && (
                <Table
                  data={allTaskType}
                  projectId={selectedProjectId}
                  tabId={'tabs-task-type'}
                />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="tabs-task-priority"
              role="tabpanel"
              aria-labelledby="tabs-task-priority-tab"
            >
              <form
                onSubmit={handleTaskPrioritySubmit(handleTaskPriority)}
                className="mb-4"
              >
                <div className="w-full flex items-center">
                  <div className="w-full">
                    <FormInputField
                      label={''}
                      type={'text'}
                      fieldName={'name'}
                      register={registerTaskPriority}
                      errors={errorsTaskPriority}
                      isRequired={true}
                      placeholder={'Please Enter Task priority'}
                    />
                  </div>
                  <div>
                    <div className="ml-3 flex items-center">
                      <input
                        type="color"
                        className=" "
                        {...registerTaskPriority('priorityColor', {
                          onChange: (e) => setColorCode(e.target.value),
                        })}
                      />
                      <label className="mx-2">{colorCode}</label>
                    </div>
                  </div>
                  <div className="w-full">
                    <IconPicker value={selectIcon} onChange={onIconChange} />
                  </div>
                  <div className="ml-4">
                    <button className="border rounded-lg mt-0 btn-primary w-fit mr-0 ml-auto">
                      {'Save'}
                    </button>
                  </div>
                </div>
              </form>
              {!!allTaskPriority && !!allTaskPriority.length && (
                <TaskPriorityTable
                  data={allTaskPriority}
                  projectId={selectedProjectId}
                />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="tabs-tracking-status"
              role="tabpanel"
              aria-labelledby="tabs-tracking-status-tab"
            >
              <form
                onSubmit={handleTrackingStatusSubmit(handleTrackingStatus)}
                className="mb-4"
              >
                <div className="lg:w-3/4 flex items-baseline">
                  <div className="w-1/2 mr-4">
                    <FormInputField
                      label={''}
                      type={'text'}
                      fieldName={'name'}
                      register={registerTrackingStatus}
                      errors={errorsTrackingStatus}
                      isRequired={true}
                      placeholder={'Please Enter Tracking status'}
                    />
                  </div>
                  <div className="w-1/2 relative">
                    <FormSelector
                      label={''}
                      fieldName={'flowStatusId'}
                      register={registerTrackingStatus}
                      errors={errorsTrackingStatus}
                      isRequired={false}
                      data={chooseStandardStatus}
                      options="name"
                    />
                  </div>
                  <div className="mt-4 ml-4">
                    <button className="border rounded-lg mt-0 btn-primary w-fit mr-0 ml-auto">
                      {'Save'}
                    </button>
                  </div>
                </div>
              </form>

              <TackingStatusTable
                data={allTrackingStatus}
                projectId={selectedProjectId}
              />
            </div>
            <div
              className="tab-pane fade"
              id="tabs-tags"
              role="tabpanel"
              aria-labelledby="tabs-tags-tab"
            >
              <div>
                <form className="flex items-baseline mb-4">
                  <div className="flex items-baseline md:w-1/2">
                    <div className="w-full">
                      <FormInputField
                        label={''}
                        type={'text'}
                        fieldName={'name'}
                        register={registerTags}
                        errors={errorsTags}
                        isRequired={true}
                        placeholder="Please Enter Tags"
                      />
                    </div>
                    <div className="ml-4">
                      <button
                        className="border rounded-lg mt-0 btn-primary w-fit mr-0 ml-auto"
                        onClick={handleTagsSubmit(handleTagsStatus)}
                      >
                        {'Save'}
                      </button>
                    </div>
                  </div>
                </form>
                {!!allTags && !!allTags.length && (
                  <Table
                    data={allTags}
                    projectId={selectedProjectId}
                    tabId={'tags'}
                  />
                )}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tabs-users"
              role="tabpanel"
              aria-labelledby="tabs-users-tab"
            >
              <div>
                {!!users && !!users.length && (
                  <UserRole data={users} projectId={selectedProjectId} />
                )}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tabs-email-template"
              role="tabpanel"
              aria-labelledby="tabs-email-template-tab"
            >
              <div>{<EmailTemplate />}</div>
            </div>
          </div>
        </div>
      </div>

      {DeleteShowModal && (
        <DeleteModal
          DeleteShowModal={DeleteShowModal}
          setDeleteShowModal={(val) => setDeleteShowModal(val)}
          handleDelete={handleDelete}
          data={title}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetProjectScrumRequest: getProjectScrum,
  dispatchDeleteProjectRequest: deleteProjectRequest,
  dispatchUpdateProjectRequest: updateProjectRequest,
  dispatchSaveTaskTypeRequest: saveTaskType,
  dispatchSaveTaskPriorityRequest: saveTaskPriority,
  dispatchSaveTrackingStatusRequest: saveTrackingStatus,
  dispatchSaveTagRequest: saveTag,
  dispatchResetStateRequest: resetState,
  dispatchGetFlowStatus: flowStatusList,
  dispatchGetProjectRequest: getAllProjectRequest,
  dispatchGetEmailTemplateRequest: getEmailTemplateRequest,
  dispatchResetEmailAlertsRequest: emailResetState,
};
export default connect(null, mapDispatchToProps)(Projects);
