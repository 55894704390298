import React, { useState } from 'react';
import Sidebar from '../sidebar';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Header from '../header';
import 'tw-elements';
import ProtectedRoutes from '../../ProtectedRoutes';
import AdminDashboard from '../admin/adminDashboard';
import Scrum from '../admin/scrum';
import Footer from '../common/footer';

import AcceptInvites from '../invites';
import AuthComponent from '../AuthComponent';
import Projects from '../admin/projects';
import LabelChange from '../admin/label-change';
import Notifications from '../common/notifications';
import Sprint from '../admin/sprint';
import Report from '../admin/report';
import Author from '../admin/author';
import SlackConnect from '../admin/author/SlackConnect';
import GlobalSearch from '../admin/globalSearch';

import { connect } from 'react-redux';
import {
  endTaskTimerStart,
  getTaskTimeTracker,
  runningTaskTimer,
  saveTaskTimerStart,
} from '../../store/task/action';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';
import { convertTime } from '../../utilities/utils';
import { resetState } from '../../store/task/taskSlice';
import NotFoundPage from '../common/notFoundPage';
import Draggable from 'react-draggable';
import EditPage from '../admin/scrum/components/taskEdit/EditPage';

const MainLayoutRoutes = ({
  dispatchSaveTaskTimerRequest,
  dispatchEndTaskTimerRequest,
  dispatchResetStateRequest,
  dispatchRunningTaskTimerRequest,
}) => {
  //toggle class on hover for sidebar
  const [isSidebarHovered, setIsSidebarHovered] = useState(true);
  
  const [taskTimer, setTaskTimer] = useState({});
  const [startStop, setStartStop] = useState(true);
  const [timer, setTimer] = useState('');
  const handleSidebarHover = () => setIsSidebarHovered(!isSidebarHovered);
  //Toggle class for Pin and Unpin main parent div
  const [isPinned, setIsPinned] = useState(true);
  const location = useLocation();
  // const role = localStorage.getItem("role");
  const handleSidebarPinned = () => {
    if (isPinned) {
      setIsPinned(false);
    } else {
      setIsPinned(true);
    }
  };
  //Toggle class for Mobile menu sidebar
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const handleMobileMenu = () => {
    if (mobileSidebar) {
      setMobileSidebar(false);
    } else {
      setMobileSidebar(true);
    }
  };
  //Back to top button when scroll
  const [setVisible] = useState(false);
  const { taskTimers, isTaskTimerEnd, runningTaskTimer } =
    useSelector((state) => state.task);
  const { projectId, projects } = useSelector((state) => state.project);
  const { projectSettings } = useSelector((state) => state.permissions);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 300);
  };

  useEffect(() => {
    if (Object.keys(projectSettings).length) {
      if(projectSettings.canStartTimer)
      {
        dispatchRunningTaskTimerRequest(projectId);
      }
    }
  }, [projectSettings]);

  window.addEventListener('scroll', toggleVisible);
  useEffect(() => {
    if (runningTaskTimer && Object.keys(runningTaskTimer).length) {
      setTaskTimer({
        taskId: runningTaskTimer.taskId,
        title: runningTaskTimer.taskTitle,
      });
      setTimer(runningTaskTimer.startTime);
    } else {
      setTaskTimer({});
    }
  }, [runningTaskTimer]);

  useEffect(() => {
    if (taskTimer && Object.keys(taskTimer).length && !runningTaskTimer) {
      if (startStop) {
        dispatchSaveTaskTimerRequest({ taskId: taskTimer.taskId });
        dispatchResetStateRequest();
      } else {
        dispatchEndTaskTimerRequest({ taskId: taskTimer.taskId });
        dispatchResetStateRequest();
      }
    }
  }, [taskTimer]);

  useEffect(() => {
    if (taskTimers) {
      setTimer(taskTimers);
    }
  }, [taskTimers]);
  useEffect(() => {
    if (isTaskTimerEnd) {
      setTimer('');
    }
  }, [isTaskTimerEnd]);
   
  const handleStop = () => {
    dispatchEndTaskTimerRequest({ taskId: taskTimer.taskId });
    dispatchResetStateRequest();
    setTaskTimer({});
  };
  return (
    <div
      className={`${isPinned ? 'pin' : 'unpin'} ${
        mobileSidebar ? 'bgoverlay' : 'bg-without-overlay'
      } ${isSidebarHovered ? 'show' : 'hide'} w-full ${
        location.pathname !== '/project-repo' ? 'flex justify-center' : ''
      }`}
    >
      {taskTimer && Object.keys(taskTimer).length && (
        <Draggable>
          <div className="task-timer text-white cursor-all-scroll">
            <div className="task-tracking-timer flex justify-around items-center">
              <span className="ml-2">{taskTimer.title}</span>
              <i
                className={`fa-solid fa-circle-${
                  startStop ? 'pause' : 'play'
                } ml-2`}
                onClick={() => setStartStop(!startStop)}
              ></i>
              {startStop && (
                <i
                  className="fa-solid fa-circle-stop ml-2"
                  onClick={() => handleStop()}
                ></i>
              )}
              <div className="ml-2">
                {timer && (
                  <ReactTimeAgo
                    date={Number(convertTime(timer))}
                    locale="en-US"
                  />
                )}
              </div>
            </div>
          </div>
        </Draggable>
      )}
      <div className="overlay hidden">
        <img
          src="../../assets/img/cross.svg"
          alt=""
          className="w-6 h-6 ml-auto mr-3 my-3 cursor-pointer"
          onClick={handleMobileMenu}
        />
      </div>
      {!!projects.length && (
        <Sidebar
          isPinned={isPinned}
          handleSidebarPinned={handleSidebarPinned}
          handleSidebarHover={handleSidebarHover}
          isSidebarHovered={isSidebarHovered}
          handleMobileMenu={handleMobileMenu}
        />
      )}
      <div
        id={`${location.pathname !== '/project-repo' ? projects.length ? 'main' :'no-project-container' : ''}`}
        className="py-5 lg:px-8 md:px-7 px-4"
      >
        <Header handleMobileMenu={handleMobileMenu}/>
        <div
          className={`${
            location.pathname !== '/project-repo' ? 'mt-16' : ''
          } pt-2 custom-mt`}
        >
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              <Route
                path="/projectinvite"
                element={AuthComponent(<AcceptInvites />)}
              ></Route>
              <Route path="/" element={AuthComponent(<AdminDashboard />)} />
              <Route
                path="/board"
                element={<Scrum setTaskTimer={setTaskTimer} />}
              />
              <Route path="/sprint" element={AuthComponent(<Sprint />)} />
              <Route path="/task/:id" element={AuthComponent(<EditPage />)} />
              <Route path="/task" element={AuthComponent(<EditPage />)} />
              <Route
                path="/settings"
                element={AuthComponent(<Projects />)}
              ></Route>

              <Route
                path="/label-change"
                element={AuthComponent(<LabelChange />)}
              ></Route>
              <Route
                path="/notifications"
                element={AuthComponent(<Notifications />)}
              ></Route>
              <Route path="/report" element={AuthComponent(<Report />)}></Route>
              <Route path="/slack" element={AuthComponent(<Author />)}></Route>
              <Route
                path="/integrations/slack/callback"
                element={AuthComponent(<SlackConnect />)}
              />
              <Route
                path="/task-list"
                element={AuthComponent(<GlobalSearch />)}
              ></Route>
            </Route>
            <Route path="/not-found" element={<NotFoundPage />} />
            <Route path="/*" element={<Navigate to="/not-found" replace />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSaveTaskTimerRequest: saveTaskTimerStart,
  dispatchEndTaskTimerRequest: endTaskTimerStart,
  dispatchResetStateRequest: resetState,
  dispatchRunningTaskTimerRequest: runningTaskTimer,
  dipatchGetTaskTimeTracker: getTaskTimeTracker,
};
export default connect(null, mapDispatchToProps)(MainLayoutRoutes);
