import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { removeItem } from '../../utilities/utils';
import { connect, useSelector } from 'react-redux';
import { saveOTPRequest, verifyOTPRequest } from '../../store/user/action';
import { Link, useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastContainer';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';

const ForgotPassword = ({ dispatchOTPRequest, dispatchVerifyOTPRequest }) => {
  const { checkOTPToken, changePasswordToken } = useSelector(
    (state) => state.user
  );
  const [showOTPBox, setShowOTPBox] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    removeItem('token');
    removeItem('userData');
    removeItem('refreshToken');
  }, []);

  useEffect(() => {
    if (changePasswordToken) {
      navigate('/change-password');
    }
  }, [changePasswordToken]);

  const handlePasswordChange = (data) => {
    if (checkOTPToken) {
      const finalPayload = { code: data.code, token: checkOTPToken };
      dispatchVerifyOTPRequest(finalPayload);
    } else {
      dispatchOTPRequest(data.email);
      setShowOTPBox(true);
    }
    reset();
  };

  return (
    <>
      <ToastMessage />
      <div
        className="form-wrapper h-screen"
        style={{
          backgroundImage: 'url("../../assets/img/login-page-bg.png")',
        }}
      >
        <div className="flex justify-center relative">
          <div>
            <div className="md:mt-20">
              <img
                src="../../assets/img/ensuesoft.jpg"
                className="w-38 h-38 mx-auto object-contain"
              />
              <h2 className="text-2xl text-center mb-0 text-black dark:text-[#ccc]">
                {showOTPBox ? 'Validate OTP' : 'Forgot Password!'}
              </h2>
              <h3 className="text-center mb-4 text-gray-600">
                {showOTPBox
                  ? 'Please check your email and enter OTP received'
                  : 'Enter your email and we\'ll send you a link to reset your password.?'}
              </h3>
            </div>
            <div className="px-8 pt-4 pb-5 mx-auto bg-white rounded-lg shadow-card w-96">
              <form onSubmit={handleSubmit(handlePasswordChange)}>
                {showOTPBox ? (
                  <div className="mt-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        OTP
                      </label>
                      <input
                        id="code"
                        {...register('code', {
                          required: true,
                        })}
                        type="text"
                        className="input-style"
                        autoComplete="off"
                      />
                      {errors.code?.type === 'required' && (
                        <FormFieldError message={AppConstants.requiredField} />
                      )}
                      {errors.code?.type === 'pattern' && (
                        <FormFieldError message={AppConstants.notValidEmail} />
                      )}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="px-6 py-2 mt-4 btn-primary ml-0"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Enter Your Email
                      </label>
                      <input
                        id="email"
                        {...register('email', {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        })}
                        type="email"
                        className="input-style"
                        autoComplete="off"
                      />
                      {errors.email?.type === 'required' && (
                        <FormFieldError message={AppConstants.requiredField} />
                      )}
                      {errors.email?.type === 'pattern' && (
                        <FormFieldError message={AppConstants.notValidEmail} />
                      )}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="px-6 py-2 mt-4 btn-primary ml-0"
                      >
                        Send Request
                      </button>
                    </div>
                  </div>
                )}
              </form>
              <div className="flex justify-center text-[13px] mt-5 site-clr">
                <Link to="/login">
                  <i className="fa-solid fa-arrow-left"></i> Go Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchOTPRequest: saveOTPRequest,
  dispatchVerifyOTPRequest: verifyOTPRequest,
};
export default connect(null, mapDispatchToProps)(ForgotPassword);
