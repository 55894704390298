import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  getSingleTrackingStatusDataRequestFailure,
  getSingleTrackingStatusDataRequestSuccess,
  getTaskReportRequestFailure,
  getTaskReportRequestSuccess,
} from './dashboardSlice';
import { requestFailureRedirection } from '../common/commonSlice';

function* asyncDashboardReport(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_DASHBOARD_DATA.url}?ProjectId=${data.payload}`,
      data.payload
    );
    if (response.status === 200) {
      yield put(getTaskReportRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getTaskReportRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Single Tracking status data
function* asyncSingleTrackingStatusData(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_SINGLE_STATUS_DATA.url}?ProjectId=${data.payload.projectId}&TrackingStatusId=${data.payload.trackingStatusId}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getSingleTrackingStatusDataRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      yield put(getSingleTrackingStatusDataRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

export function* dashboardSaga() {
  yield all([
    takeEvery(constants.getTaskReportRequestActionType, asyncDashboardReport),
    takeEvery(
      constants.getSingleTrackingStatusDataRequestActionType,
      asyncSingleTrackingStatusData
    ),
  ]);
}
