import React, { useEffect, useState } from 'react';
import {compareCurrentDate } from '../../../utilities/utils';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import CustomAvatar from '../../common/customAvatar';
import { Draggable } from 'react-beautiful-dnd';

const Task = ({
  id,
  issueTitle,
  assignedTo,
  handleEditModal,
  tagId,
  dueDate,
  setTaskTimer,
  singleTask,
  index,
  assigneName
}) => {
  const { tags } = useSelector((state) => state.task);
  const { projectSettings } = useSelector((state) => state.permissions);

  const [tagName, setTagName] = useState('');

  useEffect(() => {
    if (tagId && !!tags && !!tags.length) {
      const tName = tags.find((tag) => tag.id === tagId);
      setTagName(tName?.name);
    }
  }, [tagId]);

  const handleStartTime = (data) => {
    setTaskTimer({ taskId: data.id, title: data.title });
  };
  return (
    <>
      {!projectSettings.canStartTimer ? (
        <div className="px-2 py-1">
          <div
            className={`ticket-main cursor-pointer ${
              dueDate !== null
                ? compareCurrentDate(dueDate)
                  ? singleTask.flowStatusId === 'Ready' ||
                    singleTask.flowStatusId === 'Complete'
                    ? ''
                    : 'bg-[#f9b2b2]'
                  : ''
                : ''
            }`}
            onDoubleClick={() => {
              return handleEditModal(id);
            }}
          >
            <div className="mr-2 w-9/12">
              <p>{issueTitle}</p>
              {tagName ? (
                <span className="ux-design mt-1.5 text-gray-500">
                  {tagName !== '' && tagName}
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="w-3/12">
              {projectSettings?.canEditTask && (
                <div className="dropdown inline-block relative">
                  <img
                    src="../../assets/img/dots-vertical.svg"
                    alt=""
                    className="w-7 h-7 rotate-90 ml-1"
                  />

                  <>
                    <ul
                      id="dropdown-menu"
                      className="dropdown-menu absolute hidden pt-1 bg-white shadow rounded py-2 px-4 mb-2 whitespace-nowrap"
                    >
                      <li>
                        <button
                          type="button"
                          onClick={() => handleEditModal(id)}
                        >
                          <i
                            className="fa fa-pencil mr-2 cursor-pointer w-[18px]"
                            aria-hidden="true"
                          ></i>
                          Edit
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          onClick={() =>
                            handleStartTime({ id, title: issueTitle })
                          }
                        >
                          <i
                            className="fa-solid fa-clock mr-2 cursor-pointer w-[18px]"
                            aria-hidden="true"
                          ></i>
                          Start Time
                        </button>
                      </li>
                    </ul>
                  </>
                </div>
              )}
              {assignedTo !== null && assignedTo !== 0 ? (
                <CustomAvatar id={assignedTo} idPrefix={id} assigneeName={assigneName} />
              ) : (
                <div className="user">
                  <div className="avatar">
                    <Avatar
                      round={true}
                      title="Unassigned"
                      name="Un Assigned"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Draggable index={index} draggableId={singleTask.id.toString()}>
          {(provided) => (
            <div
              className="px-2 py-1"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                className={`ticket-main cursor-pointer ${
                  dueDate !== null
                    ? compareCurrentDate(dueDate)
                      ? singleTask.flowStatusId === 'Ready' ||
                        singleTask.flowStatusId === 'Complete'
                        ? ''
                        : 'bg-[#f9b2b2]'
                      : ''
                    : ''
                }`}
                onDoubleClick={() => {
                  return handleEditModal(id);
                }}
              >
                <div className="mr-2 w-9/12">
                  <p>{issueTitle}</p>
                  {tagName ? (
                    <span className="ux-design mt-1.5 text-gray-500">
                      {tagName !== '' && tagName}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="w-3/12">
                  {projectSettings?.canEditTask && (
                    <div className="dropdown inline-block relative">
                      <img
                        src="../../assets/img/dots-vertical.svg"
                        alt=""
                        className="w-7 h-7 rotate-90 ml-1"
                      />

                      <>
                        <ul
                          id="dropdown-menu"
                          className="dropdown-menu absolute hidden pt-1 bg-white shadow rounded py-2 px-4 mb-2 whitespace-nowrap"
                        >
                          <li>
                            <button
                              type="button"
                              onClick={() => handleEditModal(id)}
                            >
                              <i
                                className="fa fa-pencil mr-2 cursor-pointer w-[18px]"
                                aria-hidden="true"
                              ></i>
                              Edit
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                handleStartTime({ id, title: issueTitle })
                              }
                            >
                              <i
                                className="fa-solid fa-clock mr-2 cursor-pointer w-[18px]"
                                aria-hidden="true"
                              ></i>
                              Start Time
                            </button>
                          </li>
                        </ul>
                      </>
                    </div>
                  )}
                  {assignedTo !== null && assignedTo !== 0 ? (
                    <CustomAvatar id={assignedTo} idPrefix={id} assigneeName={assigneName}/>
                  ) : (
                    <div className="user">
                      <div className="avatar">
                        <Avatar
                          round={true}
                          title="Unassigned"
                          name="Un Assigned"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Draggable>
      )}
    </>
  );
};

export default Task;
