import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import TrackingStatusCol from './TrackingStatusCol';
import {
  changeTrackingOrder,
  deleteTrackingStatus,
} from '../../../../store/project/actions';
import DeleteModal from '../../deleteModal';
import Loader from '../../../loader';

const TackingStatusTable = ({
  data,
  projectId,
  dispatchDeleteTrackingStatus,
}) => {
  const { loading } = useSelector((state) => state.project);
  const [DeleteShowModal, setDeleteShowModal] = useState(false);
  const [deletedData, setDeletedData] = useState(null);

  const handleDelete = () => {
    if (deletedData.id) {
      dispatchDeleteTrackingStatus({ id: deletedData.id });
      setDeleteShowModal(false);
    }
  };

  return (
    <>
      <Loader isVisible={loading} />
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
        <thead className="text-sm font-medium text-gray-900 bg-gray-50 sticky z-[99]">
          <tr className="border border-l-0 border-r-0 flex">
            <div className="w-full hover:cursor-move grid grid-cols-3 justify-between bg-white border-b cursor-pointer select-none dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <div className="px-6 py-3 font-semibold">Title</div>
              <div className="px-6 py-3 font-semibold">Flow Type</div>
              <div className="px-6 py-3 font-semibold"></div>
            </div>
          </tr>
        </thead>
        <tbody>
          {!!data &&
            !!data.length &&
            data.map((task, idx) => (
              <tr
                className="bg-white border-b select-none dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                key={idx}
              >
                <TrackingStatusCol
                  id={idx}
                  data={task}
                  projectId={projectId}
                  setDeleteShowModal={setDeleteShowModal}
                  setDeletedData={setDeletedData}
                />
              </tr>
            ))}
        </tbody>
      </table>
      {DeleteShowModal && (
        <DeleteModal
          DeleteShowModal={DeleteShowModal}
          setDeleteShowModal={(val) => setDeleteShowModal(val)}
          handleDelete={handleDelete}
          data={deletedData}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchTrackingOrderChange: changeTrackingOrder,
  dispatchDeleteTrackingStatus: deleteTrackingStatus,
};
export default connect(null, mapDispatchToProps)(TackingStatusTable);
