import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
// import { statusTypes, issues } from "../../components/admin/scrum/Lists";
import {
  addChecklistRequestFailure,
  addChecklistRequestSuccess,
  addTaskCommentRequestFailure,
  addTaskCommentRequestSuccess,
  deleteAttachmentFileRequestFailure,
  deleteAttachmentFileRequestSuccess,
  deleteSubTaskRequestFailure,
  deleteSubTaskRequestSuccess,
  deleteTaskCommentRequestFailure,
  deleteTaskCommentRequestSuccess,
  deleteTaskRequestFailure,
  deleteTaskRequestSuccess,
  deleteTimeSpendRequestFailure,
  deleteTimeSpendRequestSuccess,
  endTaskTimerStartRequestFailure,
  endTaskTimerStartRequestSuccess,
  getAllChecklistRequestFailure,
  getAllChecklistRequestSuccess,
  getAllTaskCommentRequestFailure,
  getAllTaskCommentRequestSuccess,
  getAllTaskHistoryRequestFailure,
  getAllTaskHistoryRequestSuccess,
  getAllTaskListRequestFailure,
  getAllTaskListRequestSuccess,
  getAllTaskRequestFailure,
  getAllTaskRequestSuccess,
  getAttachmentFileRequestFailure,
  getAttachmentFileRequestSuccess,
  getKanbanRequestFailure,
  getKanbanRequestSuccess,
  getScrumRequestFailure,
  getScrumRequestSuccess,
  getTaskRequestFailure,
  getTaskRequestSuccess,
  getTaskTimeTrackerRequestFailure,
  getTaskTimeTrackerRequestSuccess,
  getTimeSpendRequestFailure,
  getTimeSpendRequestSuccess,
  runningTaskTimerRequestFailure,
  runningTaskTimerRequestSuccess,
  saveAttachmentFileRequestFailure,
  saveAttachmentFileRequestSuccess,
  saveEditorImageRequestFailure,
  saveEditorImageRequestSuccess,
  saveInvitesRequestFailure,
  saveInvitesRequestSuccess,
  saveSprintTaskOrderRequestFailure,
  saveSprintTaskOrderRequestSuccess,
  saveSubTaskRequestFailure,
  saveSubTaskRequestSuccess,
  saveTaskTimerStartRequestFailure,
  saveTaskTimerStartRequestSuccess,
  saveTimeSpendRequestFailure,
  saveTimeSpendRequestSuccess,
  sendTaskRequestFailure,
  sendTaskRequestSuccess,
  taskTimeTrackerRequestFailure,
  taskTimeTrackerRequestSuccess,
  updateChecklistRequestFailure,
  updateChecklistRequestSuccess,
  updateTaskCommentRequestFailure,
  updateTaskCommentRequestSuccess,
  updateTaskRequestFailure,
  updateTaskRequestSuccess,
} from './taskSlice';
import { requestFailureRedirection } from '../common/commonSlice';

// get all task
function* asyncTask(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_TASK.url}/${data.payload.taskId}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getTaskRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      // yield put(requestFailureRedirection());
      errorMsg(err.response?.data.message);
    } else {
      yield put(getTaskRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// update task
function* asyncTaskUpdate(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_TASK.url}/${data.payload.id}`,
      data.payload.data,
      true
    );
    if (response.status === 200) {
      yield put(updateTaskRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateTaskRequestFailure());
    if (err.response?.data.message) {
      // yield put(requestFailureRedirection());
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// save task
function* asyncSendTask(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SEND_TASK.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(sendTaskRequestSuccess('Task is added!'));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(sendTaskRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// delete task
function* asyncDeleteTask(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.DELETE_TASK.url}/${data.payload}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(deleteTaskRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteTaskRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

function* asyncGetScrum(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.SRCUM.url}?ProjectId=${data.payload}&IsActive=true`,
      data.payload
    );
    if (response.status === 200) {
      yield put(getScrumRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(getScrumRequestFailure());
    if (err.response?.data.message) {
      console.log(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// get all data kanban
function* asyncGetKanban(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.KANBAN.url}?ProjectId=${data.payload}&IsActive=true`,
      data.payload
    );

    if (response.status === 200) {
      yield put(getKanbanRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(getKanbanRequestFailure());
    if (err.response?.data.message) {
      console.log(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

function* asyncSaveInvites(data) {
  try {
    const response = yield ApiService.postData(
      URLS.INVITES.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveInvitesRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveInvitesRequestFailure());
    if (err.response.data.statusCode === 400) {
      errorMsg(err.response.data.message);
    } else {
      errorMsg('Already invited!');
    }
  }
}

// save Editor image
function* asyncSaveEditorImage(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SEND_UPLOAD_IMAGE.url,
      data.payload.formData
    );
    if (response.status === 200) {
      yield put(saveEditorImageRequestSuccess(response.data));
    }
    data.payload.editor.insertEmbed(null, 'image', `${response.data.data}`);
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveEditorImageRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// add checklist
function* asyncAddChecklist(data) {
  try {
    const response = yield ApiService.postData(
      URLS.ADD_CHECKLIST.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(addChecklistRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(addChecklistRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// get All Check list
function* asyncGetAllChecklist(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.ADD_CHECKLIST.url}?TaskId=${data.payload.payload.TaskId}&SortBy=CreatedAt&SortByAsc=${data.payload.payload.SortByAsc}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getAllChecklistRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getAllChecklistRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// update check list
function* asyncUpdateChecklist(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_CHECKLIST.url}/${data.payload.id}`,
      data.payload.data,
      true
    );
    if (response.status === 200) {
      yield put(updateChecklistRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateChecklistRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// add task comment
function* asyncAddTaskComment(data) {
  try {
    const response = yield ApiService.postData(
      URLS.ADD_TASK_COMMENT.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(addTaskCommentRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(addTaskCommentRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Get all Task comment
function* asyncGetAllTaskComment(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.ADD_TASK_COMMENT.url}/paged-list?TaskId=${data.payload.payload.TaskId}&SortBy=CreatedAt&SortByAsc=${data.payload.payload.SortByAsc}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getAllTaskCommentRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getAllTaskCommentRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// delete task comment
function* asyncDeleteTaskComment(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.ADD_TASK_COMMENT.url}/delete/${data.payload}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(deleteTaskCommentRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteTaskCommentRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// update task Comment
function* asyncUpdateTaskComment(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_TASK_COMMENT.url}/${data.payload.id}`,
      data.payload.data,
      true
    );
    if (response.status === 200) {
      yield put(updateTaskCommentRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateTaskCommentRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// get All task History
function* asyncGetAllTaskHistory(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_ALL_TASK_HISTORY.url}?TaskId=${data.payload.payload.taskId}&PageNumber=${data.payload.payload.pageNumber}&PageSize=${data.payload.payload.pageSize}&SortBy=CreatedAt&SortByAsc=${data.payload.payload.SortByAsc}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      const paginationMeta = JSON.parse(response.headers['x-pagination']);
      const finalResponse = { data: response.data, paginationMeta };
      yield put(getAllTaskHistoryRequestSuccess(finalResponse));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getAllTaskHistoryRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// save time spend
function* asyncSaveTimeSpend(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SAVE_TIME_SPEND.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveTimeSpendRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveTimeSpendRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// get all time spend
function* asyncGetTimeSpend(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.SAVE_TIME_SPEND.url}?TaskId=${data.payload.payload.TaskId}&SortBy=CreatedAt&SortByAsc=${data.payload.payload.SortByAsc}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getTimeSpendRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getTimeSpendRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// delete time spend
function* asyncDeleteTimeSpend(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_TIME_SPEND.url}/delete/${data.payload}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(deleteTimeSpendRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteTimeSpendRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// save sub Task
function* asyncSaveSubTask(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SAVE_SUB_TASK.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveSubTaskRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveSubTaskRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Delete sub Task
function* asyncDeleteSubTask(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_SUB_TASK.url}/delete/${data.payload.id}?isPermanent=${data.payload.isPermanent}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(deleteSubTaskRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteSubTaskRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Get All Task
function* asyncGetAllTask(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_BACKLOG_SPRINT_TASK.url}?projectId=${data.payload.projectId}&SprintId=${data.payload.sprintId}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getAllTaskRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getAllTaskRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Get All Task List with pagination
function* asyncGetAllTaskList(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_ALL_TASK_LIST.url}?projectId=${data.payload.projectId}${
        data.payload.payload.keyword
          ? `&keyword=${data.payload.payload.keyword}`
          : ''
      }&PageNumber=${data.payload.payload.pageNumber}&PageSize=${
        data.payload.payload.pageSize
      }&SortBy=CreatedAt&SortByAsc=${data.payload.payload.SortByAsc}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      const paginationMeta = JSON.parse(response.headers['x-pagination']);
      const finalResponse = { data: response.data, paginationMeta };
      yield put(getAllTaskListRequestSuccess(finalResponse));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getAllTaskListRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Sprint task Order
function* asyncSaveSprintTask(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SPRINT_TASK_ORDER.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveSprintTaskOrderRequestSuccess(response.data));
    }
  } catch (error) {
    const err = error;
    yield put(saveSprintTaskOrderRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// SAVE ATTACHMENT FILE
function* asyncSaveAttachmentFile(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.ATTACHMENT_FILE.url}?taskid=${data.payload.taskId}`,
      data.payload.formData,
      true
    );
    if (response.status === 200) {
      yield put(saveAttachmentFileRequestSuccess(response.data));
    }
  } catch (error) {
    const err = error;
    yield put(saveAttachmentFileRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// GET ATTACHMENT FILE
function* asyncGetAttachmentFile(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_ATTACHMENT_FILE.url}?TaskId=${data.payload.taskId}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getAttachmentFileRequestSuccess(response.data));
    }
  } catch (error) {
    const err = error;
    yield put(getAttachmentFileRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Delete ATTACHMENT FILE
function* asyncDeleteAttachmentFile(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.DELETE_ATTACHMENT_FILE.url}/${data.payload.id}?attachmentId=${data.payload.attachmentid}&id=${data.payload.id}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(deleteAttachmentFileRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteAttachmentFileRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Save Task Timer start
function* asyncSaveTaskTimerStart(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_TASK_TIMER.url}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveTaskTimerStartRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveTaskTimerStartRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Task Timer end
function* asyncTaskTimerEnd(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.TASK_TIMER_END.url}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(endTaskTimerStartRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(endTaskTimerStartRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Running task timer
function* asyncRunningTaskTimer(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.RUNNING_TASK_TIMER.url}`,
      data.payload
    );
    if (response.status === 200) {
      yield put(runningTaskTimerRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(runningTaskTimerRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Task Time Tracker
function* asyncTaskTimeTracker(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.TASK_TIME_TRACKER.url}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(taskTimeTrackerRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(taskTimeTrackerRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

//Get Task Time Tracker
function* asyncGetTaskTimeTracker(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.TASK_TIME_TRACKER.url}?TaskId=${data.payload.payload.TaskId}&SortBy=CreatedAt&SortByAsc=${data.payload.payload.SortByAsc}`,
      data.payload.projectId,
      true
    );
    if (response.status === 200) {
      yield put(getTaskTimeTrackerRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(getTaskTimeTrackerRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

export function* taskSaga() {
  yield all([
    takeEvery(constants.getTaskRequestActionType, asyncTask),
    takeEvery(constants.deleteTaskRequestActionType, asyncDeleteTask),
    takeEvery(constants.updateTaskRequestActionType, asyncTaskUpdate),
    takeEvery(constants.sendTaskRequestActionType, asyncSendTask),
    takeEvery(constants.getScrumRequestActionType, asyncGetScrum),
    takeEvery(constants.getKanbanRequestActionType, asyncGetKanban),
    takeEvery(constants.saveInvitesRequestActionType, asyncSaveInvites),
    takeEvery(constants.saveEditorImageRequestActionType, asyncSaveEditorImage),
    takeEvery(constants.addCheckListRequestActionType, asyncAddChecklist),
    takeEvery(constants.getAllCheckListRequestActionType, asyncGetAllChecklist),
    takeEvery(constants.updateCheckListRequestActionType, asyncUpdateChecklist),
    takeEvery(constants.addTaskCommentsRequestActionType, asyncAddTaskComment),
    takeEvery(
      constants.deleteTaskCommentRequestActionType,
      asyncDeleteTaskComment
    ),
    takeEvery(
      constants.getAllTaskCommentsRequestActionType,
      asyncGetAllTaskComment
    ),
    takeEvery(
      constants.getAllTaskHistoryRequestActionType,
      asyncGetAllTaskHistory
    ),
    takeEvery(
      constants.updateTaskCommentRequestActionType,
      asyncUpdateTaskComment
    ),
    takeEvery(constants.saveTimeSpendRequestActionType, asyncSaveTimeSpend),
    takeEvery(constants.getTimeSpendRequestActionType, asyncGetTimeSpend),
    takeEvery(constants.deleteTimeSpendRequestActionType, asyncDeleteTimeSpend),
    takeEvery(constants.saveSubTaskRequestActionType, asyncSaveSubTask),
    takeEvery(constants.getAllTaskRequestActionType, asyncGetAllTask),
    takeEvery(constants.getAllTaskListRequestActionType, asyncGetAllTaskList),
    takeEvery(constants.deleteSubTaskRequestActionType, asyncDeleteSubTask),
    takeEvery(
      constants.saveSprintTaskOrderRequestActionType,
      asyncSaveSprintTask
    ),
    takeEvery(
      constants.saveAttachmentFileRequestActionType,
      asyncSaveAttachmentFile
    ),
    takeEvery(
      constants.getAttachmentFileRequestActionType,
      asyncGetAttachmentFile
    ),
    takeEvery(
      constants.deleteAttachmentFileRequestActionType,
      asyncDeleteAttachmentFile
    ),
    takeEvery(
      constants.saveTaskTimerStartRequestActionType,
      asyncSaveTaskTimerStart
    ),
    takeEvery(constants.endTaskTimerStartRequestActionType, asyncTaskTimerEnd),
    takeEvery(
      constants.runningTaskTimerRequestActionType,
      asyncRunningTaskTimer
    ),
    takeEvery(constants.taskTimeTrackerRequestActionType, asyncTaskTimeTracker),
    takeEvery(
      constants.getTaskTimeTrackerRequestActionType,
      asyncGetTaskTimeTracker
    ),
  ]);
}
