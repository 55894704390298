import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormInputField from '../../../formInputField';
import { saveProjectRequest } from '../../../../store/project/actions';
import {
  resetArrayState,
  resetState,
} from '../../../../store/project/projectSlice';

const ProjectModal = ({
  showModal,
  setShowModal,
  dispatchSaveProjectRequest,
  dispatchResetStateRequest,
  dispatchResetStatusRequest,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    };
  }, []);

  const handleDataSubmit = (data) => {
    const finalData = {
      name: data.name,
    };
    dispatchSaveProjectRequest(finalData);
    setShowModal(false);
    dispatchResetStateRequest();
    dispatchResetStatusRequest();
  };

  return (
    <div className={`modal ${showModal ? 'show' : 'hide'}`}>
      <div className="modal-dialog modal-lg modal-dialog-centered max-w-[36rem]">
        <div className="modal-content">
          <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
            <h5 className="text-xl font-medium leading-normal text-gray-800">
              Create Project
            </h5>

            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="btn-close box-content"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className={'modal-body relative p-5'}>
            <form key={1} onSubmit={handleSubmit(handleDataSubmit)}>
              <div className="w-full">
                <FormInputField
                  label={''}
                  type={'text'}
                  fieldName={'name'}
                  register={register}
                  errors={errors}
                  isRequired={true}
                  placeholder={'Enter project name'}
                />
              </div>
              <div className="ml-4 mt-3">
                <button className="border rounded-lg mt-0 btn-primary w-fit mr-0 ml-auto">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSaveProjectRequest: saveProjectRequest,
  dispatchResetStateRequest: resetState,
  dispatchResetStatusRequest: resetArrayState,
};
export default connect(null, mapDispatchToProps)(ProjectModal);
