import React, { useEffect,  } from 'react';
import { connect } from 'react-redux';

const DeleteModal = ({
  DeleteShowModal,
  setDeleteShowModal,
  data,
  handleDelete,
}) => {
  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    const modalDialog = document.getElementById(
      'modal-dialog modal-lg modal-dialog-centered'
    );
    if (modalDialog) {
      modalDialog.appendChild(backdrop);
    }
    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
      const modalOverlay = document.getElementsByClassName('modal-overlay');
      if (modalOverlay.length) {
        for (let i = 0; i < modalOverlay.length; i++) {
          modalOverlay[i].remove();
        }
      }
    };
  }, []);

  return (
    <div className={`modal ${DeleteShowModal ? 'show' : 'hide'}`}>
      <div className="modal-dialog modal-lg modal-dialog-centered max-w-4xl w-[38rem]">
        <div className="modal-content">
          <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
            {data.name !== 'Discard' && (
              <>
                <h5 className="text-xl font-medium leading-normal text-gray-800">
                  Delete {data.name}
                </h5>
                <button
                  onClick={() => setDeleteShowModal(false)}
                  type="button"
                  className="btn-close box-content"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </>
            )}
          </div>
          <div className="modal-body relative p-5">
            <div className="text-center">
              <p>
                {data.name === 'Discard'
                  ? 'You have unsaved changes. If you leave the screen now, your changes will be lost. To prevent any data loss, please save your work before leaving.'
                  : 'Are you sure you want to delete?'}
              </p>
            </div>
          </div>

          <div className="flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
            <button
              onClick={() => setDeleteShowModal(false)}
              type="button"
              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn btn-cancel"
              data-bs-dismiss="modal"
            >
              {data.name === 'Discard' ? 'Stay' : 'Cancel'}
            </button>
            <button
              onClick={() => handleDelete()}
              type="button"
              className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0 mr-3"
            >
              {data.name === 'Discard' ? 'Leave Anyways' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  // dispatching
};
export default connect(null, mapDispatchToProps)(DeleteModal);
