import React from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import CustomAvatar from '../customAvatar';
import Avatar from 'react-avatar';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllTaskList } from '../../../store/task/action';
import Loader from '../../loader';

const GlobalSearchModal = ({ searchText, dispatchGetAllTaskListRequest }) => {
  const { allTaskList, searchLoading } = useSelector((state) => state.task);
  const { projectId } = useSelector((state) => state.project);
  const [isMessage, setIsMessage] = useState(false);
  const navigate = useNavigate();
  const allTaskPreview = () => {
    navigate('/task-list');
  };

  useEffect(() => {
    if (searchLoading) {
      if (allTaskList && allTaskList.length) {
        setIsMessage(false);
      } else {
        setIsMessage(true);
      }
    }
  }, [searchLoading]);

  useEffect(() => {
    if (searchText) {
      const timeOutId = setTimeout(
        () =>
          dispatchGetAllTaskListRequest({
            payload: {
              pageNumber: 1,
              pageSize: 10,
              SortByAsc: true,
              keyword: searchText,
            },
            projectId: projectId,
          }),
        1000
      );
      return () => clearTimeout(timeOutId);
    }
  }, [searchText]);

  const onClicktoView = ({ taskId }) => {
    navigate(`/task/${taskId}`);
  };

  return (
    <>
      <div
        className="dropdown-menu dropdown-menu-ul w-96 p-0"
        aria-labelledby="global-search"
      >
        {searchText && (
          <div>
            {searchLoading ? (
              <Loader />
            ) : allTaskList && allTaskList.length ? (
              allTaskList.map((item, idx) => (
                <div className="notification-max-h overflow-y-auto overflow-x-hidden" key={idx}>
                  <div
                    key={idx}
                    className={'border-b-2 p-4 cursor-pointer'}
                    onClick={() =>
                      onClicktoView({
                        taskId: item.id,
                      })
                    }
                  >
                    <div className="flex relative">
                      <div className="user absolute top-1">
                        {item.assignedTo ? (
                          <CustomAvatar id={item.assignedTo} idPrefix={item.id} />
                        ) : (
                          <div className="user">
                            <div className="avatar">
                              <Avatar
                                round={true}
                                title="Unassigned"
                                name="Un Assigned"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="ml-10">
                        <div className="md:flex items-center">
                          <p className="mt-1 ml-2">{item.title}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                {isMessage && (
                  <div className="mt-5">
                    <h6 className="text-2xl font-bold text-black dark:text-[#ccc] mb-1 text-center">
                      No matching record found!
                    </h6>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {!!allTaskList.length && allTaskList.length >= 6 && searchText && (
          <div className="bg-white w-full text-center p-2 border-t-2">
            <button
              className="bg-red-600 hover:bg-red-700 !text-white font-semibold py-2 px-32 border rounded-full shadow"
              onClick={() => allTaskPreview()}
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetAllTaskListRequest: getAllTaskList,
};
export default connect(null, mapDispatchToProps)(GlobalSearchModal);
