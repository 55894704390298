import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import './asset/style.scss';
import MainLayoutRoutes from './components/layout/MainLayoutRoutes';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { removeItem } from './utilities/utils';
import 'react-toastify/dist/ReactToastify.css';
import TimeAgo from 'javascript-time-ago';
import 'react-tooltip/dist/react-tooltip.css';
import en from 'javascript-time-ago/locale/en.json';
import ForgotPassword from './components/forgotPassword';
import ChangePassword from './components/forgotPassword/ChangePassword';
import Register from './components/home';
import Login from './components/admin/login';
import AxiosInterceptorNavigate from './utilities/api.service';
import { deleteTaskRequestSuccess, sendTaskRequestSuccess, updateTaskRequestSuccess } from './store/task/taskSlice';
import store from './store/store';
TimeAgo.addDefaultLocale(en);

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isCallbackLink } = useSelector((state) => state.login);
  useEffect(() => {
    if (isCallbackLink) {
      removeItem('token');
      navigate('/login', { state: { from: location.pathname } });
    }
  }, [isCallbackLink]);
  useEffect(() => {
    // Create a WebSocket connection
    const socket = new WebSocket('wss://task-api.ensuesoft.com/ws');

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      switch (data.Type) {
      case 'TaskDeleted':store.dispatch(deleteTaskRequestSuccess({data:data.Data,message:'Record archieved'}));
        break;
      case 'TaskAdded':store.dispatch(sendTaskRequestSuccess('Task is added!'));
        break;
      case 'TaskUpdated':store.dispatch(updateTaskRequestSuccess({data: data.Data,message:'Record updated',statusCode:200}))
        break;
      default:
        break;
      }
    };
    
    // Clean up the WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
  })
  return (
    <div>
      {<AxiosInterceptorNavigate />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/register" element={<Register />}></Route>
        <Route path="*" element={<MainLayoutRoutes />} />
      </Routes>
    </div>
  );
}

export default App;
