import React from 'react';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';

const FormSelector = ({
  label,
  fieldName,
  register,
  errors,
  isRequired,
  data,
  options,
  handleChange,
}) => {
  return (
    <div className={`relative ${label ? 'with-label' : ''}`}>
      <label className="block text-sm font-medium mb-1">
        {label} {isRequired ? <span className="text-red-500">*</span> : ''}
      </label>
      <div className="relative">
        <div className="arrow-down">
          <i className="fas fa-chevron-down"></i>
        </div>
        <select
          className="border border-gray-300 input-style"
          id={fieldName}
          {...register(fieldName, {
            required: isRequired,
            onChange: (e) => handleChange && handleChange(e),
          })}
        >
          {data && data.length ? (
            data.map((category, index) => {
              return (
                <option key={index} value={category.id}>
                  {category[`${options}`]}
                </option>
              );
            })
          ) : (
            <></>
          )}
        </select>
      </div>
      {errors[fieldName]?.type === 'required' && (
        <FormFieldError message={AppConstants.requiredField} />
      )}
    </div>
  );
};

export default FormSelector;
