import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DeleteSprintRequest,
  activeSprintRequest,
  completedSprintRequest,
  getSprintRequest,
  getSprintTaskRequest,
  removeSprintTaskRequest,
  saveSprintTaskRequest,
  updateSprintRequest,
} from '../../../store/sprint/action';
import { useSelector } from 'react-redux';
import { resetState } from '../../../store/sprint/sprintSlice';
import { getFormattedDate, successMsg } from '../../../utilities/utils';
import ToastMessage from '../../ToastContainer';
import Loader from '../../loader';
import { resetStateRedirection } from '../../../store/common/commonSlice';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import CreateSprintModal from './modal/CreateSprintModal';
import AddBackModal from './modal/AddBackModal';
import SprintComponent from './components/SprintComponent';
import { getAllTrackingStatus } from '../../../store/project/actions';
import ConfirmModal from '../../common/confirmModal';
import { useForm } from 'react-hook-form';

const Sprint = ({
  dispatchGetSprintRequest,
  dispatchResetStateRequest,
  dispatchGetSprintTaskRequest,
  dispatchRemoveSprintTaskRequest,
  dispatchIsCompletedSprintRequest,
  dispatchActiveSprintRequest,
  dispatchDeleteSprintRequest,
  dispatchSaveSprintTaskRequest,
  dispatchGetAllTrackingStatusRequest,
  dispatchUpdateSprintRequest,
}) => {
  const { projectId } = useSelector((state) => state.project);
  const { projectSettings } = useSelector((state) => state.permissions);
  const {
    isSprintAdded,
    isSprintTaskAdded,
    isSprintTaskRemoved,
    isSprintCompleted,
    isActivatedSprint,
    isDeletedSprint,
    isSprintUpdated,
    message,
    allSprint,
    loading,
    allSprintTask,
  } = useSelector((state) => state.sprint);
  const [showModal, setShowModal] = useState(false);
  const [showBacklog, setShowBacklog] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);
  const [sprintInfo, setSprintInfo] = useState();
  const [sprintName, setSprintName] = useState();
  const [singleSprint, setSingleSprint] = useState();
  const [removeToSprintTask, setRemoveToSprintTask] = useState([]);
  const [sprintId, setSprintId] = useState();
  const [allTaskSprint, setAllTaskSprint] = useState([]);
  const [showEditMode, setShowEditMode] = useState(false);
  const [isEditShow, setIsEditShow] = useState(false);
  const [completeSprintShowModal, setCompleteSprintShowModal] = useState(false);
  const [isCompletedSprint, setIsCompletedSprint] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const selectProjectRef = useRef(null);

  const {
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (projectSettings && Object.keys(projectSettings).length) {
      if (!projectSettings.canAccessSprint) {
        navigate('/board');
      }
    }
  }, [projectSettings]);

  useEffect(() => {
    if (!!allSprint && !!allSprint.length) {
      let sprintObj = allSprint.filter((item) => item.isActiveSprint === true);
      if (sprintObj.length) {
        setSprintInfo({ name: sprintObj[0].name, id: sprintObj[0].id });
        setSprintName(sprintObj[0].name);
        setSprintId(sprintObj[0].id);
        setSingleSprint(sprintObj[0]);
        if (sprintObj[0].isCompleted) {
          setShowEditMode(false);
        } else {
          setShowEditMode(true);
        }
      } else {
        setSprintInfo({ name: allSprint[0].name, id: allSprint[0].id });
        setSprintName(allSprint[0].name);
        setSprintId(allSprint[0].id);
        setSingleSprint(allSprint[0]);
      }
    } else {
      setSprintInfo({});
      setSprintName(null);
      setSingleSprint(null);
    }
  }, [allSprint]);

  useEffect(() => {
    if (projectId) {
      if (projectSettings?.canAccessSprint) {
        dispatchGetSprintRequest(projectId);
      }
      dispatchGetAllTrackingStatusRequest(projectId);
    }
  }, [projectSettings]);

  useEffect(() => {
    if (isSprintUpdated) {
      dispatchGetSprintRequest(projectId);
      setIsEditShow(false);
    }
  }, [isSprintUpdated]);

  useEffect(() => {
    if (sprintId) {
      dispatchGetSprintTaskRequest({
        projectId: projectId,
        sprintId: sprintId,
      });
      setRemoveToSprintTask([]);
    }
  }, [sprintId]);

  useEffect(() => {
    if (!!allSprint && !!allSprint.length && sprintId) {
      let completedSprint = allSprint.filter((item) => item.id === sprintId);

      if (completedSprint && completedSprint[0].isComplete === true) {
        setIsCompletedSprint(true);
      }
    }
  }, [sprintId]);

  useEffect(() => {
    if (!!allSprintTask && !!allSprintTask.length && !!allSprint.length) {
      setAllTaskSprint(allSprintTask);
    } else {
      setAllTaskSprint([]);
    }
    setRemoveToSprintTask([]);
  }, [allSprintTask, allSprint]);

  useEffect(() => {
    if (
      isSprintAdded ||
      isSprintTaskAdded ||
      isSprintTaskRemoved ||
      isSprintCompleted ||
      isActivatedSprint ||
      isDeletedSprint ||
      isSprintUpdated
    ) {
      successMsg(message);
    }
    dispatchResetStateRequest();
    setRemoveToSprintTask([]);
  }, [
    isSprintAdded,
    isSprintTaskAdded,
    isSprintTaskRemoved,
    isSprintCompleted,
    isActivatedSprint,
    isDeletedSprint,
    isSprintUpdated,
  ]);
  useEffect(() => {
    dispatchResetStateRequest();
  }, [projectId]);
  useEffect(() => {
    if (isSprintCompleted || isActivatedSprint) {
      dispatchGetSprintRequest(projectId);
    }
  }, [isSprintCompleted, isActivatedSprint]);

  useEffect(() => {
    if (isSprintTaskRemoved || isSprintTaskAdded || isActivatedSprint) {
      dispatchGetSprintTaskRequest({
        projectId: projectId,
        sprintId: sprintId,
      });
    }
  }, [isSprintTaskRemoved, isSprintTaskAdded, isActivatedSprint]);

  const handleClick = (data) => {
    const findSprint = allSprint.find((item) => item.id === data.id);
    if (findSprint.isComplete) {
      setShowEditMode(false);
    } else {
      setShowEditMode(true);
    }
    setIsCompletedSprint(false);
    setSprintInfo({ name: data.name, id: data.id });
    setSprintName(data.name);
    setSingleSprint(findSprint);
    setSprintId(data.id);
    handleOnBlur();
  };

  const handleOnBlur = () => {
    setShowDropdown(true);
  };

  const handleSprint = () => {
    setShowModal(true);
  };

  const handleRemoveCheckBox = (e, taskId) => {
    if (e.target.checked) {
      setRemoveToSprintTask([...removeToSprintTask, taskId]);
    } else {
      setRemoveToSprintTask(
        removeToSprintTask.filter((item) => item !== taskId)
      );
    }
  };
  const handleRemoveTaskSprint = () => {
    if (removeToSprintTask.length !== 0) {
      const finalPayload = {
        sprintId: sprintInfo.id,
        taskId: removeToSprintTask,
      };
      dispatchRemoveSprintTaskRequest(finalPayload);
    }
  };

  const handleIsCompleted = (targetSprintId) => {
    if (targetSprintId) {
      const finalPayload = {
        id: sprintId,
        projectId: projectId,
        nextSprintId: targetSprintId,
        isCompleted: true,
      };
      dispatchIsCompletedSprintRequest(finalPayload);
    }
    setCompleteSprintShowModal(false);
  };

  const handleActiveSprint = (id) => {
    const finalPayload = { id, isActiveSprint: true, projectId };
    dispatchActiveSprintRequest(finalPayload);
  };

  const handleBacklog = () => {
    setShowBacklog(true);
  };

  const handleDeleteSprint = (id) => {
    dispatchDeleteSprintRequest(id);
  };

  const closeSelectProjectDropdown = (e) => {
    if (
      selectProjectRef.current &&
      !showDropdown &&
      !selectProjectRef.current.contains(e.target)
    ) {
      handleOnBlur();
    }
  };
  document.addEventListener('mousedown', (e) => closeSelectProjectDropdown(e));

  const handleViewTask = (taskId) => {
    navigate(`/task/${taskId}`);
  };

  const handleAddTaskToSprint = (sprintId) => {
    if (removeToSprintTask.length !== 0) {
      const finalPayload = { sprintId, taskId: removeToSprintTask };
      dispatchSaveSprintTaskRequest(finalPayload);
    }
  };

  const handleEditSprint = () => {
    const payload = {
      sprintId: sprintId,
      data: [
        {
          path: '/name',
          op: 'replace',
          value: sprintName,
        },
      ],
    };
    dispatchUpdateSprintRequest(payload);
  };

  const handleCompletedSprint = (sprintId) => {
    setCompleteSprintShowModal(true);
    setSprintId(sprintId);
  };
  const handleAllCheckbox = (e) => {
    if (e.target.checked) {
      const idList = allTaskSprint.map((item) => item.taskId);
      setRemoveToSprintTask(idList);
    } else {
      setRemoveToSprintTask([]);
    }
  };
  return (
    <>
      <Loader isVisible={loading} />
      <ToastMessage />
      <div>
        <div className="relative h-calc overflow-y-auto mt-5 list-view px-4 py">
          <div className="flex justify-between border-b-2 pb-3 mt-3 ">
            {isEditShow ? (
              <form onSubmit={handleSubmit(handleEditSprint)}>
                <div className="flex">
                  <input
                    className="input-style"
                    type="text"
                    ref={inputRef}
                    value={sprintName}
                    onChange={(e) => setSprintName(e.target.value)}
                  />

                  <div className="mt-2 ml-3 flex justify-center">
                    <span
                      className="cursor-pointer text-green-600"
                      onClick={handleSubmit((d) => handleEditSprint(d))}
                    >
                      <i className="fa-solid fa-check"></i>
                    </span>
                    <span
                      className="cursor-pointer ml-3 text-red-500"
                      onClick={() => setIsEditShow(false)}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>
                </div>
              </form>
            ) : (
              <div ref={selectProjectRef} className="flex align-middle">
                {!!allSprint && !!allSprint.length && (
                  <>
                    <div className="relative min-w-auto max-w-auto">
                      <div
                        className="bg-white flex items-center rounded-md"
                        onClick={() => setShowDropdown(!showDropdown)}
                      >
                        <input
                          type="text"
                          value={sprintInfo?.name}
                          name="select"
                          id="select"
                          className="w-full text-2xl cursor-pointer appearance-none truncate text-[14px] px-2 caret-transparent hover:bg-gray-50 rounded-[4px]"
                        />
                      </div>
                      <input
                        type="checkbox"
                        name="show_more"
                        id="show_more"
                        className="hidden peer bg-red-400"
                      />
                      <div
                        className={`absolute rounded shadow bg-white max-h-[400px] overflow-y-auto z-[9] text-[14px] sprint-dropdown ${
                          showDropdown ? 'hidden' : 'peer-checked:flex'
                        } flex-col w-full mt-1 border border-gray-200`}
                      >
                        {!!allSprint &&
                          allSprint.map((item, idx) => {
                            return (
                              <div
                                className="cursor-pointer group border-t"
                                key={idx}
                                onClick={() =>
                                  handleClick({
                                    id: item.id,
                                    name: item.name,
                                  })
                                }
                              >
                                <p
                                  className={'block p-2 border-transparent border-l-4 group-hover:border-[#e32135]} group-hover:bg-gray-100'}
                                >
                                  {item.name}
                                  <span className="ml-2">
                                    {item.isComplete ? (
                                      <label className="ux-design mt-1.5 bg-[#3e8656] text-white rounded-2xl">
                                        Complete
                                      </label>
                                    ) : item.isActiveSprint ? (
                                      <label className="bg-[#e2efff] ux-design mt-1.5 rounded-2xl">
                                        Active
                                      </label>
                                    ) : (
                                      <label className="bg-[#F06D1F] ux-design mt-1.5 text-white rounded-2xl">
                                        New
                                      </label>
                                    )}
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {showEditMode && (
                      <span
                        className="cursor-pointer"
                        onClick={() => setIsEditShow(true)}
                      >
                        {' '}
                        <i className="fa-solid fa-pen-to-square mt-2"></i>
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
            <div>
              <button
                className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border rounded mr-4"
                onClick={() => handleSprint()}
              >
                Create Sprint
              </button>
            </div>
          </div>
          {/* sprint dates */}
          <div className={`my-5 ${singleSprint ? 'block' : 'hidden'}`}>
            {/* acco */}

            <div>
              <div className="py-4 flex justify-between">
                {singleSprint && singleSprint.length !== 0 && (
                  <>
                    <div className="flex justify-around">
                      <h1 className="font-semibold mr-1">Sprint </h1>
                      <div className="flex justify-between">
                        <div>
                          <div className="flex justify-start">
                            <p>
                              <b>Start Date : </b>
                              {getFormattedDate(singleSprint.startDate)}
                            </p>
                            <p className="ml-8">
                              <b>End Date: </b>
                              {getFormattedDate(singleSprint.endDate)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="ml-8">
                        {singleSprint.isComplete && singleSprint.isComplete
                          ? ''
                          : singleSprint.isActiveSprint && (
                            <label className="bg-[#e2efff] ux-design mt-1.5 rounded-2xl">
                                Active
                            </label>
                          )}
                        {singleSprint.isComplete && (
                          <label
                            className={`ux-design bg-[#3e8656] text-white rounded-2xl ${
                              singleSprint.isActivatedSprint ? 'ml-8' : ''
                            }`}
                          >
                            Completed
                          </label>
                        )}
                      </div>
                      <div className="ml-5">
                        {singleSprint.isActiveSprint ? (
                          <button
                            className="bg-red-500 hover:bg-red-400 text-white font-bold px-2 border rounded mr-4 text-sm"
                            onClick={() =>
                              handleCompletedSprint(singleSprint?.id)
                            }
                          >
                            <small>Complete Sprint</small>
                          </button>
                        ) : singleSprint.isComplete ? (
                          ''
                        ) : (
                          <button
                            className="bg-red-500 hover:bg-red-400 text-white font-bold px-2 border rounded mr-4 text-sm"
                            onClick={() => handleActiveSprint(singleSprint.id)}
                          >
                            <small>Activate Sprint</small>
                          </button>
                        )}
                      </div>
                    </div>
                    {!singleSprint.isComplete && (
                      <div className="dropdown inline-block relative">
                        <img
                          src="../../assets/img/dots-vertical.svg"
                          alt=""
                          className="w-7 h-7 rotate-90 m-0 cursor-pointer"
                        />

                        <ul
                          id="dropdown-menu"
                          className="dropdown-menu absolute hidden pt-1 bg-white shadow rounded py-2 px-4 mb-2 whitespace-nowrap right-0 left-align"
                        >
                          {!singleSprint.isActiveSprint &&
                            !singleSprint.isComplete && (
                            <li className="text-red-500 mb-2">
                              <button
                                type="button"
                                onClick={() =>
                                  handleDeleteSprint(singleSprint.id)
                                }
                              >
                                <i
                                  className="fa fa-trash mr-2 cursor-pointer w-[18px]"
                                  aria-hidden="true"
                                ></i>
                                  Delete
                              </button>
                            </li>
                          )}
                          <li className="text-red-500">
                            <button
                              type="button"
                              onClick={() => handleBacklog()}
                            >
                              <i
                                className="fa-solid fa-circle-plus mr-2 cursor-pointer w-[18px]"
                                aria-hidden="true"
                              ></i>
                              Add Task
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                )}
                {/* acco */}

                {/* till-here */}
              </div>
            </div>
            {/* till here */}
            <div>
              <div className="rounded-none border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                {!!allSprintTask && !!allTaskSprint.length ? (
                  <SprintComponent
                    allTaskSprint={allTaskSprint}
                    removeToSprintTask={removeToSprintTask}
                    handleRemoveTaskSprint={handleRemoveTaskSprint}
                    handleViewTask={handleViewTask}
                    handleRemoveCheckBox={handleRemoveCheckBox}
                    handleAddTaskToSprint={handleAddTaskToSprint}
                    isCompletedSprint={isCompletedSprint}
                    sprintId={sprintId}
                    handleAllCheckbox={handleAllCheckbox}
                  />
                ) : (
                  <div className="text-center p-5">
                    <i className="fas fa-bell-slash"></i>
                    <h2 className="font-bold mt-2">
                      No task available in current sprint!
                    </h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <CreateSprintModal
            showModal={showModal}
            setShowModal={(val) => setShowModal(val)}
          />
        )}
        {showBacklog && (
          <AddBackModal
            showModal={showBacklog}
            setShowModal={(val) => setShowBacklog(val)}
            sprintId={sprintId}
          />
        )}
        {completeSprintShowModal && (
          <ConfirmModal
            completeSprintShowModal={completeSprintShowModal}
            setCompleteSprintShowModal={(val) =>
              setCompleteSprintShowModal(val)
            }
            handleConfirmSprint={(sprintId)=>handleIsCompleted(sprintId)}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetSprintRequest: getSprintRequest,
  dispatchUpdateSprintRequest: updateSprintRequest,
  dispatchResetStateRequest: resetState,
  dispatchGetSprintTaskRequest: getSprintTaskRequest,
  dispatchRemoveSprintTaskRequest: removeSprintTaskRequest,
  dispatchIsCompletedSprintRequest: completedSprintRequest,
  dispatchActiveSprintRequest: activeSprintRequest,
  dispatchRedirectionState: resetStateRedirection,
  dispatchDeleteSprintRequest: DeleteSprintRequest,
  dispatchSaveSprintTaskRequest: saveSprintTaskRequest,
  dispatchGetAllTrackingStatusRequest: getAllTrackingStatus,
};
export default connect(null, mapDispatchToProps)(Sprint);
