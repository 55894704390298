import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { htmlStringToEditorState } from './Helper';
import { connect } from 'react-redux';
import { saveEmailEnableAndDisableRequest } from '../../../../store/emailSettings/action';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from '../../../loader';

const defaultOfferText = `Dear <strong><span id='name'>Name</span>,</strong>
<p><span id='whoToContact'>Who To Contact</span> is pleased to offer you the Full-Time position of <span id='jobTitle'>Job Title</span> The starting salary offered for this position is <span id='payRateAndPeriod'>Pay Rate and period</span>, paid <span id='payFrequency'>Pay Frequency</span>. Your start date with Garrett Harris DEMO is on <span id='sentOn'> Start Date </span>.This offer will expire at 5:00pm on <span id='expireOn'>Offer Expiration Date</span></p>
   `;

const EmailTemplate = ({ dispatchSaveEmailEnableAndDisableRequest }) => {
  const { loading } = useSelector((state) => state.project);
  const [editorState, setEditorState] = useState(
    htmlStringToEditorState(defaultOfferText, true)
  );
  const [emailSub, setEmailSub] = useState(false);
  const { projectId, singleProject } = useSelector((state) => state.project);

  const onEditorStateChange = (changedEditorState) => {
    setEditorState(changedEditorState);
  };
  useEffect(() => {
    if (singleProject && Object.keys(singleProject).length) {
      setEmailSub(singleProject.emailSubscription);
    }
  }, [singleProject]);

  const onChangeToggle = (e) => {
    setEmailSub(e.target.checked);
    const finalPayload = {
      emailSubscription: e.target.checked,
      projectId: projectId,
    };
    dispatchSaveEmailEnableAndDisableRequest(finalPayload);
  };
  return (
    <>
      <Loader isVisible={loading} />
      <div>
        <div className="flex items-center">
          <span className="mr-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            Email Alerts
          </span>
          <label className="relative inline-flex items-center mr-5 cursor-pointer">
            <input
              type="checkbox"
              checked={emailSub}
              className="sr-only peer checkbox-style"
              onChange={(e) => onChangeToggle(e)}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
          </label>
        </div>
        <div className="mt-5">
          <ul
            className="nav nav-tabs flex overflow-auto list-none"
            id="tabs-tab"
            role="tablist"
          >
            <li className="nav-item whitespace-nowrap" role="presentation">
              <a
                href="#tabs"
                className={'nav-link active'}
                id="tabs-template-1"
                data-bs-toggle="pill"
                data-bs-target="#tabs-template1"
                role="tab"
                aria-controls="tabs-template1"
                aria-selected="true"
              >
                Template 1
              </a>
            </li>
            <li className="nav-item whitespace-nowrap" role="presentation">
              <a
                href="#tabs-template-2"
                className={'nav-link'}
                id="tabs-template-2"
                data-bs-toggle="pill"
                data-bs-target="#tabs-template2"
                role="tab"
                aria-controls="tabs-template2"
                aria-selected="false"
              >
                Template 2
              </a>
            </li>
            <li className="nav-item whitespace-nowrap" role="presentation">
              <a
                href="#tabs-template-3"
                className="nav-link"
                id="tabs-template-3"
                data-bs-toggle="pill"
                data-bs-target="#tabs-template3"
                role="tab"
                aria-controls="tabs-template3"
                aria-selected="false"
              >
                Template 3
              </a>
            </li>
          </ul>
        </div>
        <div
          className="tab-content border border-gray-300 px-5 pt-5 pb-3 h-[400px] overflow-auto"
          id="tabs-tabContent"
        >
          <div
            className={'tab-pane fade show active'}
            id="tabs-template-1"
            role="tabpanel"
            aria-labelledby="tabs-template1"
          >
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            />
            ;
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = {
  // dispatching
  dispatchSaveEmailEnableAndDisableRequest: saveEmailEnableAndDisableRequest,
};
export default connect(null, mapDispatchToProps)(EmailTemplate);
