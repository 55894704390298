import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: true,
  success: false,
  error: true,
  message: '',
  taskReport: [],
  trackingStatusData: [],
  isLoading: true,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getTaskReportRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          taskReport: action.payload.data,
          loading: false,
        };
      }
      return state;
    },
    getTaskReportRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    },

    // Get Single Tracking Status Data
    getSingleTrackingStatusDataRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          trackingStatusData: action.payload.data,
          isLoading: false,
        };
      }
      return state;
    },
    getSingleTrackingStatusDataRequestFailure: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },

    // reset state
    resetStateDashboard: (state) => {
      return {
        ...state,
        message: '',
        isLoading: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getTaskReportRequestSuccess,
  getTaskReportRequestFailure,
  getSingleTrackingStatusDataRequestSuccess,
  getSingleTrackingStatusDataRequestFailure,
  resetStateDashboard,
  resetArrayState,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
