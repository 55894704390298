import moment from 'moment';
import { toast } from 'react-toastify';
import { URLS } from './endPoints';
import { rootUrl } from './api.service';
import store from '../store/store';
import { saveProjectId } from '../store/project/projectSlice';

export const getFormattedDate = (date) => {
  const formattedDate = new Date(date);
  return formattedDate.toLocaleDateString('en-GB');
};
export const getInputDateFormat = (date) => {
  const formatted_date = moment(getFormattedDate(date), 'DD/MM/YYYY').format(
    'YYYY-MM-DD'
  );
  return formatted_date;
};

export const getItem = (itemname) => {
  return localStorage.getItem(itemname);
};

export const removeItem = (itemname) => {
  localStorage.removeItem(itemname);
};

export const setItem = (itemname, item) => {
  localStorage.setItem(itemname, item);
};

export const successMsg = (message) => {
  toast.success(message);
};
export const errorMsg = (message, isAccessMessage) => {
  toast.error(isAccessMessage ? `You have no access to ${message}` : message);
};
export const warningMsg = (message) => {
  toast.warning(message);
};
export const infoMsg = (message) => {
  toast.info(message);
};
export const deleteCookies = () => {
  const allCookies = document.cookie.split(';');

  for (let i = 0; i < allCookies.length; i++)
    document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString();
};

export const compareDate = (currentDate, oldDate) => {
  if (currentDate.getTime() < oldDate.getTime()) return true;
  else if (currentDate.getTime() > oldDate.getTime()) return false;
};

export const getCookieData = (cookieName) => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1];
};

export const ItemTypes = {
  CARD: 'card',
};

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}

export const toolbar = {
  container: [
    [{ header: '1' }, { header: [2, 3, 4, 5, 6] }, { font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],

    ['link', 'image'],
    ['clean'],
    ['code-block'],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ],
};

export function imageUpload(image) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', image);
    fetch(`${rootUrl}/${URLS.SEND_UPLOAD_IMAGE.url}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        resolve({ attachmentName: image.name, attachmentUrl: result.data });
      })
      .catch((error) => {
        reject('Upload failed');
        console.error('Error:', error);
      });
  });
}

export function convertTime(serverdate) {
  var utcDate = serverdate;
  var localDate = moment.utc(utcDate).zone('+05:30');
  return localDate;
}

export function getNextMondayDate(date) {
  var d = new Date(date.getTime());
  var diff = d.getDate() - d.getDay() + 1;
  if (d.getDay() === 0) {
    diff -= 7;
  } else {
    diff += 7;
  }
  return getInputDateFormat(new Date(d.setDate(diff)));
}

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(' ');
}

export function convertHourMintues(min) {
  const hours = Math.floor(min / 60);
  const minutes = min % 60;
  return `${hours} h ${minutes} Min`.toString();
}

export function UnSavedChange(projectId, taskId, content, fieldName) {
  if (projectId) {
    const unsavedData = taskId? { projectId, id: taskId }:{projectId};
    unsavedData[fieldName] = content;
    if (JSON.parse(localStorage.getItem(`${taskId ? 'commentOnTask': 'unSavedTaskDataLocal'}`))) {
      let unSavedDataList = JSON.parse(
        localStorage.getItem(`${taskId ? 'commentOnTask': 'unSavedTaskDataLocal'}`)
      );
      const singleUnSavedData = unSavedDataList.find(
        (item) => taskId? item.id === taskId && item.projectId === projectId :item.projectId === projectId
      );
      if (singleUnSavedData) {
        singleUnSavedData[fieldName] = content;
      } else {
        unSavedDataList.push(unsavedData);
      }
      localStorage.setItem(`${taskId ? 'commentOnTask': 'unSavedTaskDataLocal'}`,JSON.stringify(unSavedDataList));
    } else {
      localStorage.setItem(`${taskId ? 'commentOnTask': 'unSavedTaskDataLocal'}`,JSON.stringify([unsavedData]));
    }
  }
}

export function countDigit(number) {
  let count = 0;
  while (number > 0) {
    number = Math.floor(number / 10);
    count++;
  }
  return count;
}

export function compareCurrentDate(date) {
  const now = new Date();
  const taskDate = new Date(date);
  if (taskDate < now) {
    return true;
  } else {
    return false;
  }
}

export function getFileType(filename) {
  const extension = filename.substr(0, filename.lastIndexOf('.'));
  return extension.toUpperCase();
}

export function convertUTCDateToLocalDate(date) {
  if(date)
  {
    var newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return newDate;
  }else{
    return null;
  }
}

export const addOverlayOnEditor = () => {
  const quillContainerElement =
    document.getElementsByClassName('ql-container');
  for (let i = 0; i < quillContainerElement.length; i++) {
    const overlayElement = document.createElement('div');
    overlayElement.setAttribute('id', 'editor-overlay');
    quillContainerElement[i].append(overlayElement);
  }
};

export const removeOverlayFromEditor = () => {
  const overlayElement = document.getElementById('editor-overlay');
  overlayElement.remove();
};

export const setProjectInfo = (id,dispatch)=>{ 
  if(id)
  {
    localStorage.setItem('selectedProjectId', id );
    sessionStorage.setItem('selectedProjectId', id); 
    dispatch(saveProjectId(id)) 
  }
}

export const getProjectInfo = (name)=>{
  const stores = store.getState();
  let id = null;
  if(stores.project.projectId){
    id= stores.project.projectId;
  }else if(JSON.parse(sessionStorage.getItem(name))) {
    id = parseInt(sessionStorage.getItem(name))
  }else if(JSON.parse(localStorage.getItem(name))) {
    id = parseInt(JSON.parse(localStorage.getItem(name)));
  }
 
  return id === null ? id : parseInt(id);
}