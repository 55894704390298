import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormFieldError from '../../formFieldError';
import * as AppConstants from '../../../utilities/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
  getLoginDetailsRequest,
  sendLoginRequest,
} from '../../../store/login/action';
import {
  removeCallbackLinkAction,
  resetStateRequest,
} from '../../../store/login/loginSlice';
import ToastMessage from '../../ToastContainer';
import { resetArrayState } from '../../../store/project/projectSlice';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';

const Login = ({
  dispatchSendLoginRequest,
  dispatchResetLoginStateRequest,
  dispatchLoginWithGoogle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from, search } = location.state || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   removeItem("token");
  //   removeItem("userData");
  //   removeItem("refreshToken");
  // }, []);

  const handleLogin = (data) => {
    document.getElementById('root')?.classList.add('login-page-content');
    dispatchSendLoginRequest(data);
  };
  const { isLoggedIn, isGoogleLogin} = useSelector(
    (state) => state.login
  );
  const [passValue, setPassValue] = useState({
    password: '',
    showPassword: false,
  });

  useEffect(() => {
    if (isLoggedIn || isGoogleLogin) {
      const pageUrl = '/board';
      if (from && search) {
        navigate(`${from}${search}`);
      } else {
        navigate(`${pageUrl}`);
      }
      dispatchResetLoginStateRequest();
    }
  }, [isLoggedIn, isGoogleLogin]);

  const responseMessage = (response) => {
    dispatchLoginWithGoogle({ idToken: response.credential });
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const handleClickShowPassword = () => {
    setPassValue({ ...passValue, showPassword: !passValue.showPassword });
  };

  return (
    <>
      <ToastMessage />

      <div
        className="form-wrapper h-screen"
        style={{
          backgroundImage: 'url("../../assets/img/login-page-bg.png")',
        }}
      >
        <div className="flex justify-center relative h-screen items-center">
          <div>
            <div>
              <img
                src="../../assets/img/ensuesoft.jpg"
                className="w-38 h-38 mx-auto object-contain"
              />

              <h2 className="text-2xl text-center mb-0 text-black dark:text-[#ccc]">
                Welcome!
              </h2>
              <h3 className="text-center mb-4 text-gray-600 dark:text-[#ccc]">
                Please sign-in to continue
              </h3>
            </div>
            <div className="px-8 pt-4 pb-4 bg-white dark:bg-[#283046] rounded-lg shadow-card w-96">
              <form onSubmit={handleSubmit(handleLogin)}>
                <div className="mt-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Email
                    </label>
                    <input
                      id="userName"
                      {...register('email', {
                        required: true,
                        maxLength: 200,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      })}
                      type="text"
                      className="input-style"
                    />
                    {errors.userName?.type === 'required' && (
                      <FormFieldError message={AppConstants.requiredField} />
                    )}
                    {errors.userName?.type === 'pattern' && (
                      <FormFieldError message={AppConstants.notValidEmail} />
                    )}
                    {errors.userName?.type === 'maxLength' && (
                      <FormFieldError message={AppConstants.maxLengthReached} />
                    )}
                  </div>
                  <div className="mt-4">
                    <div className="flex-items mb-1">
                      <label className="block text-sm font-medium">
                        Password
                      </label>
                      <Link
                        to="/forgot-password"
                        className="text-xs site-clr hover:underline"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <div className="relative">
                      <input
                        id="password"
                        {...register('password', { required: true })}
                        type={passValue.showPassword ? 'text' : 'password'}
                        className="input-style"
                      />
                      {errors.password?.type === 'required' && (
                        <FormFieldError message={AppConstants.requiredField} />
                      )}
                      <div
                        className={`fa-solid ${
                          passValue.showPassword ? 'fa-eye-slash' : 'fa-eye'
                        } absolute right-[10px] top-[11px]`}
                        onClick={handleClickShowPassword}
                      ></div>
                    </div>
                  </div>

                  <div className="form-check form-check-inline mt-2">
                    <input className="checkbox-style" type="checkbox" />
                    <label className="form-check-label inline-block">
                      Remember me
                    </label>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="px-6 py-2 mt-4 btn-primary ml-0"
                    >
                      Sign-in
                    </button>
                  </div>
                </div>
              </form>
              {/* Login with google */}
              <div className="mt-3">
                <GoogleOAuthProvider clientId="961644620937-07n0d959mcsm23rd92aga657stou7rp1.apps.googleusercontent.com">
                  <div className="flex justify-center">
                    <GoogleLogin
                      theme="outline"
                      type="icon"
                      shape="square"
                      // text="signin with google"
                      onSuccess={responseMessage}
                      onError={errorMessage}
                    />
                  </div>
                </GoogleOAuthProvider>
              </div>
              <h3 className="text-center mt-4 text-gray-600 dark:text-[#ccc]">
                Doesn't have an account?{' '}
                <Link
                  to="/register"
                  className="text-2xs site-clr hover:underline"
                >
                  Sign Up
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSendLoginRequest: sendLoginRequest,
  dispatchResetLoginStateRequest: resetStateRequest,
  dispatchRemoveCallbackLinkRequest: removeCallbackLinkAction,
  dispatchResetProjectStateRequest: resetArrayState,
  dispatchLoginWithGoogle: getLoginDetailsRequest,
};
export default connect(null, mapDispatchToProps)(Login);
