export const URLS = {
  //LOGIN
  LOGIN: { url: 'api/token' },
  REFRESH_TOKEN: { url: 'api/token/refresh-token' },
  USER_PROFILE_PIC_URL: { url: 'api/user' },
  // REGISTER
  REGISTER: { url: 'api/user' },
  // PROJECT
  GET_PROJECTS: { url: 'api/projects/paged-list' },
  SAVE_PROJECTS: { url: 'api/projects' },
  GET_PROJECT_INVITES: { url: 'api/projectinvite/accept' },
  GET_USER_INVITES_PROJECT: { url: 'api/projectinvite/paged-list' },
  UPDATE_PROJECT_REQUEST: { url: 'api/projects/update' },
  GET_ACCEPT_PROJECT_DETAILS: {
    url: 'api/projectinvite/info',
  },
  // Task
  SEND_TASK: { url: 'api/task' },
  UPDATE_TASK: { url: 'api/task/update' },
  GET_TASK: { url: 'api/task' },
  DELETE_TASK: { url: 'api/task/delete' },
  //Tracking Status
  TRACKING_STATUS: { url: 'api/trackingstatus' },
  // SPrint
  SRCUM: { url: 'api/task/board-data' },
  // KANBAN
  KANBAN: { url: 'api/task/board-tasks' },
  //Invites
  INVITES: { url: 'api/projectinvite/new' },
  // profilePic
  PROFILE_PIC: { url: 'api/user/profile-pic-upload' },
  PERSONAL_DETAILS: { url: 'api/user/update' },
  // Task Type
  SEND_TASK_TYPE: { url: 'api/tasktype' },
  GET_PROJECT_SCRUM: { url: 'api/projects/references' },
  UPDATE_TASK_TYPE: { url: 'api/tasktype/update' },
  // Task Priority
  SAVE_TASK_PRIORITY: { url: 'api/taskpriority' },
  GET_ALL_TASK_PRIORITY: { url: 'api/taskpriority/paged-list' },
  UPDATE_TASK_PRIORITY: { url: 'api/taskpriority/update' },
  // Tracking Status
  SAVE_TRACKING_STATUS: { url: 'api/trackingstatus' },
  GET_ALL_TRACKING_STATUS: { url: 'api/trackingstatus/paged-list' },
  UPDATE_TRACKING_STATUS: { url: 'api/trackingstatus/update' },
  // Tag
  SAVE_TAG: { url: 'api/tag' },
  GET_ALL_TAG: { url: 'api/tag/paged-list' },
  UPDATE_TAG: { url: 'api/tag/update' },
  // checklist
  ADD_CHECKLIST: { url: 'api/taskchecklist' },
  UPDATE_CHECKLIST: { url: 'api/taskchecklist/update' },
  // Task Comment
  ADD_TASK_COMMENT: { url: 'api/taskcomments' },
  UPDATE_TASK_COMMENT: { url: 'api/taskcomments/update' },
  // Task History
  GET_ALL_TASK_HISTORY: { url: 'api/taskhistory/paged-list' },
  GET_ALL_NOTIFICATION: { url: 'api/taskhistory/notifications' },
  // editor upload image
  SEND_UPLOAD_IMAGE: { url: 'api/user/uploadfile' },
  // get all checklist,comment and history
  TASK_TRACKING_SCRUM: { url: 'api/task/tasktracking' },
  // TIME SPEND
  SAVE_TIME_SPEND: { url: 'api/tasktime' },
  // Dashboard
  GET_DASHBOARD_DATA: { url: 'api/task/aggregates' },
  // SINGLE TRACKING STATUS DATA
  GET_SINGLE_STATUS_DATA: { url: 'api/task' },
  // Remove Project invitation
  SAVE_REMOVE_PROJECT: { url: 'api/projectinvite/remove' },
  // Project Role Change
  PROJECT_ROLE_CHANGE: { url: 'api/projectinvite/rolechange' },
  //Forgot Passwor
  FORGOT_PASSWORD: { url: 'api/user' },
  // SUB TASK
  SAVE_SUB_TASK: { url: 'api/taskrelation' },
  //SAVE ROLES & PERMISSIONS
  SAVE_PERMISSIONS: { url: 'api/roles' },
  GET_LABEL_SETTINGS: { url: 'api/projects/load' },
  // SPRINT
  GET_SPRINT: { url: 'api/sprint' },
  //TASK MOVE
  SAVE_TASK_NEW_SPRINT: { url: 'api/sprint/iscompleted' },
  //UPDATE SPRINT
  UPDATE_SPRINT: { url: 'api/sprint/update' },
  //SPRINT TASK
  GET_SPRINT_TASK: { url: 'api/sprinttask' },
  //SAVE NOTIFICATIONS
  SAVE_NOTIFICATIONS: { url: 'api/notification' },
  // GET NOTIFICATIONS
  GET_NOTIFICATIONS: { url: 'api/taskhistory/unreadcount' },
  // GET REPORTS
  GET_REPORTS: { url: 'api/taskhistory/report' },
  // CONNECT SLACK
  CONNECT_SLACK: { url: 'api/slack' },
  CONNECT_SAVE: { url: 'api/slack/access' },
  // SPRINT TASK AVAILABLE
  GET_BACKLOG_SPRINT_TASK: { url: 'api/sprinttask/sprinttaskavailable' },
  //GET ALL TASK WITH PAGINATION
  GET_ALL_TASK_LIST: { url: 'api/task/paged-list' },
  // GET FLOW STATUS LIST
  GET_FLOW_STATUS: { url: 'api/trackingstatus/flowstatus' },
  // TRACKING STATUS ORDER
  TRACKING_STATUS_ORDER: { url: 'api/task/orderindex' },
  // SPRINT TASK ORDER
  SPRINT_TASK_ORDER: { url: 'api/task/taskorder' },
  // ATTACHMENT FILE
  ATTACHMENT_FILE: { url: 'api/task/uploadfile' },
  //GET ATTACHMENT FILE
  GET_ATTACHMENT_FILE: { url: 'api/task/attachments' },
  // DELETE ATTACHMENT FILE
  DELETE_ATTACHMENT_FILE: { url: 'api/task/attachments/delete' },
  // DELETE USER
  DELETE_USER: { url: 'api/user/delete' },
  // SAVE TASK TIMER START
  SAVE_TASK_TIMER: { url: 'api/tasktimetracker/start' },
  // TASK TIMER END
  TASK_TIMER_END: { url: 'api/tasktimetracker/end' },
  // RUNNING TASK TIMER
  RUNNING_TASK_TIMER: { url: 'api/tasktimetracker/user/running' },
  //TASK TIME TRACKER
  TASK_TIME_TRACKER: { url: 'api/tasktimetracker' },
  // Email Template
  GET_EMAIL_TEMPLATE: { url: 'api/emailtemplates' },
  SAVE_EMAIL_TEMPLATE: { url: 'api/emailtemplates' },
  UPDATE_EMAIL_TEMPLATE: { url: 'api/emailtemplates/update/' },
  DELETE_EMAIL_TEMPLATE: { url: 'api/emailtemplates/delete/' },
  // GET PROJECT SETTINGS
  GET_PROJECT_SETTING: { url: 'api/task/basic-settings' },
  // SAVE EMAIL SETTINGS
  SAVE_EMAIL_SETTING: { url: 'api/projectusers/emailsubsc' },

  // MARK ALL AS READ
  MARK_ALL_AS_READ: { url: 'api/taskhistory/unreadnotifications' },
};
