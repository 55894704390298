import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import FormFieldError from '../../formFieldError';
import * as AppConstants from '../../../utilities/constants';

const ConfirmModal = ({
  completeSprintShowModal,
  setCompleteSprintShowModal,
  handleConfirmSprint,
}) => {
  const [sprints, setSprints] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [sprintId, setSprintId] = useState(0);
  const { allSprint } = useSelector((state) => state.sprint);
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    const modalDialog = document.getElementById(
      'modal-dialog modal-lg modal-dialog-centered'
    );
    if (modalDialog) {
      modalDialog.appendChild(backdrop);
    }
    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
      const modalOverlay = document.getElementsByClassName('modal-overlay');
      if (modalOverlay.length) {
        for (let i = 0; i < modalOverlay.length; i++) {
          modalOverlay[i].remove();
        }
      }
    };
  }, []);

  useEffect(() => {
    if (allSprint && allSprint.length) {
      setSprints(
        allSprint.filter(
          (item) => item.isActiveSprint !== true && item.isComplete !== true
        )
      );
    }
  }, [allSprint]);

  useEffect(() => {
    if (!isChecked) {
      setSprintId(0);
      setIsError(false);
    }
  }, [isChecked]);

  const handleDataSubmit = () => {
    if(isChecked)
    {
      if(!isError && sprintId)
      {
        handleConfirmSprint(sprintId);
      }else{
        setSprintId(0)
        setIsError(true)
      }

    }else{
      handleConfirmSprint(allSprint.filter(
        (item) => item.isActiveSprint === true && item.isComplete !== true
      )[0].id);
    }
  };

  return (
    <div className={`modal ${completeSprintShowModal ? 'show' : 'hide'}`}>
      <div className="modal-dialog modal-lg modal-dialog-centered max-w-4xl w-[38rem]">
        <div className="modal-content">
          <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
            <h5 className="text-xl font-medium leading-normal text-gray-800">
              Confirm Complete Sprint
            </h5>
            <button
              onClick={() => setCompleteSprintShowModal(false)}
              type="button"
              className="btn-close box-content"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body relative p-5">
            <div className="text-center">
              <p>Are you sure you want to mark this sprint Completed?</p>
            </div>

            <div className={'form-group mb-2 mt-5'}>
              <input
                type={'checkbox'}
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#f24759] checked:border-[#f24759] focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain mr-2"
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label>Move Current sprint task to new sprint.</label>
            </div>
            <div className="mt-2">
              {isChecked && (
                <select
                  className="input-style"
                  onChange={(e) => {
                    setSprintId(e.target.value)
                    setIsError(false)
                  }}
                  value={sprintId}
                >
                  <option value={0}>Choose the sprint</option>
                  {!!sprints &&
                    !!sprints.length &&
                    sprints.map((sprint, idx) => (
                      <option key={idx} value={sprint.id}>
                        {sprint.name}
                      </option>
                    ))}
                </select>
              )}
            </div>
            {isError && (<FormFieldError message={AppConstants.requiredField} />)}
          </div>

          <div className="flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
            <button
              onClick={() => setCompleteSprintShowModal(false)}
              type="button"
              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn btn-cancel"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              onClick={() => handleDataSubmit()}
              type="button"
              className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0 mr-3"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
