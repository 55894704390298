import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  success: false,
  tasks: [],
  isTaskAdded: false,
  message: '',
  projectUsers: [],
  trackingStatuses: [],
  sprints: [],
  taskPriorities: [],
  taskTypes: [],
  tags: [],
  isInvitesAdded: false,
  isUpdateTask: false,
  singleTask: {},
  isImageUpload: false,
  isAddCheckList: false,
  allChecklist: [],
  isTaskComment: false,
  allTaskComment: [],
  allTaskHistory: [],
  isUpdateTaskComment: false,
  isUpdateChecklist: false,
  isDeletedTask: false,
  isDeletedTaskComment: false,
  paginationMeta: {},
  imageLink: '',
  allTimeSpend: [],
  isAddedTimeSpend: false,
  isDeletedTimeSpend: false,
  invitedData: {},
  isTaskRelationAdded: false,
  isTaskRelationDeleted: false,
  allTask: [],
  allTaskList: [],
  allTaskPaginationMeta: [],
  isSprintTaskOrdered: false,
  isAttachFileAdded: false,
  allAttachmentFile: [],
  searchLoading: true,
  fileLoading: true,
  isFileAttachmentDeleted: false,
  taskTimers: '',
  isTaskTimerEnd: false,
  activeSprintName: {},
  runningTaskTimer: {},
  isDisableBtn: false,
  isTaskTimerStart: false,
  taskTimeTracker: [],
  isTaskTimeTracker: false,
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    updateTaskRequestSuccess: (state, action) => {
      if (action.payload) {
        let tasks = JSON.parse(JSON.stringify(state.tasks));
        let tags = JSON.parse(JSON.stringify(state.tags));
        let tempTasks = [...tasks];
        const taskToChange = tasks.find(
          (item) => item.id === action.payload.data.id
        );

        const updatedTask = tempTasks.filter((task) => {
          if (task.id === taskToChange.id) {
            for (let key in taskToChange) {
              taskToChange[key] = action.payload.data[key];
            }
          }
          return task;
        });
        tags.push({
          id: action.payload.data.tagId,
          name: action.payload.data.tagName,
        });

        return {
          ...state,
          isUpdateTask: true,
          tasks: updatedTask,
          singleTask:taskToChange,
          tags,
          message: action.payload.message,
          loading: false,
        };
      }
      return state;
    },
    updateTaskRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isUpdateTask: false,
      };
    },

    sendTaskRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          message: action.payload,
          isTaskAdded: true,
          isDisableBtn: true,
        };
      }
      return state;
    },
    sendTaskRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isTaskAdded: false,
        isDisableBtn: true,
      };
    },

    // delete task
    deleteTaskRequestSuccess: (state, action) => {
      let tasks = JSON.parse(JSON.stringify(state.tasks));
      tasks.splice(
        tasks.findIndex((item) => item.id === action.payload.data.id),
        1
      );
      if (action.payload) {
        return {
          ...state,
          isDeletedTask: true,
          tasks,
          message: action.payload.message,
          loading: false,
        };
      }
      return state;
    },
    deleteTaskRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isDeletedTask: false,
      };
    },

    getScrumRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          projectUsers: action.payload.projectUsers,
          trackingStatuses: action.payload.trackingStatuses,
          taskPriorities: action.payload.taskPriorities,
          taskTypes: action.payload.taskTypes,
          tags: action.payload.tags,
          sprints: action.payload.sprint,
          activeSprintName: action.payload.sprint.find((sprintItem)=>sprintItem.isActiveSprint === true),
          loading: false,
        };
      }
      return state;
    },
    getScrumRequestFailure: (state) => {
      return { ...state, loading: false };
    },

    // kanban
    getKanbanRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          tasks: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getKanbanRequestFailure: (state) => {
      return { ...state, loading: false };
    },

    saveInvitesRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          invitedData: action.payload.data,
          message: action.payload.message,
          isInvitesAdded: true,
        };
      }
      return state;
    },
    saveInvitesRequestFailure: (state) => {
      return { ...state, loading: false, isInvitesAdded: false };
    },
    getTaskRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          singleTask: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getTaskRequestFailure: (state) => {
      return { ...state, loading: false };
    },

    saveEditorImageRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isImageUpload: true,
          imageLink: action.payload.data,
          loading: false,
        };
      }
      return state;
    },
    saveEditorImageRequestFailure: (state) => {
      return {
        ...state,
        isImageUpload: false,
        loading: false,
      };
    },

    // add Checklist
    addChecklistRequestSuccess: (state, action) => {
      let allChecklist = JSON.parse(JSON.stringify(state.allChecklist));
      allChecklist.push(action.payload.data);
      if (action.payload) {
        return {
          ...state,
          isAddCheckList: true,
          message: action.payload.message,
          allChecklist,
          loading: false,
        };
      }
      return state;
    },
    addChecklistRequestFailure: (state) => {
      return {
        ...state,
        isAddCheckList: false,
        loading: false,
      };
    },

    // get All Checklist
    getAllChecklistRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allChecklist: action.payload.data,
          loading: false,
        };
      }
      return state;
    },
    getAllChecklistRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // update Checklist
    updateChecklistRequestSuccess: (state, action) => {
      let allChecklist = JSON.parse(JSON.stringify(state.allChecklist));
      const checklistToChange = allChecklist.find(
        (item) => item.id === action.payload.data.id
      );
      checklistToChange.isComplete = action.payload.data.isComplete;
      checklistToChange.title = action.payload.data.title;

      if (action.payload) {
        return {
          ...state,
          allChecklist,
          isUpdateChecklist: true,
          message: action.payload.message,
          loading: false,
        };
      }
      return state;
    },
    updateChecklistRequestFailure: (state) => {
      return {
        ...state,
        isUpdateChecklist: false,
        loading: false,
      };
    },

    // add task comment
    addTaskCommentRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isTaskComment: true,
          message: 'Added Successfully!',
          loading: false,
        };
      }
      return state;
    },
    addTaskCommentRequestFailure: (state) => {
      return {
        ...state,
        isTaskComment: false,
        loading: false,
      };
    },

    // get All Task Comment
    getAllTaskCommentRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allTaskComment: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getAllTaskCommentRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // update task comment
    updateTaskCommentRequestSuccess: (state, action) => {
      let allTaskComment = JSON.parse(JSON.stringify(state.allTaskComment));
      const taskToChange = allTaskComment.find(
        (item) => item.id === action.payload.data.id
      );
      taskToChange.comment = action.payload.data.comment;
      if (action.payload) {
        return {
          ...state,
          isUpdateTaskComment: true,
          allTaskComment,
          message: action.payload.message,
          loading: false,
        };
      }
      return state;
    },
    updateTaskCommentRequestFailure: (state) => {
      return {
        ...state,
        isUpdateTaskComment: false,
        loading: false,
      };
    },

    // delete task comment
    deleteTaskCommentRequestSuccess: (state, action) => {
      let allTaskComment = JSON.parse(JSON.stringify(state.allTaskComment));
      allTaskComment.splice(
        allTaskComment.findIndex((item) => item.id === action.payload.data.id),
        1
      );
      if (action.payload) {
        return {
          ...state,
          isDeletedTaskComment: true,
          allTaskComment,
          message: action.payload.message,
          loading: false,
        };
      }
      return state;
    },
    deleteTaskCommentRequestFailure: (state) => {
      return {
        ...state,
        isDeletedTaskComment: false,
        loading: false,
      };
    },

    // get all Task History
    getAllTaskHistoryRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allTaskHistory: action.payload.data,
          paginationMeta: action.payload.paginationMeta,
          loading: false,
        };
      }
      return state;
    },
    getAllTaskHistoryRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    //save time spend
    saveTimeSpendRequestSuccess: (state, action) => {
      let allTimeSpend = JSON.parse(JSON.stringify(state.allTimeSpend));
      allTimeSpend.push(action.payload.data);
      if (action.payload) {
        return {
          ...state,
          loading: false,
          message: action.payload.message,
          allTimeSpend,
          isAddedTimeSpend: true,
        };
      }
      return state;
    },
    saveTimeSpendRequestFailure: (state) => {
      return { ...state, loading: false, isAddedTimeSpend: false };
    },

    // get All time spend
    getTimeSpendRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allTimeSpend: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getTimeSpendRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // delete time spend
    deleteTimeSpendRequestSuccess: (state, action) => {
      let allTimeSpend = JSON.parse(JSON.stringify(state.allTimeSpend));
      allTimeSpend.splice(
        allTimeSpend.findIndex((item) => item.id === action.payload.data.id),
        1
      );
      if (action.payload) {
        return {
          ...state,
          isDeletedTimeSpend: true,
          allTimeSpend,
          message: action.payload.message,
          loading: false,
        };
      }
      return state;
    },
    deleteTimeSpendRequestFailure: (state) => {
      return {
        ...state,
        isDeletedTimeSpend: false,
        loading: false,
      };
    },

    //save sub task
    saveSubTaskRequestSuccess: (state, action) => {
      let singleTask = JSON.parse(JSON.stringify(state.singleTask));
      let finalData = [
        ...singleTask.taskRelation,
        action.payload.data.relatedTask,
      ];
      singleTask.taskRelation = finalData;

      if (action.payload) {
        return {
          ...state,
          loading: false,
          message: action.payload.message,
          singleTask,
          isTaskRelationAdded: true,
        };
      }
      return state;
    },
    saveSubTaskRequestFailure: (state) => {
      return { ...state, loading: false, isTaskRelationAdded: false };
    },

    //Delete sub task
    deleteSubTaskRequestSuccess: (state, action) => {
      let singleTask = JSON.parse(JSON.stringify(state.singleTask));
      singleTask.taskRelation.splice(
        singleTask.taskRelation.findIndex(
          (item) => item.relatedTaskId === action.payload.data.relatedTaskId
        ),
        1
      );
      if (action.payload) {
        return {
          ...state,
          singleTask,
          message: action.payload.message,
          isTaskRelationDeleted: true,
        };
      }
      return state;
    },
    deleteSubTaskRequestFailure: (state) => {
      return { ...state, isTaskRelationDeleted: false };
    },

    //Sprint Task order
    saveSprintTaskOrderRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          tasks: action.payload.data,
          message: action.payload.message,
          isSprintTaskOrdered: true,
          isUpdateTask: true,
        };
      }
      return state;
    },
    saveSprintTaskOrderRequestFailure: (state) => {
      return { ...state, isSprintTaskOrdered: false, isUpdateTask: false };
    },

    getAllTaskRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allTask: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getAllTaskRequestFailure: (state) => {
      return { ...state, loading: false };
    },

    // Get All task list with pagination
    getAllTaskListRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allTaskList: action.payload.data,
          allTaskPaginationMeta: action.payload.paginationMeta,
          searchLoading: false,
        };
      }
      return state;
    },
    getAllTaskListRequestFailure: (state) => {
      return { ...state, searchLoading: false };
    },

    //Save Attachment File
    saveAttachmentFileRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allAttachmentFile: action.payload.data,
          message: action.payload.message,
          isAttachFileAdded: true,
          loading: false,
        };
      }
      return state;
    },
    saveAttachmentFileRequestFailure: (state) => {
      return { ...state, isAttachFileAdded: false, loading: false };
    },

    //Get Attachment File
    getAttachmentFileRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allAttachmentFile: action.payload.data,
          fileLoading: false,
        };
      }
      return state;
    },
    getAttachmentFileRequestFailure: (state) => {
      return { ...state, fileLoading: false };
    },

    // delete attachment file
    deleteAttachmentFileRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isFileAttachmentDeleted: true,
          message: action.payload.message,
          loading: false,
        };
      }
      return state;
    },
    deleteAttachmentFileRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isFileAttachmentDeleted: false,
      };
    },

    // Start task Timer
    saveTaskTimerStartRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          taskTimers: action.payload.data.startTime,
          message: action.payload.message,
          isTaskTimerStart: true,
          loading: false,
        };
      }
      return state;
    },
    saveTaskTimerStartRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // End task Timer
    endTaskTimerStartRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          message: action.payload.message,
          isTaskTimerEnd: true,
          isTaskTimerStart: false,
          loading: false,
        };
      }
      return state;
    },
    endTaskTimerStartRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isTaskTimerEnd: false,
      };
    },

    // Running Task Timer
    runningTaskTimerRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          message: action.payload.message,
          runningTaskTimer: action.payload.data,
          loading: false,
        };
      }
      return state;
    },
    runningTaskTimerRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // Task Time Tracker
    taskTimeTrackerRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          message: action.payload.message,
          isTaskTimeTracker: true,
          loading: false,
        };
      }
      return state;
    },
    taskTimeTrackerRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isTaskTimeTracker: false,
      };
    },

    //Get Task Time Tracker
    getTaskTimeTrackerRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          message: action.payload.message,
          taskTimeTracker: action.payload.data,
          loading: false,
        };
      }
      return state;
    },
    getTaskTimeTrackerRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isTaskTimeTracker: false,
      };
    },

    // reset state
    resetState: (state) => {
      return {
        ...state,
        message: '',
        isTaskAdded: false,
        isUpdateTaskComment: false,
        isUpdateTask: false,
        isAddCheckList: false,
        isTaskComment: false,
        isUpdateChecklist: false,
        isDeletedTask: false,
        isDeletedTaskComment: false,
        loading: false,
        isDeletedTimeSpend: false,
        isAddedTimeSpend: false,
        isTaskRelationAdded: false,
        isTaskRelationDeleted: false,
        isSprintTaskOrdered: false,
        isAttachFileAdded: false,
        isFileAttachmentDeleted: false,
        isTaskTimerEnd: false,
        taskTimers: '',
        isDisableBtn: false,
        isTaskTimeTracker: false,
        allTask: [],
      };
    },
    // reset running task timer
    resetRunningTaskTimerRequest: (state) => {
      return {
        ...state,
        runningTaskTimer: {},
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateTaskRequestSuccess,
  updateTaskRequestFailure,
  getTaskRequestSuccess,
  getTaskRequestFailure,
  sendTaskRequestSuccess,
  sendTaskRequestFailure,
  getScrumRequestSuccess,
  getScrumRequestFailure,
  getKanbanRequestSuccess,
  getKanbanRequestFailure,
  saveInvitesRequestSuccess,
  saveInvitesRequestFailure,
  saveEditorImageRequestSuccess,
  saveEditorImageRequestFailure,
  addChecklistRequestSuccess,
  addChecklistRequestFailure,
  getAllChecklistRequestSuccess,
  getAllChecklistRequestFailure,
  addTaskCommentRequestSuccess,
  addTaskCommentRequestFailure,
  getAllTaskCommentRequestSuccess,
  getAllTaskCommentRequestFailure,
  getAllTaskHistoryRequestSuccess,
  getAllTaskHistoryRequestFailure,
  updateTaskCommentRequestSuccess,
  updateTaskCommentRequestFailure,
  updateChecklistRequestSuccess,
  updateChecklistRequestFailure,
  deleteTaskRequestSuccess,
  deleteTaskRequestFailure,
  deleteTaskCommentRequestSuccess,
  deleteTaskCommentRequestFailure,
  getTimeSpendRequestSuccess,
  getTimeSpendRequestFailure,
  saveTimeSpendRequestSuccess,
  saveTimeSpendRequestFailure,
  deleteTimeSpendRequestSuccess,
  deleteTimeSpendRequestFailure,
  saveSubTaskRequestSuccess,
  saveSubTaskRequestFailure,
  deleteSubTaskRequestSuccess,
  deleteSubTaskRequestFailure,
  getAllTaskRequestSuccess,
  getAllTaskRequestFailure,
  getAllTaskListRequestSuccess,
  getAllTaskListRequestFailure,
  saveSprintTaskOrderRequestSuccess,
  saveSprintTaskOrderRequestFailure,
  saveAttachmentFileRequestSuccess,
  saveAttachmentFileRequestFailure,
  getAttachmentFileRequestSuccess,
  getAttachmentFileRequestFailure,
  deleteAttachmentFileRequestSuccess,
  deleteAttachmentFileRequestFailure,
  saveTaskTimerStartRequestSuccess,
  saveTaskTimerStartRequestFailure,
  endTaskTimerStartRequestSuccess,
  endTaskTimerStartRequestFailure,
  runningTaskTimerRequestSuccess,
  runningTaskTimerRequestFailure,
  resetRunningTaskTimerRequest,
  taskTimeTrackerRequestSuccess,
  taskTimeTrackerRequestFailure,
  getTaskTimeTrackerRequestSuccess,
  getTaskTimeTrackerRequestFailure,
  resetState,
} = taskSlice.actions;

export default taskSlice.reducer;
