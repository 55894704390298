import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  getAllReportsRequestFailure,
  getAllReportsRequestSuccess,
} from './reportSlice';

// Get All Reports
function* asyncAllReports(data) {
  try {
    const { payload } = data;
    const finalArr = [];

    if (payload.userId) {
      finalArr.push(`UserId=${payload.userId}`);
    }
    if (payload.fromDate) {
      finalArr.push(`FromDate=${payload.fromDate}`);
    }
    if (payload.toDate) {
      finalArr.push(`ToDate=${payload.toDate}`);
    }
    const response = yield ApiService.getData(
      `${URLS.GET_REPORTS.url}?${finalArr.join('&')}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getAllReportsRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      console.log(err.response?.data.message);
    } else {
      yield put(getAllReportsRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

export function* reportSaga() {
  yield all([
    takeEvery(constants.getAllReportsRequestActionType, asyncAllReports),
  ]);
}
