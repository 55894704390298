import React, { useEffect, useState } from 'react';
import './styles.scss';
import ToastMessage from '../../ToastContainer';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { taskReportRequest } from '../../../store/dashboard/action';
import Avatar from 'react-avatar';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { resetStateRedirection } from '../../../store/common/commonSlice';
import Loader from '../../loader';
import ShowDataModal from './ShowDataModal';
import { getAllTrackingStatus } from '../../../store/project/actions';

const AdminDashboard = ({
  dispatchGetTaskReportRequest,
  dispatchRedirectionState,
  dispatchGetAllTrackingStatusRequest,
}) => {
  const { projectId } = useSelector(
    (state) => state.project
  );
  const { taskReport, loading } = useSelector((state) => state.dashboard);
  const { projectSettings } = useSelector((state) => state.permissions);
  const [showModal, setShowModal] = useState(false);
  const [trackingStatusInfo, setTrackingStatusInfo] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (projectSettings && projectId) {
      if (!projectSettings.canAccessDashboard) {
        navigate('/board');
      }else{
        dispatchGetTaskReportRequest(projectId);
      }
    }
    dispatchRedirectionState();
  }, [projectSettings]);
  
  const handleData = (statusInfo) => {
    setShowModal(true);
    setTrackingStatusInfo(statusInfo);
  };

  useEffect(() => {
    if (projectId) {
      dispatchGetAllTrackingStatusRequest(projectId);
    }
  }, []);

  return (
    <>
      <ToastMessage />
      <div className="dashboard">
        <div className="mt-5">
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
            {loading ? (
              <Loader />
            ) : (
              !!taskReport &&
              !!taskReport.length &&
              taskReport.map((report, idx) => (
                <div
                  className="dashboard-card flex-items justify-center"
                  key={idx}
                >
                  <div className="flex-items w-full p-4">
                    <div
                      className="flex flex-col cursor-pointer"
                      onClick={() => handleData(report)}
                    >
                      <span
                        className="text-2xl font-semibold w-fit"
                        id={report.name}
                      >
                        {report.count}
                      </span>
                      <Tooltip
                        className="tooltip"
                        anchorId={report.name}
                        content="Task"
                        place="top"
                      ></Tooltip>
                      <span className="mb-2">{report.name}</span>
                    </div>
                    <div>
                      <div className="rounded-full">
                        <div
                          className={'w-10 h-10 flex items-center justify-center bg-light-1 rounded-full'}
                        >
                          <div className="user">
                            <div className="avatar">
                              <Avatar name={report.name} round={true} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        {showModal && (
          <ShowDataModal
            showModal={showModal}
            setShowModal={(val) => setShowModal(val)}
            trackingStatusInfo={trackingStatusInfo}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetTaskReportRequest: taskReportRequest,
  dispatchRedirectionState: resetStateRedirection,
  dispatchGetAllTrackingStatusRequest: getAllTrackingStatus,
};
export default connect(null, mapDispatchToProps)(AdminDashboard);
