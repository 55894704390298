import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveConnectSlackRequest } from '../../../store/author/action';
import { useSelector } from 'react-redux';

const SlackConnect = ({ dispatchSaveConnectSlackRequest }) => {
  const { isSlackConnected } = useSelector((state) => state.author);
  const [searchParams] = useSearchParams();
  const loginCode = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(() => {
    if (loginCode) {
      dispatchSaveConnectSlackRequest({
        code: loginCode,
      });
    }
  }, [loginCode]);

  useEffect(() => {
    if (isSlackConnected) {
      navigate('/project-repo');
    }
  }, [isSlackConnected]);

  return <></>;
};

const mapDispatchToProps = {
  // dispatching
  dispatchSaveConnectSlackRequest: saveConnectSlackRequest,
};
export default connect(null, mapDispatchToProps)(SlackConnect);
