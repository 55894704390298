import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  getAllNotificationRequestFailure,
  getAllNotificationRequestSuccess,
  getAllTaskHistoryRequestFailure,
  getAllTaskHistoryRequestSuccess,
  getAllUnreadNotificationRequestFailure,
  getAllUnreadNotificationRequestSuccess,
  saveNotificationRequestFailure,
  saveNotificationRequestSuccess,
} from './notificationsSlice';
import { requestFailureRedirection } from '../common/commonSlice';

// Save Notifications
function* asyncSaveNotification(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SAVE_NOTIFICATIONS.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveNotificationRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      console.log(err.response?.data.message);
    } else {
      yield put(saveNotificationRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Get All Task History
function* asyncAllTaskHistory(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_ALL_NOTIFICATION.url}?ProjectId=${data.payload.projectId}&PageNumber=${data.payload.payload.pageNumber}&PageSize=${data.payload.payload.pageSize}&SortBy=CreatedAt&SortByAsc=${data.payload.payload.SortByAsc}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      const paginationMeta = JSON.parse(response.headers['x-pagination']);
      const finalResponse = { data: response.data, paginationMeta };
      yield put(getAllTaskHistoryRequestSuccess(finalResponse));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getAllTaskHistoryRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Get Notifications
function* asyncGetNotification(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_NOTIFICATIONS.url}?ProjectId=${data.payload.projectId}&PageNumber=${data.payload.payload.pageNumber}&PageSize=${data.payload.payload.pageSize}&SortBy=CreatedAt&SortByAsc=${data.payload.payload.SortByAsc}`,
      data.payload.projectId,
      true
    );
    if (response.status === 200) {
      yield put(getAllNotificationRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      console.log(err.response?.data.message);
    } else {
      yield put(getAllNotificationRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// MARK ALL AS READ Notifications
function* asyncGetAllUnreadNotification(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.MARK_ALL_AS_READ.url}?ProjectId=${data.payload}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(getAllUnreadNotificationRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      console.log(err.response?.data.message);
    } else {
      yield put(getAllUnreadNotificationRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

export function* notificationsSaga() {
  yield all([
    takeEvery(
      constants.saveNotificationRequestActionType,
      asyncSaveNotification
    ),
    takeEvery(
      constants.getAllTaskHistoryRequestActionType,
      asyncAllTaskHistory
    ),
    takeEvery(
      constants.getAllNotificationRequestActionType,
      asyncGetNotification
    ),
    takeEvery(
      constants.getAllUnreadNotificationRequestActionType,
      asyncGetAllUnreadNotification
    ),
  ]);
}
