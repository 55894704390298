import React, { memo } from 'react';
import { Link } from 'react-router-dom';

const SubMenu = ({
  menuId,
  menuData,
  handleSubMenuClick,
  subMenuId,
  isSidebarHovered,
}) => {
  return (
    <ul
      id="nav-menu"
      className={`custom-dropdown ${
        menuId === menuData.id && isSidebarHovered ? 'show' : ''
      } space-y-2`}
    >
      {menuData.subMenu.length !== 0 &&
        menuData.subMenu.map((subMenu, index) => {
          return (
            <li key={index} className="sub-menu-li">
              <Link
                to={`${subMenu.route}`}
                className={`inner-menus ${
                  subMenuId === subMenu.id ? 'active' : ''
                }`}
                onClick={() => handleSubMenuClick(subMenu.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-2.5 h-2.5 mr-2"
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentcolor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                </svg>
                {subMenu.name}
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

export default memo(SubMenu);
