import * as Constants from './constants';

// Task
export const getTaskRequest = (payload) => {
  return {
    type: Constants.getTaskRequestActionType,
    payload,
  };
};

export const updateTaskRequest = (payload) => {
  return {
    type: Constants.updateTaskRequestActionType,
    payload,
  };
};

export const sendTaskRequest = (payload) => {
  return {
    type: Constants.sendTaskRequestActionType,
    payload,
  };
};

export const deleteTaskRequest = (payload) => {
  return {
    type: Constants.deleteTaskRequestActionType,
    payload,
  };
};

export const getAllTaskRequest = (payload) => {
  return {
    type: Constants.getAllTaskRequestActionType,
    payload,
  };
};

//scrum
export const getScrum = (payload) => {
  return {
    type: Constants.getScrumRequestActionType,
    payload,
  };
};

//scrum
export const getKanban = (payload) => {
  return {
    type: Constants.getKanbanRequestActionType,
    payload,
  };
};

export const saveInvites = (payload) => {
  return {
    type: Constants.saveInvitesRequestActionType,
    payload,
  };
};
// Editor image
export const saveEditorImage = (payload) => {
  return {
    type: Constants.saveEditorImageRequestActionType,
    payload,
  };
};

// add Checklist
export const addCheckList = (payload) => {
  return {
    type: Constants.addCheckListRequestActionType,
    payload,
  };
};

// get all Check list
export const getAllCheckList = (payload) => {
  return {
    type: Constants.getAllCheckListRequestActionType,
    payload,
  };
};

// update checkList
export const updateCheckList = (payload) => {
  return {
    type: Constants.updateCheckListRequestActionType,
    payload,
  };
};

// add task comment
export const addTaskComment = (payload) => {
  return {
    type: Constants.addTaskCommentsRequestActionType,
    payload,
  };
};

// get all Task comment
export const getAllTaskComment = (payload) => {
  return {
    type: Constants.getAllTaskCommentsRequestActionType,
    payload,
  };
};

// update task comment
export const updateTaskComment = (payload) => {
  return {
    type: Constants.updateTaskCommentRequestActionType,
    payload,
  };
};

// delete task comment
export const deleteTaskComment = (payload) => {
  return {
    type: Constants.deleteTaskCommentRequestActionType,
    payload,
  };
};

// Task history
export const getAllTaskHistory = (payload) => {
  return {
    type: Constants.getAllTaskHistoryRequestActionType,
    payload,
  };
};

// Time spend

export const saveTimeSpend = (payload) => {
  return {
    type: Constants.saveTimeSpendRequestActionType,
    payload,
  };
};

export const getTimeSpend = (payload) => {
  return {
    type: Constants.getTimeSpendRequestActionType,
    payload,
  };
};

export const deleteTimeSpend = (payload) => {
  return {
    type: Constants.deleteTimeSpendRequestActionType,
    payload,
  };
};

// Sub Task Added
export const saveSubTask = (payload) => {
  return {
    type: Constants.saveSubTaskRequestActionType,
    payload,
  };
};

// GET ALL TASK LIST WITH PAGINATION
export const getAllTaskList = (payload) => {
  return {
    type: Constants.getAllTaskListRequestActionType,
    payload,
  };
};

// Sub Task Deleted
export const deleteSubTask = (payload) => {
  return {
    type: Constants.deleteSubTaskRequestActionType,
    payload,
  };
};

// Save sprint task order
export const saveSprintTaskOrder = (payload) => {
  return {
    type: Constants.saveSprintTaskOrderRequestActionType,
    payload,
  };
};

// Save Attachment file
export const saveAttachmentFile = (payload) => {
  return {
    type: Constants.saveAttachmentFileRequestActionType,
    payload,
  };
};

// Get Attachment file
export const getAttachmentFile = (payload) => {
  return {
    type: Constants.getAttachmentFileRequestActionType,
    payload,
  };
};

// Get Attachment file
export const deleteAttachmentFile = (payload) => {
  return {
    type: Constants.deleteAttachmentFileRequestActionType,
    payload,
  };
};

// Save task timer start
export const saveTaskTimerStart = (payload) => {
  return {
    type: Constants.saveTaskTimerStartRequestActionType,
    payload,
  };
};

// Save task timer end
export const endTaskTimerStart = (payload) => {
  return {
    type: Constants.endTaskTimerStartRequestActionType,
    payload,
  };
};

// Running task timer
export const runningTaskTimer = (payload) => {
  return {
    type: Constants.runningTaskTimerRequestActionType,
    payload,
  };
};

//Task Time Tracker
export const taskTimeTracker = (payload) => {
  return {
    type: Constants.taskTimeTrackerRequestActionType,
    payload,
  };
};
//Task Time Tracker
export const getTaskTimeTracker = (payload) => {
  return {
    type: Constants.getTaskTimeTrackerRequestActionType,
    payload,
  };
};
