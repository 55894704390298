import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  updatePersonalDetailsRequest,
  updateUserProfilePicRequest,
} from '../../../store/user/action';
import Avatar from 'react-avatar';
import FormInputField from '../../formInputField';
import ToastMessage from '../../ToastContainer';
import { successMsg } from '../../../utilities/utils';
import { resetState } from '../../../store/user/userSlice';

const UserProfile = ({
  showModal,
  setShowModal,
  userDetails,
  dispatchUpdateProfilePicRequest,
  dispatchUpdatePersonalDetailsRequest,
  dispatchResetStateRequest,
}) => {
  const {isProfileUpdated } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    };
  }, []);

  const [imageLoad, setImageLoad] = useState(false);
  const [editFlag, setEditFlag] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  useEffect(() => {
    reset({ firstName: userDetails.firstName, lastName: userDetails.lastName });
  }, [userDetails]);

  const onImageChange = (e) => {
    const formData = new FormData();
    const image = [...e.target.files];
    formData.append('file', image[0]);
    dispatchUpdateProfilePicRequest(formData);
  };

  const handleDetails = (data) => {
    const finalPayload = { id: userDetails.id, data };
    dispatchUpdatePersonalDetailsRequest(finalPayload);
    setEditFlag(false);
  };
 
  useEffect(() => {
    if (isProfileUpdated) {
      successMsg('Updated Successful!');
    }
    dispatchResetStateRequest();
  }, [isProfileUpdated]);

  return (
    <>
      <ToastMessage />
      <div className={`modal ${showModal ? 'show' : 'hide'}`}>
        <div className="modal-dialog modal-lg modal-dialog-centered max-w-md common-modal">
          <div className="modal-content">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <h5 className="text-xl font-medium leading-normal text-gray-800">
                Profile Details
              </h5>
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="btn-close box-content"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-5">
              <div className="lg:flex justify-center">
                <div
                  className="pb-3 w-full relative"
                  style={{ height: 'fit-content' }}
                >
                  <div>
                    <div className="flex-items xl:flex lg:block justify-center">
                      <div className="profile-image mr-2">
                        <div className="relative mx-auto h-[100px] w-[100px]">
                          <span
                            className={'block editProfile animate-bounce top-auto items-center justify-center'}
                          >
                            <input
                              className="fa fa-pencil cursor-pointer relative left-1.5 bottom-0.5"
                              type="file"
                              accept="image/*"
                              style={{ width: '11px' }}
                              onChange={onImageChange}
                              aria-hidden="true"
                            />
                          </span>
                          {userDetails.profilePicUrl === '' ? (
                            <Avatar name={userDetails.firstName} round={true} />
                          ) : (
                            <>
                              <img
                                onLoad={() => {
                                  setImageLoad(true);
                                }}
                                src={
                                  userDetails.profilePicUrl
                                    ? `${userDetails.profilePicUrl}`
                                    : '../../../assets/img/profile-img.png'
                                }
                                alt="Asia Software Solutions"
                                className={`empProfile ${
                                  imageLoad ? 'inline-block' : 'hidden'
                                } w-full h-full rounded-full`}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg-flex">
                <div
                  className="pb-3 w-full relative"
                  style={{ height: 'fit-content' }}
                >
                  <div className="border rounded-md">
                    <form
                      onSubmit={handleSubmit(handleDetails)}
                      className={`${
                        editFlag ? 'enable' : 'disable'
                      } customForm p-4`}
                    >
                      {/* <Tooltip title="Edit details"> */}
                      <span
                        className="editProfile bg-white"
                        onClick={() => setEditFlag(!editFlag)}
                      >
                        <i
                          className="fa fa-pencil text-[#959595] text-[12px]"
                          aria-hidden="true"
                        ></i>
                      </span>
                      {/* </Tooltip> */}

                      <div className="flex-items justify-end">
                        <div className="save-details mt-1">
                          <button
                            type="submit"
                            className="btn-primary mt-0"
                            style={{ marginRight: 'unset' }}
                          >
                            Save
                          </button>
                        </div>
                      </div>

                      <div className={'form-group mb-2'}>
                        <FormInputField
                          label={'First Name'}
                          type={'text'}
                          fieldName={'firstName'}
                          register={register}
                          errors={errors}
                          isRequired={true}
                          placeholder={'First Name'}
                        />
                      </div>
                      <div className={'form-group mb-2'}>
                        <FormInputField
                          label={'Last Name'}
                          type={'text'}
                          fieldName={'lastName'}
                          register={register}
                          errors={errors}
                          isRequired={true}
                          placeholder={'Last Name'}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = {
  // dispatching
  dispatchUpdateProfilePicRequest: updateUserProfilePicRequest,
  dispatchUpdatePersonalDetailsRequest: updatePersonalDetailsRequest,
  dispatchResetStateRequest: resetState,
};
export default connect(null, mapDispatchToProps)(UserProfile);
