// Sprint
export const getSprintRequestActionType = 'GET_SPRINT_REQUEST';
export const saveSprintRequestActionType = 'SAVE_SPRINT_REQUEST';
export const getSprintTaskRequestActionType = 'GET_SPRINT_TASK_REQUEST';
export const saveSprintTaskRequestActionType = 'SAVE_SPRINT_TASK_REQUEST';
export const removeSprintTaskRequestActionType = 'REMOVE_SPRINT_TASK_REQUEST';
export const completedSprintRequestActionType = 'IS_COMPLETED_SPRINT_REQUEST';
export const activeSprintRequestActionType = 'ACTIVE_SPRINT_REQUEST';
export const deleteSprintRequestActionType = 'DELETE_SPRINT_REQUEST';

export const updateSprintRequestActionType = 'UPDATE_SPRINT_REQUEST';
