import * as Constants from './constants';
// update Profile
export const updateUserProfilePicRequest = (payload) => {
  return {
    type: Constants.updateUserProfilePicRequestActionType,
    payload,
  };
};

// update Personal details
export const updatePersonalDetailsRequest = (payload) => {
  return {
    type: Constants.updatePersonalDetailsRequestActionType,
    payload,
  };
};

// GET USER PROFILE PIC URL
export const getUserProfilePicUrlRequest = (payload) => {
  return {
    type: Constants.getUserProfilePicRequestActionType,
    payload,
  };
};

// save forgot password
export const savePasswordChangeRequest = (payload) => {
  return {
    type: Constants.savePasswordChangeRequestType,
    payload,
  };
};

export const saveOTPRequest = (payload) => {
  return {
    type: Constants.saveOTPRequestType,
    payload,
  };
};

export const verifyOTPRequest = (payload) => {
  return {
    type: Constants.verifyOTPRequestType,
    payload,
  };
};
