import * as Constants from './constants';

// Task
export const getSprintRequest = (payload) => {
  return {
    type: Constants.getSprintRequestActionType,
    payload,
  };
};

// Update Sprint
export const updateSprintRequest = (payload) => {
  return {
    type: Constants.updateSprintRequestActionType,
    payload,
  };
};

export const saveSprintRequest = (payload) => {
  return {
    type: Constants.saveSprintRequestActionType,
    payload,
  };
};

// get sprint task
export const getSprintTaskRequest = (payload) => {
  return {
    type: Constants.getSprintTaskRequestActionType,
    payload,
  };
};

// Save sprint task
export const saveSprintTaskRequest = (payload) => {
  return {
    type: Constants.saveSprintTaskRequestActionType,
    payload,
  };
};

// Remove sprint task
export const removeSprintTaskRequest = (payload) => {
  return {
    type: Constants.removeSprintTaskRequestActionType,
    payload,
  };
};

// Is completed Spint
export const completedSprintRequest = (payload) => {
  return {
    type: Constants.completedSprintRequestActionType,
    payload,
  };
};

// Active Spint
export const activeSprintRequest = (payload) => {
  return {
    type: Constants.activeSprintRequestActionType,
    payload,
  };
};

// Delete Spint
export const DeleteSprintRequest = (payload) => {
  return {
    type: Constants.deleteSprintRequestActionType,
    payload,
  };
};
