import React from 'react';
import CustomAvatar from '../../customAvatar';
import { useSelector } from 'react-redux';
import { convertHourMintues } from '../../../../utilities/utils';

const TimeSpendTable = ({ data, setIsShowTimeSpend }) => {
  const { userData } = useSelector((state) => state.user);
  const fullName = (createBy) => {
    let data = userData.find((user) => user.id === createBy);
    return data.firstName + ' ' + data.lastName;
  };

  //get total time in hour and mintues
  const total = data.reduce((total, task) => {
    return total + task.taskTimeSpend;
  }, 0);

  return (
    <>
      <div className="flex justify-end">
        <h3 className="mb-3 mt-1 text-right">
          Total Task Hours :-
          <b> {convertHourMintues(total)}</b>
        </h3>

        <div className="mt-1 ml-2 cursor-pointer" onClick={setIsShowTimeSpend}>
          <i className="fa-solid fa-plus"></i>
        </div>
      </div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
        <thead className="text-sm font-medium text-gray-900 bg-gray-50 sticky -top-[1px] z-[99]">
          <tr className="border">
            <th scope="col" className="px-3 py-2">
              Time Logged By
            </th>
            <th scope="col" className="px-3 py-2">
              Details
            </th>
            <th scope="col" className="px-3 py-2">
              Start Time
            </th>
            <th scope="col" className="px-3 py-2">
              End Time
            </th>
            <th scope="col" className="px-3 py-2">
              Total Time
            </th>
            <th scope="col" className="px-3 py-2">
              {/* Time Spent(Mins) */}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((task, idx) => (
            <tr
              className="bg-white border-b select-none dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              key={idx}
            >
              <td className="px-6 py-6">
                <div className="flex">
                  <CustomAvatar id={task.createdBy} idPrefix={task.id} />
                  <span className="mt-2 ml-2"> {fullName(task.createdBy)}</span>
                </div>
              </td>
              <td>
                <span className="ml-3">
                  {' '}
                  {task.details ? task.details : 'Not Found '}
                </span>
              </td>
              <td>
                <span className="ml-3"> {task.startTime}</span>
              </td>
              <td>
                <span className="ml-3">
                  {task.endTime ? task.endTime : 'Null'}
                </span>
              </td>
              <td className="px-6 py-3">{`${task.taskTimeSpend} Mintues`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TimeSpendTable;
