import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  success: false,
  imageLink: '',
  error: true,
  isProfileUpdated: false,
  message: '',
  userData: [],
  checkOTPToken: '',
  isChangedPassword: false,
  changePasswordToken: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserProfilePicRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          imageLink: action.payload.data,
          isProfileUpdated: true,
          message: 'Updated successful!',
          loading: false,
        };
      }
      return state;
    },
    updateUserProfilePicRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
        isProfileUpdated: false,
      };
    },
    updateUserPersonalDetailsRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isProfileUpdated: true,
          message: action.payload.data.message,
        };
      }
      return state;
    },
    updateUserPersonalDetailsRequestFailure: (state) => {
      return {
        ...state,
        isProfileUpdated: false,
      };
    },

    getUserProfilePicUrlRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          userData: action.payload.data,
        };
      }
      return state;
    },
    getUserProfilePicUrlRequestFailure: (state) => {
      return {
        ...state,
      };
    },

    //forgot password
    passwordForgotRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          message: action.payload.message,
          isChangedPassword: true,
          loading: false,
        };
      }
      return state;
    },
    passwordForgotRequestFailure: (state) => {
      return {
        ...state,
        isChangedPassword: false,
        loading: false,
      };
    },

    //save otp
    saveOTPRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          checkOTPToken: action.payload.data,
          message: action.payload.message,
        };
      }
      return state;
    },
    saveOTPRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // verify otp
    verifyOTPRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          changePasswordToken: action.payload.data,
        };
      }
      return state;
    },

    verifyOTPRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // reset state
    resetState: (state) => {
      return {
        ...state,
        isProfileUpdated: false,
        isChangedPassword: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserProfilePicRequestSuccess,
  updateUserProfilePicRequestFailure,
  updateUserPersonalDetailsRequestSuccess,
  updateUserPersonalDetailsRequestFailure,
  getUserProfilePicUrlRequestSuccess,
  getUserProfilePicUrlRequestFailure,
  passwordForgotRequestSuccess,
  passwordForgotRequestFailure,
  saveOTPRequestSuccess,
  saveOTPRequestFailure,
  verifyOTPRequestSuccess,
  verifyOTPRequestFailure,
  resetState,
} = userSlice.actions;

export default userSlice.reducer;
