export const somethingWrong = 'Something went wrong';
export const requiredField = 'Required Field';
export const minLength = 'Minimum length is 8 characters';
export const maxLengthReached = 'Max. length reached';
export const notValidEmail = 'Not a valid Email';
export const notValidMessage = 'Not a valid';
export const notValidCode = 'Not a valid code';
export const notValidurl = 'Not a valid url';
export const notValidName = 'Not a valid name';
export const notMatchPassword = 'Confirm Password is not Matched!';

notValidEmail