import createSagaMiddleware from 'redux-saga';
import RootSaga from './saga';
import { configureStore } from '@reduxjs/toolkit';
import RootReducer from './reducers';
// import socketMiddleware from '../middleware/socketMiddleware';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    })
      .prepend(sagaMiddleware)
      // .prepend(socketMiddleware);
  },
});

sagaMiddleware.run(RootSaga);

export default store;
