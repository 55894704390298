import { createSlice, current } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  success: false,
  inviteStatus: 0,
  message: '',
  invitedUser: [],
  projects: [],
  instantCreatedProjectDetails: {},
  isProjectAdded: false,
  isTaskTypeAdded: false,
  allTaskType: [],
  allTags: [],
  allTaskPriority: [],
  isTaskPriorityAdded: false,
  allTrackingStatus: [],
  trackingStatusList: [],
  isTrackingStatusAdded: false,
  isTagAdded: false,
  singleProject: {},
  isProjectUpdated: false,
  isTaskTypeUpdated: false,
  isTaskPriorityUpdated: false,
  isTrackingStatusUpdated: false,
  isTagUpdated: false,
  isDeleteTaskType: false,
  isDeleteTaskPriority: false,
  isDeleteTrackingStatus: false,
  isTagDeleted: false,
  isProjectDeleted: false,
  projectAcceptToken: '',
  isAccepted: 0,
  projectInviteInfo: {},
  projectId: 0,
  isRemoveProject: false,
  isUserRoleChanged: false,
  users: [],
  flowStatus: [],
  isChangeTrackingStatusOrder: false,
  isUserDeleted: false,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectInviteAcceptanceRequestSuccess: (state, action) => {
      if (action.payload) {
        state.projectInviteInfo.inviteStatus = action.payload.inviteStatus;      
        if(action.payload.inviteStatus === 1)
        {
          const doesExist = state.projects.some(function (ele) {
            return ele.id === state.projectInviteInfo?.projectId;
          });
          if (!doesExist) {
            const projectInvite = {
              id: state.projectInviteInfo?.projectId,
              name: state.projectInviteInfo?.projectName,
              description: 'Description',
              organisationId: 0,
              organisationName: state.projectInviteInfo.organisationName,
            };
            state.projects.push(projectInvite);
          }
          state.projectId = state.projectInviteInfo.projectId;
        }
        state.message = action.payload.message;
        state.loading = false;
      }
    },
    setProjectInviteAcceptanceRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    },
    sendProjectInvitiesUserRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          invitedUser: action.payload,
          loading: false,
        };
      }
    },
    sendProjectInvitiesUserRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // accept project details
    getAcceptProjectInfoRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          projectInviteInfo: action.payload.data,
          loading: false,
        };
      }
      return state;
    },
    getAcceptProjectInfoRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        message: 'This Project is already accepted!',
        success: false,
        isAccepted: 0,
      };
    },

    // Get All Project
    getAllProjectRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          projects: action.payload.data,
        };
      }
      return state;
    },
    getAllProjectRequestFailure: (state) => {
      return { ...state, loading: false };
    },

    //save Project
    saveProjectRequestSuccess: (state, action) => {
      if (action.payload) {
        state.projects.push(action.payload.data);
        state.loading = false;
        state.message = action.payload.message;
        state.projectId = action.payload.data.id;
        state.instantCreatedProjectDetails= {id: action.payload.data.id,name: action.payload.data.name};
        state.isProjectAdded = true;
      }
      
    },
    saveProjectRequestFailure: (state) => {
      return { ...state, loading: false, isProjectAdded: false };
    },

    //delete Project
    deleteProjectRequestSuccess: (state, action) => {
      if (action.payload) {
        state.projects.splice(
          state.projects.findIndex((item) => item.id === action.payload.data.id),
          1
        );
        state.loading = false;
        state.message = action.payload.message;
        state.isProjectDeleted = true;
      }
    },
    deleteProjectRequestFailure: (state) => {
      return { ...state, loading: false, isProjectDeleted: false };
    },

    // save Task Type
    saveTaskTypeRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTaskType.push(action.payload.data);
        state.isTaskTypeAdded = true;
        state.message = action.payload.message
        
      }
    },
    saveTaskTypeRequestFailure: (state) => {
      return {
        ...state,
        isTaskTypeAdded: false,
      };
    },

    // update Task type
    updateTaskTypeRequestSuccess: (state, action) => {
      if(action.payload)
      {
        state.allTaskType.find(
          (item) =>{
            if(item.id === action.payload.data.id)
            {
              item.name = action.payload.data.name
            }
          }
        );
        state.isTaskTypeUpdated = true;
        state.message = action.payload.message
      }
      
    },
    updateTaskTypeRequestFailure: (state) => {
      return {
        ...state,
        isTaskTypeUpdated: false,
      };
    },

    // Delete Task Type
    deleteTaskTypeRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTaskType.splice(
          state.allTaskType.findIndex((item) => item.id === action.payload.data.id),
          1
        );
        state.isDeleteTaskType = true;
        state.message = action.payload.message;
      }
    },
    deleteTaskTypeRequestFailure: (state) => {
      return {
        ...state,
        isDeleteTaskType: false,
      };
    },

    // save Task Priority
    saveTaskPriorityRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTaskPriority.push(action.payload.data);
        state.isTaskPriorityAdded = true;
        state.message = action.payload.message;
      }
    },
    saveTaskPriorityRequestFailure: (state) => {
      return {
        ...state,
        isTaskPriorityAdded: false,
      };
    },

    // update task Priority
    updateTaskPriorityRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTaskPriority.find(
          (item) => {
            if(item.id === action.payload.data.id)
            {
              item.name = action.payload.data.name;
              item.priorityColor = action.payload.data.priorityColor;
              item.icon = action.payload.data.icon
            }
          }
        );
        state.isTaskPriorityUpdated = true;
        state.message = action.payload.message;
      }
    },
    updateTaskPriorityRequestFailure: (state) => {
      return {
        ...state,
        isTaskPriorityUpdated: false,
      };
    },

    // delete task priority
    deleteTaskPriorityRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTaskPriority.splice(
          state.allTaskPriority.findIndex((item) => item.id === action.payload.data.id),
          1
        );
        state.isDeleteTaskPriority = true;
        state.message = action.payload.message;
      }
    },
    deleteTaskPriorityRequestFailure: (state) => {
      return {
        ...state,
        isDeleteTaskPriority: false,
      };
    },

    // save Tracking status
    saveTrackingStatusRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTrackingStatus.push(action.payload.data);
        state.isTrackingStatusAdded = true;
        state.message = action.payload.message;
      }
    },
    saveTrackingStatusRequestFailure: (state) => {
      return {
        ...state,
        isTrackingStatusAdded: false,
      };
    },

    // update tracking status
    updateTrackingStatusRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTrackingStatus.find(
          (item) =>{
            if(item.id === action.payload.data.id)
            {
              item.name = action.payload.data.name;
              item.flowStatusId = action.payload.data.flowStatusId;
            }
          }
        );
        state.isTrackingStatusUpdated= true;
        state.message = action.payload.message;
      }
    },
    updateTrackingStatusRequestFailure: (state) => {
      return {
        ...state,
        isTrackingStatusUpdated: false,
      };
    },

    // delete tracking status
    deleteTrackingStatusRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTrackingStatus.splice(
          state.allTrackingStatus.findIndex(
            (item) => item.id === action.payload.data.id
          ),
          1
        );
        state.isDeleteTrackingStatus = true;
        state.message = action.payload.message;
      }
    },
    deleteTrackingStatusRequestFailure: (state) => {
      return {
        ...state,
        isDeleteTrackingStatus: false,
      };
    },

    // get All tracking Status
    getAllTrackingStatusRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          trackingStatusList: action.payload.data,
          message: action.payload.message,
        };
      }
      return state;
    },
    getAllTrackingStatusRequestFailure: (state) => {
      return {
        ...state,
      };
    },

    // save Tag
    saveTagRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTags.push(action.payload.data);
        state.isTagAdded = true;
        state.message = action.payload.message;
      }
    },
    saveTagRequestFailure: (state) => {
      return {
        ...state,
        isTagAdded: false,
      };
    },

    // update tag
    updateTagRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTags.find(
          (item) =>{
            if(item.id === action.payload.data.id)
            {
              item.name = action.payload.data.name;
            }
          }
        );
        state.isTagUpdated = true;
        state.message = action.payload.message;
      }
    },
    updateTagRequestFailure: (state) => {
      return {
        ...state,
        isTagUpdated: false,
      };
    },

    // delete tag
    deleteTagRequestSuccess: (state, action) => {
      if (action.payload) {
        state.allTags.splice(
          state.allTags.findIndex((item) => item.id === action.payload.data.id),
          1
        );
        state.isTagDeleted = true;
        state.message = action.payload.message
      }
    },
    deleteTagRequestFailure: (state) => {
      return {
        ...state,
        isTagDeleted: false,
      };
    },

    // project scrum
    getProjectScrumRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          users: action.payload.projectUsers,
          allTaskType: action.payload.taskType,
          allTaskPriority: action.payload.taskPriority,
          allTrackingStatus: action.payload.trackingStatuses,
          allTags: action.payload.tags ? action.payload.tags : [],
          singleProject: action.payload.project,
          loading: false,
        };
      }
      return state;
    },
    getProjectScrumRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // update project
    updateProjectRequestSuccess: (state, action) => {
      let projects = current(state.projects);
      const updatedSelectedProjectInfo = projects.map(project => project.id === action.payload.data.id ? {...project,name: action.payload.data.name} : project)
      const singleProject = projects.map(
        (item) =>{
          if(item.id === action.payload.data.id)
          {
            return {...item,name:action.payload.data.name}
          }
        }
      );
      if (action.payload) {
        return {
          ...state,
          isProjectUpdated: true,
          projects:updatedSelectedProjectInfo,
          singleProject,
          message: action.payload.message,
        };
      }
      return state;
    },
    updateProjectRequestFailure: (state) => {
      return {
        ...state,
        isProjectUpdated: false,
      };
    },

    //Remove Project
    removeProjectRequestSuccess: (state, action) => {
      let invitedUser = state.invitedUser;
      const updatedArr = invitedUser.filter((user) => {
        return user.id !== action.payload.data;
      });
      if (action.payload) {
        return {
          ...state,
          loading: false,
          invitedUser: updatedArr,
          message: action.payload.message,
          isRemoveProject: true,
        };
      }
      return state;
    },
    removeProjectRequestFailure: (state) => {
      return { ...state, loading: false, isRemoveProject: false };
    },

    // user role changed 
    setUserRoleChangeRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isUserRoleChanged: true,
          message: action.payload.message,
        };
      }
      return state;
    },
    setUserRoleChangeRequestFailure: (state) => {
      return {
        ...state,
        isUserRoleChanged: false,
      };
    },

    // Flow Status List
    getFlowStatusListRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          flowStatus: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getFlowStatusListRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // Change Tracking Status Order
    saveTrackingStatusOrderRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isChangeTrackingStatusOrder: true,
          allTrackingStatus: action.payload,
          loading: false,
        };
      }
      return state;
    },
    saveTrackingStatusOrderRequestFailure: (state) => {
      return {
        ...state,
        isChangeTrackingStatusOrder: false,
        loading: false,
      };
    },

    // Delete User
    deleteUserRequestSuccess: (state, action) => {
      
      if (action.payload) {
        state.users.splice(
          state.users.findIndex((item) => item.id === action.payload.data.userId),
          1
        );
        state.isUserDeleted = true;
        state.message = action.payload.message
      }
    },
    deleteUserRequestFailure: (state) => {
      return {
        ...state,
        isUserDeleted: false,
      };
    },

    // reset state
    resetState: (state) => {
      return {
        ...state,
        message: '',
        isTaskAdded: false,
        isProjectAdded: false,
        isProjectUpdated: false,
        isTaskTypeAdded: false,
        isTaskPriorityAdded: false,
        isTrackingStatusAdded: false,
        isTagAdded: false,
        isTaskTypeUpdated: false,
        isTaskPriorityUpdated: false,
        isTrackingStatusUpdated: false,
        isTagUpdated: false,
        isUpdateTask: false,
        isDeleteTaskType: false,
        isDeleteTaskPriority: false,
        isDeleteTrackingStatus: false,
        isTagDeleted: false,
        isProjectDeleted: false,
        isAccepted: 0,
        isRemoveProject: false,
        isUserRoleChanged: false,
        isChangeTrackingStatusOrder: false,
        isUserDeleted: false,
      };
    },

    // reset array type state
    resetArrayState: (state) => {
      return {
        ...state,
        allTrackingStatus: [],
        allTaskPriority: [],
        allTaskType: [],
        allTags: [],
        projectInviteInfo: {},
      };
    },

    // store project id
    saveProjectId: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          projectId: action.payload,
        };
      }
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProjectInviteAcceptanceRequestSuccess,
  setProjectInviteAcceptanceRequestFailure,
  sendProjectInvitiesUserRequestSuccess,
  sendProjectInvitiesUserRequestFailure,
  saveProjectRequestSuccess,
  saveProjectRequestFailure,
  saveTaskTypeRequestSuccess,
  saveTaskTypeRequestFailure,
  saveTaskPriorityRequestSuccess,
  saveTaskPriorityRequestFailure,
  saveTrackingStatusRequestSuccess,
  saveTrackingStatusRequestFailure,
  saveTagRequestSuccess,
  saveTagRequestFailure,
  getProjectScrumRequestSuccess,
  getProjectScrumRequestFailure,
  updateProjectRequestSuccess,
  updateProjectRequestFailure,
  updateTaskTypeRequestSuccess,
  updateTaskTypeRequestFailure,
  updateTaskPriorityRequestSuccess,
  updateTaskPriorityRequestFailure,
  updateTrackingStatusRequestSuccess,
  updateTrackingStatusRequestFailure,
  updateTagRequestSuccess,
  updateTagRequestFailure,
  deleteTaskTypeRequestSuccess,
  deleteTaskTypeRequestFailure,
  deleteTaskPriorityRequestSuccess,
  deleteTaskPriorityRequestFailure,
  deleteTrackingStatusRequestSuccess,
  deleteTrackingStatusRequestFailure,
  getAllTrackingStatusRequestSuccess,
  getAllTrackingStatusRequestFailure,
  deleteTagRequestSuccess,
  deleteTagRequestFailure,
  deleteProjectRequestSuccess,
  deleteProjectRequestFailure,
  getAllProjectRequestSuccess,
  getAllProjectRequestFailure,
  getAcceptProjectInfoRequestSuccess,
  getAcceptProjectInfoRequestFailure,
  removeProjectRequestSuccess,
  removeProjectRequestFailure,
  setUserRoleChangeRequestSuccess,
  setUserRoleChangeRequestFailure,
  getFlowStatusListRequestSuccess,
  getFlowStatusListRequestFailure,
  saveTrackingStatusOrderRequestSuccess,
  saveTrackingStatusOrderRequestFailure,
  deleteUserRequestSuccess,
  deleteUserRequestFailure,
  resetState,
  resetArrayState,
  saveProjectId,
} = projectSlice.actions;

export default projectSlice.reducer;
