import * as Constants from './constants';

// Get label settings
export const getLabelSettingsRequest = (payload) => {
  return {
    type: Constants.getLabelSettingsRequestActionType,
    payload,
  };
};

// Update Label settings
export const updateLabelSettingsRequest = (payload) => {
  return {
    type: Constants.updateLabelSettingsRequestActionType,
    payload,
  };
};

// Get Project settings
export const getProjectSettingsRequest = (payload) => {
  return {
    type: Constants.getProjectSettingsRequestActionType,
    payload,
  };
};
