import React from 'react';
import { useEffect } from 'react';
import Pagination from '../../../../common/pagination';
import HistoryContainer from '../history';
import { getAllTaskHistory } from '../../../../../store/task/action';
import { connect, useSelector } from 'react-redux';

const HistoryList = ({ singleTask, dispatchGetAllTaskHistory }) => {
  const { allTaskHistory, paginationMeta, isUpdateTask } = useSelector(
    (state) => state.task
  );
  const { projectId } = useSelector((state) => state.project);
  
  useEffect(() => {
    if (isUpdateTask) {
      dispatchGetAllTaskHistory({
        payload: {
          taskId: singleTask.id,
          pageNumber: 1,
          pageSize: 10,
          SortByAsc: false,
        },
        projectId: projectId,
      });
    }
  }, [isUpdateTask]);

  useEffect(() => {
    if (Object.keys(singleTask).length !== 0 && projectId) {
      dispatchGetAllTaskHistory({
        payload: {
          taskId: singleTask.id,
          pageNumber: 1,
          pageSize: 10,
          SortByAsc: false,
        },
        projectId: projectId,
      });
    }
  }, [singleTask]);

  const handlePageChange = (pageNumber) => {
    dispatchGetAllTaskHistory({
      payload: {
        taskId: singleTask.id,
        pageNumber: pageNumber,
        pageSize: 10,
        SortByAsc: false,
      },
      projectId: projectId,
    });
  };
  return (
    <>
      <div>
        {!!allTaskHistory && !!allTaskHistory.length ? (
          <>
            <HistoryContainer data={allTaskHistory} tabId={'task-history'} />
            {!!paginationMeta && !!Object.keys(paginationMeta).length && (
              <div>
                <Pagination
                  className="pagination-bar"
                  currentPage={paginationMeta.CurrentPage}
                  totalCount={paginationMeta.TotalCount}
                  pageSize={paginationMeta.PageSize}
                  onPageChange={(page) => handlePageChange(page)}
                />
              </div>
            )}
          </>
        ) : (
          <div>
            <p className="text-center font-semibold">No Any History yet!</p>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetAllTaskHistory: getAllTaskHistory,
};
export default connect(null, mapDispatchToProps)(HistoryList);
