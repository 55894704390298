import { all } from 'redux-saga/effects';
import { loginSaga } from '../login/loginSaga';
import { taskSaga } from '../task/taskSaga';
import { projectSaga } from '../project/projectSaga';
import { userSaga } from '../user/userSaga';
import { dashboardSaga } from '../dashboard/dashboardSaga';
import { permissionsSaga } from '../permissions/permissionsSaga';
import { sprintSaga } from '../sprint/sprintSaga';
import { notificationsSaga } from '../notifications/notificationsSaga';
import { reportSaga } from '../reports/reportSaga';
import { authorSaga } from '../author/authorSaga';
import { emailTemplateSaga } from '../emailTemplate/emailTemplateSaga';
import { emailSettingsSaga } from '../emailSettings/emailSettingsSaga';

export default function* RootSaga() {
  yield all([
    loginSaga(),
    taskSaga(),
    projectSaga(),
    userSaga(),
    dashboardSaga(),
    permissionsSaga(),
    sprintSaga(),
    notificationsSaga(),
    reportSaga(),
    authorSaga(),
    emailTemplateSaga(),
    emailSettingsSaga(),
  ]);
}
