import { combineReducers } from 'redux';
import loginSlice from '../login/loginSlice';
import taskSlice from '../task/taskSlice';
import projectSlice from '../project/projectSlice';
import userSlice from '../user/userSlice';
import dashboardSlice from '../dashboard/dashboardSlice';
import commonSlice from '../common/commonSlice';
import permissionsSlice from '../permissions/permissionsSlice';
import sprintSlice from '../sprint/sprintSlice';
import notificationsSlice from '../notifications/notificationsSlice';
import reportSlice from '../reports/reportSlice';
import authorSlice from '../author/authorSlice';
import emailTemplateSlice from '../emailTemplate/emailTemplateSlice';
import emailSettingsSlice from '../emailSettings/emailSettingsSlice';
const appReducer = combineReducers({
  login: loginSlice,
  task: taskSlice,
  project: projectSlice,
  user: userSlice,
  dashboard: dashboardSlice,
  common: commonSlice,
  permissions: permissionsSlice,
  sprint: sprintSlice,
  notification: notificationsSlice,
  reports: reportSlice,
  author: authorSlice,
  emailTemplate: emailTemplateSlice,
  emailSubscription: emailSettingsSlice,
});

const initialState = appReducer({}, {});
const RootReducer = (state, action) => {
  if (action.type === 'login/sendLogoutRequest') {
    state = initialState;
  }
  return appReducer(state, action);
};

export default RootReducer;
