import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import { requestFailureRedirection } from '../common/commonSlice';
import {
  getConnectSlackRequestFailure,
  getConnectSlackRequestSuccess,
  saveConnectSlackRequestFailure,
  saveConnectSlackRequestSuccess,
  saveSlackTriggerMsgRequestFailure,
  saveSlackTriggerMsgRequestSuccess,
} from './authorSlice';

// get Slack URL
function* asyncConnectSlack() {
  try {
    const response = yield ApiService.getData(`${URLS.CONNECT_SLACK.url}/auth`);
    if (response.status === 200) {
      yield put(getConnectSlackRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getConnectSlackRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// save slack function
function* asyncSaveConnectSlack(data) {
  try {
    const response = yield ApiService.postData(
      URLS.CONNECT_SAVE.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveConnectSlackRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(saveConnectSlackRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// slack Trigger Message
function* asyncSaveSlackTriggerMsg(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.CONNECT_SLACK.url}?clientId=${data.payload}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveSlackTriggerMsgRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(saveSlackTriggerMsgRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

export function* authorSaga() {
  yield all([
    takeEvery(constants.getSlackRequestActionType, asyncConnectSlack),
    takeEvery(constants.saveSlackRequestActionType, asyncSaveConnectSlack),
    takeEvery(
      constants.saveSlackTriggerMsgRequestActionType,
      asyncSaveSlackTriggerMsg
    ),
  ]);
}
