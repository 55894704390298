import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  success: false,
  error: true,
  message: '',
  slackURL: '',
  slackDetails: {},
  isSlackConnected: false,
};

export const authorSlice = createSlice({
  name: 'author',
  initialState,
  reducers: {
    getConnectSlackRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          slackURL: action.payload.data,
          loading: false,
        };
      }
      return state;
    },
    getConnectSlackRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    },

    // save Slack Connect
    saveSlackTriggerMsgRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isSlackConnected: true,
          loading: false,
        };
      }
      return state;
    },
    saveSlackTriggerMsgRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isSlackConnected: false,
        success: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getConnectSlackRequestSuccess,
  getConnectSlackRequestFailure,
  saveConnectSlackRequestSuccess,
  saveConnectSlackRequestFailure,
  saveSlackTriggerMsgRequestSuccess,
  saveSlackTriggerMsgRequestFailure,
} = authorSlice.actions;

export default authorSlice.reducer;
