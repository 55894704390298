import React from 'react';
import CustomAvatar from '../../../common/customAvatar';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

const TableDataRow = ({
  handleEditModal,
  handleSelectChange,
  issue,
  index,
}) => {
  const { trackingStatuses, taskPriorities, tags } = useSelector(
    (state) => state.task
  );
  const [tagName, setTagName] = useState('N/A');

  useEffect(() => {
    if (issue && !!tags && !!tags.length) {
      if (issue.tagId) {
        const tName = tags.find((tag) => tag.id === issue.tagId);
        if (tName) {
          setTagName(tName?.name);
        }
      }
    }
  }, [issue]);
  return (
    <>
      <tr className="mt-5 border-b" key={index}>
        <th
          className="px-3 py-2 font-medium text-gray-900 select-none cursor-pointer"
          onDoubleClick={() => handleEditModal(issue.id)}
        >
          {issue.title}
        </th>
        <td className="px-3 py-2">
          <div className="relative">
            <div className="arrow-down">
              <i className="fas fa-chevron-down"></i>
            </div>
            <select
              className="input-style select-none cursor-pointer"
              value={issue.trackingStatusId}
              onChange={(e) => {
                handleSelectChange({
                  fieldName: 'trackingStatusId',
                  fValue: e.target.value,
                  id: issue.id,
                });
              }}
            >
              {trackingStatuses.map((taskStatus, idx) => 
                (
                  <option key={idx} value={taskStatus.id} className="select-none">
                    {taskStatus.name}
                  </option>
                ) 
              )}
            </select>
          </div>
        </td>
        <td className="px-3 py-2">
          <div className="relative">
            <div className="arrow-down">
              <i className="fas fa-chevron-down"></i>
            </div>
            <select
              className="input-style select-none cursor-pointer"
              value={issue.taskPriorityId}
              onChange={(e) => {
                handleSelectChange({
                  fieldName: 'taskPriorityId',
                  fValue: e.target.value,
                  id: issue.id,
                });
              }}
            >
              {taskPriorities.map((taskPriority, idx) => 
                (
                  <option key={idx} value={taskPriority.id} className="select-none">
                    {taskPriority.name}
                  </option>
                )
              )}
            </select>
          </div>
        </td>
        <td className="px-3 py-2">
          {issue.assignedTo ? (
            <CustomAvatar
              id={issue.assignedTo}
              idPrefix={`avatars_${issue.assignedTo}`}
              assigneeName={issue.assigneeName}
            />
          ) : (
            <div className="user select-none">
              <div className="avatar select-none">
                <Avatar round={true} title="Unassigned" name="Un Assigned" />
              </div>
            </div>
          )}
        </td>
        <td className="px-3 py-2">
          {tagName ? (
            <span className="ux-design mt-1.5 text-gray-500">{tagName}</span>
          ) : (
            ''
          )}
        </td>
        <td className="px-3 py-2 select-none">
          {issue.createdBy && (
            <CustomAvatar
              id={issue.createdBy}
              idPrefix={`produced_${issue.createdBy}`}
            />
          )}
        </td>
        <td className="px-3 py-2">
          <div className="dropdown inline-block relative cursor-pointer">
            <img
              src="../../assets/img/dots-vertical.svg"
              alt=""
              className="w-7 h-7 rotate-90 ml-1"
            />

            <>
              <ul
                id="dropdown-menu"
                className="dropdown-menu absolute hidden pt-1 bg-white shadow rounded py-2 px-4 mb-2 whitespace-nowrap !left-auto !right-0"
              >
                <li>
                  <button
                    type="button"
                    onClick={() => handleEditModal(issue.id)}
                  >
                    <i
                      className="fa fa-pencil mr-2 cursor-pointer w-[18px]"
                      aria-hidden="true"
                    ></i>
                    Edit
                  </button>
                </li>
              </ul>
            </>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableDataRow;
