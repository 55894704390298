export const setProjectInviteRequestActionType = 'PROJECT_INVITES_REQUEST';

export const invitedUserProjectRequestActionType =
  'USER_INVITES_PROJECT_REQUEST';

export const getProjectInvitationInfoRequestActionType =
  'GET_ACCEPTED_PROJECT_REQUEST';

// project
export const saveProjectRequestActionType = 'SAVE_PROJECT_REQUEST';
export const getProjectScrumRequestActionType = 'GET_PROJECT_SCRUM_REQUEST';
export const updateProjectRequestActionType = 'UPDATE_PROJECT_REQUEST';
export const deleteProjectRequestActionType = 'DELETE_PROJECT_REQUEST';
export const getAllProjectRequestActionType = 'GET_ALL_PROJECT_REQUEST';

// task type
export const sendTaskTypeRequestActionType = 'SEND_TASK_TYPE_REQUEST';
export const updateTaskTypeRequestActionType = 'UPDATE_TASK_TYPE_REQUEST';
export const deleteTaskTypeRequestActionType = 'DELETE_TASK_TYPE_REQUEST';

// task Priority
export const saveTaskPriorityRequestActionType = 'SAVE_TASK_PRIORITY_REQUEST';
export const deleteTaskPriorityRequestActionType =
  'DELETE_TASK_PRIORITY_REQUEST';
export const updateTaskPriorityRequestActionType =
  'UPDATE_TASK_PRIORITY_REQUEST';

// tracking status
export const saveTrackingStatusRequestActionType =
  'SAVE_TRACKING_STATUS_REQUEST';
export const updateTrackingStatusRequestActionType =
  'UPDATE_TRACKING_STATUS_REQUEST';
export const deleteTrackingStatusRequestActionType =
  'DELETE_TRACKING_STATUS_REQUEST';
export const getTrackingStatusRequestActionType = 'GET_TRACKING_STATUS_REQUEST';

// tags
export const saveTagRequestActionType = 'SAVE_TAG_REQUEST';
export const updateTagRequestActionType = 'UPDATE_TAG_REQUEST';
export const deleteTagRequestActionType = 'DELETE_TAG_REQUEST';

// remove project
export const removeProjectRequestActionType = 'REMOVE_PROJECT_REQUEST';

// project Role Change
export const userRoleChangeRequestActionType = 'USER_ROLE_CHANGE_REQUEST';

// flow status
export const flowStatusRequestActionType = 'FLOW_STATUS_REQUEST';
// Change Tracking status order
export const changeTrackingStatusOrderRequestActionType =
  'CHANGE_TRACKING_ORDER_REQUEST';

// Delete the user
export const userDeleteRequestActionType = 'USER_DELETE_REQUEST';
