import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { saveSprintTaskRequest } from '../../../../store/sprint/action';
import CustomAvatar from '../../../common/customAvatar';
import UserProfileList from '../../../common/userList';
import { getAllTaskRequest } from '../../../../store/task/action';
import Loader from '../../../loader';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { resetState } from '../../../../store/task/taskSlice';

const AddBackModal = ({
  showModal,
  setShowModal,
  dispatchSaveSprintTaskRequest,
  sprintId,
  dispatchGetAllTaskRequest,
  dispatchResetStateRequest,
}) => {
  const { allTask, loading } = useSelector((state) => state.task);
  const { projectId, trackingStatusList } = useSelector(
    (state) => state.project
  );
  const [addToSprintTask, setAddToSprintTask] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [statusId, setStatusId] = useState('');

  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (!!allTask && !!allTask.length) {
      setFilteredTasks(allTask);
    }
  }, [allTask]);
  useEffect(() => {
    let filterData;
    if (selectedUser.length) {
      const selectUserFilterData = allTask.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      if (searchText) {
        const searchData = selectUserFilterData.filter((item) => {
          return (
            item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        });
        if (statusId !== '' && statusId !== 'null') {
          filterData = searchData.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          filterData = searchData;
        }
      } else {
        if (statusId !== '' && statusId !== 'null') {
          filterData = selectUserFilterData.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          filterData = selectUserFilterData;
        }
      }
    } else {
      if (searchText) {
        const searchData = allTask.filter((item) => {
          return (
            item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        });
        if (statusId !== '' && statusId !== 'null') {
          filterData = searchData.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          filterData = searchData;
        }
      } else if (statusId !== '' && statusId !== 'null') {
        filterData = allTask.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(statusId);
        });
      } else {
        filterData = allTask;
      }
    }
    setFilteredTasks(filterData);
  }, [selectedUser, allTask]);

  useEffect(() => {
    if (projectId) {
      dispatchGetAllTaskRequest({ projectId, sprintId });
      dispatchResetStateRequest();
    }
  }, [projectId]);

  const userFilterData = (id) => {
    const isUserAlreadySelected = selectedUser.includes(id);
    if (!isUserAlreadySelected) {
      setSelectedUser([...selectedUser, id]);
    } else {
      const updatedUserSelected = selectedUser.filter((us) => us !== id);
      setSelectedUser(updatedUserSelected);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const arr = [...allTask];
    if (selectedUser.length) {
      const userFilterData = arr.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      let filData;
      const searchData = userFilterData.filter((item) => {
        return (
          item.taskName.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
          -1
        );
      });
      if (statusId !== '' && statusId !== 'null') {
        filData = searchData.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(statusId);
        });
      } else {
        filData = searchData;
      }
      setFilteredTasks(filData);
    } else {
      let index;
      if (e.target.value) {
        const searchData = arr.filter((item) => {
          return (
            item.taskName
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) !== -1
          );
        });
        if (statusId !== '' && statusId !== 'null') {
          index = searchData.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          index = searchData;
        }
      } else {
        if (statusId !== '' && statusId !== 'null') {
          index = arr.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          index = arr;
        }
      }
      setFilteredTasks(index);
    }
  };

  const handleSearchClose = () => {
    setSearchText('');
    if (selectedUser.length) {
      const userFilterData = allTask.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      setFilteredTasks(userFilterData);
    } else {
      setFilteredTasks(allTask);
    }
  };

  const handleCheckBox = (e, taskId) => {
    if (e.target.checked) {
      setAddToSprintTask([...addToSprintTask, taskId]);
    } else {
      setAddToSprintTask(addToSprintTask.filter((item) => item !== taskId));
    }
  };

  const handleAllCheckbox = (e) => {
    if (e.target.checked) {
      const idList = filteredTasks.map((item) => item.taskId);
      setAddToSprintTask(idList);
    } else {
      setAddToSprintTask([]);
    }
  };
  const handleAddToTaskSprint = () => {
    if (addToSprintTask.length !== 0) {
      const finalPayload = { sprintId, taskId: addToSprintTask };
      dispatchSaveSprintTaskRequest(finalPayload);
    }
    setShowModal(false);
  };

  const getStatus = (statusId) => {
    if (!!trackingStatusList && !!trackingStatusList.length) {
      let d = trackingStatusList.filter((status) => {
        return parseInt(status.id) === parseInt(statusId);
      });
      if (d.length !== 0) {
        return d[0].name;
      }
    }
  };

  const handleSelectChange = (event) => {
    setStatusId(event);
    const arr = [...allTask];
    if (event !== 'null') {
      let filData;
      const searchData = arr.filter((item) => {
        return parseInt(item.trackingStatusId) === parseInt(event);
      });
      if (selectedUser.length) {
        const selectedData = searchData.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );

        if (searchText) {
          filData = selectedData.filter((item) => {
            return (
              item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1
            );
          });
        } else {
          filData = selectedData;
        }
      } else {
        const index = arr.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(event);
        });
        if (searchText) {
          filData = index.filter((item) => {
            return (
              item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1
            );
          });
        } else {
          filData = index;
        }
      }
      setFilteredTasks(filData);
    } else {
      let filData;
      if (selectedUser.length) {
        const userFilterData = arr.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );
        if (searchText) {
          filData = userFilterData.filter((item) => {
            return (
              item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1
            );
          });
        } else {
          filData = userFilterData;
        }
      } else {
        if (searchText) {
          filData = arr.filter((item) => {
            return (
              item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1
            );
          });
        } else {
          filData = arr;
        }
      }
      setFilteredTasks(filData);
    }
  };

  return (
    <>
      <Loader isVisible={loading} />
      <div className={`modal ${showModal ? 'show' : 'hide'}`}>
        <div className="modal-dialog modal-lg modal-dialog-centered w-[55rem]">
          <div className="modal-content">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <h5 className="text-xl font-medium leading-normal text-gray-800">
                Add Sprint Task
              </h5>

              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="btn-close box-content"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="accordion-item bg-white border-b-2 border-gray-200 rounded-none">
              <div className="accordion-header mb-0" id="headingMain">
                <div className="flex justify-between">
                  <UserProfileList
                    selectedUser={selectedUser}
                    userFilterData={userFilterData}
                  />
                  <div className="accordion-body p-4 pb-0">
                    <div className="ticket-heading justify-end pt-4">
                      <div className="flex items-center">
                        <div className="relative">
                          <input
                            type="search"
                            className="search-bar pr-[22px]"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => handleSearch(e)}
                          />
                          <i
                            className={`fas fa-times absolute right-[11px] top-[13px] cursor-pointer site-clr hover:cursor-pointer ${
                              searchText ? 'block' : 'hidden'
                            }`}
                            onClick={() => handleSearchClose()}
                          ></i>
                        </div>
                        <div className="flex border items-center ml-3 rounded-[0.5rem] h-[2.5rem] tasks-btn">
                          <div className="relative">
                            <div className="arrow-down">
                              <i className="fas fa-chevron-down"></i>
                            </div>
                            <select
                              className="input-style select-none cursor-pointer"
                              onChange={(e) => {
                                handleSelectChange(e.target.value);
                              }}
                            >
                              <option value={'null'}>All</option>
                              {trackingStatusList.length &&
                                trackingStatusList.map((item, idx) => (
                                  <option
                                    key={idx}
                                    value={item.id}
                                    className="select-none"
                                  >
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={'modal-body relative p-5 h-[500px] md:h-[400px] xl:h-[458px] overflow-auto'}
            >
              <div className="text-end">
                {!!addToSprintTask.length && (
                  <button
                    className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border rounded mr-4"
                    onClick={() => handleAddToTaskSprint()}
                  >
                    Add
                  </button>
                )}
                <table className="w-full text-sm text-left border mt-2">
                  <thead className="text-sm font-medium text-gray-900 bg-gray-50 z-[99]">
                    <tr className="border">
                      <th className="p-4">
                        <input
                          type="checkbox"
                          checked={
                            addToSprintTask.length !== 0 &&
                            filteredTasks.length !== 0
                              ? addToSprintTask.length === filteredTasks.length
                              : false
                          }
                          className="form-check-input cstm-ckeckbox"
                          onChange={(e) => handleAllCheckbox(e)}
                        />
                      </th>
                      <th>Assign To</th>
                      <th scope="col" className="py-2">
                        Title
                      </th>
                      {trackingStatusList.length ? (
                        <th scope="col">Status</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td>
                          <Loader />
                        </td>
                      </tr>
                    ) : filteredTasks.length ? (
                      filteredTasks.map((task, idx) => (
                        <tr className="mt-5 border-b" key={idx}>
                          <td className="p-4">
                            <input
                              type="checkbox"
                              className="form-check-input cstm-ckeckbox"
                              checked={addToSprintTask?.includes(task.taskId)}
                              onChange={(e) => handleCheckBox(e, task.taskId)}
                            />
                          </td>
                          <td>
                            {task.assignedTo ? (
                              <CustomAvatar
                                id={task.assignedTo}
                                idPrefix={task.id}
                              />
                            ) : (
                              <div className="user">
                                <div className="avatar">
                                  <Avatar
                                    round={true}
                                    title="Unassigned"
                                    name="Un Assigned"
                                  />
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="cursor-pointer">
                            <Link to={`/task/${task.taskId}`} target="_blank">
                              {task.taskName}
                            </Link>
                          </td>
                          {trackingStatusList.length && (
                            <td>{getStatus(task.trackingStatusId)}</td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <div className="text-center p-5">
                            <i className="fas fa-bell-slash"></i>
                            <h2 className="font-bold mt-2">No Any Task yet!</h2>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = {
  // dispatching
  dispatchSaveSprintTaskRequest: saveSprintTaskRequest,
  dispatchGetAllTaskRequest: getAllTaskRequest,
  dispatchResetStateRequest: resetState,
};
export default connect(null, mapDispatchToProps)(AddBackModal);
