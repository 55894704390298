import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';

import {
  deleteTaskPriority,
  updateTaskPriority,
} from '../../../../store/project/actions';
import DeleteModal from '../../deleteModal';
import FormInputField from '../../../formInputField';
import IconPicker from '../../../admin/projects/components/IconPicker';
import Loader from '../../../loader';
import { useSelector } from 'react-redux';

const TaskPriorityTable = ({
  data,
  projectId,
  dispatchUpdateTaskPriority,
  dispatchDeleteTaskPriority,
}) => {
  const [DeleteShowModal, setDeleteShowModal] = useState(false);
  const [deletedData, setDeletedData] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [colorCode, setColorCode] = useState('#000000');
  const [selectIcon, setSelectIcon] = useState('');
  const [showBtn, setShowBtn] = useState(true);
  const { loading } = useSelector((state) => state.project);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const handleDelete = () => {
    if (deletedData.id) {
      dispatchDeleteTaskPriority({ id: deletedData.id });
      setDeleteShowModal(false);
    }
  };

  const onIconChange = (icon) => {
    setSelectIcon(icon);
  };

  const handleEditChanged = (singleItem) => {
    setEditMode(singleItem.id);
    setSelectIcon(singleItem.icon ? singleItem.icon : '');
    setColorCode(
      singleItem.priorityColor ? singleItem.priorityColor : '#000000'
    );
    reset(singleItem);
    setShowBtn(false);
  };

  const handleDataSubmit = (data) => {
    const finalData = {
      name: data.name,
      projectId: projectId,
      priorityId: data.id,
      icon: selectIcon,
      priorityColor: data.priorityColor,
    };
    dispatchUpdateTaskPriority(finalData);
    onEdit();
  };
  const onEdit = () => {
    setShowBtn(true);
    reset();
    setEditMode(null);
  };
  const onDelete = (task) => {
    setDeleteShowModal(true);
    setDeletedData(task);
  };

  return (
    <>
      <Loader isVisible={loading} />
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
        <tbody>
          {!!data &&
            !!data.length &&
            data.map((task, idx) => (
              <tr
                className="bg-white border-b select-none dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                key={idx}
              >
                <td className="px-6 py-3">
                  {editMode === task.id ? (
                    <div className="flex">
                      <div className="w-full">
                        <FormInputField
                          type={'text'}
                          fieldName={'name'}
                          register={register}
                          errors={errors}
                          placeholder={'Project-name'}
                        />
                      </div>
                      <div className="ml-3 w-full">
                        <input
                          type="color"
                          className=" "
                          value={colorCode}
                          {...register('priorityColor', {
                            onChange: (e) => setColorCode(e.target.value),
                          })}
                        />
                        <label className="mx-2">{colorCode}</label>
                      </div>
                      <div className="w-full">
                        <IconPicker
                          value={selectIcon}
                          onChange={onIconChange}
                        />
                      </div>
                      <div className="flex flex-wrap items-center justify-end w-full">
                        {!showBtn && (
                          <>
                            <button
                              className="mr-2 comment-edit-icon"
                              onClick={handleSubmit(handleDataSubmit)}
                            >
                              <i className="fa-solid fa-check"></i>
                            </button>
                            <button
                              className="dlt-comment-icon"
                              onClick={() => onEdit()}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>{task.name}</div>
                  )}
                </td>
                {editMode !== task.id && (
                  <>
                    <td>
                      <div>
                        {task.priorityColor ? (
                          <div
                            className={'w-[20px] h-[20px] rounded-full'}
                            style={{ backgroundColor: task.priorityColor }}
                          ></div>
                        ) : (
                          <div className="w-[20px] h-[20px] rounded-full bg-black"></div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        {task.icon ? (
                          <i className={task.icon}></i>
                        ) : (
                          <div>N/A</div>
                        )}
                      </div>
                    </td>
                  </>
                )}
                <td className="px-6 py-3">
                  <div className="text-left">
                    <div className={'dropdown inline-block relative'}>
                      <span
                        className="social-link cursor-pointer"
                        id="dropdownDefault"
                        data-dropdown-toggle="dropdown dAction"
                      >
                        <img
                          src="../../assets/img/dots-vertical.svg"
                          className="w-5 h-5"
                        />
                      </span>
                      <ul
                        id="dropdown-menu"
                        className="dropdown-menu absolute hidden pt-1 bg-white dark:bg-[#283046] shadow rounded py-2 px-4 mb-2 whitespace-nowrap"
                      >
                        <li className="mb-2">
                          <button
                            type="button"
                            onClick={() => handleEditChanged(task)}
                          >
                            <i
                              className="fa fa-pencil mr-2 cursor-pointer w-[18px]"
                              aria-hidden="true"
                            ></i>
                            Edit
                          </button>
                        </li>

                        <li className="text-red-500">
                          <button type="button" onClick={() => onDelete(task)}>
                            <i
                              className="fa fa-trash mr-2 cursor-pointer w-[18px]"
                              aria-hidden="true"
                            ></i>
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {DeleteShowModal && (
        <DeleteModal
          DeleteShowModal={DeleteShowModal}
          setDeleteShowModal={(val) => setDeleteShowModal(val)}
          handleDelete={handleDelete}
          data={deletedData}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchUpdateTaskPriority: updateTaskPriority,
  dispatchDeleteTaskPriority: deleteTaskPriority,
};
export default connect(null, mapDispatchToProps)(TaskPriorityTable);
