export const Icons = [
  { title: 'fab fa-500px' },

  { title: 'fab fa-accessible-icon' },

  { title: 'fab fa-accusoft' },

  { title: 'fab fa-acquisitions-incorporated' },

  { title: 'fas fa-ad' },

  { title: 'fas fa-address-book' },

  { title: 'far fa-address-book' },

  { title: 'fas fa-address-card' },

  { title: 'far fa-address-card' },

  { title: 'fas fa-adjust' },

  { title: 'fab fa-adn' },

  { title: 'fab fa-adversal' },

  { title: 'fab fa-affiliatetheme' },

  { title: 'fas fa-air-freshener' },

  { title: 'fab fa-algolia' },

  { title: 'fas fa-align-center' },

  { title: 'fas fa-align-justify' },

  { title: 'fas fa-align-left' },

  { title: 'fas fa-align-right' },

  { title: 'fab fa-alipay' },

  { title: 'fas fa-allergies' },

  { title: 'fab fa-amazon' },

  { title: 'fab fa-amazon-pay' },

  { title: 'fas fa-ambulance' },

  { title: 'fas fa-american-sign-language-interpreting' },

  { title: 'fab fa-amilia' },

  { title: 'fas fa-anchor' },

  { title: 'fab fa-android' },

  { title: 'fab fa-angellist' },

  { title: 'fas fa-angle-double-down' },

  { title: 'fas fa-angle-double-left' },

  { title: 'fas fa-angle-double-right' },

  { title: 'fas fa-angle-double-up' },

  { title: 'fas fa-angle-down' },

  { title: 'fas fa-angle-left' },

  { title: 'fas fa-angle-right' },

  { title: 'fas fa-angle-up' },

  { title: 'fas fa-angry' },

  { title: 'far fa-angry' },

  { title: 'fab fa-angrycreative' },

  { title: 'fab fa-angular' },

  { title: 'fas fa-ankh' },

  { title: 'fab fa-app-store' },

  { title: 'fab fa-app-store-ios' },

  { title: 'fab fa-apper' },

  { title: 'fab fa-apple' },

  { title: 'fas fa-apple-alt' },

  { title: 'fab fa-apple-pay' },

  { title: 'fas fa-archive' },

  { title: 'fas fa-archway' },

  { title: 'fas fa-arrow-alt-circle-down' },

  { title: 'far fa-arrow-alt-circle-down' },

  { title: 'fas fa-arrow-alt-circle-left' },

  { title: 'far fa-arrow-alt-circle-left' },

  { title: 'fas fa-arrow-alt-circle-right' },

  { title: 'far fa-arrow-alt-circle-right' },

  { title: 'fas fa-arrow-alt-circle-up' },

  { title: 'far fa-arrow-alt-circle-up' },

  { title: 'fas fa-arrow-circle-down' },

  { title: 'fas fa-arrow-circle-left' },

  { title: 'fas fa-arrow-circle-right' },

  { title: 'fas fa-arrow-circle-up' },

  { title: 'fas fa-arrow-down' },

  { title: 'fas fa-arrow-left' },

  { title: 'fas fa-arrow-right' },

  { title: 'fas fa-arrow-up' },

  { title: 'fas fa-arrows-alt' },

  { title: 'fas fa-arrows-alt-h' },

  { title: 'fas fa-arrows-alt-v' },

  { title: 'fas fa-assistive-listening-systems' },

  { title: 'fas fa-asterisk' },

  { title: 'fab fa-asymmetrik' },

  { title: 'fas fa-at' },

  { title: 'fas fa-atlas' },

  { title: 'fas fa-atom' },

  { title: 'fab fa-audible' },

  { title: 'fas fa-audio-description' },

  { title: 'fab fa-autoprefixer' },

  { title: 'fab fa-avianex' },

  { title: 'fab fa-aviato' },

  { title: 'fas fa-award' },

  { title: 'fab fa-aws' },

  { title: 'fas fa-backspace' },

  { title: 'fas fa-backward' },

  { title: 'fas fa-balance-scale' },

  { title: 'fas fa-ban' },

  { title: 'fas fa-band-aid' },

  { title: 'fab fa-bandcamp' },

  { title: 'fas fa-barcode' },

  { title: 'fas fa-bars' },

  { title: 'fas fa-baseball-ball' },

  { title: 'fas fa-basketball-ball' },

  { title: 'fas fa-bath' },

  { title: 'fas fa-battery-empty' },

  { title: 'fas fa-battery-full' },

  { title: 'fas fa-battery-half' },

  { title: 'fas fa-battery-quarter' },

  { title: 'fas fa-battery-three-quarters' },

  { title: 'fas fa-bed' },

  { title: 'fas fa-beer' },

  { title: 'fab fa-behance' },

  { title: 'fab fa-behance-square' },

  { title: 'fas fa-bell' },

  { title: 'far fa-bell' },

  { title: 'fas fa-bell-slash' },

  { title: 'far fa-bell-slash' },

  { title: 'fas fa-bezier-curve' },

  { title: 'fas fa-bible' },

  { title: 'fas fa-bicycle' },

  { title: 'fab fa-bimobject' },

  { title: 'fas fa-binoculars' },

  { title: 'fas fa-birthday-cake' },

  { title: 'fab fa-bitbucket' },

  { title: 'fab fa-bitcoin' },

  { title: 'fab fa-bity' },

  { title: 'fab fa-black-tie' },

  { title: 'fab fa-blackberry' },

  { title: 'fas fa-blender' },

  { title: 'fas fa-blender-phone' },

  { title: 'fas fa-blind' },

  { title: 'fab fa-blogger' },

  { title: 'fab fa-blogger-b' },

  { title: 'fab fa-bluetooth' },

  { title: 'fab fa-bluetooth-b' },

  { title: 'fas fa-bold' },

  { title: 'fas fa-bolt' },

  { title: 'fas fa-bomb' },

  { title: 'fas fa-bone' },

  { title: 'fas fa-bong' },

  { title: 'fas fa-book' },

  { title: 'fas fa-book-dead' },

  { title: 'fas fa-book-open' },

  { title: 'fas fa-book-reader' },

  { title: 'fas fa-bookmark' },

  { title: 'far fa-bookmark' },

  { title: 'fas fa-bowling-ball' },

  { title: 'fas fa-box' },

  { title: 'fas fa-box-open' },

  { title: 'fas fa-boxes' },

  { title: 'fas fa-braille' },

  { title: 'fas fa-brain' },

  { title: 'fas fa-briefcase' },

  { title: 'fas fa-briefcase-medical' },

  { title: 'fas fa-broadcast-tower' },

  { title: 'fas fa-broom' },

  { title: 'fas fa-brush' },

  { title: 'fab fa-btc' },

  { title: 'fas fa-bug' },

  { title: 'fas fa-building' },

  { title: 'far fa-building' },

  { title: 'fas fa-bullhorn' },

  { title: 'fas fa-bullseye' },

  { title: 'fas fa-burn' },

  { title: 'fab fa-buromobelexperte' },

  { title: 'fas fa-bus' },

  { title: 'fas fa-bus-alt' },

  { title: 'fas fa-business-time' },

  { title: 'fab fa-buysellads' },

  { title: 'fas fa-calculator' },

  { title: 'fas fa-calendar' },

  { title: 'far fa-calendar' },
  { title: 'fas fa-calendar-alt' },

  { title: 'far fa-calendar-alt' },

  { title: 'fas fa-calendar-check' },

  { title: 'far fa-calendar-check' },

  { title: 'fas fa-calendar-minus' },

  { title: 'far fa-calendar-minus' },

  { title: 'fas fa-calendar-plus' },

  { title: 'far fa-calendar-plus' },

  { title: 'fas fa-calendar-times' },

  { title: 'far fa-calendar-times' },

  { title: 'fas fa-camera' },

  { title: 'fas fa-camera-retro' },

  { title: 'fas fa-campground' },

  { title: 'fas fa-cannabis' },

  { title: 'fas fa-capsules' },

  { title: 'fas fa-car' },

  { title: 'fas fa-car-alt' },

  { title: 'fas fa-car-battery' },

  { title: 'fas fa-car-crash' },

  { title: 'fas fa-car-side' },

  { title: 'fas fa-caret-down' },

  { title: 'fas fa-caret-left' },

  { title: 'fas fa-caret-right' },

  { title: 'fas fa-caret-square-down' },

  { title: 'far fa-caret-square-down' },

  { title: 'fas fa-caret-square-left' },

  { title: 'far fa-caret-square-left' },

  { title: 'fas fa-caret-square-right' },

  { title: 'far fa-caret-square-right' },

  { title: 'fas fa-caret-square-up' },

  { title: 'far fa-caret-square-up' },

  { title: 'fas fa-caret-up' },

  { title: 'fas fa-cart-arrow-down' },

  { title: 'fas fa-cart-plus' },

  { title: 'fas fa-cat' },

  { title: 'fab fa-cc-amazon-pay' },

  { title: 'fab fa-cc-amex' },

  { title: 'fab fa-cc-apple-pay' },

  { title: 'fab fa-cc-diners-club' },

  { title: 'fab fa-cc-discover' },

  { title: 'fab fa-cc-jcb' },

  { title: 'fab fa-cc-mastercard' },

  { title: 'fab fa-cc-paypal' },

  { title: 'fab fa-cc-stripe' },

  { title: 'fab fa-cc-visa' },

  { title: 'fab fa-centercode' },

  { title: 'fas fa-certificate' },

  { title: 'fas fa-chair' },

  { title: 'fas fa-chalkboard' },

  { title: 'fas fa-chalkboard-teacher' },

  { title: 'fas fa-charging-station' },

  { title: 'fas fa-chart-area' },

  { title: 'fas fa-chart-bar' },

  { title: 'far fa-chart-bar' },

  { title: 'fas fa-chart-line' },

  { title: 'fas fa-chart-pie' },

  { title: 'fas fa-check' },

  { title: 'fas fa-check-circle' },

  { title: 'far fa-check-circle' },

  { title: 'fas fa-check-double' },

  { title: 'fas fa-check-square' },

  { title: 'far fa-check-square' },

  { title: 'fas fa-chess' },

  { title: 'fas fa-chess-bishop' },

  { title: 'fas fa-chess-board' },

  { title: 'fas fa-chess-king' },

  { title: 'fas fa-chess-knight' },

  { title: 'fas fa-chess-pawn' },

  { title: 'fas fa-chess-queen' },

  { title: 'fas fa-chess-rook' },

  { title: 'fas fa-chevron-circle-down' },

  { title: 'fas fa-chevron-circle-left' },

  { title: 'fas fa-chevron-circle-right' },

  { title: 'fas fa-chevron-circle-up' },

  { title: 'fas fa-chevron-down' },

  { title: 'fas fa-chevron-left' },

  { title: 'fas fa-chevron-right' },

  { title: 'fas fa-chevron-up' },

  { title: 'fas fa-child' },

  { title: 'fab fa-chrome' },

  { title: 'fas fa-church' },

  { title: 'fas fa-circle' },

  { title: 'far fa-circle' },

  { title: 'fas fa-circle-notch' },

  { title: 'fas fa-city' },

  { title: 'fas fa-clipboard' },

  { title: 'far fa-clipboard' },

  { title: 'fas fa-clipboard-check' },

  { title: 'fas fa-clipboard-list' },

  { title: 'fas fa-clock' },

  { title: 'far fa-clock' },

  { title: 'fas fa-clone' },

  { title: 'far fa-clone' },

  { title: 'fas fa-closed-captioning' },

  { title: 'far fa-closed-captioning' },

  { title: 'fas fa-cloud' },

  { title: 'fas fa-cloud-download-alt' },

  { title: 'fas fa-cloud-meatball' },

  { title: 'fas fa-cloud-moon' },

  { title: 'fas fa-cloud-moon-rain' },

  { title: 'fas fa-cloud-rain' },

  { title: 'fas fa-cloud-showers-heavy' },

  { title: 'fas fa-cloud-sun' },

  { title: 'fas fa-cloud-sun-rain' },

  { title: 'fas fa-cloud-upload-alt' },

  { title: 'fab fa-cloudscale' },

  { title: 'fab fa-cloudsmith' },

  { title: 'fab fa-cloudversify' },

  { title: 'fas fa-cocktail' },

  { title: 'fas fa-code' },

  { title: 'fas fa-code-branch' },

  { title: 'fab fa-codepen' },

  { title: 'fab fa-codiepie' },

  { title: 'fas fa-coffee' },

  { title: 'fas fa-cog' },

  { title: 'fas fa-cogs' },

  { title: 'fas fa-coins' },

  { title: 'fas fa-columns' },

  { title: 'fas fa-comment' },

  { title: 'far fa-comment' },

  { title: 'fas fa-comment-alt' },

  { title: 'far fa-comment-alt' },

  { title: 'fas fa-comment-dollar' },

  { title: 'fas fa-comment-dots' },

  { title: 'far fa-comment-dots' },

  { title: 'fas fa-comment-slash' },

  { title: 'fas fa-comments' },

  { title: 'far fa-comments' },

  { title: 'fas fa-comments-dollar' },

  { title: 'fas fa-compact-disc' },

  { title: 'fas fa-compass' },

  { title: 'far fa-compass' },

  { title: 'fas fa-compress' },

  { title: 'fas fa-concierge-bell' },

  { title: 'fab fa-connectdevelop' },

  { title: 'fab fa-contao' },

  { title: 'fas fa-cookie' },

  { title: 'fas fa-cookie-bite' },

  { title: 'fas fa-copy' },

  { title: 'far fa-copy' },

  { title: 'fas fa-copyright' },

  { title: 'far fa-copyright' },

  { title: 'fas fa-couch' },

  { title: 'fab fa-cpanel' },

  { title: 'fab fa-creative-commons' },

  { title: 'fab fa-creative-commons-by' },

  { title: 'fab fa-creative-commons-nc' },

  { title: 'fab fa-creative-commons-nc-eu' },

  { title: 'fab fa-creative-commons-nc-jp' },

  { title: 'fab fa-creative-commons-nd' },

  { title: 'fab fa-creative-commons-pd' },

  { title: 'fab fa-creative-commons-pd-alt' },

  { title: 'fab fa-creative-commons-remix' },

  { title: 'fab fa-creative-commons-sa' },

  { title: 'fab fa-creative-commons-sampling' },

  { title: 'fab fa-creative-commons-sampling-plus' },

  { title: 'fab fa-creative-commons-share' },
  { title: 'fab fa-creative-commons-zero' },

  { title: 'fas fa-credit-card' },

  { title: 'far fa-credit-card' },

  { title: 'fab fa-critical-role' },

  { title: 'fas fa-crop' },

  { title: 'fas fa-crop-alt' },

  { title: 'fas fa-cross' },

  { title: 'fas fa-crosshairs' },

  { title: 'fas fa-crow' },

  { title: 'fas fa-crown' },

  { title: 'fab fa-css3' },

  { title: 'fab fa-css3-alt' },

  { title: 'fas fa-cube' },

  { title: 'fas fa-cubes' },

  { title: 'fas fa-cut' },

  { title: 'fab fa-cuttlefish' },

  { title: 'fab fa-d-and-d' },

  { title: 'fab fa-d-and-d-beyond' },

  { title: 'fab fa-dashcube' },

  { title: 'fas fa-database' },

  { title: 'fas fa-deaf' },

  { title: 'fab fa-delicious' },

  { title: 'fas fa-democrat' },

  { title: 'fab fa-deploydog' },

  { title: 'fab fa-deskpro' },

  { title: 'fas fa-desktop' },

  { title: 'fab fa-dev' },

  { title: 'fab fa-deviantart' },

  { title: 'fas fa-dharmachakra' },

  { title: 'fas fa-diagnoses' },

  { title: 'fas fa-dice' },

  { title: 'fas fa-dice-d20' },

  { title: 'fas fa-dice-d6' },

  { title: 'fas fa-dice-five' },

  { title: 'fas fa-dice-four' },

  { title: 'fas fa-dice-one' },

  { title: 'fas fa-dice-six' },

  { title: 'fas fa-dice-three' },

  { title: 'fas fa-dice-two' },

  { title: 'fab fa-digg' },

  { title: 'fab fa-digital-ocean' },

  { title: 'fas fa-digital-tachograph' },

  { title: 'fas fa-directions' },

  { title: 'fab fa-discord' },

  { title: 'fab fa-discourse' },

  { title: 'fas fa-divide' },

  { title: 'fas fa-dizzy' },

  { title: 'far fa-dizzy' },

  { title: 'fas fa-dna' },

  { title: 'fab fa-dochub' },

  { title: 'fab fa-docker' },

  { title: 'fas fa-dog' },

  { title: 'fas fa-dollar-sign' },

  { title: 'fas fa-dolly' },

  { title: 'fas fa-dolly-flatbed' },

  { title: 'fas fa-donate' },

  { title: 'fas fa-door-closed' },

  { title: 'fas fa-door-open' },

  { title: 'fas fa-dot-circle' },

  { title: 'far fa-dot-circle' },

  { title: 'fas fa-dove' },

  { title: 'fas fa-download' },

  { title: 'fab fa-draft2digital' },

  { title: 'fas fa-drafting-compass' },

  { title: 'fas fa-dragon' },

  { title: 'fas fa-draw-polygon' },

  { title: 'fab fa-dribbble' },

  { title: 'fab fa-dribbble-square' },

  { title: 'fab fa-dropbox' },

  { title: 'fas fa-drum' },

  { title: 'fas fa-drum-steelpan' },

  { title: 'fas fa-drumstick-bite' },

  { title: 'fab fa-drupal' },

  { title: 'fas fa-dumbbell' },

  { title: 'fas fa-dungeon' },

  { title: 'fab fa-dyalog' },

  { title: 'fab fa-earlybirds' },

  { title: 'fab fa-ebay' },

  { title: 'fab fa-edge' },

  { title: 'fas fa-edit' },

  { title: 'far fa-edit' },

  { title: 'fas fa-eject' },

  { title: 'fab fa-elementor' },

  { title: 'fas fa-ellipsis-h' },

  { title: 'fas fa-ellipsis-v' },

  { title: 'fab fa-ello' },

  { title: 'fab fa-ember' },

  { title: 'fab fa-empire' },

  { title: 'fas fa-envelope' },

  { title: 'far fa-envelope' },

  { title: 'fas fa-envelope-open' },

  { title: 'far fa-envelope-open' },

  { title: 'fas fa-envelope-open-text' },

  { title: 'fas fa-envelope-square' },

  { title: 'fab fa-envira' },

  { title: 'fas fa-equals' },

  { title: 'fas fa-eraser' },

  { title: 'fab fa-erlang' },

  { title: 'fab fa-ethereum' },

  { title: 'fab fa-etsy' },

  { title: 'fas fa-euro-sign' },

  { title: 'fas fa-exchange-alt' },

  { title: 'fas fa-exclamation' },

  { title: 'fas fa-exclamation-circle' },

  { title: 'fas fa-exclamation-triangle' },

  { title: 'fas fa-expand' },

  { title: 'fas fa-expand-arrows-alt' },

  { title: 'fab fa-expeditedssl' },

  { title: 'fas fa-external-link-alt' },

  { title: 'fas fa-external-link-square-alt' },

  { title: 'fas fa-eye' },

  { title: 'far fa-eye' },

  { title: 'fas fa-eye-dropper' },

  { title: 'fas fa-eye-slash' },

  { title: 'far fa-eye-slash' },

  { title: 'fab fa-facebook' },

  { title: 'fab fa-facebook-f' },

  { title: 'fab fa-facebook-messenger' },

  { title: 'fab fa-facebook-square' },

  { title: 'fab fa-fantasy-flight-games' },

  { title: 'fas fa-fast-backward' },

  { title: 'fas fa-fast-forward' },

  { title: 'fas fa-fax' },

  { title: 'fas fa-feather' },

  { title: 'fas fa-feather-alt' },

  { title: 'fas fa-female' },

  { title: 'fas fa-fighter-jet' },

  { title: 'fas fa-file' },

  { title: 'far fa-file' },

  { title: 'fas fa-file-alt' },

  { title: 'far fa-file-alt' },

  { title: 'fas fa-file-archive' },

  { title: 'far fa-file-archive' },

  { title: 'fas fa-file-audio' },

  { title: 'far fa-file-audio' },

  { title: 'fas fa-file-code' },

  { title: 'far fa-file-code' },

  { title: 'fas fa-file-contract' },

  { title: 'fas fa-file-csv' },

  { title: 'fas fa-file-download' },

  { title: 'fas fa-file-excel' },

  { title: 'far fa-file-excel' },

  { title: 'fas fa-file-export' },

  { title: 'fas fa-file-image' },

  { title: 'far fa-file-image' },

  { title: 'fas fa-file-import' },

  { title: 'fas fa-file-invoice' },

  { title: 'fas fa-file-invoice-dollar' },

  { title: 'fas fa-file-medical' },

  { title: 'fas fa-file-medical-alt' },

  { title: 'fas fa-file-pdf' },

  { title: 'far fa-file-pdf' },

  { title: 'fas fa-file-powerpoint' },

  { title: 'far fa-file-powerpoint' },

  { title: 'fas fa-file-prescription' },

  { title: 'fas fa-file-signature' },

  { title: 'fas fa-file-upload' },

  { title: 'fas fa-file-video' },

  { title: 'far fa-file-video' },

  { title: 'fas fa-file-word' },

  { title: 'far fa-file-word' },

  { title: 'fas fa-fill' },

  { title: 'fas fa-fill-drip' },

  { title: 'fas fa-film' },

  { title: 'fas fa-filter' },

  { title: 'fas fa-fingerprint' },

  { title: 'fas fa-fire' },

  { title: 'fas fa-fire-extinguisher' },

  { title: 'fab fa-firefox' },

  { title: 'fas fa-first-aid' },

  { title: 'fab fa-first-order' },

  { title: 'fab fa-first-order-alt' },

  { title: 'fab fa-firstdraft' },

  { title: 'fas fa-fish' },

  { title: 'fas fa-fist-raised' },

  { title: 'fas fa-flag' },

  { title: 'far fa-flag' },

  { title: 'fas fa-flag-checkered' },

  { title: 'fas fa-flag-usa' },

  { title: 'fas fa-flask' },

  { title: 'fab fa-flickr' },

  { title: 'fab fa-flipboard' },

  { title: 'fas fa-flushed' },

  { title: 'far fa-flushed' },

  { title: 'fab fa-fly' },

  { title: 'fas fa-folder' },

  { title: 'far fa-folder' },

  { title: 'fas fa-folder-minus' },

  { title: 'fas fa-folder-open' },

  { title: 'far fa-folder-open' },

  { title: 'fas fa-folder-plus' },

  { title: 'fas fa-font' },

  { title: 'fab fa-font-awesome' },

  { title: 'fab fa-font-awesome-alt' },

  { title: 'fab fa-font-awesome-flag' },

  { title: 'far fa-font-awesome-logo-full' },

  { title: 'fas fa-font-awesome-logo-full' },

  { title: 'fab fa-font-awesome-logo-full' },

  { title: 'fab fa-fonticons' },

  { title: 'fab fa-fonticons-fi' },

  { title: 'fas fa-football-ball' },

  { title: 'fab fa-fort-awesome' },

  { title: 'fab fa-fort-awesome-alt' },

  { title: 'fab fa-forumbee' },

  { title: 'fas fa-forward' },

  { title: 'fab fa-foursquare' },

  { title: 'fab fa-free-code-camp' },

  { title: 'fab fa-freebsd' },

  { title: 'fas fa-frog' },

  { title: 'fas fa-frown' },

  { title: 'far fa-frown' },

  { title: 'fas fa-frown-open' },

  { title: 'far fa-frown-open' },

  { title: 'fab fa-fulcrum' },

  { title: 'fas fa-funnel-dollar' },

  { title: 'fas fa-futbol' },

  { title: 'far fa-futbol' },

  { title: 'fab fa-galactic-republic' },

  { title: 'fab fa-galactic-senate' },

  { title: 'fas fa-gamepad' },

  { title: 'fas fa-gas-pump' },

  { title: 'fas fa-gavel' },

  { title: 'fas fa-gem' },

  { title: 'far fa-gem' },

  { title: 'fas fa-genderless' },

  { title: 'fab fa-get-pocket' },

  { title: 'fab fa-gg' },

  { title: 'fab fa-gg-circle' },

  { title: 'fas fa-ghost' },

  { title: 'fas fa-gift' },

  { title: 'fab fa-git' },

  { title: 'fab fa-git-square' },

  { title: 'fab fa-github' },

  { title: 'fab fa-github-alt' },

  { title: 'fab fa-github-square' },

  { title: 'fab fa-gitkraken' },

  { title: 'fab fa-gitlab' },

  { title: 'fab fa-gitter' },

  { title: 'fas fa-glass-martini' },

  { title: 'fas fa-glass-martini-alt' },

  { title: 'fas fa-glasses' },

  { title: 'fab fa-glide' },

  { title: 'fab fa-glide-g' },

  { title: 'fas fa-globe' },

  { title: 'fas fa-globe-africa' },

  { title: 'fas fa-globe-americas' },

  { title: 'fas fa-globe-asia' },

  { title: 'fab fa-gofore' },

  { title: 'fas fa-golf-ball' },

  { title: 'fab fa-goodreads' },

  { title: 'fab fa-goodreads-g' },

  { title: 'fab fa-google' },

  { title: 'fab fa-google-drive' },

  { title: 'fab fa-google-play' },

  { title: 'fab fa-google-plus' },

  { title: 'fab fa-google-plus-g' },

  { title: 'fab fa-google-plus-square' },

  { title: 'fab fa-google-wallet' },

  { title: 'fas fa-gopuram' },

  { title: 'fas fa-graduation-cap' },

  { title: 'fab fa-gratipay' },

  { title: 'fab fa-grav' },

  { title: 'fas fa-greater-than' },

  { title: 'fas fa-greater-than-equal' },

  { title: 'fas fa-grimace' },

  { title: 'far fa-grimace' },

  { title: 'fas fa-grin' },

  { title: 'far fa-grin' },

  { title: 'fas fa-grin-alt' },

  { title: 'far fa-grin-alt' },

  { title: 'fas fa-grin-beam' },

  { title: 'far fa-grin-beam' },

  { title: 'fas fa-grin-beam-sweat' },

  { title: 'far fa-grin-beam-sweat' },

  { title: 'fas fa-grin-hearts' },

  { title: 'far fa-grin-hearts' },

  { title: 'fas fa-grin-squint' },

  { title: 'far fa-grin-squint' },

  { title: 'fas fa-grin-squint-tears' },

  { title: 'far fa-grin-squint-tears' },

  { title: 'fas fa-grin-stars' },

  { title: 'far fa-grin-stars' },

  { title: 'fas fa-grin-tears' },

  { title: 'far fa-grin-tears' },

  { title: 'fas fa-grin-tongue' },

  { title: 'far fa-grin-tongue' },

  { title: 'fas fa-grin-tongue-squint' },

  { title: 'far fa-grin-tongue-squint' },

  { title: 'fas fa-grin-tongue-wink' },

  { title: 'far fa-grin-tongue-wink' },

  { title: 'fas fa-grin-wink' },

  { title: 'far fa-grin-wink' },

  { title: 'fas fa-grip-horizontal' },

  { title: 'fas fa-grip-vertical' },

  { title: 'fab fa-gripfire' },

  { title: 'fab fa-grunt' },

  { title: 'fab fa-gulp' },

  { title: 'fas fa-h-square' },

  { title: 'fab fa-hacker-news' },

  { title: 'fab fa-hacker-news-square' },

  { title: 'fab fa-hackerrank' },

  { title: 'fas fa-hammer' },

  { title: 'fas fa-hamsa' },

  { title: 'fas fa-hand-holding' },

  { title: 'fas fa-hand-holding-heart' },

  { title: 'fas fa-hand-holding-usd' },

  { title: 'fas fa-hand-lizard' },

  { title: 'far fa-hand-lizard' },

  { title: 'fas fa-hand-paper' },

  { title: 'far fa-hand-paper' },

  { title: 'fas fa-hand-peace' },

  { title: 'far fa-hand-peace' },

  { title: 'fas fa-hand-point-down' },

  { title: 'far fa-hand-point-down' },

  { title: 'fas fa-hand-point-left' },

  { title: 'far fa-hand-point-left' },

  { title: 'fas fa-hand-point-right' },

  { title: 'far fa-hand-point-right' },

  { title: 'fas fa-hand-point-up' },

  { title: 'far fa-hand-point-up' },

  { title: 'fas fa-hand-pointer' },

  { title: 'far fa-hand-pointer' },

  { title: 'fas fa-hand-rock' },

  { title: 'far fa-hand-rock' },

  { title: 'fas fa-hand-scissors' },

  { title: 'far fa-hand-scissors' },

  { title: 'fas fa-hand-spock' },

  { title: 'far fa-hand-spock' },

  { title: 'fas fa-hands' },

  { title: 'fas fa-hands-helping' },

  { title: 'fas fa-handshake' },

  { title: 'far fa-handshake' },

  { title: 'fas fa-hanukiah' },

  { title: 'fas fa-hashtag' },

  { title: 'fas fa-hat-wizard' },

  { title: 'fas fa-haykal' },

  { title: 'fas fa-hdd' },

  { title: 'far fa-hdd' },

  { title: 'fas fa-heading' },

  { title: 'fas fa-headphones' },

  { title: 'fas fa-headphones-alt' },

  { title: 'fas fa-headset' },

  { title: 'fas fa-heart' },

  { title: 'far fa-heart' },

  { title: 'fas fa-heartbeat' },

  { title: 'fas fa-helicopter' },

  { title: 'fas fa-highlighter' },

  { title: 'fas fa-hippo' },

  { title: 'fab fa-hips' },

  { title: 'fab fa-hire-a-helper' },

  { title: 'fas fa-history' },

  { title: 'fas fa-hockey-puck' },

  { title: 'fas fa-home' },

  { title: 'fab fa-hooli' },

  { title: 'fab fa-hornbill' },

  { title: 'fas fa-horse' },

  { title: 'fas fa-hospital' },

  { title: 'far fa-hospital' },

  { title: 'fas fa-hospital-alt' },

  { title: 'fas fa-hospital-symbol' },

  { title: 'fas fa-hot-tub' },

  { title: 'fas fa-hotel' },

  { title: 'fab fa-hotjar' },

  { title: 'fas fa-hourglass' },

  { title: 'far fa-hourglass' },

  { title: 'fas fa-hourglass-end' },

  { title: 'fas fa-hourglass-half' },

  { title: 'fas fa-hourglass-start' },

  { title: 'fas fa-house-damage' },

  { title: 'fab fa-houzz' },

  { title: 'fas fa-hryvnia' },

  { title: 'fab fa-html5' },

  { title: 'fab fa-hubspot' },

  { title: 'fas fa-i-cursor' },

  { title: 'fas fa-id-badge' },

  { title: 'far fa-id-badge' },

  { title: 'fas fa-id-card' },

  { title: 'far fa-id-card' },

  { title: 'fas fa-id-card-alt' },

  { title: 'fas fa-image' },

  { title: 'far fa-image' },

  { title: 'fas fa-images' },

  { title: 'far fa-images' },

  { title: 'fab fa-imdb' },

  { title: 'fas fa-inbox' },

  { title: 'fas fa-indent' },

  { title: 'fas fa-industry' },

  { title: 'fas fa-infinity' },

  { title: 'fas fa-info' },

  { title: 'fas fa-info-circle' },

  { title: 'fab fa-instagram' },

  { title: 'fab fa-internet-explorer' },

  { title: 'fab fa-ioxhost' },

  { title: 'fas fa-italic' },

  { title: 'fab fa-itunes' },

  { title: 'fab fa-itunes-note' },

  { title: 'fab fa-java' },

  { title: 'fas fa-jedi' },

  { title: 'fab fa-jedi-order' },

  { title: 'fab fa-jenkins' },

  { title: 'fab fa-joget' },

  { title: 'fas fa-joint' },

  { title: 'fab fa-joomla' },

  { title: 'fas fa-journal-whills' },

  { title: 'fab fa-js' },

  { title: 'fab fa-js-square' },

  { title: 'fab fa-jsfiddle' },

  { title: 'fas fa-kaaba' },

  { title: 'fab fa-kaggle' },

  { title: 'fas fa-key' },

  { title: 'fab fa-keybase' },

  { title: 'fas fa-keyboard' },

  { title: 'far fa-keyboard' },

  { title: 'fab fa-keycdn' },

  { title: 'fas fa-khanda' },

  { title: 'fab fa-kickstarter' },

  { title: 'fab fa-kickstarter-k' },

  { title: 'fas fa-kiss' },

  { title: 'far fa-kiss' },

  { title: 'fas fa-kiss-beam' },

  { title: 'far fa-kiss-beam' },

  { title: 'fas fa-kiss-wink-heart' },

  { title: 'far fa-kiss-wink-heart' },

  { title: 'fas fa-kiwi-bird' },

  { title: 'fab fa-korvue' },

  { title: 'fas fa-landmark' },

  { title: 'fas fa-language' },

  { title: 'fas fa-laptop' },

  { title: 'fas fa-laptop-code' },

  { title: 'fab fa-laravel' },

  { title: 'fab fa-lastfm' },

  { title: 'fab fa-lastfm-square' },

  { title: 'fas fa-laugh' },

  { title: 'far fa-laugh' },

  { title: 'fas fa-laugh-beam' },

  { title: 'far fa-laugh-beam' },

  { title: 'fas fa-laugh-squint' },

  { title: 'far fa-laugh-squint' },

  { title: 'fas fa-laugh-wink' },

  { title: 'far fa-laugh-wink' },

  { title: 'fas fa-layer-group' },

  { title: 'fas fa-leaf' },

  { title: 'fab fa-leanpub' },

  { title: 'fas fa-lemon' },

  { title: 'far fa-lemon' },

  { title: 'fab fa-less' },

  { title: 'fas fa-less-than' },

  { title: 'fas fa-less-than-equal' },

  { title: 'fas fa-level-down-alt' },

  { title: 'fas fa-level-up-alt' },

  { title: 'fas fa-life-ring' },

  { title: 'far fa-life-ring' },

  { title: 'fas fa-lightbulb' },

  { title: 'far fa-lightbulb' },

  { title: 'fab fa-line' },

  { title: 'fas fa-link' },

  { title: 'fab fa-linkedin' },

  { title: 'fab fa-linkedin-in' },

  { title: 'fab fa-linode' },

  { title: 'fab fa-linux' },

  { title: 'fas fa-lira-sign' },

  { title: 'fas fa-list' },

  { title: 'fas fa-list-alt' },

  { title: 'far fa-list-alt' },

  { title: 'fas fa-list-ol' },

  { title: 'fas fa-list-ul' },

  { title: 'fas fa-location-arrow' },

  { title: 'fas fa-lock' },

  { title: 'fas fa-lock-open' },

  { title: 'fas fa-long-arrow-alt-down' },

  { title: 'fas fa-long-arrow-alt-left' },

  { title: 'fas fa-long-arrow-alt-right' },

  { title: 'fas fa-long-arrow-alt-up' },

  { title: 'fas fa-low-vision' },

  { title: 'fas fa-luggage-cart' },

  { title: 'fab fa-lyft' },

  { title: 'fab fa-magento' },

  { title: 'fas fa-magic' },

  { title: 'fas fa-magnet' },

  { title: 'fas fa-mail-bulk' },

  { title: 'fab fa-mailchimp' },

  { title: 'fas fa-male' },

  { title: 'fab fa-mandalorian' },

  { title: 'fas fa-map' },

  { title: 'far fa-map' },

  { title: 'fas fa-map-marked' },

  { title: 'fas fa-map-marked-alt' },

  { title: 'fas fa-map-marker' },

  { title: 'fas fa-map-marker-alt' },

  { title: 'fas fa-map-pin' },

  { title: 'fas fa-map-signs' },

  { title: 'fab fa-markdown' },

  { title: 'fas fa-marker' },

  { title: 'fas fa-mars' },

  { title: 'fas fa-mars-double' },

  { title: 'fas fa-mars-stroke' },

  { title: 'fas fa-mars-stroke-h' },

  { title: 'fas fa-mars-stroke-v' },

  { title: 'fas fa-mask' },

  { title: 'fab fa-mastodon' },

  { title: 'fab fa-maxcdn' },

  { title: 'fas fa-medal' },

  { title: 'fab fa-medapps' },

  { title: 'fab fa-medium' },

  { title: 'fab fa-medium-m' },

  { title: 'fas fa-medkit' },

  { title: 'fab fa-medrt' },

  { title: 'fab fa-meetup' },

  { title: 'fab fa-megaport' },

  { title: 'fas fa-meh' },

  { title: 'far fa-meh' },

  { title: 'fas fa-meh-blank' },

  { title: 'far fa-meh-blank' },

  { title: 'fas fa-meh-rolling-eyes' },

  { title: 'far fa-meh-rolling-eyes' },

  { title: 'fas fa-memory' },

  { title: 'fas fa-menorah' },

  { title: 'fas fa-mercury' },

  { title: 'fas fa-meteor' },

  { title: 'fas fa-microchip' },

  { title: 'fas fa-microphone' },

  { title: 'fas fa-microphone-alt' },

  { title: 'fas fa-microphone-alt-slash' },

  { title: 'fas fa-microphone-slash' },

  { title: 'fas fa-microscope' },

  { title: 'fab fa-microsoft' },

  { title: 'fas fa-minus' },

  { title: 'fas fa-minus-circle' },

  { title: 'fas fa-minus-square' },

  { title: 'far fa-minus-square' },

  { title: 'fab fa-mix' },

  { title: 'fab fa-mixcloud' },

  { title: 'fab fa-mizuni' },

  { title: 'fas fa-mobile' },

  { title: 'fas fa-mobile-alt' },

  { title: 'fab fa-modx' },

  { title: 'fab fa-monero' },

  { title: 'fas fa-money-bill' },

  { title: 'fas fa-money-bill-alt' },

  { title: 'far fa-money-bill-alt' },

  { title: 'fas fa-money-bill-wave' },

  { title: 'fas fa-money-bill-wave-alt' },

  { title: 'fas fa-money-check' },

  { title: 'fas fa-money-check-alt' },

  { title: 'fas fa-monument' },

  { title: 'fas fa-moon' },

  { title: 'far fa-moon' },

  { title: 'fas fa-mortar-pestle' },

  { title: 'fas fa-mosque' },

  { title: 'fas fa-motorcycle' },

  { title: 'fas fa-mountain' },

  { title: 'fas fa-music' },

  { title: 'fab fa-napster' },

  { title: 'fab fa-neos' },

  { title: 'fas fa-network-wired' },

  { title: 'fas fa-neuter' },

  { title: 'fas fa-newspaper' },

  { title: 'far fa-newspaper' },

  { title: 'fab fa-nimblr' },

  { title: 'fab fa-nintendo-switch' },

  { title: 'fab fa-node' },

  { title: 'fab fa-node-js' },

  { title: 'fas fa-not-equal' },

  { title: 'fas fa-notes-medical' },

  { title: 'fab fa-npm' },

  { title: 'fab fa-ns8' },

  { title: 'fab fa-nutritionix' },

  { title: 'fas fa-object-group' },

  { title: 'far fa-object-group' },

  { title: 'fas fa-object-ungroup' },

  { title: 'far fa-object-ungroup' },

  { title: 'fab fa-odnoklassniki' },

  { title: 'fab fa-odnoklassniki-square' },

  { title: 'fas fa-oil-can' },

  { title: 'fab fa-old-republic' },

  { title: 'fas fa-om' },

  { title: 'fab fa-opencart' },

  { title: 'fab fa-openid' },

  { title: 'fab fa-opera' },

  { title: 'fab fa-optin-monster' },

  { title: 'fab fa-osi' },

  { title: 'fas fa-otter' },

  { title: 'fas fa-outdent' },

  { title: 'fab fa-page4' },

  { title: 'fab fa-pagelines' },

  { title: 'fas fa-paint-brush' },

  { title: 'fas fa-paint-roller' },

  { title: 'fas fa-palette' },

  { title: 'fab fa-palfed' },

  { title: 'fas fa-pallet' },

  { title: 'fas fa-paper-plane' },

  { title: 'far fa-paper-plane' },

  { title: 'fas fa-paperclip' },

  { title: 'fas fa-parachute-box' },

  { title: 'fas fa-paragraph' },

  { title: 'fas fa-parking' },

  { title: 'fas fa-passport' },

  { title: 'fas fa-pastafarianism' },

  { title: 'fas fa-paste' },

  { title: 'fab fa-patreon' },

  { title: 'fas fa-pause' },

  { title: 'fas fa-pause-circle' },

  { title: 'far fa-pause-circle' },

  { title: 'fas fa-paw' },

  { title: 'fab fa-paypal' },

  { title: 'fas fa-peace' },

  { title: 'fas fa-pen' },

  { title: 'fas fa-pen-alt' },

  { title: 'fas fa-pen-fancy' },

  { title: 'fas fa-pen-nib' },

  { title: 'fas fa-pen-square' },

  { title: 'fas fa-pencil-alt' },

  { title: 'fas fa-pencil-ruler' },

  { title: 'fab fa-penny-arcade' },

  { title: 'fas fa-people-carry' },

  { title: 'fas fa-percent' },

  { title: 'fas fa-percentage' },

  { title: 'fab fa-periscope' },

  { title: 'fas fa-person-booth' },

  { title: 'fab fa-phabricator' },

  { title: 'fab fa-phoenix-framework' },

  { title: 'fab fa-phoenix-squadron' },

  { title: 'fas fa-phone' },

  { title: 'fas fa-phone-slash' },

  { title: 'fas fa-phone-square' },

  { title: 'fas fa-phone-volume' },

  { title: 'fab fa-php' },

  { title: 'fab fa-pied-piper' },

  { title: 'fab fa-pied-piper-alt' },

  { title: 'fab fa-pied-piper-hat' },

  { title: 'fab fa-pied-piper-pp' },

  { title: 'fas fa-piggy-bank' },

  { title: 'fas fa-pills' },

  { title: 'fab fa-pinterest' },

  { title: 'fab fa-pinterest-p' },

  { title: 'fab fa-pinterest-square' },

  { title: 'fas fa-place-of-worship' },

  { title: 'fas fa-plane' },

  { title: 'fas fa-plane-arrival' },

  { title: 'fas fa-plane-departure' },

  { title: 'fas fa-play' },

  { title: 'fas fa-play-circle' },

  { title: 'far fa-play-circle' },

  { title: 'fab fa-playstation' },

  { title: 'fas fa-plug' },

  { title: 'fas fa-plus' },

  { title: 'fas fa-plus-circle' },

  { title: 'fas fa-plus-square' },

  { title: 'far fa-plus-square' },

  { title: 'fas fa-podcast' },

  { title: 'fas fa-poll' },

  { title: 'fas fa-poll-h' },

  { title: 'fas fa-poo' },

  { title: 'fas fa-poo-storm' },

  { title: 'fas fa-poop' },

  { title: 'fas fa-portrait' },

  { title: 'fas fa-pound-sign' },

  { title: 'fas fa-power-off' },

  { title: 'fas fa-pray' },

  { title: 'fas fa-praying-hands' },

  { title: 'fas fa-prescription' },

  { title: 'fas fa-prescription-bottle' },

  { title: 'fas fa-prescription-bottle-alt' },

  { title: 'fas fa-print' },

  { title: 'fas fa-procedures' },

  { title: 'fab fa-product-hunt' },

  { title: 'fas fa-project-diagram' },

  { title: 'fab fa-pushed' },

  { title: 'fas fa-puzzle-piece' },

  { title: 'fab fa-python' },

  { title: 'fab fa-qq' },

  { title: 'fas fa-qrcode' },

  { title: 'fas fa-question' },

  { title: 'fas fa-question-circle' },

  { title: 'far fa-question-circle' },

  { title: 'fas fa-quidditch' },

  { title: 'fab fa-quinscape' },

  { title: 'fab fa-quora' },

  { title: 'fas fa-quote-left' },

  { title: 'fas fa-quote-right' },

  { title: 'fas fa-quran' },

  { title: 'fab fa-r-project' },

  { title: 'fas fa-rainbow' },

  { title: 'fas fa-random' },

  { title: 'fab fa-ravelry' },

  { title: 'fab fa-react' },

  { title: 'fab fa-reacteurope' },

  { title: 'fab fa-readme' },

  { title: 'fab fa-rebel' },

  { title: 'fas fa-receipt' },

  { title: 'fas fa-recycle' },

  { title: 'fab fa-red-river' },

  { title: 'fab fa-reddit' },

  { title: 'fab fa-reddit-alien' },

  { title: 'fab fa-reddit-square' },

  { title: 'fas fa-redo' },

  { title: 'fas fa-redo-alt' },

  { title: 'fas fa-registered' },

  { title: 'far fa-registered' },

  { title: 'fab fa-renren' },

  { title: 'fas fa-reply' },

  { title: 'fas fa-reply-all' },

  { title: 'fab fa-replyd' },

  { title: 'fas fa-republican' },

  { title: 'fab fa-researchgate' },

  { title: 'fab fa-resolving' },

  { title: 'fas fa-retweet' },

  { title: 'fab fa-rev' },

  { title: 'fas fa-ribbon' },

  { title: 'fas fa-ring' },

  { title: 'fas fa-road' },

  { title: 'fas fa-robot' },

  { title: 'fas fa-rocket' },

  { title: 'fab fa-rocketchat' },

  { title: 'fab fa-rockrms' },

  { title: 'fas fa-route' },

  { title: 'fas fa-rss' },

  { title: 'fas fa-rss-square' },

  { title: 'fas fa-ruble-sign' },

  { title: 'fas fa-ruler' },
  { title: 'fas fa-ruler-combined' },

  { title: 'fas fa-ruler-horizontal' },

  { title: 'fas fa-ruler-vertical' },

  { title: 'fas fa-running' },

  { title: 'fas fa-rupee-sign' },

  { title: 'fas fa-sad-cry' },

  { title: 'far fa-sad-cry' },

  { title: 'fas fa-sad-tear' },

  { title: 'far fa-sad-tear' },

  { title: 'fab fa-safari' },

  { title: 'fab fa-sass' },

  { title: 'fas fa-save' },

  { title: 'far fa-save' },

  { title: 'fab fa-schlix' },

  { title: 'fas fa-school' },

  { title: 'fas fa-screwdriver' },

  { title: 'fab fa-scribd' },

  { title: 'fas fa-scroll' },

  { title: 'fas fa-search' },

  { title: 'fas fa-search-dollar' },

  { title: 'fas fa-search-location' },

  { title: 'fas fa-search-minus' },

  { title: 'fas fa-search-plus' },

  { title: 'fab fa-searchengin' },

  { title: 'fas fa-seedling' },

  { title: 'fab fa-sellcast' },

  { title: 'fab fa-sellsy' },

  { title: 'fas fa-server' },

  { title: 'fab fa-servicestack' },

  { title: 'fas fa-shapes' },

  { title: 'fas fa-share' },

  { title: 'fas fa-share-alt' },

  { title: 'fas fa-share-alt-square' },

  { title: 'fas fa-share-square' },

  { title: 'far fa-share-square' },

  { title: 'fas fa-shekel-sign' },

  { title: 'fas fa-shield-alt' },

  { title: 'fas fa-ship' },

  { title: 'fas fa-shipping-fast' },

  { title: 'fab fa-shirtsinbulk' },

  { title: 'fas fa-shoe-prints' },

  { title: 'fas fa-shopping-bag' },

  { title: 'fas fa-shopping-basket' },

  { title: 'fas fa-shopping-cart' },

  { title: 'fab fa-shopware' },

  { title: 'fas fa-shower' },

  { title: 'fas fa-shuttle-van' },

  { title: 'fas fa-sign' },

  { title: 'fas fa-sign-in-alt' },

  { title: 'fas fa-sign-language' },

  { title: 'fas fa-sign-out-alt' },

  { title: 'fas fa-signal' },

  { title: 'fas fa-signature' },

  { title: 'fab fa-simplybuilt' },

  { title: 'fab fa-sistrix' },

  { title: 'fas fa-sitemap' },

  { title: 'fab fa-sith' },

  { title: 'fas fa-skull' },

  { title: 'fas fa-skull-crossbones' },

  { title: 'fab fa-skyatlas' },

  { title: 'fab fa-skype' },

  { title: 'fab fa-slack' },

  { title: 'fab fa-slack-hash' },

  { title: 'fas fa-slash' },

  { title: 'fas fa-sliders-h' },

  { title: 'fab fa-slideshare' },

  { title: 'fas fa-smile' },

  { title: 'far fa-smile' },

  { title: 'fas fa-smile-beam' },

  { title: 'far fa-smile-beam' },

  { title: 'fas fa-smile-wink' },

  { title: 'far fa-smile-wink' },

  { title: 'fas fa-smog' },

  { title: 'fas fa-smoking' },

  { title: 'fas fa-smoking-ban' },

  { title: 'fab fa-snapchat' },

  { title: 'fab fa-snapchat-ghost' },

  { title: 'fab fa-snapchat-square' },

  { title: 'fas fa-snowflake' },

  { title: 'far fa-snowflake' },

  { title: 'fas fa-socks' },

  { title: 'fas fa-solar-panel' },

  { title: 'fas fa-sort' },

  { title: 'fas fa-sort-alpha-down' },

  { title: 'fas fa-sort-alpha-up' },

  { title: 'fas fa-sort-amount-down' },

  { title: 'fas fa-sort-amount-up' },

  { title: 'fas fa-sort-down' },

  { title: 'fas fa-sort-numeric-down' },

  { title: 'fas fa-sort-numeric-up' },

  { title: 'fas fa-sort-up' },

  { title: 'fab fa-soundcloud' },

  { title: 'fas fa-spa' },

  { title: 'fas fa-space-shuttle' },

  { title: 'fab fa-speakap' },

  { title: 'fas fa-spider' },

  { title: 'fas fa-spinner' },

  { title: 'fas fa-splotch' },

  { title: 'fab fa-spotify' },

  { title: 'fas fa-spray-can' },

  { title: 'fas fa-square' },

  { title: 'far fa-square' },

  { title: 'fas fa-square-full' },

  { title: 'fas fa-square-root-alt' },

  { title: 'fab fa-squarespace' },

  { title: 'fab fa-stack-exchange' },

  { title: 'fab fa-stack-overflow' },

  { title: 'fas fa-stamp' },

  { title: 'fas fa-star' },

  { title: 'far fa-star' },

  { title: 'fas fa-star-and-crescent' },

  { title: 'fas fa-star-half' },

  { title: 'far fa-star-half' },

  { title: 'fas fa-star-half-alt' },

  { title: 'fas fa-star-of-david' },

  { title: 'fas fa-star-of-life' },

  { title: 'fab fa-staylinked' },

  { title: 'fab fa-steam' },

  { title: 'fab fa-steam-square' },

  { title: 'fab fa-steam-symbol' },

  { title: 'fas fa-step-backward' },

  { title: 'fas fa-step-forward' },

  { title: 'fas fa-stethoscope' },

  { title: 'fab fa-sticker-mule' },

  { title: 'fas fa-sticky-note' },

  { title: 'far fa-sticky-note' },

  { title: 'fas fa-stop' },

  { title: 'fas fa-stop-circle' },

  { title: 'far fa-stop-circle' },

  { title: 'fas fa-stopwatch' },

  { title: 'fas fa-store' },

  { title: 'fas fa-store-alt' },

  { title: 'fab fa-strava' },

  { title: 'fas fa-stream' },

  { title: 'fas fa-street-view' },

  { title: 'fas fa-strikethrough' },

  { title: 'fab fa-stripe' },

  { title: 'fab fa-stripe-s' },

  { title: 'fas fa-stroopwafel' },

  { title: 'fab fa-studiovinari' },

  { title: 'fab fa-stumbleupon' },

  { title: 'fab fa-stumbleupon-circle' },

  { title: 'fas fa-subscript' },

  { title: 'fas fa-subway' },

  { title: 'fas fa-suitcase' },

  { title: 'fas fa-suitcase-rolling' },

  { title: 'fas fa-sun' },

  { title: 'far fa-sun' },

  { title: 'fab fa-superpowers' },

  { title: 'fas fa-superscript' },

  { title: 'fab fa-supple' },

  { title: 'fas fa-surprise' },

  { title: 'far fa-surprise' },

  { title: 'fas fa-swatchbook' },

  { title: 'fas fa-swimmer' },

  { title: 'fas fa-swimming-pool' },

  { title: 'fas fa-synagogue' },

  { title: 'fas fa-sync' },

  { title: 'fas fa-sync-alt' },

  { title: 'fas fa-syringe' },

  { title: 'fas fa-table' },

  { title: 'fas fa-table-tennis' },

  { title: 'fas fa-tablet' },

  { title: 'fas fa-tablet-alt' },

  { title: 'fas fa-tablets' },

  { title: 'fas fa-tachometer-alt' },

  { title: 'fas fa-tag' },

  { title: 'fas fa-tags' },

  { title: 'fas fa-tape' },

  { title: 'fas fa-tasks' },

  { title: 'fas fa-taxi' },

  { title: 'fab fa-teamspeak' },

  { title: 'fas fa-teeth' },

  { title: 'fas fa-teeth-open' },

  { title: 'fab fa-telegram' },

  { title: 'fab fa-telegram-plane' },

  { title: 'fas fa-temperature-high' },

  { title: 'fas fa-temperature-low' },

  { title: 'fab fa-tencent-weibo' },

  { title: 'fas fa-terminal' },

  { title: 'fas fa-text-height' },

  { title: 'fas fa-text-width' },

  { title: 'fas fa-th' },

  { title: 'fas fa-th-large' },

  { title: 'fas fa-th-list' },

  { title: 'fab fa-the-red-yeti' },

  { title: 'fas fa-theater-masks' },

  { title: 'fab fa-themeco' },

  { title: 'fab fa-themeisle' },

  { title: 'fas fa-thermometer' },

  { title: 'fas fa-thermometer-empty' },

  { title: 'fas fa-thermometer-full' },

  { title: 'fas fa-thermometer-half' },

  { title: 'fas fa-thermometer-quarter' },

  { title: 'fas fa-thermometer-three-quarters' },

  { title: 'fab fa-think-peaks' },

  { title: 'fas fa-thumbs-down' },

  { title: 'far fa-thumbs-down' },

  { title: 'fas fa-thumbs-up' },

  { title: 'far fa-thumbs-up' },

  { title: 'fas fa-thumbtack' },

  { title: 'fas fa-ticket-alt' },

  { title: 'fas fa-times' },

  { title: 'fas fa-times-circle' },

  { title: 'far fa-times-circle' },

  { title: 'fas fa-tint' },

  { title: 'fas fa-tint-slash' },

  { title: 'fas fa-tired' },

  { title: 'far fa-tired' },

  { title: 'fas fa-toggle-off' },

  { title: 'fas fa-toggle-on' },

  { title: 'fas fa-toilet-paper' },

  { title: 'fas fa-toolbox' },

  { title: 'fas fa-tooth' },

  { title: 'fas fa-torah' },

  { title: 'fas fa-torii-gate' },

  { title: 'fas fa-tractor' },

  { title: 'fab fa-trade-federation' },

  { title: 'fas fa-trademark' },

  { title: 'fas fa-traffic-light' },

  { title: 'fas fa-train' },

  { title: 'fas fa-transgender' },

  { title: 'fas fa-transgender-alt' },

  { title: 'fas fa-trash' },

  { title: 'fas fa-trash-alt' },

  { title: 'far fa-trash-alt' },

  { title: 'fas fa-tree' },

  { title: 'fab fa-trello' },

  { title: 'fab fa-tripadvisor' },

  { title: 'fas fa-trophy' },

  { title: 'fas fa-truck' },

  { title: 'fas fa-truck-loading' },

  { title: 'fas fa-truck-monster' },
  { title: 'fas fa-truck-moving' },

  { title: 'fas fa-truck-pickup' },

  { title: 'fas fa-tshirt' },

  { title: 'fas fa-tty' },

  { title: 'fab fa-tumblr' },

  { title: 'fab fa-tumblr-square' },

  { title: 'fas fa-tv' },

  { title: 'fab fa-twitch' },

  { title: 'fab fa-twitter' },

  { title: 'fab fa-twitter-square' },

  { title: 'fab fa-typo3' },

  { title: 'fab fa-uber' },

  { title: 'fab fa-uikit' },

  { title: 'fas fa-umbrella' },

  { title: 'fas fa-umbrella-beach' },

  { title: 'fas fa-underline' },

  { title: 'fas fa-undo' },

  { title: 'fas fa-undo-alt' },

  { title: 'fab fa-uniregistry' },

  { title: 'fas fa-universal-access' },

  { title: 'fas fa-university' },

  { title: 'fas fa-unlink' },

  { title: 'fas fa-unlock' },

  { title: 'fas fa-unlock-alt' },

  { title: 'fab fa-untappd' },

  { title: 'fas fa-upload' },

  { title: 'fab fa-usb' },

  { title: 'fas fa-user' },

  { title: 'far fa-user' },

  { title: 'fas fa-user-alt' },

  { title: 'fas fa-user-alt-slash' },

  { title: 'fas fa-user-astronaut' },

  { title: 'fas fa-user-check' },

  { title: 'fas fa-user-circle' },

  { title: 'far fa-user-circle' },

  { title: 'fas fa-user-clock' },

  { title: 'fas fa-user-cog' },

  { title: 'fas fa-user-edit' },

  { title: 'fas fa-user-friends' },

  { title: 'fas fa-user-graduate' },

  { title: 'fas fa-user-injured' },

  { title: 'fas fa-user-lock' },

  { title: 'fas fa-user-md' },

  { title: 'fas fa-user-minus' },

  { title: 'fas fa-user-ninja' },

  { title: 'fas fa-user-plus' },

  { title: 'fas fa-user-secret' },

  { title: 'fas fa-user-shield' },

  { title: 'fas fa-user-slash' },

  { title: 'fas fa-user-tag' },

  { title: 'fas fa-user-tie' },

  { title: 'fas fa-user-times' },

  { title: 'fas fa-users' },

  { title: 'fas fa-users-cog' },

  { title: 'fab fa-ussunnah' },

  { title: 'fas fa-utensil-spoon' },

  { title: 'fas fa-utensils' },

  { title: 'fab fa-vaadin' },

  { title: 'fas fa-vector-square' },

  { title: 'fas fa-venus' },

  { title: 'fas fa-venus-double' },

  { title: 'fas fa-venus-mars' },

  { title: 'fab fa-viacoin' },

  { title: 'fab fa-viadeo' },

  { title: 'fab fa-viadeo-square' },

  { title: 'fas fa-vial' },

  { title: 'fas fa-vials' },

  { title: 'fab fa-viber' },

  { title: 'fas fa-video' },

  { title: 'fas fa-video-slash' },

  { title: 'fas fa-vihara' },

  { title: 'fab fa-vimeo' },

  { title: 'fab fa-vimeo-square' },

  { title: 'fab fa-vimeo-v' },

  { title: 'fab fa-vine' },

  { title: 'fab fa-vk' },

  { title: 'fab fa-vnv' },

  { title: 'fas fa-volleyball-ball' },

  { title: 'fas fa-volume-down' },

  { title: 'fas fa-volume-mute' },

  { title: 'fas fa-volume-off' },

  { title: 'fas fa-volume-up' },

  { title: 'fas fa-vote-yea' },

  { title: 'fas fa-vr-cardboard' },

  { title: 'fab fa-vuejs' },

  { title: 'fas fa-walking' },

  { title: 'fas fa-wallet' },

  { title: 'fas fa-warehouse' },

  { title: 'fas fa-water' },

  { title: 'fab fa-weebly' },

  { title: 'fab fa-weibo' },

  { title: 'fas fa-weight' },

  { title: 'fas fa-weight-hanging' },

  { title: 'fab fa-weixin' },

  { title: 'fab fa-whatsapp' },

  { title: 'fab fa-whatsapp-square' },

  { title: 'fas fa-wheelchair' },

  { title: 'fab fa-whmcs' },

  { title: 'fas fa-wifi' },

  { title: 'fab fa-wikipedia-w' },

  { title: 'fas fa-wind' },

  { title: 'fas fa-window-close' },

  { title: 'far fa-window-close' },

  { title: 'fas fa-window-maximize' },

  { title: 'far fa-window-maximize' },

  { title: 'fas fa-window-minimize' },

  { title: 'far fa-window-minimize' },

  { title: 'fas fa-window-restore' },

  { title: 'far fa-window-restore' },

  { title: 'fab fa-windows' },

  { title: 'fas fa-wine-bottle' },

  { title: 'fas fa-wine-glass' },

  { title: 'fas fa-wine-glass-alt' },

  { title: 'fab fa-wix' },

  { title: 'fab fa-wizards-of-the-coast' },

  { title: 'fab fa-wolf-pack-battalion' },

  { title: 'fas fa-won-sign' },

  { title: 'fab fa-wordpress' },

  { title: 'fab fa-wordpress-simple' },

  { title: 'fab fa-wpbeginner' },

  { title: 'fab fa-wpexplorer' },

  { title: 'fab fa-wpforms' },

  { title: 'fab fa-wpressr' },

  { title: 'fas fa-wrench' },

  { title: 'fas fa-x-ray' },

  { title: 'fab fa-xbox' },

  { title: 'fab fa-xing' },

  { title: 'fab fa-xing-square' },

  { title: 'fab fa-y-combinator' },

  { title: 'fab fa-yahoo' },

  { title: 'fab fa-yandex' },

  { title: 'fab fa-yandex-international' },

  { title: 'fab fa-yelp' },

  { title: 'fas fa-yen-sign' },

  { title: 'fas fa-yin-yang' },

  { title: 'fab fa-yoast' },

  { title: 'fab fa-youtube' },

  { title: 'fab fa-youtube-square' },

  { title: 'fab fa-zhihu' },
];
