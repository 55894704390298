import React, { useRef } from 'react';
import { connect } from 'react-redux';
import {
  deleteAttachmentFile,
  getAttachmentFile,
  saveAttachmentFile,
} from '../../../../../store/task/action';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../loader';
import { useState } from 'react';
import ImageModal from '../../modal/ImageModal';
import { resetState } from '../../../../../store/task/taskSlice';
import { successMsg } from '../../../../../utilities/utils';
import ViewImage from '../viewImage/ViewImage';
const AttachmentFile = ({
  singleTask,
  dispatchSaveAttachmentFileRequest,
  dispatchGetAllAttachmentFileRequest,
  dispatchDeleteAttachmentFileRequest,
  dispatchResetStateRequest,
}) => {
  const { projectId } = useSelector((state) => state.project);
  const [showImageModal, setShowImageModal] = useState(false);
  const selectedImageSrc = '';
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [imgSource, setImgSource] = useState(null);
  const fileInputRef = useRef(null);

  const { projectSettings } = useSelector((state) => state.permissions);
  const {
    allAttachmentFile,
    fileLoading,
    isAttachFileAdded,
    isFileAttachmentDeleted,
    message,
    loading,
  } = useSelector((state) => state.task);

  useEffect(() => {
    if (
      projectSettings.canStartTimer &&
      Object.keys(singleTask).length &&
      projectId
    ) {
      dispatchGetAllAttachmentFileRequest({ taskId: singleTask.id, projectId });
    }
  }, [singleTask]);

  useEffect(() => {
    if (isAttachFileAdded) {
      if (Object.keys(singleTask).length && projectId) {
        dispatchGetAllAttachmentFileRequest({
          taskId: singleTask.id,
          projectId,
        });
      }
    }
  }, [isAttachFileAdded]);

  useEffect(() => {
    if (isFileAttachmentDeleted) {
      if (Object.keys(singleTask).length && projectId) {
        dispatchGetAllAttachmentFileRequest({
          taskId: singleTask.id,
          projectId,
        });
      }
      successMsg(message);
      dispatchResetStateRequest();
    }
  }, [isFileAttachmentDeleted]);

  const onImageUpload = (e) => {
    const formData = new FormData();
    const image = [...e.target.files];
    formData.append('file', image[0]);
    const payload = { taskId: singleTask?.id, formData };
    dispatchSaveAttachmentFileRequest(payload);
  };

  const handleDownloadClick = () => {
    allAttachmentFile.forEach(function (value, idx) {
      const response = {
        file: value,
      };
      setTimeout(() => {
        window.location.href = response.file;
      }, idx * 100);
    });
  };

  const handleDoubleClickImages = (imgURL) => {
    const parts = imgURL.split('=');
    const finalURL = `https://drive.google.com/file/d/${parts[2]}/view?usp=sharing`;
    window.open(finalURL, '_blank');
  };

  const download = (path) => {
    var element = document.createElement('a');
    var file = new Blob([path], { type: 'image/*' });
    element.href = URL.createObjectURL(file);
    element.download = 'image.jpg';
    element.click();
  };

  const handleImageDelete = (data) => {
    // setShowDeleteModal(true);
    // setImgSource(imgUrl);
    const payload = { attachmentid: data.id, id: data.taskId };
    dispatchDeleteAttachmentFileRequest(payload);
  };
  // const handleDelete = () => {
  //   if (imgSource) {
  //     // dispatchDeleteTrackingStatus({ id: deletedData.id });
  //     setShowDeleteModal(false);
  //   }
  // };

  const handleAddAttachmentClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div>
        <div className="flex justify-between">
          <span>Attachments </span>
          <div className="flex">
            <div className="dropdown inline-block relative mr-3">
              {projectSettings.canStartTimer && (
                <img
                  src="../../assets/img/dots-vertical.svg"
                  alt=""
                  className="w-7 h-7 rotate-90 m-0 cursor-pointer"
                />
              )}

              <ul
                id="dropdown-menu"
                className="dropdown-menu absolute hidden pt-1 bg-white shadow rounded py-2 px-4 mb-2 whitespace-nowrap right-0 left-align"
              >
                <li className="text-red-500">
                  <button type="button" onClick={() => handleDownloadClick()}>
                    <i
                      className="fa fa-trash mr-2 cursor-pointer w-[18px]"
                      aria-hidden="true"
                    ></i>
                    Download All
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {fileLoading && loading ? (
        <Loader isVisible={loading} />
      ) : allAttachmentFile.length ? (
        <div className="flex flex-wrap">
          {allAttachmentFile &&
            allAttachmentFile.map((item, idx) => (
              <div
                key={idx}
                className={`w-28 h-28 border border-1 cursor-pointer relative hover:bg-slate-100 view-image-iframe ${
                  idx === 0 ? 'm-1 ml-0' : 'm-1'
                }`}
                onDoubleClick={() =>
                  handleDoubleClickImages(item.attachmentUrl)
                }
              >
                <ViewImage url={item.attachmentUrl} />
                <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-to-r from-red-200 via-red-200 to-red-200 opacity-0 transition duration-300 ease-in-out hover:opacity-70">
                  <div className="text-right">
                    <span
                      className="mr-2 pt-2"
                      onClick={() => download(item.attachmentUrl)}
                    >
                      <i className="fa-solid fa-cloud-arrow-down"></i>
                    </span>
                    <span
                      className="mr-1 pt-2"
                      onClick={() =>
                        handleImageDelete({ taskId: item.taskId, id: item.id })
                      }
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          <div
            className="flex justify-center items-center w-8"
            onClick={() => handleAddAttachmentClick()}
          >
            <form className="cursor-pointer">
              <input
                className="cursor-pointer relative left-1.5 bottom-0.5 w-3 hidden"
                type="file"
                ref={fileInputRef}
                onChange={onImageUpload}
                aria-hidden="true"
              />
              <i className="fa-solid fa-plus text-2xl"></i>
            </form>
          </div>
        </div>
      ) : (
        <>
          {projectSettings.canStartTimer && (
            <div>
              <div className="mt-8 flex justify-center">
                <button className="cursor-pointer relative overflow-hidden upload-btn w-1/2 text-[#b4b5b6] rounded-[4px]">
                  <label className="cursor-pointer py-[2rem] block h-full w-full">
                    <i className="fas fa-file-upload text-[2.5rem] site-clr"></i>
                    <div className="text-center text-[20px] mt-4">
                      Upload Your Files
                    </div>
                    <div className="text-center text-[14px]">
                      (No Attachments)
                    </div>
                    <input
                      className="absolute top-0 left-0 text-sm text-grey-500 hidden"
                      type="file"
                      onChange={onImageUpload}
                    />
                  </label>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {showImageModal && (
        <ImageModal
          imageSrc={selectedImageSrc}
          onClose={() => setShowImageModal(false)}
        />
      )}
      {/* {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={(val) => setShowDeleteModal(val)}
          handleDelete={handleDelete}
          data={"imgSource"}
        />
      )} */}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSaveAttachmentFileRequest: saveAttachmentFile,
  dispatchGetAllAttachmentFileRequest: getAttachmentFile,
  dispatchDeleteAttachmentFileRequest: deleteAttachmentFile,
  dispatchResetStateRequest: resetState,
};
export default connect(null, mapDispatchToProps)(AttachmentFile);
