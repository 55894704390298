import React from 'react';
import CustomAvatar from '../../customAvatar';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';

const TableRow = ({ data, fromId }) => {
  const { trackingStatusList } = useSelector((state) => state.project);

  const getStatus = (statusId) => {
    if (!!trackingStatusList && !!trackingStatusList.length) {
      let d = trackingStatusList.filter((status) => {
        return parseInt(status.id) === parseInt(statusId);
      });
      if (d.length !== 0) {
        return d[0].name;
      }
    }
  };
  return (
    <>
      <tr className="mt-5 border-b">
        <td className="p-4">
          {fromId === 1 ? (
            data.assignedTo ? (
              <CustomAvatar id={data.assignedTo} idPrefix={data.id} assigneeName={data.assigneeName} />
            ) : (
              <div className="user">
                <div className="avatar">
                  <Avatar round={true} title="Unassigned" name="Un Assigned" />
                </div>
              </div>
            )
          ) : (
            <></>
          )}
        </td>
        <td className="p-4">{data.title}</td>
        <td className="p-4">{getStatus(data.trackingStatusId)}</td>
      </tr>
    </>
  );
};

export default TableRow;
