import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  success: false,
  message: '',
  isSprintAdded: false,
  isSprintTaskAdded: false,
  isSprintTaskRemoved: false,
  isSprintCompleted: false,
  isActivatedSprint: false,
  isDeletedSprint: false,
  isSprintUpdated: false,
  allSprint: [],
  allSprintTask: [],
};

export const sprintSlice = createSlice({
  name: 'sprint',
  initialState,
  reducers: {
    // get sprint
    getSprintRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allSprint: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getSprintRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // Save Sprint
    saveSprintRequestSuccess: (state, action) => {
      if (action.payload) {
        let allSprint = JSON.parse(JSON.stringify(state.allSprint));
        allSprint.push(action.payload.data);
        return {
          ...state,
          loading: false,
          isSprintAdded: true,
          allSprint,
          message: action.payload.message,
        };
      }
      return state;
    },
    saveSprintRequestFailure: (state) => {
      return { ...state, loading: false };
    },

    // Update Sprint
    updateSprintRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          isSprintUpdated: true,
          message: action.payload.message,
        };
      }
      return state;
    },
    updateSprintRequestFailure: (state) => {
      return { ...state, loading: false, isSprintUpdated: false };
    },

    // get sprint Task
    getSprintTaskRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allSprintTask: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getSprintTaskRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    // Save Sprint Task
    saveSprintTaskRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          isSprintTaskAdded: true,
          message: 'Added Successfully!',
        };
      }
      return state;
    },
    saveSprintTaskRequestFailure: (state) => {
      return { ...state, loading: false, isSprintTaskAdded: false };
    },

    // Remove Sprint Task
    removeSprintTaskRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          isSprintTaskRemoved: true,
          message: 'Deleted Successfully!',
        };
      }
      return state;
    },
    removeSprintTaskRequestFailure: (state) => {
      return { ...state, loading: false, isSprintTaskRemoved: false };
    },

    // Is Completed Sprint
    completedSprintRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          isSprintCompleted: true,
          message: action.payload.message,
        };
      }
      return state;
    },
    completedSprintRequestFailure: (state) => {
      return { ...state, loading: false, isSprintCompleted: false };
    },

    // Active Sprint
    activeSprintRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          isActivatedSprint: true,
          message: action.payload.message,
        };
      }
      return state;
    },
    activeSprintRequestFailure: (state) => {
      return { ...state, loading: false, isActivatedSprint: false };
    },

    // Delete Sprint
    deleteSprintRequestSuccess: (state, action) => {
      if (action.payload) {
        let allSprint = JSON.parse(JSON.stringify(state.allSprint));
        const updatedAllSprint = allSprint.filter(
          (item) => item.id !== action.payload.data.id
        );
        return {
          ...state,
          loading: false,
          isDeletedSprint: true,
          allSprint: updatedAllSprint,
          message: action.payload.message,
        };
      }
      return state;
    },
    deleteSprintRequestFailure: (state) => {
      return { ...state, loading: false, isDeletedSprint: false };
    },

    // reset state
    resetState: (state) => {
      return {
        ...state,
        message: '',
        isSprintAdded: false,
        isSprintTaskAdded: false,
        isSprintTaskRemoved: false,
        isSprintCompleted: false,
        isActivatedSprint: false,
        isDeletedSprint: false,
        isSprintUpdated: false,
        // allSprint: [],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getSprintRequestSuccess,
  getSprintRequestFailure,
  saveSprintRequestSuccess,
  saveSprintRequestFailure,
  getSprintTaskRequestSuccess,
  getSprintTaskRequestFailure,
  saveSprintTaskRequestSuccess,
  saveSprintTaskRequestFailure,
  removeSprintTaskRequestSuccess,
  removeSprintTaskRequestFailure,
  completedSprintRequestSuccess,
  completedSprintRequestFailure,
  activeSprintRequestSuccess,
  activeSprintRequestFailure,
  deleteSprintRequestSuccess,
  deleteSprintRequestFailure,
  updateSprintRequestSuccess,
  updateSprintRequestFailure,
  resetState,
} = sprintSlice.actions;

export default sprintSlice.reducer;
