import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Data from '../../utilities/sidebarData.json';
import Menu from './components/Menu';
import { useSelector } from 'react-redux';

const Sidebar = ({
  isPinned,
  handleSidebarPinned,
  handleSidebarHover,
  isSidebarHovered,
  handleMobileMenu,
}) => {
  const [menuId, setMenuId] = useState(null);
  const [subMenuId, setSubMenuId] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const location = useLocation();
  const [settings, setSettings] = useState(null);
  const [labelText, setLabelText] = useState(null);
  const { tasks } = useSelector((state) => state.task);
  const { projects, projectId } = useSelector((state) => state.project);
  const { projectSettings, menuLebels } = useSelector((state) => state.permissions);
  const [isProjectTypeId, setIsProjectTypeId] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (projects && projects.length) {
      if (projectId) {
        const project = projects.find((pro) => {
          return pro.id === Number(projectId);
        });
        if (project && Object.keys(project).length !== 0) {
          if (project.projectTypeId === 2) {
            setIsProjectTypeId(true);
          } else {
            setIsProjectTypeId(false);
          }
        }
      }
    }
  }, [projects, projectId]);

  useEffect(() => {
    if (projectSettings) {
      setSettings(projectSettings);
    }
    if (menuLebels) {
      setLabelText(menuLebels);
    }
  }, [projectSettings]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/board' || location.pathname === '/task') {
      setMenuId(4);
    } else if (location.pathname === '/') {
      setMenuId(1);
    } else if (location.pathname === '/settings') {
      setMenuId(6);
    } else if (location.pathname === '/roles') {
      setMenuId(8);
    } else if (location.pathname === '/label-change') {
      setMenuId(9);
    } else if (location.pathname === '/sprint') {
      setMenuId(11);
    } else if (location.pathname === '/report') {
      setMenuId(14);
    } else if (location.pathname === '/slack') {
      setMenuId(15);
    }
  }, [tasks]);

  useEffect(() => {
    if (location) {
      const match = location.pathname.match(/^\/task\/(\d+)$/);
      setIsHighlighted(match);
    }
  }, [location]);

  useEffect(() => {
    if (isHighlighted) {
      setMenuId(0);
    }
  }, [isHighlighted]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isMobile = width <= 768;
  const handleMenuClick = (id, subMenuLength, menuRoute) => {
    if (menuRoute !== '') {
      navigate(menuRoute);
    }
    if (subMenuLength === 0 && isMobile) {
      handleMobileMenu();
    }
    if (menuId === id) {
      setMenuId(id);
    } else {
      setMenuId(id);
      setSubMenuId('');
    }
  };

  const handleSubMenuClick = (id) => {
    if (isMobile) {
      handleMobileMenu();
    }
    setSubMenuId(id);
  };

  return (
    <div className={`${location.pathname !== '/project-repo' ? '' : 'hidden'}`}>
      <aside
        id="sidebar"
        className={'sidebar relative'}
        onMouseEnter={
          !isPinned
            ? handleSidebarHover
            : () => {
              return null;
            }
        }
        onMouseLeave={
          !isPinned
            ? handleSidebarHover
            : () => {
              return null;
            }
        }
      >
        <div className="flex flex-col space-y-6">
          <nav>
            <div className="flex justify-between items-center mb-3">
              <div className="ml-[15px] text-red-500 font-bold text-logo">
                Task Tracking
              </div>
              <div className="mx-auto text-red-500 font-bold text-[18px] smalltext-logo">
                T
              </div>
              <div className="flex items-center">
                <img
                  src="../../assets/img/unpin-icon.svg"
                  alt=""
                  onClick={handleSidebarPinned}
                  className="w-5 mb-1 ml-4 cursor-pointer mr-4 unpin-icon"
                />

                <img
                  src="../../assets/img/pin-icon.svg"
                  alt=""
                  onClick={handleSidebarPinned}
                  className="w-5 mb-1 ml-4 cursor-pointer mr-4 hidden pin-icon"
                />
              </div>
            </div>

            <ul>
              {Data &&
                Data.map((menus, index) => {
                  let label;
                  if (labelText) {
                    const keys = Object.keys(labelText);
                    if (keys.includes(menus.label)) {
                      label = labelText[menus.label];
                    } else if (menus.label === 'report') {
                      label = 'Report';
                    } else if (menus.label === 'Slack') {
                      label = 'Connect Slack';
                    } else {
                      label = 'Label Settings';
                    }
                  }
                  let disp;
                  if (menus.name === 'Dashboard') {
                    if (settings && settings.canAccessDashboard) {
                      disp = 'block';
                    } else {
                      disp = 'hidden';
                    }
                  } else if (menus.name === 'Settings') {
                    if (settings && settings.canAccessSettings) {
                      disp = 'block';
                    } else {
                      disp = 'hidden';
                    }
                  } else if (menus.name === 'Label Settings') {
                    if (settings && settings.canChangeLabel) {
                      disp = 'block';
                    } else {
                      disp = 'hidden';
                    }
                  } else if (menus.name === 'Sprint') {
                    if (settings && settings.canAccessSprint) {
                      if (isProjectTypeId) {
                        disp = 'hidden';
                      } else {
                        disp = 'block';
                      }
                    } else {
                      if (isProjectTypeId) {
                        disp = 'hidden';
                      } else {
                        disp = 'hidden';
                      }
                    }
                  } else {
                    disp = 'block';
                  }

                  return (
                    <Menu
                      key={index}
                      index={index}
                      handleMenuClick={handleMenuClick}
                      menuId={menuId}
                      menuData={menus}
                      handleSubMenuClick={handleSubMenuClick}
                      subMenuId={subMenuId}
                      isSidebarHovered={isSidebarHovered}
                      isMobile={isMobile}
                      displayMenu={disp}
                      labelText={label}
                    />
                  );
                })}
            </ul>
          </nav>
        </div>

        <div className="absolute bottom-[1.5rem] left-[1.5rem] z-[9999] hidden bottom-text">
          <strong>Ensuesoft Private Limited</strong>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
