import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  success: false,
  message: '',
};

export const emailTemplateSlice = createSlice({
  name: 'emailTemplate',
  initialState,
  reducers: {
    // Get Email Template
    getEmailTemplateRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          projects: action.payload,
        };
      }
      return state;
    },
    getEmailTemplateRequestFailure: (state) => {
      return { ...state, loading: false };
    },

    //save Email Template
    saveEmailTemplateRequestSuccess: (state, action) => {
      let projects = JSON.parse(JSON.stringify(state.projects));
      projects.push(action.payload.data);
      if (action.payload) {
        return {
          ...state,
          loading: false,
          message: action.payload.message,
          projects,
          projectId: action.payload.data.id,
          instantCreatedProjectDetails: {
            id: action.payload.data.id,
            name: action.payload.data.name,
          },
          isProjectAdded: true,
        };
      }
      return state;
    },
    saveEmailTemplateRequestFailure: (state) => {
      return { ...state, loading: false, isProjectAdded: false };
    },

    //delete Email Template
    deleteEmailTemplateRequestSuccess: (state, action) => {
      let projects = JSON.parse(JSON.stringify(state.projects));
      projects.splice(
        projects.findIndex((item) => item.id === action.payload.data.id),
        1
      );
      if (action.payload) {
        return {
          ...state,
          loading: false,
          message: action.payload.message,
          projects,
          isProjectDeleted: true,
        };
      }
      return state;
    },
    deleteEmailTemplateRequestFailure: (state) => {
      return { ...state, loading: false, isProjectDeleted: false };
    },

    // update Email Template
    updateEmailTemplateRequestSuccess: (state, action) => {
      let allTaskType = JSON.parse(JSON.stringify(state.allTaskType));
      const taskTypeToChange = allTaskType.find(
        (item) => item.id === action.payload.data.id
      );
      taskTypeToChange.name = action.payload.data.name;
      if (action.payload) {
        return {
          ...state,
          isTaskTypeUpdated: true,
          allTaskType,
          message: action.payload.message,
        };
      }
      return state;
    },
    updateEmailTemplateRequestFailure: (state) => {
      return {
        ...state,
        isTaskTypeUpdated: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getEmailTemplateRequestSuccess,
  getEmailTemplateRequestFailure,
  saveEmailTemplateRequestSuccess,
  saveEmailTemplateRequestFailure,
  updateEmailTemplateRequestSuccess,
  updateEmailTemplateRequestFailure,
  deleteEmailTemplateRequestSuccess,
  deleteEmailTemplateRequestFailure,
} = emailTemplateSlice.actions;

export default emailTemplateSlice.reducer;
