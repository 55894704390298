import React, { memo } from 'react';
import SubMenu from './SubMenu';
const Menu = ({
  index,
  handleMenuClick,
  menuId,
  menuData,
  handleSubMenuClick,
  subMenuId,
  isSidebarHovered,
  isMobile,
  displayMenu,
  labelText,
}) => {
  return (
    <li key={index} className={displayMenu}>
      {isSidebarHovered || isMobile ? (
        <span
          style={{ color: '#a6a4b0' }}
          className={`${
            menuData.heading === ''
              ? ''
              : 'ml-4 leading-10 font-semibold text-xs block menu-h'
          }`}
        >
          {menuData.heading}
        </span>
      ) : (
        <div>
          <i
            className={`${menuData.notHovered}`}
            style={{ color: '#a6a4b0' }}
          ></i>
        </div>
      )}

      <span
        className={`side-menu cursor-pointer ${
          menuData.subMenu.length !== 0
            ? `${menuId === menuData.id ? 'active-menu' : ''}`
            : `${menuId === menuData.id ? 'active' : ''}`
        } `}
        onClick={() =>
          handleMenuClick(menuData.id, menuData.subMenu.length, menuData.route)
        }
      >
        <img
          className="w-4"
          alt=""
          src={`../../../assets/img/sidebar-icons/${menuData.icon}`}
        />

        <span className="text-sm ml-2">{labelText}</span>
        {menuData.subMenu.length !== 0 && (
          <>
            {menuId === menuData.id ? (
              <i className="fas fa-chevron-down mr-2"></i>
            ) : (
              <i className="fas fa-chevron-right mr-2"></i>
            )}
          </>
        )}
      </span>

      <SubMenu
        menuId={menuId}
        menuData={menuData}
        handleSubMenuClick={handleSubMenuClick}
        subMenuId={subMenuId}
        isSidebarHovered={isSidebarHovered}
      />
    </li>
  );
};

export default memo(Menu);
