// task
export const getTaskRequestActionType = 'GET_TASK_REQUEST';
export const updateTaskRequestActionType = 'UPDATE_TASK_REQUEST';
export const sendTaskRequestActionType = 'SEND_TASK_REQUEST';
export const deleteTaskRequestActionType = 'DEL_TASK_REQUEST';
export const getAllTaskRequestActionType = 'GET_ALL_TASK_REQUEST';
export const getAllTaskListRequestActionType = 'GET_ALL_TASK_LIST_REQUEST';

// Scrum
export const getScrumRequestActionType = 'GET_SCRUM_REQUEST';
export const saveInvitesRequestActionType = 'SAVE_INVITES_REQUEST';

//kanban
export const getKanbanRequestActionType = 'GET_KANBAN_REQUEST';

// upload editior image
export const saveEditorImageRequestActionType = 'SAVE_EDITOR_IMAGE_REQUEST';

// add checklist
export const addCheckListRequestActionType = 'ADD_CHECKLIST_REQUEST';
export const getAllCheckListRequestActionType = 'GET_ALL_CHECKLIST_REQUEST';
export const updateCheckListRequestActionType = 'UPDATE_CHECKLIST_REQUEST';

// add comments
export const addTaskCommentsRequestActionType = 'ADD_TASK_COMMENTS_REQUEST';
export const getAllTaskCommentsRequestActionType =
  'GET_ALL_TASK_COMMENTS_REQUEST';
export const updateTaskCommentRequestActionType =
  'UPDATE_TASK_COMMENTS_REQUEST';
export const deleteTaskCommentRequestActionType = 'DELETE_TASK_COMMENT_REQUEST';

//Task History
export const getAllTaskHistoryRequestActionType = 'GET_ALL_TASK_HISTORY';

// Time spend
export const saveTimeSpendRequestActionType = 'SAVE_TIME_SPEND_REQUEST';
export const getTimeSpendRequestActionType = 'GET_TIME_SPEND_REQUEST';
export const deleteTimeSpendRequestActionType = 'DELETE_TIME_SPEND_REQUEST';

// Sub task added
export const saveSubTaskRequestActionType = 'SAVE_SUB_TASK_REQUEST';
// Sub task deleted
export const deleteSubTaskRequestActionType = 'DELETE_SUB_TASK_REQUEST';
// sprint task orders
export const saveSprintTaskOrderRequestActionType =
  'SAVE_SPRINT_TASK_ORDER_REQUEST';
// Attachment file
export const saveAttachmentFileRequestActionType =
  'SAVE_ATTACHEMENT_FILE_REQUEST';

export const getAttachmentFileRequestActionType =
  'GET_ATTACHEMENT_FILE_REQUEST';

export const deleteAttachmentFileRequestActionType =
  'DELETE_ATTACHEMENT_FILE_REQUEST';

// Task Timer start
export const saveTaskTimerStartRequestActionType =
  'SAVE_TASK_TIMER_START_REQUEST';

// Task Timer end
export const endTaskTimerStartRequestActionType = 'SAVE_TASK_TIMER_END_REQUEST';

// Running task timer
export const runningTaskTimerRequestActionType = 'RUNNING_TASK_TIMER_REQUEST';
// Task Time Tracker
export const taskTimeTrackerRequestActionType = 'TASK_TIME_TRACKER_REQUEST';
//Get Task Time Tracker
export const getTaskTimeTrackerRequestActionType =
  'GET_TASK_TIME_TRACKER_REQUEST';
