// update Profile
export const updateUserProfilePicRequestActionType =
  'UPDATE_USER_PROFILE_PIC_REQUEST';

// update Personal Details
export const updatePersonalDetailsRequestActionType =
  'UPDATE_PERSONAL_DETAILS_REQUEST';

// get user profile pic
export const getUserProfilePicRequestActionType = 'GET_USER_PROFILE_PIC_URL';

// forgot password
export const savePasswordChangeRequestType = 'SAVE_CHANGE_PASSWORD_REQUEST';
export const saveOTPRequestType = 'SAVE_OTP_REQUEST';
export const verifyOTPRequestType = 'VERIFY_OTP_REQUEST';
