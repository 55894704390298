import React from 'react';

const ViewImage = ({ url }) => {
  return (
    <iframe
      src={`https://drive.google.com/file/d/${url.split('=')[2]}/preview`}
      width="100%"
      height={'100%'}
      type="application/pdf"
    ></iframe>
  );
};

export default ViewImage;
