import React from 'react';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';

const FormTextareaField = ({
  label,
  fieldName,
  register,
  errors,
  isRequired,
  maxLength,
  rows,
  placeholder,
}) => {
  return (
    <>
      <label className="block text-sm font-medium mb-1">
        {' '}
        {label} {isRequired ? <span className="text-red-500">*</span> : ''}
      </label>
      <textarea
        placeholder={placeholder}
        rows={rows}
        className="input-style"
        id={fieldName}
        {...register(fieldName, {
          required: isRequired,
          maxLength: maxLength,
        })}
      />
      {errors[fieldName]?.type === 'required' && (
        <FormFieldError message={AppConstants.requiredField} />
      )}
      {errors[fieldName]?.type === 'maxLength' && (
        <FormFieldError message={AppConstants.maxLengthReached} />
      )}
    </>
  );
};

export default FormTextareaField;
