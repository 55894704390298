import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import CustomAvatar from '../customAvatar';
import Avatar from 'react-avatar';
import ReactTimeAgo from 'react-time-ago';
import { convertTime } from '../../../utilities/utils';
import Pagination from '../pagination';
import Loader from '../../loader';
import { useNavigate } from 'react-router-dom';
import {
  getAlltaskHistoryRequest,
  saveNotificationRequest,
} from '../../../store/notifications/action';

const Notifications = ({
  dispatchGetAllTaskHistory,
  dispatchSaveNotificationRequest,
}) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const { projectId } = useSelector((state) => state.project);
  const {
    allTaskHistory,
    paginationMeta,
  } = useSelector((state) => state.notification);

  useEffect(() => {
    if (projectId) {
      setShowLoader(true);
    }
  }, [projectId]);

  useEffect(() => {
    if (allTaskHistory) {
      setShowLoader(false);
    }
  }, [allTaskHistory]);

  const handlePageChange = (pageNumber) => {
    dispatchGetAllTaskHistory({
      payload: {
        pageNumber: pageNumber,
        pageSize: 10,
        SortByAsc: false,
      },
      projectId: projectId,
    });
  };

  const onClicktoView = ({ taskId, historyId }) => {
    dispatchSaveNotificationRequest({ taskHistoryId: historyId });
    navigate(`/task/${taskId}`);
  };

  return (
    <div>
      <div className="overflow-auto notification-container">
        {!!allTaskHistory && !showLoader ? (
          allTaskHistory.length !== 0 ? (
            allTaskHistory.map((item, idx) => (
              <div
                key={idx}
                className={`border-b-2 p-4 cursor-pointer ${
                  item.isRead ? '' : 'bg-[#feedf0]'
                }`}
                onClick={() =>
                  onClicktoView({
                    taskId: item.taskId,
                    historyId: item.id,
                  })
                }
              >
                <div className="flex relative">
                  <div className="user absolute top-1">
                    {item.userId ? (
                      <CustomAvatar id={item.userId} idPrefix={item.id} assigneeName={item.changedBy} />
                    ) : (
                      <div className="user">
                        <div className="avatar">
                          <Avatar
                            round={true}
                            title="Unassigned"
                            name="Un Assigned"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="ml-10">
                    <div className="md:flex items-center">
                      <p
                        className="mt-1 ml-2"
                        dangerouslySetInnerHTML={{
                          __html: item.changedValue,
                        }}
                      ></p>
                    </div>
                    <div>
                      <b className="ml-2">
                        <ReactTimeAgo
                          date={Number(convertTime(item.createdAt))}
                          locale="en-US"
                        />
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="text-center py-4">
                <i className="fas fa-bell-slash"></i>
                <h2 className="font-bold mt-2">No Notification yet!</h2>
              </div>
            </>
          )
        ) : (
          <Loader />
        )}
      </div>
      {!!paginationMeta && !!Object.keys(paginationMeta).length && (
        <div>
          <Pagination
            className="pagination-bar"
            currentPage={paginationMeta.CurrentPage}
            totalCount={paginationMeta.TotalCount}
            pageSize={paginationMeta.PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetAllTaskHistory: getAlltaskHistoryRequest,
  dispatchSaveNotificationRequest: saveNotificationRequest,
};
export default connect(null, mapDispatchToProps)(Notifications);
