// read notifications
export const saveNotificationRequestActionType =
  'SAVE_NOTIFICATION_REPORT_REQUEST';

export const getAllTaskHistoryRequestActionType =
  'GET_all_TASK_HISTORY_REQUEST';
export const getAllNotificationRequestActionType =
  'GET_ALL_NOTIFICATION_REQUEST';
export const getAllUnreadNotificationRequestActionType =
  'GET_ALL_UNREAD_NOTIFICATION_REQUEST';
