import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from '../../formInputField';
import { connect } from 'react-redux';
import {
  updateTaskType,
  updateTaskPriority,
  updateTrackingStatus,
  deleteTaskType,
  deleteTaskPriority,
  deleteTrackingStatus,
  deleteTag,
  updateTag,
  userRoleChange,
} from '../../../store/project/actions';
import { useSelector } from 'react-redux';
import { resetState } from '../../../store/project/projectSlice';
import {
  deleteTaskComment,
  deleteTimeSpend,
  updateTaskComment,
} from '../../../store/task/action';
import DeleteModal from '../deleteModal';
import Loader from '../../loader';
const Table = ({
  data,
  projectId,
  tabId,
  dispatchUpdateTaskTypeRequest,
  dispatchResetStateRequest,
  dispatchUpdateTaskPriority,
  dispatchUpdateTrackingStatus,
  dispatchUpdateTag,
  dispatchDeleteTaskTypeRequest,
  dispatchDeleteTaskPriority,
  dispatchDeleteTrackingStatus,
  dispatchDeleteTag,
  dispatchUpdateTaskComment,
  dispatchDeleteTaskComment,
  dispatchUserRoleChangeRequest,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const {
    loading,
    isTaskTypeUpdated,
    isTaskPriorityUpdated,
    isTrackingStatusUpdated,
    isUserRoleChanged,
    isTagUpdated,
    flowStatus,
  } = useSelector((state) => state.project);
  const { isUpdateTaskComment } = useSelector((state) => state.task);
  const [editMode, setEditMode] = useState(null);
  const [showBtn, setShowBtn] = useState(true);
  const [DeleteShowModal, setDeleteShowModal] = useState(false);
  const [deletedData, setDeletedData] = useState(null);
  const [chooseStandardStatus, setChooseStandardStatus] = useState([]);
  const [standTrackingStatusId, setStandTrackingStatusId] = useState(0);
  useEffect(() => {
    if (flowStatus) {
      setChooseStandardStatus(flowStatus);
    }
  }, [flowStatus]);

  const onEdit = () => {
    setShowBtn(true);
    reset();
    setEditMode(null);
  };

  const handleDataSubmit = (data) => {
    if (tabId === 'tabs-task-type') {
      const finalData = {
        name: data.name,
        orderIndex: 1,
        projectId: projectId,
        taskTypeId: data.id,
      };
      dispatchUpdateTaskTypeRequest(finalData);
    } else if (tabId === 'tabs-task-priority') {
      const finalData = {
        name: data.name,
        description: data.description,
        projectId: projectId,
        priorityId: data.id,
      };
      dispatchUpdateTaskPriority(finalData);
    } else if (tabId === 'tabs-tracking-status') {
      const finalData = {
        name: data.name,
        description: data.description,
        projectId: projectId,
        trackingId: data.id,
        flowStatusId: parseInt(data.flowStatusId),
      };
      dispatchUpdateTrackingStatus(finalData);
    } else if (tabId === 'task-comment') {
      const finalData = {
        id: editMode,
        data: [
          {
            path: '/comment',
            op: 'replace',
            value: data.comment,
          },
        ],
      };
      dispatchUpdateTaskComment(finalData);
    } else if (tabId === 'tags') {
      const finalData = {
        name: data.name,
        tagId: data.id,
        projectId: projectId,
      };
      dispatchUpdateTag(finalData);
    } else if (tabId === 'users') {
      const finalData = {
        projectId: projectId,
        userId: data.id,
        roleId: data.role,
      };
      dispatchUserRoleChangeRequest(finalData);
    }
  };

  useEffect(() => {
    if (
      isTaskTypeUpdated ||
      isTaskPriorityUpdated ||
      isTrackingStatusUpdated ||
      isTagUpdated ||
      isUserRoleChanged
    ) {
      dispatchResetStateRequest();
      onEdit();
    }
  }, [
    isTaskTypeUpdated,
    isTaskPriorityUpdated,
    isTrackingStatusUpdated,
    isTagUpdated,
    isUserRoleChanged,
  ]);

  useEffect(() => {
    if (isUpdateTaskComment) {
      onEdit();
    }
  }, [isUpdateTaskComment]);

  const onDelete = (task) => {
    setDeleteShowModal(true);
    setDeletedData(task);
  };
  const handleDelete = () => {
    if (deletedData.id) {
      if (tabId === 'tabs-task-type') {
        dispatchDeleteTaskTypeRequest({ id: deletedData.id });
      } else if (tabId === 'tabs-task-priority') {
        dispatchDeleteTaskPriority({ id: deletedData.id });
      } else if (tabId === 'tabs-tracking-status') {
        dispatchDeleteTrackingStatus({ id: deletedData.id });
      } else if (tabId === 'tags') {
        dispatchDeleteTag({ id: deletedData.id });
      } else {
        dispatchDeleteTaskComment(deletedData.id);
      }
      setDeleteShowModal(false);
    }
  };

  const handleStandTrackingStatusChange = (event) => {
    setStandTrackingStatusId(event.target.value);
  };

  return (
    <>
      <Loader isVisible={loading} />
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
        <tbody>
          {data.map((task, idx) => (
            <tr
              className="bg-white border-b select-none dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              key={idx}
            >
              <td className="px-6 py-3">
                {editMode === task.id ? (
                  <div className="flex">
                    <div className="w-3/5">
                      <FormInputField
                        type={'text'}
                        fieldName={`${
                          tabId === 'task-comment' ? 'comment' : 'name'
                        }`}
                        register={register}
                        errors={errors}
                        placeholder={'Project-name'}
                      />
                    </div>
                    <div className="flex flex-wrap items-center justify-end w-2/5">
                      {!showBtn && (
                        <>
                          <button
                            className="mr-2 comment-edit-icon"
                            onClick={handleSubmit(handleDataSubmit)}
                          >
                            <i className="fa-solid fa-check"></i>
                          </button>
                          <button
                            className="dlt-comment-icon"
                            onClick={() => onEdit()}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  task.name
                )}
              </td>

              {tabId === 'tabs-tracking-status' && (
                <td className="px-6 py-3">
                  {editMode === task.id ? (
                    <>
                      <div className="flex">
                        <div className="relative">
                          <div className="arrow-down">
                            <i className="fas fa-chevron-down"></i>
                          </div>
                          <select
                            className="input-style"
                            {...register('flowStatusId', {
                              onChange: (e) =>
                                handleStandTrackingStatusChange(e),
                            })}
                            value={standTrackingStatusId}
                          >
                            {!!chooseStandardStatus &&
                              !!chooseStandardStatus.length &&
                              chooseStandardStatus.map((status, idx) => (
                                <option key={idx} value={status.id}>
                                  {status.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="flex flex-wrap items-center justify-end w-2/5">
                          {!showBtn && (
                            <>
                              <button
                                className="mr-2 comment-edit-icon"
                                onClick={handleSubmit(handleDataSubmit)}
                              >
                                <i className="fa-solid fa-check"></i>
                              </button>
                              <button
                                className="dlt-comment-icon"
                                onClick={() => onEdit()}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : task.flowStatusId === 0 ? (
                    'None'
                  ) : (
                    !!chooseStandardStatus &&
                    !!chooseStandardStatus.length &&
                    chooseStandardStatus.find(
                      (item) => item.id === task.flowStatusId
                    ).name
                  )}
                </td>
              )}
              {showBtn ? (
                tabId === 'task-history' ? (
                  ''
                ) : (
                  <td className="px-6 py-3">
                    <div className="text-left">
                      <div className={'dropdown inline-block relative'}>
                        <span
                          className="social-link cursor-pointer"
                          id="dropdownDefault"
                          data-dropdown-toggle="dropdown dAction"
                        >
                          <img
                            src="../../assets/img/dots-vertical.svg"
                            className="w-5 h-5"
                          />
                        </span>
                        <ul
                          id="dropdown-menu"
                          className="dropdown-menu absolute hidden pt-1 bg-white dark:bg-[#283046] shadow rounded py-2 px-4 mb-2 whitespace-nowrap"
                        >
                          <li className="mb-2">
                            <button
                              type="button"
                              onClick={() => {
                                setEditMode(task.id);
                                reset(task);
                                setShowBtn(false);
                              }}
                            >
                              <i
                                className="fa fa-pencil mr-2 cursor-pointer w-[18px]"
                                aria-hidden="true"
                              ></i>
                              Edit
                            </button>
                          </li>

                          <li className="text-red-500">
                            <button
                              type="button"
                              onClick={() => onDelete(task)}
                            >
                              <i
                                className="fa fa-trash mr-2 cursor-pointer w-[18px]"
                                aria-hidden="true"
                              ></i>
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                )
              ) : (
                ''
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {DeleteShowModal && (
        <DeleteModal
          DeleteShowModal={DeleteShowModal}
          setDeleteShowModal={(val) => setDeleteShowModal(val)}
          handleDelete={handleDelete}
          data={deletedData}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchUpdateTaskTypeRequest: updateTaskType,
  dispatchDeleteTaskTypeRequest: deleteTaskType,
  dispatchResetStateRequest: resetState,
  dispatchUpdateTaskPriority: updateTaskPriority,
  dispatchDeleteTaskPriority: deleteTaskPriority,
  dispatchUpdateTrackingStatus: updateTrackingStatus,
  dispatchUpdateTag: updateTag,
  dispatchDeleteTrackingStatus: deleteTrackingStatus,
  dispatchDeleteTag: deleteTag,
  dispatchUpdateTaskComment: updateTaskComment,
  dispatchDeleteTaskComment: deleteTaskComment,
  dispatchDeleteTimeSpend: deleteTimeSpend,
  dispatchUserRoleChangeRequest: userRoleChange,
};
export default connect(null, mapDispatchToProps)(Table);
