import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  deleteEmailTemplateRequestFailure,
  deleteEmailTemplateRequestSuccess,
  getEmailTemplateRequestFailure,
  getEmailTemplateRequestSuccess,
  saveEmailTemplateRequestFailure,
  saveEmailTemplateRequestSuccess,
  updateEmailTemplateRequestFailure,
  updateEmailTemplateRequestSuccess,
} from './emailTemplateSlice';

// Get all Email Template
function* asyncGetAllEmailTemplate(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_EMAIL_TEMPLATE.url}`,
      data.payload
    );

    if (response.status === 200) {
      yield put(getEmailTemplateRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    yield put(getEmailTemplateRequestFailure());
  }
}

// save email Template
function* asyncSaveEmailTemplate(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SAVE_EMAIL_TEMPLATE.url,
      data.payload,
      false
    );
    if (response.status === 200) {
      yield put(saveEmailTemplateRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveEmailTemplateRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}
// update Email Template
function* asyncUpdateEmailTemplate(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_EMAIL_TEMPLATE.url}/${data.payload.id}`,
      data.payload.data,
      true
    );
    if (response.status === 200) {
      yield put(updateEmailTemplateRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateEmailTemplateRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// delete Email Template
function* asyncDeleteEmailTemplate(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.DELETE_EMAIL_TEMPLATE.url}/delete/${data.payload}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(deleteEmailTemplateRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteEmailTemplateRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

export function* emailTemplateSaga() {
  yield all([
    takeEvery(
      constants.getEmailTemplateRequestActionType,
      asyncGetAllEmailTemplate
    ),
    takeEvery(
      constants.saveEmailTemplateRequestActionType,
      asyncSaveEmailTemplate
    ),
    takeEvery(
      constants.updateEmailTemplateRequestActionType,
      asyncUpdateEmailTemplate
    ),
    takeEvery(
      constants.deleteEmailTemplateRequestActionType,
      asyncDeleteEmailTemplate
    ),
  ]);
}
