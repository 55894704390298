import React, { useEffect} from 'react';
import { getSingleTrackingStatusDataRequest } from '../../../store/dashboard/action';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import CommonTable from '../../common/table/commonTable';
import { resetStateDashboard } from '../../../store/dashboard/dashboardSlice';

const ShowDataModal = ({
  showModal,
  setShowModal,
  trackingStatusInfo,
  dispatchSingleTrackingStatusDataRequest,
  dispatchResetStateRequest,
}) => {
  const { trackingStatusData, isLoading } = useSelector(
    (state) => state.dashboard
  );
  const { projectId } = useSelector((state) => state.project);

  const taskHeadData = [
    {
      colName: 'Assign To',
      value: 'name',
    },
    {
      colName: 'Title',
      value: 'email',
    },
    {
      colName: 'Status',
      value: 'status',
    },
  ];

  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (Object.keys(trackingStatusInfo).length !== 0 && projectId) {
      dispatchSingleTrackingStatusDataRequest({
        projectId: projectId,
        trackingStatusId: trackingStatusInfo.id,
      });
    }
  }, [trackingStatusInfo]);

  const handleCloseModal = () => {
    setShowModal(false);
    dispatchResetStateRequest();
  };

  return (
    <>
      <div className={`modal ${showModal ? 'show' : 'hide'}`}>
        <div className="modal-dialog modal-lg modal-dialog-centered w-[55rem]">
          <div className="modal-content">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <h5 className="text-xl font-medium leading-normal text-gray-800">
                {trackingStatusInfo.name}
              </h5>
              <button
                onClick={() => handleCloseModal()}
                type="button"
                className="btn-close box-content"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className={'modal-body relative p-5 h-[500px] md:h-[400px] xl:h-[458px] overflow-auto'}
            >
              <div className="text-end">
                <CommonTable
                  theadData={taskHeadData}
                  tbodyData={trackingStatusData}
                  fromId={1}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSingleTrackingStatusDataRequest: getSingleTrackingStatusDataRequest,
  dispatchResetStateRequest: resetStateDashboard,
};
export default connect(null, mapDispatchToProps)(ShowDataModal);
