import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg, getProjectInfo, setProjectInfo } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  deleteProjectRequestFailure,
  deleteProjectRequestSuccess,
  deleteTagRequestFailure,
  deleteTagRequestSuccess,
  deleteTaskPriorityRequestFailure,
  deleteTaskPriorityRequestSuccess,
  deleteTaskTypeRequestFailure,
  deleteTaskTypeRequestSuccess,
  deleteTrackingStatusRequestFailure,
  deleteTrackingStatusRequestSuccess,
  deleteUserRequestFailure,
  deleteUserRequestSuccess,
  getAcceptProjectInfoRequestFailure,
  getAcceptProjectInfoRequestSuccess,
  getAllProjectRequestFailure,
  getAllProjectRequestSuccess,
  getAllTrackingStatusRequestFailure,
  getAllTrackingStatusRequestSuccess,
  getFlowStatusListRequestFailure,
  getFlowStatusListRequestSuccess,
  getProjectScrumRequestFailure,
  getProjectScrumRequestSuccess,
  removeProjectRequestFailure,
  removeProjectRequestSuccess,
  saveProjectRequestFailure,
  saveProjectRequestSuccess,
  setUserRoleChangeRequestFailure,
  setUserRoleChangeRequestSuccess,
  saveTagRequestFailure,
  saveTagRequestSuccess,
  saveTaskPriorityRequestFailure,
  saveTaskPriorityRequestSuccess,
  saveTaskTypeRequestFailure,
  saveTaskTypeRequestSuccess,
  saveTrackingStatusOrderRequestFailure,
  saveTrackingStatusOrderRequestSuccess,
  saveTrackingStatusRequestFailure,
  saveTrackingStatusRequestSuccess,
  setProjectInviteAcceptanceRequestFailure,
  setProjectInviteAcceptanceRequestSuccess,
  sendProjectInvitiesUserRequestFailure,
  sendProjectInvitiesUserRequestSuccess,
  updateProjectRequestFailure,
  updateProjectRequestSuccess,
  updateTagRequestFailure,
  updateTagRequestSuccess,
  updateTaskPriorityRequestFailure,
  updateTaskPriorityRequestSuccess,
  updateTaskTypeRequestFailure,
  updateTaskTypeRequestSuccess,
  updateTrackingStatusRequestFailure,
  updateTrackingStatusRequestSuccess,
} from './projectSlice';
import { requestFailureRedirection } from '../common/commonSlice';

function* asyncSetProjectInvites(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.GET_PROJECT_INVITES.url}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      let inviteStatus;
      if (data.payload.status === 1) {
        inviteStatus = 1;
      } else {
        inviteStatus = 2;
      }
      yield put(
        setProjectInviteAcceptanceRequestSuccess({
          message: response.data.message,
          inviteStatus,
        })
      );
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(setProjectInviteAcceptanceRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

function* asyncProjectInvitesUser(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_USER_INVITES_PROJECT.url}?ProjectId=${data.payload}`,
      data.payload
    );
    if (response.status === 200) {
      yield put(sendProjectInvitiesUserRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(sendProjectInvitiesUserRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// accept project details
function* asyncGetAcceptProjectInfo(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_ACCEPT_PROJECT_DETAILS.url}?token=${data.payload}`
    );
    if (response.status === 200) {
      yield put(getAcceptProjectInfoRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(getAcceptProjectInfoRequestFailure());
    if (err.response?.data.message) {
      console.log(err);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Get All project
function* asyncGetAllProjects(payload) {
  try {
    const response = yield ApiService.getData(`${URLS.SAVE_PROJECTS.url}`);
    if (response.status === 200) {
      let getProjectId = getProjectInfo('selectedProjectId')
      if(!getProjectId && response.data.data.length)
      {
        getProjectId = response.data.data[0].id;
      }
      setProjectInfo(getProjectId,payload.payload.dispatch)
      yield put(getAllProjectRequestSuccess({data:response.data.data,projectId:payload.payload.id}));
    }
  } catch (error) {
    yield put(getAllProjectRequestFailure());
  }
}

// project scrum
function* asyncGetProjectScrum(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_PROJECT_SCRUM.url}?ProjectId=${data.payload}`,
      data.payload
    );
    if (response.status === 200) {
      yield put(getProjectScrumRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response.status === 403) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getProjectScrumRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// update project
function* asyncUpdateProject(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_PROJECT_REQUEST.url}/${data.payload.id}`,
      data.payload.data,
      true
    );
    if (response.status === 200) {
      yield put(updateProjectRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateProjectRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

function* asyncSaveProject(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SAVE_PROJECTS.url,
      data.payload,
      false
    );
    if (response.status === 200) {
      yield put(saveProjectRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveProjectRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// delete project
function* asyncDeleteProject(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_PROJECTS.url}/delete/${data.payload}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(deleteProjectRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteProjectRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// save task type
function* asyncSaveTaskType(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SEND_TASK_TYPE.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveTaskTypeRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveTaskTypeRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// update task type
function* asyncUpdateTaskType(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_TASK_TYPE.url}/${data.payload.taskTypeId}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(updateTaskTypeRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateTaskTypeRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// delete task type
function* asyncDeleteTaskType(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SEND_TASK_TYPE.url}/delete/${data.payload.id}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(deleteTaskTypeRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteTaskTypeRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Task Priority
function* asyncSaveTaskPriority(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SAVE_TASK_PRIORITY.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveTaskPriorityRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveTaskPriorityRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// update Task Priority
function* asyncUpdateTaskPriority(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_TASK_PRIORITY.url}/${data.payload.priorityId}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(updateTaskPriorityRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateTaskPriorityRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// delete task Priority
function* asyncDeleteTaskPriority(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_TASK_PRIORITY.url}/delete/${data.payload.id}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(deleteTaskPriorityRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteTaskPriorityRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Tracking Status
function* asyncSaveTrackingStatus(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SAVE_TRACKING_STATUS.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveTrackingStatusRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveTrackingStatusRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// update tracking status
function* asyncUpdateTrackingStatus(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_TRACKING_STATUS.url}/${data.payload.trackingId}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(updateTrackingStatusRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateTrackingStatusRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// delete tracking status
function* asyncDeleteTrackingStatus(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_TRACKING_STATUS.url}/delete/${data.payload.id}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(deleteTrackingStatusRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(deleteTrackingStatusRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// get All tracking status

function* asyncGetAllTrackingStatus(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.SAVE_TRACKING_STATUS.url}?ProjectId=${data.payload}`,
      data.payload
    );
    if (response.status === 200) {
      yield put(getAllTrackingStatusRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(getAllTrackingStatusRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Tags
function* asyncSaveTag(data) {
  try {
    const response = yield ApiService.postData(
      URLS.SAVE_TAG.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveTagRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(saveTagRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// update tracking status
function* asyncUpdateTag(data) {
  try {
    const finalPayload = {
      name: data.payload.name,
      projectId: data.payload.projectId,
    };
    const response = yield ApiService.postData(
      `${URLS.UPDATE_TAG.url}/${data.payload.tagId}`,
      finalPayload,
      true
    );
    if (response.status === 200) {
      yield put(updateTagRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateTagRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// delete tracking status
function* asyncDeleteTag(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_TAG.url}/delete/${data.payload.id}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(deleteTagRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(deleteTagRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

function* asyncRemoveProject(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_REMOVE_PROJECT.url}?inviteId=${data.payload}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(removeProjectRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(removeProjectRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

// Project Role Change
function* asyncProjectRoleChange(data) {
  try {
    const response = yield ApiService.postData(
      URLS.PROJECT_ROLE_CHANGE.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(setUserRoleChangeRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(setUserRoleChangeRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Flow Status List
function* asyncFlowStatusList(data) {
  try {
    const response = yield ApiService.getData(
      URLS.GET_FLOW_STATUS.url,
      data.payload
    );

    if (response.status === 200) {
      yield put(getFlowStatusListRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getFlowStatusListRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Change Tracking Status Order
function* asyncChangeTrackingOrder(data) {
  try {
    const response = yield ApiService.postData(
      URLS.TRACKING_STATUS_ORDER.url,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveTrackingStatusOrderRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(saveTrackingStatusOrderRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Delete User
function* asyncDeleteUser(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.DELETE_USER.url}?UserId=${data.payload.userId}&ProjectId=${data.payload.projectId}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(deleteUserRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(deleteUserRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

export function* projectSaga() {
  yield all([
    takeEvery(
      constants.setProjectInviteRequestActionType,
      asyncSetProjectInvites
    ),
    takeEvery(
      constants.invitedUserProjectRequestActionType,
      asyncProjectInvitesUser
    ),
    takeEvery(
      constants.getProjectInvitationInfoRequestActionType,
      asyncGetAcceptProjectInfo
    ),
    takeEvery(constants.getAllProjectRequestActionType, asyncGetAllProjects),
    takeEvery(constants.saveProjectRequestActionType, asyncSaveProject),
    takeEvery(constants.sendTaskTypeRequestActionType, asyncSaveTaskType),
    takeEvery(
      constants.saveTaskPriorityRequestActionType,
      asyncSaveTaskPriority
    ),
    takeEvery(
      constants.saveTrackingStatusRequestActionType,
      asyncSaveTrackingStatus
    ),
    takeEvery(constants.saveTagRequestActionType, asyncSaveTag),
    takeEvery(constants.getProjectScrumRequestActionType, asyncGetProjectScrum),
    takeEvery(constants.updateProjectRequestActionType, asyncUpdateProject),
    takeEvery(constants.deleteProjectRequestActionType, asyncDeleteProject),
    takeEvery(constants.updateTaskTypeRequestActionType, asyncUpdateTaskType),
    takeEvery(
      constants.updateTaskPriorityRequestActionType,
      asyncUpdateTaskPriority
    ),
    takeEvery(
      constants.updateTrackingStatusRequestActionType,
      asyncUpdateTrackingStatus
    ),
    takeEvery(constants.updateTagRequestActionType, asyncUpdateTag),
    takeEvery(constants.deleteTaskTypeRequestActionType, asyncDeleteTaskType),
    takeEvery(
      constants.deleteTaskPriorityRequestActionType,
      asyncDeleteTaskPriority
    ),
    takeEvery(
      constants.deleteTrackingStatusRequestActionType,
      asyncDeleteTrackingStatus
    ),
    takeEvery(
      constants.getTrackingStatusRequestActionType,
      asyncGetAllTrackingStatus
    ),
    takeEvery(constants.deleteTagRequestActionType, asyncDeleteTag),
    takeEvery(constants.removeProjectRequestActionType, asyncRemoveProject),
    takeEvery(
      constants.userRoleChangeRequestActionType,
      asyncProjectRoleChange
    ),
    takeEvery(constants.flowStatusRequestActionType, asyncFlowStatusList),
    takeEvery(
      constants.changeTrackingStatusOrderRequestActionType,
      asyncChangeTrackingOrder
    ),
    takeEvery(constants.userDeleteRequestActionType, asyncDeleteUser),
  ]);
}
