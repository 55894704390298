import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: true,
  success: false,
  error: true,
  message: '',
  allReports: [],
  paginationMeta: {},
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // get All task History
    getAllReportsRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allReports: action.payload.data,
          loading: false,
        };
      }
      return state;
    },
    getAllReportsRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    },

    // reset state
    resetState: (state) => {
      return {
        ...state,
        message: '',
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAllReportsRequestSuccess,
  getAllReportsRequestFailure,
  resetState,
  resetArrayState,
} = reportSlice.actions;

export default reportSlice.reducer;
