import React from 'react';

const RemoveModal = ({ setShowModal, handleRemoveTaskSprint }) => {
  return (
    // <div className={`modal ${completeSprintShowModal ? "show" : "hide"}`}>
    <div className={`modal ${'show'}`}>
      <div className="modal-dialog modal-lg modal-dialog-centered max-w-4xl w-[38rem]">
        <div className="modal-content">
          <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
            <h5 className="text-xl font-medium leading-normal text-gray-800">
              Remove Task
            </h5>
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="btn-close box-content"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body relative p-5">
            <div className="text-center">
              <p>Are you sure you want to Remove Task?</p>
            </div>
          </div>

          <div className="flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn btn-cancel"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              onClick={() => {
                handleRemoveTaskSprint();
                setShowModal(false);
              }}
              type="button"
              className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0 mr-3"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveModal;
