import React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import FormInputField from '../../../formInputField';
import { saveSprintRequest } from '../../../../store/sprint/action';
import {
  getInputDateFormat,
  getNextMondayDate,
} from '../../../../utilities/utils';

const CreateSprintModal = ({
  showModal,
  setShowModal,
  dispatchSaveSprintRequest,
}) => {
  const { projectId } = useSelector((state) => state.project);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    };
  }, []);

  useEffect(() => {
    setValue('startDate', getNextMondayDate(new Date()).replaceAll('/', '-'));
    const d = watch('startDate');
    const now = new Date(d);
    now.setDate(now.getDate() + 14);
    setValue('endDate', getInputDateFormat(now));
  }, []);

  const handleSubmitSprint = (data) => {
    dispatchSaveSprintRequest({
      projectId: projectId,
      name: data.name,
      startDate: data.startDate,
      endDate: data.endDate,
    });
    setShowModal(false);
  };
  return (
    <>
      <div className={`modal ${showModal ? 'show' : 'hide'}`}>
        <div className="modal-dialog modal-lg modal-dialog-centered max-w-[36rem]">
          <div className="modal-content">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <h5 className="text-xl font-medium leading-normal text-gray-800">
                Create Sprint
              </h5>

              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="btn-close box-content"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className={'modal-body relative p-5'}>
              <div className="text-start">
                <form onSubmit={handleSubmit(handleSubmitSprint)}>
                  <div className="w-full">
                    <FormInputField
                      label={'Sprint Name:'}
                      type={'text'}
                      fieldName={'name'}
                      register={register}
                      errors={errors}
                      isRequired={true}
                      placeholder={'Enter sprint name'}
                    />

                    <div className="mt-3">
                      <FormInputField
                        label={'Start Date'}
                        type={'date'}
                        fieldName={'startDate'}
                        register={register}
                        errors={errors}
                        isRequired={true}
                        placeholder={''}
                      />
                    </div>
                    <div className="mt-3">
                      <FormInputField
                        label={'End Date'}
                        type={'date'}
                        fieldName={'endDate'}
                        register={register}
                        errors={errors}
                        isRequired={true}
                        placeholder={''}
                      />
                    </div>
                  </div>

                  <div className="flex flex-shrink-0 flex-wrap items-end justify-end p-4 rounded-b-md">
                    <button
                      type="submit"
                      className="btn-primary w-auto mx-0 mt-0 mr-0"
                      data-bs-dismiss="modal"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSaveSprintRequest: saveSprintRequest,
};
export default connect(null, mapDispatchToProps)(CreateSprintModal);
