import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg, setItem } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  getLoginDetailsRequestFailure,
  getLoginDetailsRequestSuccess,
  sendLoginRequestFailure,
  sendLoginRequestSuccess,
  sendUserRequestFailure,
  sendUserRequestSuccess,
} from './loginSlice';

function* asyncLogin(data) {
  try {
    const response = yield ApiService.postLoginData(
      URLS.LOGIN.url,
      data.payload
    );
    if (response.status === 200) {
      setItem('token', response.data.token);
      const userData = {
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        id: response.data.id,
        profilePicUrl: response.data.profilePicUrl,
      };
      const date = new Date();
      const updateDate = new Date(response.data.expiresIn * 1000);
      document.cookie =
        'RefreshCookie  = ' +
        updateDate.toString() +
        '; expires = ' +
        updateDate.toString();

      const now = new Date();
      const secondSinceEpoch = Math.round(now.getTime() / 1000);

      const expireTimeInSecond =
        response.data.refreshTokenExpiresIn - secondSinceEpoch;
      const expiryRefreshTokenDate = new Date(date.getTime());

      expiryRefreshTokenDate.setDate(
        expiryRefreshTokenDate.getDate() + expireTimeInSecond
      );

      document.cookie =
        'RefreshTokenExpiryCookie = ' +
        expiryRefreshTokenDate.toString() +
        '; expires = ' +
        expiryRefreshTokenDate.toString();
      setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('userData', JSON.stringify(userData));
      yield put(sendLoginRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(sendLoginRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Incorrect Email or Password!');
    }
  }
}

function* asyncRegister(data) {
  try {
    const response = yield ApiService.postData(URLS.REGISTER.url, data.payload);
    if (response.status === 200) {
      yield put(sendUserRequestSuccess(response.data.message));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(sendUserRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

function* asyncLoginDetails(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.REGISTER.url}/login-with-google`,
      data.payload
    );
    if (response.status === 200) {
      setItem('token', response.data.token);
      const userData = {
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        id: response.data.id,
        profilePicUrl: response.data.profilePicUrl,
      };
      const date = new Date();
      const updateDate = new Date(response.data.expiresIn * 1000);
      document.cookie =
        'RefreshCookie  = ' +
        updateDate.toString() +
        '; expires = ' +
        updateDate.toString();
      const now = new Date();
      const secondSinceEpoch = Math.round(now.getTime() / 1000);
      console.log(secondSinceEpoch);
      const expireTimeInSecond =
        response.data.refreshTokenExpiresIn - secondSinceEpoch;
      const expiryRefreshTokenDate = new Date(date.getTime());

      expiryRefreshTokenDate.setDate(
        expiryRefreshTokenDate.getDate() + expireTimeInSecond
      );

      document.cookie =
        'RefreshTokenExpiryCookie = ' +
        expiryRefreshTokenDate.toString() +
        '; expires = ' +
        expiryRefreshTokenDate.toString();
      setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('userData', JSON.stringify(userData));
      yield put(getLoginDetailsRequestSuccess(response.data));
    }
  } catch (error) {
    yield put(getLoginDetailsRequestFailure());
    if (error.response?.data.message) {
      console.log(error.response?.data.message);
    } else {
      errorMsg('Something is wrong!');
    }
  }
}

export function* loginSaga() {
  yield all([
    takeEvery(constants.sendLoginRequestActionType, asyncLogin),
    takeEvery(constants.sendUserRequestActionType, asyncRegister),
    takeEvery(constants.getLoginDetailsRequestActionType, asyncLoginDetails),
  ]);
}
