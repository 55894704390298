import React, { useEffect, useState } from 'react';
import '../../comments/styles.scss';
import { connect } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import Avatar from 'react-avatar';
import ReactTimeAgo from 'react-time-ago';
import {
  deleteTaskComment,
  saveEditorImage,
  updateTaskComment,
} from '../../../../../store/task/action';
import { convertTime } from '../../../../../utilities/utils';
import CustomAvatar from '../../../../common/customAvatar';
const HistoryContainer = ({ data }) => {
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if (data && data.length) {
      setAllData(data);
    }
  }, [allData]);
  return (
    <>
      {!!data &&
        !!data.length &&
        data.map((item, idx) => (
          <div
            className="mb-4 rounded-[5px] comment task-history-comments"
            key={idx}
          >
            <div className="content border-b py-2">
              <div className="user flex items-center">
                {item.userId ? (
                  <CustomAvatar id={item.userId} idPrefix={item.id} assigneeName={item.changedBy} />
                ) : (
                  <div className="user">
                    <div className="avatar">
                      <Avatar
                        round={true}
                        title="Unassigned"
                        name="Un Assigned"
                      />
                    </div>
                  </div>
                )}
                <div className="w-full">
                  <div className="flex items-center">
                    <div className="text-[13px] w-full ml-1">
                      <span className="font-bold max-w-[6rem] text-ellipsis whitespace-nowrap mr-1">
                        {item.changedBy}
                      </span>

                      <span>
                        {item.oldValue !== null && item.newValue
                          ? 'Changed'
                          : item.oldValue === null && item.newValue
                            ? 'Added'
                            : item.newValue === null &&
                            item.oldValue &&
                            'Deleted'}
                        <span className="mx-1">the</span>
                        <span className="font-bold">{item.fields}</span>
                      </span>
                      <span className="ml-2">
                        <b>
                          <ReactTimeAgo
                            date={Number(convertTime(item.createdAt))}
                            locale="en-US"
                          />
                        </b>
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 flex">
                    {item.oldValue ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: item.oldValue }}
                      ></span>
                    ) : (item.oldValue === null || item.oldValue === '') &&
                      item.newValue ? (
                        ''
                      ) : (
                        <span>None</span>
                      )}
                    {item.oldValue === null || item.oldValue === '' ? (
                      ''
                    ) : (
                      <b className="flex items-center">
                        <i className="fas fa-long-arrow-alt-right text-[#979797] mx-3"></i>
                      </b>
                    )}
                    {item.newValue ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: item.newValue }}
                      ></span>
                    ) : (
                      <span>None</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchUpdateTaskComment: updateTaskComment,
  dispatchDeleteTaskComment: deleteTaskComment,
  dispatchSaveEditorImageRequest: saveEditorImage,
};
export default connect(null, mapDispatchToProps)(HistoryContainer);
