import React from 'react';

const Footer = () => {
  return (
    <footer id="siteFooter" className="fixed inset-x-0 bottom-0 p-4 z-[9] hidden">
      <div className="text-right">
        <strong>Ensuesoft Private Limited</strong>
      </div>
    </footer>
  );
};

export default Footer;
