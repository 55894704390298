import * as Constants from './constants';

// Slack connect
export const getConnectSlackRequest = (payload) => {
  return {
    type: Constants.getSlackRequestActionType,
    payload,
  };
};

// save Slack Connect
export const saveConnectSlackRequest = (payload) => {
  return {
    type: Constants.saveSlackRequestActionType,
    payload,
  };
};

// Slack Trigger message
export const saveSlackTriggerMsgRequest = (payload) => {
  return {
    type: Constants.saveSlackTriggerMsgRequestActionType,
    payload,
  };
};
