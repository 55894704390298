/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import FormInputField from '../../../formInputField';
import FormSelector from '../../../formSelector';
import AttachmentFile from '../components/attachments';
import {
  getAllCheckList,
  getAllTaskComment,
  getAllTaskHistory,
  getTaskTimeTracker,
  sendTaskRequest,
  updateTaskRequest,
} from '../../../../store/task/action';
import {
  UnSavedChange,
  addOverlayOnEditor,
  convertUTCDateToLocalDate,
  getInputDateFormat,
  getItem,
  imageUpload,
  removeOverlayFromEditor,
  toolbar,
} from '../../../../utilities/utils';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import ImageUploader from 'quill-image-uploader';
import CreatableSelect from 'react-select/creatable';
import ImageModal from '../modal/ImageModal';
import Avatar from 'react-avatar';
import { resetState } from '../../../../store/task/taskSlice';
import FormFieldError from '../../../formFieldError';
import * as AppConstants from '../../../../utilities/constants';
import LinkIssueTask from '../components/linkIssue/LinkIssueTask';
import HistoryList from '../components/history/HistoryList';
import TimeSpend from '../components/timeSpend/index';
import CustomAvatar from '../../../common/customAvatar';
import CheckList from './checkList/index';
import Comments from './comments';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';

const TaskSingle = forwardRef(({
  taskId,
  modalId,
  popup,
  singleTask,
  dispatchSendTaskRequest,
  dispatchUpdateTaskRequest,
  dispatchGetAllTaskComments,
  dispatchGetAllCheckList,
  dispatchGetAllTaskHistory,
  dipatchGetTaskTimeTracker,
  addSubTaskShow,
  setAddSubTaskShow,
  handleDeleteTask,
  setEditMode,
  editMode,
  setResetTitle,
  resetTitle,
  dispatchResetStateRequest,
  setTaskTimer,
  discardModal
},ref) => {
  const {
    projectUsers,
    taskTypes,
    taskPriorities,
    trackingStatuses,
    sprints,
    tags,
    isTaskAdded,
    isDisableBtn,
    isUpdateTask,
    isTaskTimerStart,
  } = useSelector((state) => state.task);
  let datePickerRef = useRef(null);
  let impactRef = useRef(null);
  const { projectId, projects } = useSelector((state) => state.project);
  const { projectSettings } = useSelector((state) => state.permissions);
  const quillRef = useRef(null);
  const tabRef = useRef(null);
  const selectProjectRef = useRef(null);
  const [editorValue, setEditorValue] = useState('');
  const [changeTitle, setChangeTitle] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [finalTag, setFinalTag] = useState(null);
  const [selectProjectId, setSelectProjectId] = useState();
  const [canEditTask, setCanEditTask] = useState(false);
  const [canDeleteTask, setCanDeleteTask] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState('');
  // const [unSaveTitle, setUnSaveTitle] = useState();
  const [showTabsMenus, setShowTabsMenus] = useState();
  const [isSortedByAsc, setIsSortedByAsc] = useState(true);
  const [isProjectTypeId, setIsProjectTypeId] = useState(false);
  const [attachFiles, setAttachFiles] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [selectUserName, setSelectUserName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [btnEnable, setBtnEnable] = useState(false);
  const [isEditTaskTitle, setIsEditTaskTitle] = useState(false);
  const sortOrderBtn =[
    'Comments',
    'Checklist',
    'History',
    'Time Spend',
  ];
  const [unSavedTaskData, setUnSavedTaskData] = useState([]);
  const { userData } = useSelector((state) => state.user);
  const [projectTitle, setProjectTitle] = useState('')
  const atValues = [];
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm();

  useImperativeHandle(ref, () => ({
    handleChangeTitle() {
      if(projectTitle)
      {
        UnSavedChange(projectId,null,projectTitle, 'title');
      }
    },
    handleChangeDescription()
    {
      if(editorValue)
      {
        UnSavedChange(projectId,null, editorValue, 'description');
      }
    }
  }),[projectId,projectTitle,editorValue])

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('unSavedTaskDataLocal'))) {
      const unSavedTaskData = JSON.parse(
        localStorage.getItem('unSavedTaskDataLocal')
      );
      setUnSavedTaskData(unSavedTaskData);
    }
  }, [projectId]);

  useEffect(() => {
    if (userData) {
      userData.map((user) =>
        atValues.push({
          id: user.id,
          value: user.firstName + ' ' + user.lastName,
        })
      );
    }
  }, [projectUsers, userData]);

  useEffect(() => {
    if (modalId === 'Tasks') {
      if (allUsers && allUsers.length && Object.keys(singleTask).length) {
        let selectedUser = allUsers.find(
          (user) => user.id === singleTask.assignedTo
        );
        if (selectedUser) {
          setSelectUserName(selectedUser.userName);
          setSelectedUserId(selectedUser.id);
        } else {
          setSelectedUserId(null);
          setSelectUserName('Unassigned');
        }
      }
    } else {
      setSelectUserName('Unassigned');
    }
  }, [singleTask, allUsers, modalId]);

  useEffect(() => {
    if (projects && projects.length) {
      if (projectId) {
        const project = projects.find((pro) => {
          return pro.id === Number(projectId);
        });
        if (project && Object.keys(project).length !== 0) {
          if (project.projectTypeId === 2) {
            setIsProjectTypeId(true);
          } else {
            setIsProjectTypeId(false);
          }
        }
      }
    }
  }, [projects, projectId]);

  useEffect(() => {
    const settings = projectSettings;
    if (settings) {
      if (settings.canEditTask) {
        setCanEditTask(settings.canEditTask);
      } else {
        setCanEditTask(settings.canEditTask);
      }
      if (settings.canDeleteTask) {
        setCanDeleteTask(settings.canDeleteTask);
      } else {
        setCanDeleteTask(settings.canDeleteTask);
      }
    }
  }, [projectSettings]);

  useEffect(() => {
    if (tags.length) {
      const suggestions = tags.map((tag) => {
        return {
          value: tag.name,
          label: tag.name,
        };
      });
      setTagsSuggestions(suggestions);
      if (modalId !== 'Tasks') {
        setFinalTag({});
      }
    }
  }, []);

  useEffect(() => {
    if (isUpdateTask) {
      if (tags.length) {
        const suggestions = tags.map((tag) => {
          return {
            value: tag.name,
            label: tag.name,
          };
        });

        setTagsSuggestions(suggestions);
      }
    }
  }, [isUpdateTask]);

  useEffect(() => {
    if (JSON.parse(getItem('selectedProject'))) {
      setSelectProjectId(JSON.parse(getItem('selectedProject')).id);
    }
  }, []);

  useEffect(() => {
    if (projectId) {
      setSelectProjectId(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (addSubTaskShow) {
      if (tabRef) {
        tabRef.current.click();
      }
    }
  }, [addSubTaskShow]);

  const handleChangeDescription = (html) => {
    setEditorValue(html);
  };

  Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste, true);
  Quill.register('modules/imageUploader', ImageUploader, true);
 
  const modules = React.useMemo(
    () => ({
      toolbar: toolbar,
      imageUploader: {
        upload: (image) => {
          addOverlayOnEditor();
          return new Promise((resolve, reject) => {
            const imageUploadResponse = imageUpload(image);
            imageUploadResponse
              .then(function successValue(result) {
                resolve(result.attachmentUrl);
                attachFiles.push({ ...result, id: 0 });
                removeOverlayFromEditor();
              })
              .catch((error) => {
                reject('Upload File failed');
                console.error('Error:', error);
                removeOverlayFromEditor();
              });
          });
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@', '#'],
        source: function (searchTerm, renderItem, mentionChar) {
          let values;
          if (mentionChar === '@' || mentionChar === '#') {
            values = atValues;
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderItem(matches, searchTerm);
          }
        },
      },
    }),
    []
  );

  const handleDataSubmit = (data) => {
    if (modalId === 'Task') {
      const finalData = {
        title: data.title,
        description: editorValue,
        assignedTo: selectedUserId,
        trackingStatusId: data.trackingStatusId,
        projectId: projectId,
        taskPriorityId: data.taskPriorityId,
        taskTypeId: data.taskTypeId,
        dueDate:convertUTCDateToLocalDate(data.dueDate),
        estimatedTime: data.estimatedTime,
        sprintId: data.sprintId,
        taskAttachments: attachFiles,
        tag:
          finalTag !== null && Object.keys(finalTag).length !== 0
            ? { name: finalTag?.value }
            : { id: 0, name: '' },
      };
      setBtnEnable(true);
      dispatchSendTaskRequest(finalData);
    } else {
      let finalData = { ...data, tag: { name: finalTag.name } };
      let arr = [];
      let demoArray = [
        'id',
        'projectId',
        'dueDate',
        'taskTypeId',
        'taskHistoryData',
        'assignedTo',
        'estimatedDate',
        'taskPriorityId',
        'tag',
      ];
      for (let i = 0; i < Object.keys(finalData).length; i++) {
        if (!demoArray.includes(Object.keys(finalData)[i])) {
          arr.push({
            path: `/${Object.keys(finalData)[i]}`,
            op: 'replace',
            value: Object.values(finalData)[i],
          });
        }
      }
      const finalPayload = {
        id: finalData.id,
        data: arr,
      };
      dispatchUpdateTaskRequest(finalPayload);
    }
  };

  useEffect(() => {
    if (unSavedTaskData.length) {
      const unSavedDataList = unSavedTaskData.filter(
        (item) => item.projectId === projectId
      );
      if (modalId === 'Task') {
        if (unSavedDataList.length) {
          if (unSavedDataList[0].description && unSavedDataList[0].title) {
            setEditorValue(unSavedDataList[0].description);
            // setUnSaveTitle(unSavedDataList[0].title);
            setValue('title', unSavedDataList[0].title);
          } else {
            if (unSavedDataList[0].description) {
              setEditorValue(unSavedDataList[0].description);
            } else {
              // setUnSaveTitle(unSavedDataList[0].title);
              setValue('title', unSavedDataList[0].title);
            }
          }
          setProjectTitle(unSavedDataList[0].title)
        }
      }
    }
    return () => {
      Quill.remove;
    };
  }, [unSavedTaskData]);

  useEffect(() => {
    if (isTaskAdded) {
      if (unSavedTaskData.length) {
        const remainUnSaveTaskDataList = unSavedTaskData.filter((item) => item.projectId !== projectId);
        if (remainUnSaveTaskDataList.length === 0) {
          localStorage.removeItem('unSavedTaskDataLocal', []);
        } else {
          localStorage.setItem('unSavedTaskDataLocal', JSON.stringify(remainUnSaveTaskDataList));
        }
      }
      setAttachFiles([]);
      setUnSavedTaskData([])
    }
  }, [isTaskAdded]);

  useEffect(() => {
    if (isDisableBtn) {
      setBtnEnable(false);
      dispatchResetStateRequest();
    }
  }, [isDisableBtn]);

  useEffect(() => {
    if (singleTask && Object.keys(singleTask).length) {
      if (taskId) {
        if (singleTask.id === taskId && projectUsers.length) {
          reset(singleTask);
          setEditorValue(singleTask.description);
          setValue(
            'dueDate',
            singleTask.dueDate !== null &&
              getInputDateFormat(singleTask.dueDate)
          );
          if (singleTask.tagId) {
            let filteredTag = tags.filter((tag) => tag.id === singleTask.tagId);
            if (filteredTag && filteredTag.length) {
              filteredTag = filteredTag.map((tag) => {
                return {
                  value: tag.id,
                  label: tag.name,
                };
              });
              setFinalTag(filteredTag);
            } else {
              setFinalTag({});
            }
          } else {
            setFinalTag({});
          }
        }
      }
    }
  }, [singleTask, projectUsers, taskId,]);

  const handleEdit = (data) => {
    setChangeTitle(data);
    setEditMode(true);
  };

  const handleChangeTitle = (e) => {
    if (modalId === 'Tasks') {
      setIsEditTaskTitle(true);
      setEditMode(true);
    } else {
      setProjectTitle(e.target.value)
    }
    setChangeTitle({
      fieldName: 'title',
      fValue: e.target.value,
    });
  };

  const handleUpdateSingleField = () => {
    const finalPayload = {
      id: singleTask.id,
      data: [
        {
          path: `/${
            changeTitle.fieldName === 'editor'
              ? 'description'
              : changeTitle.fieldName
          }`,
          op: 'replace',
          value: `${
            changeTitle.fieldName === 'editor'
              ? editorValue
              : changeTitle.fValue
          }`,
        },
      ],
    };
    if (changeTitle.fValue !== '') {
      dispatchUpdateTaskRequest(finalPayload);
      setEditMode(false);
      setValue('title', changeTitle.fValue);
    } else {
      setEditMode(true);
    }
  };

  const handleSelectChange = (data, isRemoveOperation) => {
    const finalPayload = {
      id: singleTask.id,
      data: [
        {
          path: `/${data.fieldName.toLowerCase()}`,
          op: isRemoveOperation ? 'remove' : 'replace',
          value: data.fValue,
        },
      ],
    };
    dispatchUpdateTaskRequest(finalPayload);
  };

  useEffect(() => {
    const loginUser = JSON.parse(localStorage.getItem('userData'));
    const filterUser = projectUsers.some((user) => user.id === loginUser.id);
    if (filterUser && projectUsers.length) {
      setAllUsers(projectUsers);
    } else {
      setAllUsers([
        ...projectUsers,
        {
          id: loginUser.id,
          userName: loginUser.firstName + ' ' + loginUser.lastName,
          email: loginUser.email,
        },
      ]);
    }
  }, [projectUsers]);

  useEffect(() => {
    setResetTitle('Details');
  }, []);

  const handleAssignToMe = () => {
    const loginUser = JSON.parse(localStorage.getItem('userData'));
    setSelectedUserId(loginUser.id);
    setSelectUserName(`${loginUser.firstName} ${loginUser.lastName}`);
    if (modalId === 'Tasks') {
      handleSelectChange({
        fieldName: 'assignedTo',
        fValue: loginUser.id,
      });
    }
  };

  const handleAddTag = (tag) => {
    if (modalId === 'Tasks') {
      if (tag === null) {
        handleSelectChange(
          {
            fieldName: 'tag',
            fValue: null,
          },
          true
        );
      } else {
        let updatedTag;
        updatedTag = { name: tag.value };
        handleSelectChange({
          fieldName: 'tag',
          fValue: updatedTag,
        });
      }
    } else {
      let updatedTag;
      updatedTag = { value: tag.value, label: tag.value };
      setFinalTag(updatedTag);
    }
  };

  const renderContent = () => {
    return { __html: editorValue };
  };

  const handleDoubleClickImage = (event) => {
    const imageSrc = event.target.getAttribute('src');
    setSelectedImageSrc(imageSrc);
    setShowImageModal(true);
  };

  const handleDoubleClickImages = (event) => {
    if (event.target.tagName === 'IMG') {
      const imageSrc = event.target.getAttribute('src');
      setSelectedImageSrc(imageSrc);
      setShowImageModal(true);
    }
  };

  const getImagesFromQuillContent = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const delta = quill.getContents();
      const ops = delta.ops.filter((op) => op.insert && op.insert.image);
      const imageSrcs = ops.map((op) => op.insert.image);
      return imageSrcs;
    }
  };

  const handleUpdateCancel = () => {
    setEditorValue(singleTask.description);
    setEditMode(false);
  };
 
  const handleTabMenu = (tabName) => {
    setShowTabsMenus(tabName);
    setIsSortedByAsc(true);
    setAddSubTaskShow(false);
    if (tabName === 'comments-tab') {
      setResetTitle('Comments');
    } else {
      if (tabName === 'tabs-home-tab') {
        setResetTitle('Checklist');
      } else {
        if (tabName === 'tabs-task-priority-tab') {
          setResetTitle('History');
        } else {
          if (tabName === 'tabs-time-spend-tab') {
            setResetTitle('Time Spend');
          } else {
            if (tabName === 'tabs-link-issue-tab') {
              setResetTitle('Link Issue');
            } else {
              setResetTitle('File Attachments');
            }
          }
        }
      }
    }
  };

  const handleFilterData = () => {
    const payload = {
      TaskId: singleTask.id,
      SortByAsc: isSortedByAsc,
    };
    if (showTabsMenus === 'tabs-home-tab') {
      dispatchGetAllCheckList({ payload, projectId: selectProjectId });
    } else if (showTabsMenus === 'comments-tab') {
      dispatchGetAllTaskComments({ payload, projectId: selectProjectId });
    } else if (showTabsMenus === 'tabs-task-priority-tab') {
      dispatchGetAllTaskHistory({
        payload: {
          taskId: singleTask.id,
          pageNumber: 1,
          pageSize: 10,
          SortByAsc: isSortedByAsc,
        },
        projectId: selectProjectId,
      });
    } else {
      dipatchGetTaskTimeTracker({ payload, projectId: selectProjectId });
    }
  };

  const handleisSortedByAsc = () => {
    setIsSortedByAsc(!isSortedByAsc);
    setTimeout(() => {
      handleFilterData();
    }, 500);
  };

  const handleDueDate = (val) => {
    if (val) {
      handleSelectChange({
        fieldName: 'dueDate',
        fValue: val,
      });
    }
    return val;
  };

  const handleClick = (data) => {
    setSelectedUserId(data.id);
    setSelectUserName(data.name);
    handleSelectChange({
      fieldName: 'assignedTo',
      fValue: data.id,
    });
  };

  const handleOnBlur = () => {
    setShowDropdown(true);
  };

  const closeSelectProjectDropdown = (e) => {
    if (
      selectProjectRef.current &&
      !showDropdown &&
      !selectProjectRef.current.contains(e.target)
    ) {
      handleOnBlur();
    }
  };
  document.addEventListener('mousedown', (e) => closeSelectProjectDropdown(e));
  const handleCloseTitle = () => {
    if (isEditTaskTitle) {
      setValue('title', singleTask.title);
      setEditMode(false);
      setIsEditTaskTitle(false);
    }else{
      setEditMode(false);
    }
  };

  const handleStartTimer = (data) => {
    setTaskTimer({ taskId: data.id, title: data.title });
  };

  closeEditOptionClickOutside(impactRef);
  function closeEditOptionClickOutside(ref) {
    useEffect(() => {
      // Function for click event
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if(changeTitle.fieldName === 'editor' && !editMode)
          {
            setEditMode(false)
          }
          setValue('title', singleTask.title);
        }
      }
      // Adding click event listener
      document.addEventListener('click', handleOutsideClick);
      return () => document.removeEventListener('click', handleOutsideClick);
    }, [ref,singleTask,changeTitle]);
  }
  return (
    <>
      <div className='px-5 flex justify-between items-center'>
        <ul
          className='nav nav-tabs flex flex-row flex-wrap list-none border-b-0 edit-tabs'
          id='tabs-tab'
          role='tablist'
        >
          <li
            className='nav-item'
            role='presentation'
            onClick={() => setResetTitle('Details')}
          >
            <a
              href='#tabs-addition'
              className={'nav-link active'}
              id='tabs-addition-tab'
              data-bs-toggle='pill'
              data-bs-target='#tabs-addition'
              role='tab'
              aria-controls='tabs-addition'
              aria-selected='true'
            >
              Detail
            </a>
          </li>
          {modalId === 'Tasks' && (
            <>
              <li
                className='nav-item'
                role='presentation'
                onClick={() => handleTabMenu('comments-tab')}
              >
                <a
                  href='#tabs-other-info'
                  className='nav-link'
                  id='tabs-other-info-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#tabs-other-info'
                  role='tab'
                  aria-controls='tabs-other-info'
                  aria-selected='false'
                >
                  Comments
                </a>
              </li>
              <li
                className='nav-item whitespace-nowrap'
                role='presentation'
                onClick={() => handleTabMenu('tabs-link-issue-tab')}
              >
                <a
                  href='#link-issue'
                  className='nav-link'
                  id='tabs-link-issue-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#tabs-link-issue'
                  role='tab'
                  aria-controls='tabs-link-issue'
                  aria-selected='false'
                >
                  Link Issue
                </a>
              </li>

              <li
                className='nav-item whitespace-nowrap'
                role='presentation'
                onClick={() => handleTabMenu('tabs-home-tab')}
              >
                <a
                  href='#tabs-home'
                  className='nav-link '
                  id='tabs-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#tabs-home'
                  role='tab'
                  aria-controls='tabs-home'
                  aria-selected='true'
                >
                  Checklist
                </a>
              </li>
              <li
                className='nav-item whitespace-nowrap'
                role='presentation'
                onClick={() => handleTabMenu('tabs-attachments-link')}
              >
                <a
                  href='#attachments-issue'
                  className='nav-link'
                  id='tabs-attachments-link'
                  data-bs-toggle='pill'
                  data-bs-target='#tabs-attachments-issue'
                  role='tab'
                  aria-controls='tabs-attachments-issue'
                  aria-selected='false'
                >
                  Attachments
                </a>
              </li>
              <li
                className='nav-item whitespace-nowrap'
                role='presentation'
                onClick={() => handleTabMenu('tabs-task-priority-tab')}
              >
                <a
                  href='#tabs-task-priority'
                  className='nav-link'
                  id='tabs-task-priority-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#tabs-task-priority'
                  role='tab'
                  aria-controls='tabs-task-priority'
                  aria-selected='false'
                >
                  History
                </a>
              </li>
              {projectSettings.canStartTimer && (
                <li
                  className='nav-item whitespace-nowrap'
                  role='presentation'
                  onClick={() => handleTabMenu('tabs-time-spend-tab')}
                >
                  <a
                    href='#tabs-time-spends'
                    className='nav-link'
                    id='tabs-time-spend-tab'
                    data-bs-toggle='pill'
                    data-bs-target='#tabs-time-spends'
                    role='tab'
                    aria-controls='tabs-time-spends'
                    aria-selected='false'
                  >
                    Time Spend
                  </a>
                </li>
              )}
            </>
          )}
        </ul>
        {modalId === 'Tasks' && (
          <>
            <div className='flex'>
              {sortOrderBtn.includes(resetTitle) ? (
                <div
                  className='flex items-center justify-end cursor-pointer whitespace-nowrap md:ml-0 ml-3 text-[13px]'
                  onClick={() => handleisSortedByAsc()}
                >
                  <p className='mb-0'>
                    {isSortedByAsc ? 'Newest first' : 'Oldest First'}
                  </p>
                  <img
                    src='../../assets/img/arrow-down.svg'
                    alt=''
                    className='menu-icon w-9 h-[18px] p-0'
                  />
                </div>
              ) : (
                ''
              )}
              {projectSettings.canStartTimer && !isTaskTimerStart && (
                <div className='flex my-2 mx-1'  onClick={() => handleStartTimer(singleTask)}>
                  <i
                    className='fa-sharp fa-solid fa-play text-red-500'
                  ></i>
                  <sub className='block ml-2 mt-1'>start</sub>
                </div>
              )}

              {/* )} */}

              {canDeleteTask && (
                <div className='dropdown inline-block relative'>
                  <img
                    src='../../assets/img/dots-vertical.svg'
                    alt=''
                    className='w-7 h-7 rotate-90 m-0 cursor-pointer'
                  />

                  <ul
                    id='dropdown-menu'
                    className='dropdown-menu absolute hidden pt-1 bg-white shadow rounded py-2 px-4 mb-2 whitespace-nowrap right-0 left-align'
                  >
                    <li className='text-red-500'>
                      <button
                        type='button'
                        onClick={() => handleDeleteTask(singleTask.id)}
                      >
                        <i
                          className='fa fa-trash mr-2 cursor-pointer w-[18px]'
                          aria-hidden='true'
                        ></i>
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </>
        )}
        {modalId !== 'Tasks' && (
          <div className='flex items-center nav-item justify-end pt-1'>
            <button
              className='border mt-0 btn-primary w-fit mr-0 ml-auto'
              type='button'
              disabled={btnEnable}
              onClick={handleSubmit((d) => handleDataSubmit(d))}
            >
              Create
            </button>
          </div>
        )}
      </div>
      <div className='mt-4 border-b border-solid border-gray-300 rounded-t'></div>
      <div className='modal-body relative p-5 h-[500px] md:h-[400px] xl:h-[458px] overflow-auto single-task-container'>
        <div>
          <div
            className={`tab-content ${popup ? '' : ''} pr-2`}
            id='tabs-tabContent'
          >
            <div
              className={`${
                canEditTask ? '' : 'pointer-events-none'
              } tab-pane fade show active`}
              id='tabs-addition'
              role='tabpanel'
              aria-labelledby='tabs-addition-tab'
            >
              <form onSubmit={handleSubmit(handleDataSubmit)}>
                <div>
                  <div className='md:flex'>
                    <div className='lg:w-3/5 w-full md:mr-2 mb-4'>
                      <div className='mb-4' ref={impactRef}>
                        <FormInputField
                          label={'Title'}
                          type={'text'}
                          fieldName={'title'}
                          register={register}
                          errors={errors}
                          isRequired={true}
                          placeholder={'Title'}
                          handleChange={(e) =>handleChangeTitle(e)}
                          handleOnClick={() => {
                            modalId === 'Tasks' &&
                              handleEdit({
                                fieldName: 'title',
                                fValue: singleTask.title,
                              });
                          }
                          }
                        />
                        {editMode && changeTitle?.fValue === '' && (
                          <FormFieldError
                            message={AppConstants.requiredField}
                          />
                        )}
                        {editMode && changeTitle.fieldName === 'title' && (
                          <div className='flex justify-end shodow-slate-300'>
                            <i
                              type='submit'
                              className='fa-solid fa-check mr-2 comment-edit-icon'
                              aria-hidden='true'
                              onClick={ handleUpdateSingleField}
                            ></i>
                            <i
                              className='fa-solid fa-xmark dlt-comment-icon'
                              aria-hidden='true'
                              onClick={ handleCloseTitle}
                            ></i>
                          </div>
                        )}
                      </div>
                      <div className='h-full'>
                        <label className='custom-label'>Description</label>
                        <div className='description-field'>
                          {modalId === 'Task' ||
                          (editMode && changeTitle.fieldName === 'editor') ? (
                              <>
                                <ReactQuill
                                  ref={quillRef}
                                  value={editorValue}
                                  onChange={handleChangeDescription}
                                  modules={modules}
                                ></ReactQuill>
                                {modalId === 'Task' && (
                                  <div className='flex'>
                                    {!!getImagesFromQuillContent() &&
                                    !!getImagesFromQuillContent().length &&
                                    getImagesFromQuillContent().map(
                                      (imageSrc, index) => (
                                        <div
                                          key={index}
                                          className='flex-auto w-32 h-14 m-2'
                                        >
                                          <img
                                            src={imageSrc}
                                            alt='Image'
                                            className='h-[80px]'
                                            onDoubleClick={
                                              handleDoubleClickImage
                                            }
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className='ql-snow h-full'>
                                <div className='ql-editor spacing-none'>
                                  <div
                                    className='bg-slate-50 input-style h-full main-editor-wrapper'
                                    onDoubleClick={() => {
                                      modalId === 'Tasks' &&
                                      handleEdit({ fieldName: 'editor' });
                                    }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={renderContent()}
                                      onClick={handleDoubleClickImages}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                        {showImageModal && (
                          <ImageModal
                            imageSrc={selectedImageSrc}
                            onClose={() => setShowImageModal(false)}
                          />
                        )}
                        {editMode && changeTitle.fieldName === 'editor' && (
                          <div className='flex justify-end shodow-slate-300'>
                            <i
                              type='submit'
                              className='fa-solid fa-check mr-2 mb-1 comment-edit-icon'
                              aria-hidden='true'
                              onClick={() => handleUpdateSingleField()}
                            ></i>
                            <i
                              className='fa-solid fa-xmark mb-1 dlt-comment-icon'
                              aria-hidden='true'
                              onClick={() => handleUpdateCancel()}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='lg:w-2/5 w-full lg:ml-2 mb-4'>
                      <div className='mb-4'>
                        <FormSelector
                          label={'Tracking Status'}
                          fieldName={'trackingStatusId'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                          handleChange={(e) => {
                            modalId === 'Tasks' &&
                              handleSelectChange({
                                fieldName: 'trackingStatusId',
                                fValue: e.target.value,
                              });
                          }}
                          data={trackingStatuses}
                          options='name'
                        />
                      </div>
                      <div>
                        <label className='block text-sm font-medium mb-1'>
                          Assigned to
                        </label>

                        <div>
                          <div
                            ref={selectProjectRef}
                            className={'relative  !py-[0.16rem] pl-3 pr-0 rounded-md ticket-input'}
                          >
                            <div
                              className='bg-white flex items-center rounded-md '
                              onClick={() => setShowDropdown(!showDropdown)}
                            >
                              <input
                                type='text'
                                defaultValue={selectUserName && selectUserName}
                                name='select'
                                id='select'
                                className='text-[#959595] w-full text-2xl cursor-pointer appearance-none truncate text-[14px] px-2 caret-transparent rounded-[4px]'
                              />
                              <i className='fa-solid fa-chevron-down text-[#b4b5b6] text-[14px] cursor-pointer mr-2'></i>
                            </div>
                            <input
                              type='checkbox'
                              name='show_more'
                              id='show_more'
                              className='hidden peer bg-red-400'
                            />

                            <div
                              className={`absolute rounded shadow bg-white max-h-[400px] left-0 overflow-y-auto z-[9] text-[14px] ${
                                showDropdown ? 'hidden' : 'peer-checked:flex'
                              } flex-col w-full mt-1 border border-gray-200`}
                            >
                              {!!allUsers &&
                                allUsers.map((item, idx) => {
                                  return (
                                    <div
                                      className='cursor-pointer group border-t flex '
                                      key={idx}
                                      onClick={() => {
                                        setShowDropdown(true);
                                        handleClick({
                                          id: item.id,
                                          name: item.userName,
                                        });
                                      }}
                                    >
                                      <div className='flex items-center p-[0.2rem] border-transparent border-l-4 group-hover:border-[#e32135] w-full  group-hover:bg-gray-100 avatar-main'>
                                        {item.profilePicUrl ? (
                                          <CustomAvatar
                                            id={item.id}
                                            idPrefix={item.userName}
                                            assigneeName={item.userName}
                                          />
                                        ) : (
                                          <div className='user'>
                                            <div className='avatar'>
                                              <Avatar
                                                round={true}
                                                title={item.userName}
                                                name={item.userName}
                                              />
                                            </div>
                                          </div>
                                        )}

                                        <p
                                          className={`pl-2 ${
                                            item.id === selectedUserId
                                              ? 'border-[#e32135]'
                                              : ''
                                          } `}
                                        >
                                          {item.userName}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label
                          className='block text-sm font-medium text-blue-500 cursor-pointer'
                          onClick={() => handleAssignToMe()}
                        >
                          Assigned to me
                        </label>
                      </div>
                      <div className='mb-4'>
                        <FormSelector
                          label={'Priority'}
                          fieldName={'taskPriorityId'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                          handleChange={(e) => {
                            modalId === 'Tasks' &&
                              handleSelectChange({
                                fieldName: 'taskPriorityId',
                                fValue: e.target.value,
                              });
                          }}
                          data={taskPriorities}
                          options='name'
                        />
                      </div>

                      <div className='mb-4'>
                        <div>
                          <div>
                            <label className='block text-sm font-medium mb-1'>
                              Tags
                            </label>
                            {!!finalTag && (
                              <CreatableSelect
                                defaultValue={finalTag}
                                isClearable
                                onChange={(e) => handleAddTag(e)}
                                options={tagsSuggestions}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='mt-4'>
                        <FormSelector
                          label={'Type'}
                          fieldName={'taskTypeId'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                          handleChange={(e) => {
                            modalId === 'Tasks' &&
                              handleSelectChange({
                                fieldName: 'taskTypeId',
                                fValue: e.target.value,
                              });
                          }}
                          data={taskTypes}
                          options='name'
                        />
                      </div>

                      {modalId === 'Task' && isProjectTypeId === false && (
                        <div className='mt-4'>
                          <label className='block text-sm font-medium mb-1'>
                            Sprint
                          </label>

                          <div className=''>
                            <div className='arrow-down'>
                              <i className='fas fa-chevron-down'></i>
                            </div>
                            <select
                              {...register('sprintId')}
                              className='border border-gray-300 input-style'
                            >
                              <option value={0}>None</option>
                              {!!sprints && !!sprints.length &&
                                sprints.map((item, idx) => {
                                  return (
                                    <option key={idx} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      <div className='mt-4'>
                        <label className='custom-label'>Due date</label>
                        <Controller
                          name={'dueDate'}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <div className='relative'>
                                <DatePicker
                                  placeholderText='DD/MM/YYYY'
                                  selected={
                                    value && new Date(Date.parse(value))
                                  }
                                  dateFormat={'dd/MM/yyyy'}
                                  className='input-style'
                                  onChange={(val) =>
                                    onChange(
                                      modalId === 'Tasks'
                                        ? handleDueDate(val)
                                        : val
                                    )
                                  }
                                  ref={(r) => (datePickerRef = r)}
                                />
                                <i
                                  onClick={() => {
                                    if (datePickerRef) {
                                      if (!datePickerRef.state.open) {
                                        datePickerRef?.setOpen(true);
                                      }
                                    }
                                  }}
                                  className='fas fa-calendar-week absolute top-3 right-3'
                                />
                              </div>
                            );
                          }}
                          rules={{ required: false }}
                        />
                      </div>
                      <div className='mt-4'>
                        <FormInputField
                          label={'Estimated Time'}
                          type={'text'}
                          fieldName={'estimatedTime'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                          handleChange={(e) => {
                            modalId === 'Tasks' &&
                              setChangeTitle({
                                fieldName: 'estimatedTime',
                                fValue: e.target.value,
                              });
                          }}
                          handleOnClick={() => {
                            modalId === 'Tasks' &&
                              handleEdit({
                                fieldName: 'estimatedTime',
                                fValue: singleTask.estimatedTime,
                              });
                          }}
                        />
                        {editMode &&
                          changeTitle.fieldName === 'estimatedTime' && (
                          <div className='flex justify-end shodow-slate-300 mt-2'>
                            <i
                              type='submit'
                              className='fa-solid fa-check mr-2 comment-edit-icon'
                              aria-hidden='true'
                              onClick={() => handleUpdateSingleField()}
                            ></i>
                            <i
                              className='fa-solid fa-xmark dlt-comment-icon'
                              aria-hidden='true'
                              onClick={() => setEditMode(false)}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {modalId !== 'Tasks' && (
                  <div className='mt-4 flex items-center justify-end pt-2'>
                    <button
                      className='border rounded-lg mt-0 btn-primary w-fit mr-0 ml-auto'
                      disabled={btnEnable}
                      type='submit'
                    >
                      Create
                    </button>
                  </div>
                )}
              </form>
            </div>
            {/* Task Comments  */}
            <div
              className='tab-pane fade'
              id='tabs-other-info'
              role='tabpanel'
              aria-labelledby='tabs-other-info-tab'
            >
              {showTabsMenus === 'comments-tab' ? (
                <Comments
                  editMode={editMode}
                  taskId={taskId}
                  setEditMode={(val) => setEditMode(val)}
                  singleTask={singleTask}
                  isSortedByAsc={isSortedByAsc}
                  discardModal={discardModal}
                />
              ) : (
                ''
              )}
            </div>

            {/* Link issue */}
            <div
              className='tab-pane fade'
              id='tabs-link-issue'
              role='tabpanel'
              aria-labelledby='tabs-link-issue-tab'
            >
              {showTabsMenus === 'tabs-link-issue-tab' ? (
                <LinkIssueTask singleTask={singleTask} taskId={taskId} />
              ) : (
                ''
              )}
            </div>

            {/* Check list component */}
            <div
              className='tab-pane fade'
              id='tabs-home'
              role='tabpanel'
              aria-labelledby='tabs-home-tab'
            >
              {showTabsMenus === 'tabs-home-tab' ? (
                <CheckList
                  singleTask={singleTask}
                  isSortedByAsc={isSortedByAsc}
                />
              ) : (
                ''
              )}
            </div>

            {/* File Attachments */}
            <div
              className='tab-pane fade'
              id='tabs-attachments-issue'
              role='tabpanel'
              aria-labelledby='tabs-attachments-link'
            >
              {showTabsMenus === 'tabs-attachments-link' ? (
                // <LinkIssueTask singleTask={singleTask} taskId={taskId} />
                <AttachmentFile singleTask={singleTask} />
              ) : (
                ''
              )}
            </div>

            {/* History Component */}
            <div
              className='tab-pane fade'
              id='tabs-task-priority'
              role='tabpanel'
              aria-labelledby='tabs-task-priority-tab'
            >
              {showTabsMenus === 'tabs-task-priority-tab' ? (
                <HistoryList singleTask={singleTask} />
              ) : (
                ''
              )}
            </div>

            {/* Time Spend */}
            <div
              className='tab-pane fade'
              id='tabs-time-spends'
              role='tabpanel'
              aria-labelledby='tabs-time-spend-tab'
            >
              {showTabsMenus === 'tabs-time-spend-tab' ? (
                <TimeSpend
                  singleTask={singleTask}
                  isSortedByAsc={isSortedByAsc}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

const mapDispatchToProps = {
  // dispatching
  dispatchSendTaskRequest: sendTaskRequest,
  dispatchUpdateTaskRequest: updateTaskRequest,
  dispatchGetAllTaskComments: getAllTaskComment,
  dispatchGetAllCheckList: getAllCheckList,
  dispatchGetAllTaskHistory: getAllTaskHistory,
  dipatchGetTaskTimeTracker: getTaskTimeTracker,
  dispatchResetStateRequest: resetState,
};
export default connect(null, mapDispatchToProps, null, {forwardRef:true})(TaskSingle);
