import * as Constants from './constants';

// Read Notifications
export const saveNotificationRequest = (payload) => {
  return {
    type: Constants.saveNotificationRequestActionType,
    payload,
  };
};

// Task Report
export const getAlltaskHistoryRequest = (payload) => {
  return {
    type: Constants.getAllTaskHistoryRequestActionType,
    payload,
  };
};

// Get All Notifications
export const getAllNotificationRequest = (payload) => {
  return {
    type: Constants.getAllNotificationRequestActionType,
    payload,
  };
};

//get All Unread Notifications
export const getAllUnreadNotificationsRequest = (payload) => {
  return {
    type: Constants.getAllUnreadNotificationRequestActionType,
    payload,
  };
};
