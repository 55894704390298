import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  deleteUser,
  userRoleChange,
} from '../../../../store/project/actions';
import DeleteModal from '../../../common/deleteModal';

const UserRole = ({
  data,
  projectId,
  dispatchUserRoleChangeRequest,
  dispatchUserDeleteRequest,
}) => {
  const [showBtn, setShowBtn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState(null);

  useEffect(() => {
    if (data.length) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].role !== 1) {
          arr.push({ role: data[i].role, id: data[i].id, name: data[i].name });
        }
      }
      setRoles(arr);
    }
  }, [data]);

  const handleUserRole = (data) => {
    const finalData = {
      projectId: projectId,
      userId: data.id,
      roleId: roleId,
    };
    dispatchUserRoleChangeRequest(finalData);
    setShowBtn(false);
  };
  
  const handleChange = (e, id) => {
    setShowBtn(true);
    setUserId(id);
    setRoleId(e.target.value);
  };

  const handleReset = () => {
    setRoleId(null)
    setShowBtn(false);
    setUserId(null);
  };

  const onDelete = () => {
    if (deletedId) {
      const finalPayload = { userId: deletedId, projectId };
      dispatchUserDeleteRequest(finalPayload);
    }
    setShowDeleteModal(false);
    setDeletedId(null);
  };

  const handleDeleteUser = (id) => {
    setShowDeleteModal(true);
    setDeletedId(id);
  };
  return (
    <>
      {!!roles && !!roles.length ? (
        roles.map((item, idx) => (
          <div className="grid grid-cols-3 border p-3 pb-2" key={idx}>
            <h5 className="font-semi-bold mx-5 my-2 text-lg">{item.name}</h5>
            <div className="flex">
              <div className="relative w-full">
                <div className="arrow-down">
                  <i className="fas fa-chevron-down"></i>
                </div>
                <select
                  value={roleId === null ? item.role:userId===item.id ? roleId :item.role}
                  className="border border-gray-300 input-style"
                  onChange={(e) => {
                    handleChange(e, item.id);
                  }}
                >
                  <option value={2}>Manager</option>
                  <option value={3}>Team Member</option>
                  <option value={4}>Observer</option>
                </select>
              </div>
              {showBtn && item.id === userId && (
                <>
                  <i
                    type="submit"
                    onClick={() => handleUserRole(item)}
                    className="fa-solid fa-check ml-5 mt-1 comment-edit-icon"
                    aria-hidden="true"
                  ></i>
                  <i
                    type="submit"
                    onClick={() => handleReset()}
                    className="fa-solid fa-xmark ml-5 mt-1 dlt-comment-icon"
                    aria-hidden="true"
                  ></i>
                </>
              )}
              <div>
                <div className={'dropdown inline-block relative mt-2 ml-3'}>
                  <span
                    className="social-link cursor-pointer"
                    id="dropdownDefault"
                    data-dropdown-toggle="dropdown dAction"
                  >
                    <img
                      src="../../assets/img/dots-vertical.svg"
                      className="w-7 h-7 rotate-90 m-0 cursor-pointer"
                    />
                  </span>
                  <ul
                    id="dropdown-menu"
                    className="dropdown-menu absolute hidden pt-1 bg-white dark:bg-[#283046] shadow rounded py-2 px-4 mb-2 whitespace-nowrap"
                  >
                    <li className="text-red-500">
                      <button
                        type="button"
                        onClick={() => handleDeleteUser(item.id)}
                      >
                        <i
                          className="fa fa-trash mr-2 cursor-pointer w-[18px]"
                          aria-hidden="true"
                        ></i>
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center">No Any User</div>
      )}
      {showDeleteModal && (
        <DeleteModal
          DeleteShowModal={showDeleteModal}
          setDeleteShowModal={(val) => setShowDeleteModal(val)}
          handleDelete={onDelete}
          data={{ name: 'User' }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchUserRoleChangeRequest: userRoleChange,
  dispatchUserDeleteRequest: deleteUser,
};
export default connect(null, mapDispatchToProps)(UserRole);
