import React from 'react';
import { ToastContainer } from 'react-toastify';

const ToastMessage = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={500}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
    />
  );
};

export default ToastMessage;
