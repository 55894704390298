import { createSlice } from '@reduxjs/toolkit';
import { removeItem } from '../../utilities/utils';
const initialState = {
  isLoggedIn: false,
  loading: false,
  success: false,
  error: true,
  isCallbackLink: false,
  message: '',
  googleLoginDetail: {},
  isGoogleLogin: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    sendLoginRequestSuccess: (state, action) => {
      if (action.payload) {
        document.getElementById('root')?.classList.remove('login-page-content');
        return {
          ...state,
          isLoggedIn: true,
          loading: false,
        };
      }
      return state;
    },
    sendLoginRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        success: false,
      };
    },
    sendUserRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isRegister: true,
          message: action.payload,
          loading: false,
        };
      }
      return state;
    },
    sendUserRequestFailure: (state) => {
      return {
        ...state,
        isRegister: false,
        loading: false,
      };
    },

    // login with google
    getLoginDetailsRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          isGoogleLogin: true,
          googleLoginDetail: action.payload,
        };
      }
      return state;
    },
    getLoginDetailsRequestFailure: (state) => {
      return {
        ...state,
        isGoogleLogin: false,
        loading: false,
      };
    },

    sendLogoutRequest: (state) => {
      removeItem('token');
      removeItem('userData');
      removeItem('settings');
      removeItem('labels');
      removeItem('selectedProject');
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        success: false,
        isGoogleLogin: false,
      };
    },

    // setting callback link
    setCallbackLinkAction: (state) => {
      return {
        ...state,
        isCallbackLink: true,
      };
    },

    // removing callback link
    removeCallbackLinkAction: (state) => {
      return {
        ...state,
        isCallbackLink: false,
      };
    },
    resetStateRequest: (state) => {
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  sendLoginRequestSuccess,
  sendLoginRequestFailure,
  sendLogoutRequest,
  sendUserRequestSuccess,
  sendUserRequestFailure,
  setCallbackLinkAction,
  getLoginDetailsRequestSuccess,
  getLoginDetailsRequestFailure,
  removeCallbackLinkAction,
  resetStateRequest,
} = loginSlice.actions;

export default loginSlice.reducer;
