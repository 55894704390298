import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { useSelector } from 'react-redux';
import { setProjectInfo, successMsg } from '../../../../../utilities/utils';
import { resetStateRedirection } from '../../../../../store/common/commonSlice';
import DeleteModal from '../../../../common/deleteModal';
import TaskSingle from '../TaskSingle';
import ToastMessage from '../../../../ToastContainer';
import {
  deleteTaskRequest,
  getScrum,
  getTaskRequest,
} from '../../../../../store/task/action';
import { resetState } from '../../../../../store/task/taskSlice';
import { useDispatch } from 'react-redux';

const EditPage = ({
  dispatchGetTaskRequest,
  dispatchResetStateRequest,
  dispatchGetScrumRequest,
  dispatchDeleteTask,
}) => {
  const [selectProjectId, setSelectProjectId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [addSubTaskShow, setAddSubTaskShow] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const navigate = useNavigate();
  const [canEditTask, setCanEditTask] = useState(false);
  const [resetTitle, setResetTitle] = useState('');
  const [editMode, setEditMode] = useState(false);
  const { singleTask, isUpdateTask, message, trackingStatuses } = useSelector(
    (state) => state.task
  );
  const { projectSettings } = useSelector((state) => state.permissions);
  const dispatch = useDispatch()

  const { id } = useParams();
  const [urlData] = useSearchParams();

  useEffect(() => {
    if (urlData.get('taskid')) {
      setTaskId(urlData.get('taskid'));
    }
  }, []);
  useEffect(() => {
    if (id) {
      setTaskId(id);
    }
  }, [id]);

  useEffect(() => {
    if (urlData.get('projectid')) {
      setSelectProjectId(urlData.get('projectid'));
      navigate(`/task/${urlData.get('taskid')}`);
      setProjectInfo(urlData.get('projectid'),dispatch)
    } 
  }, []);

  useEffect(() => {
    const settings = projectSettings;
    if (settings) {
      if (settings.canEditTask) {
        setCanEditTask(settings.canEditTask);
      } else {
        setCanEditTask(settings.canEditTask);
      }
    } else {
      if (selectProjectId) {
        dispatchGetScrumRequest(selectProjectId);
      }
    }
  }, []);

  useEffect(() => {
    if (isUpdateTask) {
      successMsg(message);
      dispatchResetStateRequest();
    }
  }, [isUpdateTask]);

  useEffect(() => {
    if (taskId && selectProjectId) {
      dispatchGetTaskRequest({ taskId: taskId, projectId: selectProjectId });
    }
  }, [selectProjectId, taskId]);

  useEffect(() => {
    if (selectProjectId && trackingStatuses.length === 0) {
      dispatchGetScrumRequest(selectProjectId);
    }
  }, [selectProjectId]);

  const handleDeleteTask = (id) => {
    setShowDeleteModal(true);
    setDeletedId(id);
  };

  const handleDelete = () => {
    if (deletedId) {
      dispatchDeleteTask(deletedId);
      navigate('/board');
      setShowDeleteModal(false);
    }
  };

  return (
    <>
      <ToastMessage />
      <div className="edit-task-page">
        {canEditTask && (
          <>
            <h5 className="font-medium leading-normal flex items-center task-h mt-5 ml-5 mb-3">
              <span className="text-xl" id="edit-task">
                {resetTitle}
              </span>
            </h5>
          </>
        )}

        {singleTask && taskId && (
          <TaskSingle
            modalId={'Tasks'}
            singleTask={singleTask}
            taskId={parseInt(taskId)}
            handleDeleteTask={handleDeleteTask}
            addSubTaskShow={addSubTaskShow}
            setAddSubTaskShow={() => setAddSubTaskShow(false)}
            selectedProjectId={selectProjectId}
            editMode={editMode}
            setEditMode={(val) => setEditMode(val)}
            setResetTitle={setResetTitle}
          />
        )}
        {showDeleteModal && (
          <DeleteModal
            DeleteShowModal={showDeleteModal}
            setDeleteShowModal={(val) => setShowDeleteModal(val)}
            handleDelete={handleDelete}
            data={{ name: 'Task' }}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetTaskRequest: getTaskRequest,
  dispatchResetStateRequest: resetState,
  dispatchGetScrumRequest: getScrum,
  dispatchDeleteTask: deleteTaskRequest,
  dispatchRedirectionState: resetStateRedirection,
};
export default connect(null, mapDispatchToProps)(EditPage);
