import React from 'react';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';

const FormInputField = ({
  label,
  type,
  fieldName,
  register,
  errors,
  isRequired,
  maxLength,
  minLength,
  pattern,
  handleChange,
  name,
  placeholder,
  handleOnClick,
}) => {
  return (
    <>
      <label className="block text-sm font-medium mb-1">
        {' '}
        {label}{' '}
        {isRequired && label !== '' ? (
          <span className="text-red-500">*</span>
        ) : (
          ''
        )}
      </label>
      <input
        type={type}
        className={
          type === 'file'
            ? 'block w-full cursor-pointer py-0 text-sm input-style pl-0'
            : type === 'checkbox'
              ? 'checkbox-style'
              : 'ticket-input'
        }
        id={fieldName}
        name={name}
        placeholder={placeholder}
        {...register(fieldName, {
          required: isRequired,
          maxLength: maxLength,
          minLength: minLength,
          pattern: pattern,
          onChange: (e) => handleChange && handleChange(e),
        })}
        onClick={() => handleOnClick && handleOnClick()}
      />
      {errors[fieldName]?.type === 'required' && (
        <FormFieldError message={AppConstants.requiredField} />
      )}
      {errors[fieldName]?.type === 'maxLength' && (
        <FormFieldError message={AppConstants.maxLengthReached} />
      )}
      {errors[fieldName]?.type === 'pattern' && (
        <FormFieldError message={`${AppConstants.notValidMessage} ${type}`} />
      )}
    
    </>
  );
};

export default FormInputField;
