import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  emailLoading: false,
  success: false,
  error: true,
  emailMessage: '',
  allReports: [],
  paginationMeta: {},
  isEmailAlerts: false,
};

export const emailSettingsSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    saveEmailEnableAndDisableRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          emailMessage: action.payload.message,
          emailLoading: false,
          isEmailAlerts: true,
        };
      }
      return state;
    },
    saveEmailEnableAndDisableRequestFailure: (state) => {
      return {
        ...state,
        emailLoading: false,
        success: false,
        isEmailAlerts: false,
      };
    },

    // reset state
    emailResetState: (state) => {
      return {
        ...state,
        emailMessage: '',
        isEmailAlerts: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveEmailEnableAndDisableRequestSuccess,
  saveEmailEnableAndDisableRequestFailure,
  emailResetState,
} = emailSettingsSlice.actions;

export default emailSettingsSlice.reducer;
