import * as Constants from './constants';

// save Email Template
export const saveEmailTemplateRequest = (payload) => {
  return {
    type: Constants.saveEmailTemplateRequestActionType,
    payload,
  };
};

// Get Email Template
export const getEmailTemplateRequest = (payload) => {
  return {
    type: Constants.getEmailTemplateRequestActionType,
    payload,
  };
};

// update Email Template
export const updateEmailTemplateRequest = (payload) => {
  return {
    type: Constants.updateEmailTemplateRequestActionType,
    payload,
  };
};

// delete Email Template
export const deleteEmailTemplateRequest = (payload) => {
  return {
    type: Constants.deleteEmailTemplateRequestActionType,
    payload,
  };
};
