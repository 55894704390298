import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  activeSprintRequestFailure,
  activeSprintRequestSuccess,
  completedSprintRequestFailure,
  completedSprintRequestSuccess,
  deleteSprintRequestFailure,
  deleteSprintRequestSuccess,
  getSprintRequestFailure,
  getSprintRequestSuccess,
  getSprintTaskRequestFailure,
  getSprintTaskRequestSuccess,
  removeSprintTaskRequestFailure,
  removeSprintTaskRequestSuccess,
  saveSprintRequestFailure,
  saveSprintRequestSuccess,
  saveSprintTaskRequestFailure,
  saveSprintTaskRequestSuccess,
  updateSprintRequestFailure,
  updateSprintRequestSuccess,
} from './sprintSlice';
import { requestFailureRedirection } from '../common/commonSlice';
// get Sprint
function* asyncGetSprint(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_SPRINT.url}?ProjectId=${data.payload}`,
      data.payload
    );
    if (response.status === 200) {
      yield put(getSprintRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getSprintRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// save Sprint
function* asyncSaveSprint(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.GET_SPRINT.url}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveSprintRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(saveSprintRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// update Sprint
function* asyncUpdateSprint(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.UPDATE_SPRINT.url}/${data.payload.sprintId}`,
      data.payload.data,
      true
    );
    if (response.status === 200) {
      yield put(updateSprintRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(updateSprintRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// get Sprint task
function* asyncGetSprintTask(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_SPRINT_TASK.url}?SprintId=${data.payload.sprintId}`,
      data.payload.projectId
    );
    if (response.status === 200) {
      yield put(getSprintTaskRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getSprintTaskRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// save Sprint Task
function* asyncSaveSprintTask(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.GET_SPRINT_TASK.url}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(saveSprintTaskRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response.status === 422) {
      yield put(requestFailureRedirection());
      errorMsg(err.response?.data.message);
    } else {
      yield put(saveSprintTaskRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Remove Sprint Task
function* asyncRemoveSprintTask(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.GET_SPRINT_TASK.url}/removesprint`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(removeSprintTaskRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(removeSprintTaskRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Complete Sprint Task
function* asyncCompletedSprint(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_TASK_NEW_SPRINT.url}`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(completedSprintRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response.status === 422) {
      errorMsg(err.response?.data.message);
    } else {
      yield put(completedSprintRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Active sprint
function* asyncActiveSprint(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.GET_SPRINT.url}/activesprint`,
      data.payload,
      true
    );
    if (response.status === 200) {
      yield put(activeSprintRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response.status === 422) {
      errorMsg(err.response?.data.message);
    } else {
      yield put(activeSprintRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Delete sprint
function* asyncDeleteSprint(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.GET_SPRINT.url}/delete/${data.payload}`,
      {},
      true
    );
    if (response.status === 200) {
      yield put(deleteSprintRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    if (err.response.status === 422) {
      errorMsg(err.response?.data.message);
    } else {
      yield put(deleteSprintRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

export function* sprintSaga() {
  yield all([
    takeEvery(constants.getSprintRequestActionType, asyncGetSprint),
    takeEvery(constants.updateSprintRequestActionType, asyncUpdateSprint),
    takeEvery(constants.saveSprintRequestActionType, asyncSaveSprint),
    takeEvery(constants.getSprintTaskRequestActionType, asyncGetSprintTask),
    takeEvery(constants.saveSprintTaskRequestActionType, asyncSaveSprintTask),
    takeEvery(
      constants.removeSprintTaskRequestActionType,
      asyncRemoveSprintTask
    ),
    takeEvery(constants.completedSprintRequestActionType, asyncCompletedSprint),
    takeEvery(constants.activeSprintRequestActionType, asyncActiveSprint),
    takeEvery(constants.deleteSprintRequestActionType, asyncDeleteSprint),
  ]);
}
