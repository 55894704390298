import { useContext, useState } from 'react';
import { createContext } from 'react';

const appHistoryContext = createContext();

export function AppHistory({ children }) {
  const [myHistory, setMyHistory] = useState([]);

  const push = (location) => setMyHistory([...myHistory, location]);

  return (
    <appHistoryContext.Provider value={{ myHistory, push }}>
      {children}
    </appHistoryContext.Provider>
  );
}

const useAppHistory = () => useContext(appHistoryContext);
export default useAppHistory;
