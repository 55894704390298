// Get label settings
export const getLabelSettingsRequestActionType = 'GET_LABEL_SETTINGS_REQUEST';

// Update Label Settings
export const updateLabelSettingsRequestActionType =
  'UPDATE_LABEL_SETTINGS_REQUEST';

// project settings
export const getProjectSettingsRequestActionType =
  'GET_PROJECT_SETTINGS_REQUEST';
