import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: true,
  success: false,
  error: true,
  message: '',
  currentPermissions: {
    designationId: 1,
    designationName: 'dashboard ',
    moduleAccess: [
      {
        moduleId: 1,
        moduleName: 'Dashboard',
        read: false,
        write: false,
        create: false,
        delete: false,
        import: false,
        export: false,
      },
      {
        moduleId: 2,
        moduleName: 'Settings',
        read: false,
        write: false,
        create: false,
        delete: false,
        import: false,
        export: false,
      },
    ],
  },
  isPermissionsAdded: false,
  designations: [
    {
      id: 1,
      name: 'Creator ',
    },
    {
      id: 2,
      name: 'Manager',
    },
  ],
  modules: [
    {
      id: 1,
      name: 'Dashboard',
      permissions: ['read', 'write', 'create', 'delete', 'import', 'export'],
    },
    {
      id: 2,
      name: 'Settings',
      permissions: ['read', 'write', 'create', 'delete', 'import', 'export'],
    },
  ],
  allLabels: [],
  menuLebels: {},
  projectSettings: {},
  isLabelUpdated: false,
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    // get Label settings
    getLabelSettingsRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allLabels: action.payload,
          loading: false,
        };
      }
      return state;
    },
    getLabelSettingsRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    },

    // Update Label settings
    updateLabelSettingsRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allLabels: action.payload,
          isLabelUpdated: true,
          loading: false,
        };
      }
      return state;
    },
    updateLabelSettingsRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
        isLabelUpdated: false,
      };
    },

    // get Project settings
    getProjectSettingsRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          menuLebels: action.payload.labelText,
          projectSettings: action.payload.settings,
          loading: false,
        };
      }
      return state;
    },
    getProjectSettingsRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    },

    // reset state
    resetPermissionsState: (state) => {
      return {
        ...state,
        message: '',
        isPermissionsAdded: false,
        isLabelUpdated: false,
        projectSettings:{}
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLabelSettingsRequestSuccess,
  getLabelSettingsRequestFailure,
  updateLabelSettingsRequestSuccess,
  updateLabelSettingsRequestFailure,
  getProjectSettingsRequestSuccess,
  getProjectSettingsRequestFailure,
  resetPermissionsState,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
