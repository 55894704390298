import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { connect, useSelector } from 'react-redux';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import { Link } from 'react-router-dom';
import ToastMessage from '../../../ToastContainer';
import { Tooltip } from 'react-tooltip';
import DeleteModal from '../../../common/deleteModal';
import { deleteTaskRequest } from '../../../../store/task/action';
import { errorMsg, successMsg } from '../../../../utilities/utils';
import TaskSingle from '../components/TaskSingle';
import { useRef } from 'react';

const TaskModal = ({
  modalId,
  showModal,
  setShowModal,
  singleTask,
  taskId,
  dispatchDeleteTask,
  setTaskTimer,
}) => {
  const [addSubTaskShow, setAddSubTaskShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [discardModal, setDiscardModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [handleUpdateChange, setHandleUpdateChange] = useState(false);
  const [resetTitle, setResetTitle] = useState('');
  const { projectId } = useSelector((state) => state.project);  
  const [isSaveComment, setIsSaveComment] = useState(false)
  const closeModalRef = useRef(null);
  useEffect(() => {
    const backdrop = document.createElement('div');
    backdrop.id = 'modal-backdrop';
    backdrop.classList.add('modal-backdrop');
    backdrop.classList.add('show');
    document.body.appendChild(backdrop);

    return () => {
      const backdrop = document.getElementById('modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    };
  }, []);

  const handleDeleteTask = (id) => {
    setShowDeleteModal(true);
    setDeletedId(id);
  };

  const handleDelete = () => {
    if (deletedId) {
      dispatchDeleteTask(deletedId);
      setShowDeleteModal(false);
      setShowModal(false);
    }
  };

  const handleClosePopup = () => {
    if(modalId !== 'Tasks')
    {
      closeModalRef.current.handleChangeTitle()
      closeModalRef.current.handleChangeDescription()
    }
    if (editMode) {
      setDiscardModal(true);
    } else {
      setShowModal(false);
    }
  };

  const handleSaveChange = () => {
    setHandleUpdateChange(true);
    setDiscardModal(false);
    setShowDeleteModal(false);
    setIsSaveComment(true);
    setTimeout(() => {
      setShowModal(false);
      setHandleUpdateChange(false);
    }, 50);
  };

  const handleClipBoard = (id) => {
    navigator.clipboard
      .writeText(
        `${window.location.origin}/task?taskid=${id}&projectid=${projectId}`
      )
      .then(
        () => {
          successMsg('Link Copied to clipboard!');
        },
        () => {
          errorMsg('Failed to copy Link!');
        }
      );
  };

  return (
    <>
      <ToastMessage />
      <div className={`modal ${showModal ? 'show' : 'hide'}`}>
        <div
          id="modal-dialog modal-lg modal-dialog-centered"
          className="modal-dialog modal-lg modal-dialog-centered max-w-5xl px-4 lg:px-0"
        >
          <div className="modal-content">
            <div className="px-5 pt-4 flex items-center justify-between mb-4">
              <div className="flex items-center">
                <h5 className="font-medium leading-normal flex items-center task-h">
                  <span className="text-xl" id="edit-task">
                    {singleTask ? (
                      <Link to={`/task/${singleTask.id}`}>
                        {singleTask?.title}
                      </Link>
                    ) : (
                      'Create Task'
                    )}
                  </span>
                  {modalId !== 'Task' && (
                    <>
                      <Tooltip
                        className="tooltip"
                        anchorId="edit-task"
                        content="Edit task"
                        place="top"
                      ></Tooltip>
                      <button
                        className="text-[13px] py-2 px-2 rounded inline-flex items-center"
                        onClick={() => handleClipBoard(singleTask.id)}
                      >
                        <i
                          className="fas fa-link text-[15px] hidden task-link mr-2"
                          id="link-icon"
                        ></i>
                        <Tooltip
                          className="text-[13px] tooltip"
                          anchorId="link-icon"
                          content="Copy link"
                          place="top"
                        ></Tooltip>
                      </button>
                    </>
                  )}
                </h5>
              </div>

              <button
                onClick={() => handleClosePopup()}
                type="button"
                className="btn-close box-content"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeModalRef}
              ></button>
            </div>
            <TaskSingle
              taskId={taskId}
              modalId={modalId}
              singleTask={singleTask}
              popup={true}
              addSubTaskShow={addSubTaskShow}
              setAddSubTaskShow={() => setAddSubTaskShow(false)}
              handleDeleteTask={handleDeleteTask}
              editMode={editMode}
              setEditMode={(val) => setEditMode(val)}
              handleUpdateChange={handleUpdateChange}
              setResetTitle={setResetTitle}
              resetTitle={resetTitle}
              setTaskTimer={setTaskTimer}
              ref={closeModalRef}
              discardModal={isSaveComment}
            />
          </div>
        </div>

        {showDeleteModal && (
          <DeleteModal
            DeleteShowModal={showDeleteModal}
            setDeleteShowModal={(val) => setShowDeleteModal(val)}
            handleDelete={handleDelete}
            data={{ name: 'Task' }}
          />
        )}
        {discardModal && (
          <DeleteModal
            DeleteShowModal={discardModal}
            setDeleteShowModal={() => {
              setDiscardModal(false);
            }}
            data={{ name: 'Discard' }}
            handleDelete={handleSaveChange}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  dispatchDeleteTask: deleteTaskRequest,
};
export default connect(null, mapDispatchToProps)(TaskModal);
