import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

const CustomAvatar = ({ id, idPrefix, userList,assigneeName}) => {
  const [singleUserData, setSingleUserData] = useState('');
  const { userData } = useSelector((state) => state.user);
  useEffect(() => {
    if (id !== null && id !== 0 && userData.length) {
      const user = userData.filter((user) => user.id === id);
      if (user && user.length) {
        setSingleUserData(user[0]);
      }
    } else {
      setSingleUserData('');
    }
  }, [id]);
  return (
    <>
      <div
        className={`avatar-img ${userList === 'userList' ? 'border-2 border-white rounded-full z-10 relative' :' w-9 h-9 '}`} 
        id={`${idPrefix && idPrefix}_${singleUserData.firstName}`}
      >      
        <Avatar
          round={true}
          title={`${userList ==='userList' ? `${singleUserData.firstName} ${singleUserData.lastName}` :assigneeName}`}
          name={`${userList ==='userList' ? `${singleUserData.firstName} ${singleUserData.lastName}` :assigneeName}`}
          src={singleUserData.profilePicUrl}
          size={`${userList ==='userList' ? 40 :'100%'}`}
        />
         
      </div>
      {((singleUserData && singleUserData.profilePicUrl) || userList ==='userList') && (
        <Tooltip
          className="tooltip"
          anchorId={`${idPrefix && idPrefix}_${singleUserData.firstName}`}
          content={`${(userList ==='userList' || (singleUserData && singleUserData.profilePicUrl)) && `${singleUserData.firstName} ${singleUserData.lastName}`}`}
          place="top"
        ></Tooltip>
      )}
    </>
  );
};

const mapDispatchToProps = {};
export default connect(null, mapDispatchToProps)(CustomAvatar);
