import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  getLabelSettingsRequest,
  getProjectSettingsRequest,
  updateLabelSettingsRequest,
} from '../../../store/permissions/action';
import ToastMessage from '../../ToastContainer';
import { successMsg } from '../../../utilities/utils';
import { resetPermissionsState } from '../../../store/permissions/permissionsSlice';
import Loader from '../../loader';

const LabelChange = ({
  dispatchGetLabels,
  dispatchUpdateLabels,
  dispatchResetStateRequest,
  dispatchGetProjectSettingsRequest,
}) => {
  const { allLabels, isLabelUpdated, loading } = useSelector(
    (state) => state.permissions
  );
  const { projectId } = useSelector((state) => state.project);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (projectId) {
      dispatchGetLabels(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (allLabels && allLabels.length) {
      setLabels(allLabels);
    }
  }, [allLabels]);

  useEffect(() => {
    if (isLabelUpdated) {
      successMsg('Added Successfully!');
      dispatchGetProjectSettingsRequest(projectId);
    }
    dispatchResetStateRequest();
  }, [isLabelUpdated]);

  const handleLabelChange = (e, index) => {
    const updatedData = labels.map((x, idx) =>
      idx === index ? { ...x, labelText: e.target.value } : x
    );
    setLabels(updatedData);
  };

  const handleCheckBox = (val, index) => {
    const updatedData = labels.map((x, idx) =>
      idx === index ? { ...x, isDefault: val.target.checked } : x
    );
    setLabels(updatedData);
  };

  const handleSubmit = () => {
    if (labels) {
      const upadatedArr = labels.filter(
        (label) =>
          label.defaultLabelText !== label.labelText &&
          !label.isDefault &&
          label.labelText !== ''
      );
      const payload = upadatedArr.map(
        ({...rest }) => ({
          ...rest,
        })
      );
      const finalPayload = { projectId, payload };
      dispatchUpdateLabels(finalPayload);
    }
  };

  return (
    <>
      <ToastMessage />
      {loading ? (
        <Loader />
      ) : (
        <div className="cardshadow lg:w-3/5 mx-auto mt-5">
          {!!labels.length &&
            labels.map((label, idx) => (
              <div className="p-2 lg:flex items-center" key={idx}>
                <div className="border-r lg:w-1/4">
                  {label.defaultLabelText}
                </div>
                <div className="lg:w-3/4 ml-3 flex items-center">
                  <div className="mr-3 w-full">
                    <input
                      value={
                        label.isDefault
                          ? label.defaultLabelText
                          : label.labelText
                      }
                      type="text"
                      className="ticket-input"
                      id="LabelName"
                      name="LabelName"
                      disabled={label.isDefault}
                      onChange={(e) => handleLabelChange(e, idx)}
                    />
                  </div>
                  <div className="form-check form-check-inline mt-2 flex items-center lg:w-2/4">
                    <input
                      className="form-check-input cstm-ckeckbox"
                      checked={label.isDefault}
                      type="checkbox"
                      onChange={(val) => handleCheckBox(val, idx)}
                    />
                    <label>Default</label>
                  </div>
                </div>
              </div>
            ))}
          <div className="pb-1 border-t">
            <button
              type="submit"
              className="border rounded-lg btn-primary w-fit ml-auto m-5"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetLabels: getLabelSettingsRequest,
  dispatchUpdateLabels: updateLabelSettingsRequest,
  dispatchResetStateRequest: resetPermissionsState,
  dispatchGetProjectSettingsRequest: getProjectSettingsRequest,
};
export default connect(null, mapDispatchToProps)(LabelChange);
