import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  isGetSingleTaskFailed: false,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    requestFailureRedirection: (state) => {
      return { ...state, loading: false, isGetSingleTaskFailed: true };
    },
    // reset state
    resetStateRedirection: (state) => {
      return {
        ...state,
        message: '',
        isGetSingleTaskFailed: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { requestFailureRedirection, resetStateRedirection } =
  commonSlice.actions;

export default commonSlice.reducer;
