import React from 'react';
import { getConnectSlackRequest } from '../../../store/author/action';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

const Author = ({ dispatchGetConnectSlackRequest }) => {
  const [slackConnect, setSlackConnect] = useState(false);
  const { slackURL } = useSelector((state) => state.author);

  useEffect(() => {
    if (slackConnect) {
      if (slackURL) {
        window.open(slackURL, '_Self');
        setSlackConnect(false);
      }
    }
  }, [slackURL, slackConnect]);

  const connectSlack = () => {
    dispatchGetConnectSlackRequest();
    setSlackConnect(true);
  };
  return (
    <>
      <div>
        <h1>Author Page</h1>
        <button
          className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 rounded cursor-pointer"
          onClick={() => connectSlack()}
        >
          Connect Slack
        </button>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchGetConnectSlackRequest: getConnectSlackRequest,
};
export default connect(null, mapDispatchToProps)(Author);
