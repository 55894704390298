import React, { useEffect, useState } from 'react';
import { getAllTaskList } from '../../../store/task/action';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import CustomAvatar from '../../common/customAvatar';
import Avatar from 'react-avatar';
import Pagination from '../../common/pagination';
import UserProfileList from '../../common/userList';
import { getAllTrackingStatus } from '../../../store/project/actions';
import { useNavigate } from 'react-router-dom';

const GlobalSearch = ({
  dispatchGetAllTaskListRequest,
  dispatchGetAllTrackingStatusRequest,
}) => {
  const {
    allTaskList,
    allTaskPaginationMeta,
  } = useSelector((state) => state.task);
  const { projectId, trackingStatusList } = useSelector(
    (state) => state.project
  );

  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [statusId, setStatusId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      dispatchGetAllTrackingStatusRequest(projectId);
      if (allTaskList && !allTaskList.length) {
        dispatchGetAllTaskListRequest({
          payload: {
            pageNumber: 1,
            pageSize: 10,
            SortByAsc: false,
          },
          projectId: projectId,
        });
      }
    }
  }, [projectId]);

  useEffect(() => {
    if (!!allTaskList && !!allTaskList.length) {
      let filterData;
      if (selectedUser.length) {
        let selectUserFilterData;
        filterData = selectUserFilterData = allTaskList.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );
        if (searchText) {
          filterData = selectUserFilterData.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        }
      } else {
        if (searchText) {
          filterData = allTaskList.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filterData = allTaskList;
        }
      }
      setFilteredTasks(filterData);
    }
  }, [allTaskList]);

  const handlePageChange = (pageNumber) => {
    dispatchGetAllTaskListRequest({
      payload: {
        pageNumber: pageNumber,
        pageSize: 10,
        SortByAsc: false,
      },
      projectId: projectId,
    });
  };

  const getStatus = (statusId) => {
    if (!!trackingStatusList && !!trackingStatusList.length) {
      let d = trackingStatusList.filter((status) => {
        return parseInt(status.id) === parseInt(statusId);
      });
      if (d.length !== 0) {
        return d[0].name;
      }
    }
  };

  useEffect(() => {
    let filterData;
    if (selectedUser.length) {
      let selectUserFilterData = allTaskList.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      if (searchText) {
        const searchData = selectUserFilterData.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        });

        if (statusId !== '' && statusId !== 'null') {
          filterData = searchData.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          filterData = searchData;
        }
      } else {
        if (statusId !== '' && statusId !== 'null') {
          filterData = selectUserFilterData.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          filterData = selectUserFilterData;
        }
      }
    } else {
      if (searchText) {
        const searchData = allTaskList.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        });
        if (statusId !== '' && statusId !== 'null') {
          filterData = searchData.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          filterData = searchData;
        }
      } else if (statusId !== '' && statusId !== 'null') {
        filterData = allTaskList.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(statusId);
        });
      } else {
        filterData = allTaskList;
      }
    }
    setFilteredTasks(filterData);
  }, [selectedUser]);

  const userFilterData = (id) => {
    const isUserAlreadySelected = selectedUser.includes(id);
    if (!isUserAlreadySelected) {
      setSelectedUser([...selectedUser, id]);
    } else {
      const updatedUserSelected = selectedUser.filter((us) => us !== id);
      setSelectedUser(updatedUserSelected);
    }
  };

  const handleSearchClose = () => {
    setSearchText('');
    let filData;
    if (selectedUser.length) {
      const userFilterData = allTaskList.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      if (statusId !== '' && statusId !== 'null') {
        filData = userFilterData.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(statusId);
        });
      } else {
        filData = userFilterData;
      }
      setFilteredTasks(filData);
    } else {
      if (statusId !== '' && statusId !== 'null') {
        filData = allTaskList.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(statusId);
        });
      } else {
        filData = allTaskList;
      }
      setFilteredTasks(filData);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const arr = [...allTaskList];
    if (selectedUser.length) {
      const userFilterData = arr.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      let filData;
      const searchData = userFilterData.filter((item) => {
        return (
          item.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
        );
      });
      if (statusId !== '' && statusId !== 'null') {
        filData = searchData.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(statusId);
        });
      } else {
        filData = searchData;
      }
      setFilteredTasks(filData);
    } else {
      let index;
      if (e.target.value) {
        const searchData = arr.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
            -1
          );
        });
        if (statusId !== '' && statusId !== 'null') {
          index = searchData.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          index = searchData;
        }
      } else {
        if (statusId !== '' && statusId !== 'null') {
          index = arr.filter((item) => {
            return parseInt(item.trackingStatusId) === parseInt(statusId);
          });
        } else {
          index = arr;
        }
      }
      setFilteredTasks(index);
    }
  };

  const handleSelectChange = (event) => {
    setStatusId(event);
    const arr = [...allTaskList];
    if (event !== 'null') {
      let filData;
      const searchData = arr.filter((item) => {
        return parseInt(item.trackingStatusId) === parseInt(event);
      });
      if (selectedUser.length) {
        const selectedData = searchData.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );

        if (searchText) {
          filData = selectedData.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filData = selectedData;
        }
      } else {
        const index = arr.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(event);
        });
        if (searchText) {
          filData = index.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filData = index;
        }
      }
      setFilteredTasks(filData);
    } else {
      let filData;
      if (selectedUser.length) {
        const userFilterData = arr.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );
        if (searchText) {
          filData = userFilterData.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filData = userFilterData;
        }
      } else {
        if (searchText) {
          filData = arr.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filData = arr;
        }
      }
      setFilteredTasks(filData);
    }
  };

  const handleViewTask = (taskId) => {
    navigate(`/task/${taskId}`);
  };

  return (
    <>
      <div>
        <div>
          <div className="flex justify-between border-b border-solid border-gray-300">
            <UserProfileList
              selectedUser={selectedUser}
              userFilterData={userFilterData}
            />
            <div className="accordion-body p-4 pb-0">
              <div className="ticket-heading justify-end pt-4">
                <div className="flex items-center">
                  <div className="relative">
                    <input
                      type="search"
                      className="search-bar pr-[22px]"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => handleSearch(e)}
                    />
                    <i
                      className={`fas fa-times absolute right-[11px] top-[13px] cursor-pointer site-clr hover:cursor-pointer ${
                        searchText ? 'block' : 'hidden'
                      }`}
                      onClick={() => handleSearchClose()}
                    ></i>
                  </div>
                  <div className="flex border items-center ml-3 rounded-[0.5rem] h-[2.5rem] relative tasks-btn">
                    <div className="relative">
                      <div className="arrow-down">
                        <i className="fas fa-chevron-down"></i>
                      </div>
                      <select
                        className="input-style select-none cursor-pointer"
                        onChange={(e) => {
                          handleSelectChange(e.target.value);
                        }}
                      >
                        <option value={'null'}>None</option>
                        {trackingStatusList.map((item, idx) => (
                          <option
                            key={idx}
                            value={item.id}
                            className="select-none"
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table className="w-full text-sm text-left mt-2 border">
          <thead className="text-sm font-medium text-gray-900 bg-gray-50 sticky -top-[1px] z-[99]">
            <tr className="border">
              <th scope="col" className="px-3 py-2">
                Assigned to
              </th>
              <th>Title</th>
              <th>Tracking Status</th>
            </tr>
          </thead>
          <tbody>
            {!!filteredTasks && !!filteredTasks.length ? (
              filteredTasks.map((item, idx) => (
                <tr className="border-b" key={idx}>
                  <td className="pl-[5.5px]">
                    {item.assignedTo ? (
                      <CustomAvatar id={item.assignedTo} idPrefix={item.id} />
                    ) : (
                      <div className="user">
                        <div className="avatar">
                          <Avatar
                            round={true}
                            title="Unassigned"
                            name="Un Assigned"
                          />
                        </div>
                      </div>
                    )}
                  </td>
                  <td
                    className="p-4 pl-0 cursor-pointer"
                    onClick={() => handleViewTask(item.id)}
                  >
                    {item.title}
                  </td>
                  <td>{getStatus(item.trackingStatusId)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <div className="text-center p-5">
                    <i className="fas fa-bell-slash"></i>
                    <h2 className="font-bold mt-2">No Any Task yet!</h2>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!!allTaskPaginationMeta &&
        !!Object.keys(allTaskPaginationMeta).length && (
        <div>
          <Pagination
            className="pagination-bar"
            currentPage={allTaskPaginationMeta.CurrentPage}
            totalCount={allTaskPaginationMeta.TotalCount}
            pageSize={allTaskPaginationMeta.PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </div>
      )}
    </>
  );
};
const mapDispatchToProps = {
  // dispatching
  dispatchGetAllTaskListRequest: getAllTaskList,
  dispatchGetAllTrackingStatusRequest: getAllTrackingStatus,
};
export default connect(null, mapDispatchToProps)(GlobalSearch);
