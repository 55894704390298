import React from 'react';
import {
  addCheckList,
  getAllCheckList,
  updateCheckList,
} from '../../../../../store/task/action';
import { connect, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from '../../../../formInputField';
import Loader from '../../../../loader';

const CheckList = ({
  dispatchAddCheckList,
  dispatchGetAllCheckList,
  dispatchUpdateCheckList,
  singleTask,
  isSortedByAsc,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const inputs = useRef();
  const { allChecklist, loading, isAddCheckList } = useSelector(
    (state) => state.task
  );
  const { projectId } = useSelector((state) => state.project);
  const [checklistValue, setChecklistValue] = useState('');
  const [checkLists, setCheckLists] = useState([]);
  const { projectSettings } = useSelector((state) => state.permissions);
  useEffect(() => {
    if (checklistValue) {
      inputs.current.focus();
    }
  }, [checklistValue]);

  useEffect(() => {
    if (allChecklist && allChecklist.length) {
      setCheckLists(allChecklist);
    }
  }, [allChecklist]);

  useEffect(() => {
    if (projectId) {
      setCheckLists([]);
    }
  }, [projectId]);

  useEffect(() => {
    if (Object.keys(singleTask).length !== 0 && projectId) {
      const payload = { TaskId: singleTask.id, SortByAsc: false };
      dispatchGetAllCheckList({ payload, projectId: projectId });
    }
  }, [singleTask]);

  useEffect(() => {
    if (isAddCheckList) {
      const payload = { TaskId: singleTask.id, SortByAsc: !isSortedByAsc };
      dispatchGetAllCheckList({ payload, projectId: projectId });
    }
  }, [isAddCheckList]);

  // update check box of checklist
  const handleCheckBox = (val, id) => {
    const finalData = {
      id: id,
      data: [
        {
          path: '/isComplete',
          op: 'replace',
          value: val.target.checked ? true : false,
        },
      ],
    };

    dispatchUpdateCheckList(finalData);
  };

  //   add Checklist
  const handleChecklist = (data) => {
    const finalPayload = {
      taskId: singleTask?.id,
      title: data.title,
      isComplete: 0,
    };
    dispatchAddCheckList(finalPayload);
    reset({ title: '' });
  };

  //update title of checklist
  const handleChecklistTitle = (item) => {
    setChecklistValue(item);
  };
  const updateChecklistTitle = () => {
    const finalPayload = {
      id: checklistValue.id,
      data: [
        {
          path: '/title',
          op: 'replace',
          value: checklistValue.title,
        },
      ],
    };
    dispatchUpdateCheckList(finalPayload);
    setChecklistValue('');
  };
  return (
    <>
      <div>
        {loading ? (
          <Loader />
        ) : (
          !!checkLists &&
          !!checkLists.length &&
          checkLists.map((item, idx) => (
            <div key={idx} className="flex items-center">
              <input
                checked={item.isComplete}
                type={'checkbox'}
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#f24759] checked:border-[#f24759] focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain mr-2"
                onChange={(val) => handleCheckBox(val, item.id)}
              />
              {checklistValue && checklistValue.id === item.id ? (
                <div className="flex flex-row">
                  <input
                    ref={inputs}
                    type="text"
                    value={checklistValue.title}
                    onChange={(e) =>
                      setChecklistValue({
                        id: item.id,
                        title: e.target.value,
                      })
                    }
                  />
                  <i
                    className="fa-solid fa-check p-2 ml-1 bg-slate-300 hover:bg-slate-400"
                    aria-hidden="true"
                    onClick={() => updateChecklistTitle()}
                  ></i>
                  <i
                    className="fa-solid fa-xmark p-2 px-3 ml-1 bg-slate-300 hover:bg-slate-400"
                    aria-hidden="true"
                    onClick={() => setChecklistValue('')}
                  ></i>
                </div>
              ) : (
                <label
                  className="hover:bg-slate-300 hover:px-4"
                  onClick={() => handleChecklistTitle(item)}
                >
                  {item.title}
                </label>
              )}
            </div>
          ))
        )}
        <form onSubmit={handleSubmit(handleChecklist)} className="flex">
          <div className="w-full">
            {projectSettings.canStartTimer && (
              <FormInputField
                label={'Checklist'}
                type={'text'}
                fieldName={'title'}
                register={register}
                errors={errors}
                isRequired={true}
              />
            )}
          </div>

          {projectSettings.canStartTimer && (
            <button
              type="submit"
              className="btn-primary pt-2 mt-6 ml-3 w-12 flex items-center mr-2 h-10 flex-start cursor-pointer"
            >
              <img src="../../assets/img/plus.svg" className="w-3 h-3" />
            </button>
          )}
        </form>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchAddCheckList: addCheckList,
  dispatchGetAllCheckList: getAllCheckList,
  dispatchUpdateCheckList: updateCheckList,
};
export default connect(null, mapDispatchToProps)(CheckList);
