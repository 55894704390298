import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getAllReportsRequest } from '../../../store/reports/action';
import { useForm } from 'react-hook-form';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { convertHourMintues } from '../../../utilities/utils';
import Loader from '../../loader';
import { useNavigate } from 'react-router-dom';

const Report = ({ dispatchGetAllReportsRequest }) => {
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedUserId, setSelectedUserId] = useState();
  const { projectId, projects } = useSelector((state) => state.project);
  const { allReports, loading } = useSelector((state) => state.reports);
  const { userData } = useSelector((state) => state.user);
  const [excelFileName, setExcelFileName] = useState('');
  let dateByReport;

  const {
    register,
    handleSubmit,
    getValues,
    setValue
  } = useForm();

  useEffect(() => {
    let loginUserDetails = JSON.parse(localStorage.getItem('userData'));
    if (loginUserDetails) {
      setSelectedUserId(loginUserDetails.id);
    }
  }, []);

  useEffect(() => {
    if (projectId) {
      let filterProject = projects.find((project) => {
        return project.id === Number(projectId);
      });
      setSelectedProject(filterProject);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      if (selectedUserId) {
        dispatchGetAllReportsRequest({
          fromDate: getValues('fromDate')
            ? getValues('fromDate')
            : new Date().toISOString().split('T')[0],
          toDate: getValues('toDate')
            ? getValues('toDate')
            : new Date().toISOString().split('T')[0],
          projectId: projectId,
          userId: parseInt(selectedUserId),
        });
      }
    }
  }, [selectedUserId, projectId]);

  const searchReports = (data) => {
    dispatchGetAllReportsRequest({
      payload: {
        pageNumber: 1,
        pageSize: 10,
        SortByAsc: false,
      },
      fromDate: data.fromDate,
      toDate: data.toDate,
      projectId: projectId,
      userId: selectedUserId,
    });
  };

  const handleUserChange = (e) => {
    setSelectedUserId(e.target.value);
  };

  useEffect(() => {
    if (userData && userData.length) {
      if (selectedUserId) {
        let userName = userData.find(
          (user) => user.id === parseInt(selectedUserId)
        ).firstName;
        let dateByReports;
        if (dateByReport) {
          dateByReports = dateByReport;
        } else {
          dateByReports = new Date().toISOString().split('T')[0];
        }
        setExcelFileName(`${userName}-${dateByReports}`);
      }
    }
  }, [selectedUserId, userData, dateByReport]);
  const totalTimeSpent = (totalReports) => {
    let totalTimeInMin = 0;
    totalReports.map((report) => {
      report.tasks.map((task) => {
        totalTimeInMin = totalTimeInMin + task.timeSpend;
      });
    });
    const totalTime = convertHourMintues(totalTimeInMin);
    return totalTime;
  };

  useEffect(() => {
    setValue('fromDate', new Date().toISOString().substring(0, 10))
    setValue('toDate', new Date().toISOString().substring(0, 10))
    const data = getValues()

    dispatchGetAllReportsRequest({
      payload: {
        pageNumber: 1,
        pageSize: 10,
        SortByAsc: false,
      },
      fromDate: data.fromDate,
      toDate: data.toDate,
      projectId: projectId,
      userId: selectedUserId,
    });

  }, [])

  const handleOpenTask = (id, pid) => {
    navigate(`/task?taskid=${id}&projectid=${pid}`);
  };

  return (
    <>
      <Loader isVisible={loading} />
      <div>
        <div className="accordion-item bg-white border-b-2 border-gray-200 rounded-none mb-1">
          <div className="accordion-header mb-0" id="headingMain">
            <div className="flex justify-between">
              <div className="accordion-body pb-0">
                <div className="ticket-heading justify-end pt-4">
                  {Object.keys(selectedProject).length !== 0
                    ? (selectedProject.roleId === 1 ||
                        selectedProject.roleId === 4) && (
                      <div className="mt-5 mr-3">
                        <div className="relative">
                          <div className="arrow-down">
                            <i className="fas fa-chevron-down"></i>
                          </div>
                          <select
                            className="input-style"
                            onChange={(e) => handleUserChange(e)}
                            value={selectedUserId}
                          >
                            {userData &&
                                userData.length &&
                                userData.map((item, idx) => (
                                  <option key={idx} value={item.id}>
                                    {item.firstName} {item.lastName}
                                  </option>
                                ))}
                          </select>
                        </div>
                      </div>
                    )
                    : ''}
                  <form onSubmit={handleSubmit(searchReports)}>
                    <div className="flex items-center">
                      <div className="relative flex">
                        <label className="block text-sm font-medium mr-2 mt-2">
                          From
                        </label>
                        <input
                          {...register('fromDate', { required: true })}
                          type="date"
                          className="input-style"
                        />
                      </div>
                      <div className="relative flex ml-4">
                        <label className="block text-sm font-medium  mr-2 mt-2">
                          To
                        </label>
                        <input
                          {...register('toDate', { required: true })}
                          type="date"
                          className="input-style"
                        />
                      </div>
                      <div className="relative flex ml-4">
                        <div>
                          <button
                            type="submit"
                            className="border rounded-lg btn-primary w-fit ml-auto m-5"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {!!allReports && !!allReports.length && (
            <div className="text-right mb-1">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button border rounded-lg btn-primary w-fit mb-0 mr-0"
                table="table-to-xls"
                filename={`${
                  excelFileName === '' ? 'tablexls' : excelFileName
                }`}
                sheet="tablexls"
                buttonText="Download Excel Report"
              />
            </div>
          )}
        </div>
        {!!allReports && allReports.length ? (
          <table
            id="table-to-xls"
            className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-collapse border border-slate-400"
          >
            <thead className="text-sm font-medium text-gray-900 bg-gray-50 sticky -top-[1px] z-[99]">
              <tr className="border">
                <th scope="col" className="px-3 py-2">
                  Project Name
                </th>
                <th scope="col" className="px-3 py-2">
                  Task Title
                </th>
                <th scope="col" className="px-3 py-2">
                  Remark
                </th>
                <th scope="col" className="px-3 py-2">
                  Status
                </th>
                <th scope="col" className="px-3 py-2">
                  Time Spent(Mins)
                </th>
              </tr>
            </thead>
            <tbody>
              {allReports.map((item, idx) => (
                <>
                  <tr
                    className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    key={idx}
                  >
                    <td
                      rowSpan={item.tasks.length + 1}
                      className="px-6 py-3 border"
                    >
                      <strong> {item.projectName}</strong>
                    </td>
                  </tr>
                  {item.tasks.map((task, idx) => (
                    <tr
                      className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      key={idx}
                    >
                      <td
                        className="px-6 py-3 border cursor-pointer"
                        onClick={() =>
                          handleOpenTask(task.taskId, item.projectId)
                        }
                      >
                        {task.title}
                      </td>
                      <td className="px-6 py-3 border">
                        {task.taskRemarks.map((item, idx) => (
                          <div
                            key={idx}
                            dangerouslySetInnerHTML={{
                              __html: item.changedValue,
                            }}
                          ></div>
                        ))}
                      </td>
                      <td className="px-6 py-3 border">
                        {' '}
                        {task.trackingStatusName}
                      </td>
                      <td className="px-6 py-3 border">
                        {convertHourMintues(task.timeSpend)}
                      </td>
                    </tr>
                  ))}
                </>
              ))}
              <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="border-none all" colSpan={4}></td>
                <td className="px-6 py-3 border">
                  <strong> {totalTimeSpent(allReports)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        ) : 
          <>
            <div className="mt-5">
              {!loading && 
              <>
                <h6 className="text-2xl font-bold text-black dark:text-[#ccc] mb-1 text-center">
                  {!allReports.length && 'No any report Of today yet!'}
                </h6>
                <h6 className="text-2xl font-bold text-black dark:text-[#ccc] mb-1 text-center">
                  {loading ? 'No any report yet!': 'Please choose filter and click on search button to see report.'}
                </h6>
              </>
              }
            </div>
          </>
        }
      </div>
    </>
  );
};
const mapDispatchToProps = {
  // dispatching
  dispatchGetAllReportsRequest: getAllReportsRequest,
};
export default connect(null, mapDispatchToProps)(Report);
