import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import { requestFailureRedirection } from '../common/commonSlice';
import {
  getLabelSettingsRequestFailure,
  getLabelSettingsRequestSuccess,
  getProjectSettingsRequestFailure,
  getProjectSettingsRequestSuccess,
  updateLabelSettingsRequestFailure,
  updateLabelSettingsRequestSuccess,
} from './permissionsSlice';

// get Label settings
function* asyncGetLabelSettings(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_LABEL_SETTINGS.url}/${data.payload}`,
      data.payload
    );
    if (response.status === 200) {
      yield put(getLabelSettingsRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getLabelSettingsRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Update Label settings
function* asyncUpdateLabelSettings(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_PROJECTS.url}/${data.payload.projectId}`,
      data.payload.payload,
      false
    );
    if (response.status === 200) {
      yield put(updateLabelSettingsRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(updateLabelSettingsRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

// Get project settings
function* asyncGetProjectSettings(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.GET_PROJECT_SETTING.url}?ProjectId=${data.payload}`,
      data.payload
    );
    if (response.status === 200) {
      yield put(getProjectSettingsRequestSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      yield put(requestFailureRedirection());
    } else {
      yield put(getProjectSettingsRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

export function* permissionsSaga() {
  yield all([
    takeEvery(
      constants.getLabelSettingsRequestActionType,
      asyncGetLabelSettings
    ),
    takeEvery(
      constants.updateLabelSettingsRequestActionType,
      asyncUpdateLabelSettings
    ),
    takeEvery(
      constants.getProjectSettingsRequestActionType,
      asyncGetProjectSettings
    ),
  ]);
}
