import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  saveEmailEnableAndDisableRequestFailure,
  saveEmailEnableAndDisableRequestSuccess,
} from './emailSettingsSlice';

// Get All Reports
function* asyncSaveEmailSettings(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.SAVE_EMAIL_SETTING.url}`,
      data.payload,
      true
    );

    if (response.status === 200) {
      yield put(saveEmailEnableAndDisableRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;

    if (err.response?.data.message) {
      console.log(err.response?.data.message);
    } else {
      yield put(saveEmailEnableAndDisableRequestFailure());
      errorMsg('Something is wrong!');
    }
  }
}

export function* emailSettingsSaga() {
  yield all([
    takeEvery(
      constants.saveEmailEnableAndDisableRequestActionType,
      asyncSaveEmailSettings
    ),
  ]);
}
