import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import {
  UnSavedChange,
  addOverlayOnEditor,
  imageUpload,
  removeOverlayFromEditor,
  toolbar,
} from '../../../../../utilities/utils';
import ImageUploader from 'quill-image-uploader';
import ImageModal from '../../modal/ImageModal';
import {
  addTaskComment,
  getAllTaskComment,
} from '../../../../../store/task/action';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import CommentList from './CommentList';

const Comments = ({
  dispatchAddTaskComments,
  setEditMode,
  taskId,
  dispatchGetAllTaskComments,
  isSortedByAsc,
  discardModal
}) => {
  const quillRefs = useRef(null);
  const [settings, setSettings] = useState(null);
  const [btnEnable, setBtnEnable] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [selectedImageSrc, setSelectedImageSrc] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const { allTaskComment, singleTask, isTaskComment, projectUsers } =
    useSelector((state) => state.task);
  const { userData } = useSelector((state) => state.user);
  const { projectId } = useSelector((state) => state.project);
  const { projectSettings } = useSelector((state) => state.permissions);
  const atValues = [];

  const  setComments = ()=>{
    if(editorValue !=='' && editorValue !== '<p><br></p>' )
    {
      return UnSavedChange(projectId,singleTask?.id, editorValue, 'comments');
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload',setComments);
    return ()=>{
      window.removeEventListener('beforeunload',setComments);
    }
  });

  useEffect(() => {
    if(discardModal)
    { 
      if(JSON.parse(localStorage.getItem('commentOnTask')))
      {
        const unSavedComments = JSON.parse(localStorage.getItem('commentOnTask')); 
        const remainUnSavedComments= unSavedComments.filter(
          (comment) => comment.id !== singleTask.id && comment.projectId !== projectId
        );
        if(remainUnSavedComments.length)
        {
          localStorage.setItem('commentOnTask',JSON.stringify(remainUnSavedComments));
        }else{
          localStorage.removeItem('commentOnTask');
        }
      }
    }
  }, [discardModal])

  useEffect(() => {
    if (userData) {
      userData.map((user) =>
        atValues.push({
          id: user.id,
          value: user.firstName + ' ' + user.lastName,
        })
      );
    }
  }, [projectUsers, userData]);

  useEffect(() => {
    if (allTaskComment && allTaskComment.length) {
      setAllComments(allTaskComment);
    }
  }, [allTaskComment]);

  useEffect(() => {
    if (projectSettings) {
      setSettings(projectSettings);
    }
  }, []);

  useEffect(() => {
    if (taskId) {
      setAllComments([]);
    }
  }, [taskId]);

  useEffect(() => {
    if (Object.keys(singleTask).length !== 0 && projectId) {
      const payload = { TaskId: singleTask.id, SortByAsc: false };
      dispatchGetAllTaskComments({ payload, projectId: projectId });
    }
  }, [singleTask]);

  useEffect(() => {
    if (singleTask) {
      if (JSON.parse(localStorage.getItem('commentOnTask'))) {
        const comment = JSON.parse(localStorage.getItem('commentOnTask'));
        if (comment.length) {
          const getSingleComment = comment.find(
            (item) => item.id === singleTask.id
          );
          if (getSingleComment) { 
            setEditorValue(getSingleComment.comments);
          }
        } else {
          setEditorValue('');
        }
      } else {
        setEditorValue('');
      }
    }
  }, [singleTask]);

  useEffect(() => {
    if (isTaskComment) {
      setBtnEnable(false);
      setEditMode(false);
      if (Object.keys(singleTask).length !== 0 && projectId) {
        const payload = { TaskId: singleTask.id, SortByAsc: !isSortedByAsc };
        dispatchGetAllTaskComments({ payload, projectId: projectId });
      }
    }
  }, [isTaskComment]);

 

  const handleChangeComment = (html) => {
    setEditorValue(html);
    setEditMode(true);       
  };

  // add comments
  const handleComment = () => {
    if (editorValue !== '' && editorValue !== '<p><br></p>') {
      dispatchAddTaskComments({
        taskId: singleTask?.id,
        comment: editorValue,
      });
      setBtnEnable(true);
    }
    setEditorValue('');
    const unSavedComments = JSON.parse(localStorage.getItem('commentOnTask'));
    let remainUnSavedComments;
    if (unSavedComments.length) {
      remainUnSavedComments = unSavedComments.filter((comment) => comment.id !== singleTask?.id);
      if (remainUnSavedComments.length) {
        localStorage.setItem('commentOnTask', JSON.stringify(remainUnSavedComments));
      }else{
        localStorage.removeItem('commentOnTask');
      }
    }
  };

  Quill.register('modules/imageUploader', ImageUploader);
  const modules = React.useMemo(
    () => ({
      toolbar: toolbar,
      imageUploader: {
        upload: (image) => {
          addOverlayOnEditor();
          return new Promise((resolve, reject) => {
            const imageUploadResponse = imageUpload(image);
            imageUploadResponse
              .then(function successValue(result) {
                resolve(result.attachmentUrl);
                removeOverlayFromEditor();
              })
              .catch((error) => {
                reject('Upload File failed');
                console.error('Error:', error);
                removeOverlayFromEditor();
              });
          });
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@', '#'],
        source: function (searchTerm, renderItem, mentionChar) {
          let values;
          if (mentionChar === '@' || mentionChar === '#') {
            values = atValues;
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderItem(matches, searchTerm);
          }
        },
      },
    }),
    []
  );

  const handleDoubleClickImage = (event) => {
    const imageSrc = event.target.getAttribute('src');
    setSelectedImageSrc(imageSrc);
    setShowImageModal(true);
  };

  const getImagesFromQuillContent = () => {
    if (quillRefs.current) {
      const quill = quillRefs.current.getEditor();
      const delta = quill.getContents();
      const ops = delta.ops.filter((op) => op.insert && op.insert.image);
      const imageSrcs = ops.map((op) => op.insert.image);
      return imageSrcs;
    }
  };

  return (
    <>
      <div>
        {settings && settings.canAddComments && (
          <>
            <div className="mt-4">
              <div className="h-full">
                <ReactQuill
                  ref={quillRefs}
                  value={editorValue}
                  onChange={handleChangeComment}
                  modules={modules}
                ></ReactQuill>
              </div>
            </div>
            <div className="my-3 flex justify-between">
              <div className="flex">
                {!!getImagesFromQuillContent() &&
                  !!getImagesFromQuillContent().length &&
                  getImagesFromQuillContent().map((imageSrc, index) => (
                    <div
                      key={index}
                      className="flex-auto border-2 border-red-500 h-14 m-2"
                    >
                      <img
                        src={imageSrc}
                        alt="Image"
                        className="h-full"
                        onDoubleClick={handleDoubleClickImage}
                      />
                    </div>
                  ))}
              </div>
              <div className="my-auto">
                <button
                  className="border rounded-lg mt-0 btn-primary w-fit mr-0 ml-auto"
                  onClick={() => handleComment()}
                  disabled={btnEnable}
                >
                  Add
                </button>
              </div>
            </div>
            {showImageModal && (
              <ImageModal
                imageSrc={selectedImageSrc}
                onClose={() => setShowImageModal(false)}
              />
            )}
          </>
        )}
        {!!allComments && !!allComments.length && (
          <CommentList data={allComments} settings={settings} />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchAddTaskComments: addTaskComment,
  dispatchGetAllTaskComments: getAllTaskComment,
};
export default connect(null, mapDispatchToProps)(Comments);
