import React from 'react';
import { Link} from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <h1 className="text-5xl">
            <span className="text-6xl">O</span>ops!
          </h1>
          <p className="text-base font-bold mt-3">404 - PAGE NOT FOUND</p>
          <div className="flex justify-center">
            <p className="text-base mt-3 w-3/4 font-medium">
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
          </div>
          <div className="mt-3 flex items-center justify-center pt-2">
            <Link to={'/board}'}>
              <button
                className="border rounded-lg mt-0 btn-primary w-fit ml-auto"
                type="button"
              >
                GO BACK
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
