import * as Constants from './constants';

// Project invites
export const projectSetInviteAcceptanceRequest = (payload) => {
  return {
    type: Constants.setProjectInviteRequestActionType,
    payload,
  };
};

// Invited user
export const userInvitesProjectRequest = (payload) => {
  return {
    type: Constants.invitedUserProjectRequestActionType,
    payload,
  };
};

// accepted project details
export const getProjectInvitationInfoRequest = (payload) => {
  return {
    type: Constants.getProjectInvitationInfoRequestActionType,
    payload,
  };
};

// All Project
export const getAllProjectRequest = (payload) => {
  return {
    type: Constants.getAllProjectRequestActionType,
    payload
  };
};

// project scrum
export const getProjectScrum = (payload) => {
  return {
    type: Constants.getProjectScrumRequestActionType,
    payload,
  };
};

// save Project
export const saveProjectRequest = (payload) => {
  return {
    type: Constants.saveProjectRequestActionType,
    payload,
  };
};

// update project
export const updateProjectRequest = (payload) => {
  return {
    type: Constants.updateProjectRequestActionType,
    payload,
  };
};

// delete project
export const deleteProjectRequest = (payload) => {
  return {
    type: Constants.deleteProjectRequestActionType,
    payload,
  };
};

// save task type
export const saveTaskType = (payload) => {
  return {
    type: Constants.sendTaskTypeRequestActionType,
    payload,
  };
};

// update task type
export const updateTaskType = (payload) => {
  return {
    type: Constants.updateTaskTypeRequestActionType,
    payload,
  };
};

// delete task Type
export const deleteTaskType = (payload) => {
  return {
    type: Constants.deleteTaskTypeRequestActionType,
    payload,
  };
};

// save Task Priority
export const saveTaskPriority = (payload) => {
  return {
    type: Constants.saveTaskPriorityRequestActionType,
    payload,
  };
};

// update task Priority
export const updateTaskPriority = (payload) => {
  return {
    type: Constants.updateTaskPriorityRequestActionType,
    payload,
  };
};

// delete task Priority
export const deleteTaskPriority = (payload) => {
  return {
    type: Constants.deleteTaskPriorityRequestActionType,
    payload,
  };
};

// save tracking status
export const saveTrackingStatus = (payload) => {
  return {
    type: Constants.saveTrackingStatusRequestActionType,
    payload,
  };
};

// update tracking status
export const updateTrackingStatus = (payload) => {
  return {
    type: Constants.updateTrackingStatusRequestActionType,
    payload,
  };
};

// delete tracking status
export const deleteTrackingStatus = (payload) => {
  return {
    type: Constants.deleteTrackingStatusRequestActionType,
    payload,
  };
};

// save tag
export const saveTag = (payload) => {
  return {
    type: Constants.saveTagRequestActionType,
    payload,
  };
};

// update tracking status
export const updateTag = (payload) => {
  return {
    type: Constants.updateTagRequestActionType,
    payload,
  };
};

// delete tracking status
export const deleteTag = (payload) => {
  return {
    type: Constants.deleteTagRequestActionType,
    payload,
  };
};

// get all tracking status
export const getAllTrackingStatus = (payload) => {
  return {
    type: Constants.getTrackingStatusRequestActionType,
    payload,
  };
};

// remove project
export const removeProject = (payload) => {
  return {
    type: Constants.removeProjectRequestActionType,
    payload,
  };
};

// Project Role Change
export const userRoleChange = (payload) => {
  return {
    type: Constants.userRoleChangeRequestActionType,
    payload,
  };
};

// flow status
export const flowStatusList = (payload) => {
  return {
    type: Constants.flowStatusRequestActionType,
    payload,
  };
};

// change tracking status order
export const changeTrackingOrder = (payload) => {
  return {
    type: Constants.changeTrackingStatusOrderRequestActionType,
    payload,
  };
};

//Delete User
export const deleteUser = (payload) => {
  return {
    type: Constants.userDeleteRequestActionType,
    payload,
  };
};
