import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomAvatar from '../customAvatar';

const UserProfileList = ({ selectedUser, userFilterData }) => {
  const { userData } = useSelector((state) => state.user);
  const [allUsers, setAllUsers] = useState();
  useEffect(() => {
    if (userData) {
      setAllUsers(userData);
    }
  }, [userData]);

  return (
    <>
      <div className="p-4 profile-imgs">
        <div className="w-full flex items-center justify-between">
          <div className="flex">
            {!!allUsers &&
              !!allUsers.length &&
              allUsers.map((user, idx) => (
                <div
                  className={`rounded-full custom-avatar shadow-none cursor-pointer ${
                    !!selectedUser.length &&
                    selectedUser.includes(user.id) &&
                    'active'
                  }`}
                  key={idx}
                  onClick={() => userFilterData(user.id)}
                >
                  <div>
                    <CustomAvatar
                      id={user.id}
                      idPrefix={'avatar'}
                      userList={'userList'}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileList;
