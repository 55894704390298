import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import TableDataRow from './TableDataRow';

const TableView = ({ filteredTasks, handleEditModal, handleSelectChange }) => {
  const { trackingStatuses, tags } = useSelector(
    (state) => state.task
  );
  const [tagData, setTagData] = useState([]);

  useEffect(() => {
    if (tags && tags.length) {
      setTagData(tags);
    }
  }, [tagData]);

  return (
    <>
      <table className="w-full text-sm text-left border">
        <thead className="text-sm font-medium text-gray-900 bg-gray-50 sticky -top-[1px] z-[99]">
          <tr className="border">
            <th scope="col" className="px-3 py-2">
              Title
            </th>
            <th scope="col" className="px-3 py-2">
              Status
            </th>
            <th scope="col" className="px-3 py-2">
              Priority
            </th>
            <th scope="col" className="px-3 py-2">
              Assign to
            </th>
            <th scope="col" className="px-3 py-2">
              Tags
            </th>
            <th scope="col" className="px-3 py-2">
              Reported By
            </th>
            <th scope="col" className="px-3 py-2">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {!!trackingStatuses &&
            trackingStatuses.map((status) => {
              return (
                !!filteredTasks &&
                !!filteredTasks.length &&
                filteredTasks
                  .filter((item) => item.trackingStatusId === status.id)
                  .map((issue,idx) => {
                    return (
                      <>
                        <TableDataRow
                          handleEditModal={handleEditModal}
                          handleSelectChange={handleSelectChange}
                          issue={issue}
                          index={idx}
                        />
                      </>
                    );
                  })
              );
            })}
          {filteredTasks.length === 0 && (
            <tr>
              <td colSpan={7}>
                <div className="text-center p-5">
                  <i className="fas fa-bell-slash"></i>
                  <h2 className="font-bold mt-2">
                    No task available in current sprint!
                  </h2>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default TableView;
