import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { savePasswordChangeRequest } from '../../store/user/action';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';
import { successMsg } from '../../utilities/utils';
import { resetState } from '../../store/user/userSlice';
import { useState } from 'react';
const ChangePassword = ({
  dispatchPasswordChangeRequest,
  dispatchResetState,
}) => {
  const { changePasswordToken, isChangedPassword, message } = useSelector(
    (state) => state.user
  );
  const [passValue, setPassValue] = useState({
    password: '',
    showPassword: false,
    reShowPassword: false,
  });

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (isChangedPassword) {
      successMsg(message);
      dispatchResetState();
      navigate('/login');
    }
  }, [isChangedPassword]);

  useEffect(() => {
    if (!changePasswordToken) {
      navigate('/login');
    }
  }, [changePasswordToken]);

  const handlePasswordChange = (data) => {
    const finalPayload = { data: changePasswordToken, newPassword: data.pwd };
    dispatchPasswordChangeRequest(finalPayload);
    reset();
  };

  const handleClickShowPassword = () => {
    setPassValue({ ...passValue, showPassword: !passValue.showPassword });
  };
  const handleClickReShowPassword = () => {
    setPassValue({ ...passValue, reShowPassword: !passValue.reShowPassword });
  };

  return (
    <div
      className="form-wrapper h-screen"
      style={{
        backgroundImage: 'url("../../assets/img/login-page-bg.png")',
      }}
    >
      <div className="flex justify-center relative">
        <div>
          <div className="md:mt-20">
            <img
              src="../../assets/img/ensuesoft.jpg"
              className="w-38 h-38 mx-auto object-contain"
            />
            <h2 className="text-2xl text-center mb-0 text-black dark:text-[#ccc]">
              Change Password!
            </h2>
          </div>
          <div className="px-8 pt-4 pb-8 ml-9 bg-white dark:bg-[#283046] rounded-lg shadow-card w-96">
            <form onSubmit={handleSubmit(handlePasswordChange)}>
              <div className="mt-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      id="pwd"
                      {...register('pwd', {
                        required: true,
                      })}
                      type={passValue.showPassword ? 'text' : 'password'}
                      className="input-style"
                      autoComplete="off"
                    />
                    {errors.pwd?.type === 'required' && (
                      <FormFieldError message={AppConstants.requiredField} />
                    )}
                    {errors.pwd?.type === 'pattern' && (
                      <FormFieldError message={AppConstants.notValidEmail} />
                    )}
                    <div
                      className={`fa-solid ${
                        passValue.showPassword ? 'fa-eye-slash' : 'fa-eye'
                      } absolute right-[10px] top-[11px]`}
                      onClick={handleClickShowPassword}
                    ></div>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Re-type Password
                  </label>
                  <div className="relative">
                    <input
                      id="rePwd"
                      {...register('rePwd', {
                        required: true,
                      })}
                      type={passValue.reShowPassword ? 'text' : 'password'}
                      className="input-style"
                      autoComplete="off"
                    />
                    {errors.rePwd?.type === 'required' && (
                      <FormFieldError message={AppConstants.requiredField} />
                    )}
                    {watch('rePwd') && watch('pwd') !== watch('rePwd') && (
                      <FormFieldError message={AppConstants.notMatchPassword} />
                    )}
                    <div
                      className={`fa-solid ${
                        passValue.reShowPassword ? 'fa-eye-slash' : 'fa-eye'
                      } absolute right-[10px] top-[11px]`}
                      onClick={handleClickReShowPassword}
                    ></div>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="px-6 py-2 mt-4 btn-primary ml-0"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchPasswordChangeRequest: savePasswordChangeRequest,
  dispatchResetState: resetState,
};
export default connect(null, mapDispatchToProps)(ChangePassword);
