import React, { useEffect, useState } from 'react';
import TableHeadItem from './TableHeadItem';
import TableRow from './TableRow';
import Loader from '../../../loader';

const CommonTable = ({ theadData, tbodyData, fromId, loading }) => {
  const [statusInfo, setStatusInfo] = useState([]);
  useEffect(() => {
    if (tbodyData && tbodyData.length) {
      setStatusInfo(tbodyData);
    }
  }, [tbodyData]);

  return (
    <>
      <table className="w-full text-sm text-left border mt-2">
        <thead className="text-sm font-medium text-gray-900 bg-gray-50 z-[99]">
          <tr>
            {theadData.map((h) => {
              return <TableHeadItem key={h.colName} item={h.colName} />;
            })}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Loader />
          ) : (
            !!statusInfo &&
            !!statusInfo.length &&
            statusInfo.map((item, idx) => (
              <TableRow key={idx} data={item} fromId={fromId} />
            ))
          )}
        </tbody>
      </table>
    </>
  );
};

export default CommonTable;
