import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  convertTime,
  countDigit,
  deleteCookies,
  getItem,
  getProjectInfo,
  setProjectInfo,
  successMsg,
} from '../../utilities/utils';
import { connect } from 'react-redux';
import { sendLogoutRequest } from '../../store/login/loginSlice';
import UserProfile from '../common/userProfile';
import { useSelector } from 'react-redux';
import { resetState, saveProjectId } from '../../store/project/projectSlice';
import { getAllProjectRequest } from '../../store/project/actions';
import TaskModal from '../admin/scrum/modal/TaskModal';
import ProjectModal from '../admin/scrum/modal/ProjectModal';
import InvitesModal from '../admin/scrum/modal/InvitesModal';
import { Tooltip } from 'react-tooltip';
import CustomAvatar from '../common/customAvatar';
import Avatar from 'react-avatar';
import ReactTimeAgo from 'react-time-ago';
import {
  getAllNotificationRequest,
  getAllUnreadNotificationsRequest,
  getAlltaskHistoryRequest,
  saveNotificationRequest,
} from '../../store/notifications/action';
import { resetStateNotification } from '../../store/notifications/notificationsSlice';
import GlobalSearchModal from '../common/globalSearchModal';
import { getProjectSettingsRequest } from '../../store/permissions/action';
import Loader from '../loader';
import { getUserProfilePicUrlRequest } from '../../store/user/action';
import { useDispatch } from 'react-redux';

const Header = ({
  handleMobileMenu,
  dispatchSendLogoutRequest,
  dispatchSaveProjectIdRequest,
  dispatchResetState,
  dispatchGetProjectRequest,
  dispatchGetAllTaskHistory,
  dispatchSaveNotificationRequest,
  dispatchResetStateNotificationRequest,
  dispatchGetAllNotificationRequest,
  dispatchGetProjectSettingsRequest,
  dispatchGetProfilePicUrlRequest,
  dispatchGetAllUnreadNotifications,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectProjectRef = useRef(null);
  const [userDetails, setUserDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);
  const [projectName, setProjectName] = useState('');
  const [modalId, setModalId] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [settings, setSettings] = useState();
  const [labelText, setLabelText] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const [notificationToggal, setNotificationToggal] = useState(true);
  const [allNotifications, setAllNotifications] = useState([]);

  const urlPath =[
    '/',
    '/settings',
    '/sprint',
    '/label-change',
    '/task',
  ];
  const { imageLink, isProfileUpdated } = useSelector((state) => state.user);

  const { projectSettings, menuLebels } = useSelector((state) => state.permissions);
  const {
    isReadNotification,
    allNotification,
    allTaskHistory,
    notificationLoading,
    isUnreadNotification
  } = useSelector((state) => state.notification);
  const {
    projects,
    instantCreatedProjectDetails,
    projectId,
    isProjectUpdated
  } = useSelector((state) => state.project);
  const { message, isTaskAdded, isInvitesAdded, tasks} =
    useSelector((state) => state.task);
  const [urlData] = useSearchParams();
  const dispatch = useDispatch() 

  useEffect(() => {
    let projectId = null;
    if(projects.length)
    {
      projectId = getProjectInfo('selectedProjectId');
      const selectedProjected = projects.find((project) => project.id === projectId);
      if(projectId && Object.keys(selectedProjected).length) {
        setProjectName(selectedProjected.name);
      }
    }
  }, [projects,isProjectUpdated])
  

  useEffect(() => {
    if (projectId) {
      setSearchText('');
    }
  }, [projectId]);

  useEffect(() => {
    if (allTaskHistory && allTaskHistory.length) {
      setAllNotifications(allTaskHistory);
    }
  }, [allTaskHistory]);

  useEffect(() => {
    if (projectId) {
      if(projects.length)
      {
        dispatchGetProjectSettingsRequest(projectId);
        dispatchGetProfilePicUrlRequest(projectId);
      }
    }
  }, [projectId]);

  useEffect(() => {
    if (location) {
      const match = location.pathname.match(/^\/task\/(\d+)$/);
      setIsDropdownDisabled(match);
    }
  }, [location]);

  useEffect(() => {
    if (isReadNotification ) {
      dispatchGetAllNotificationRequest({
        payload: {
          pageNumber: 1,
          pageSize: 10,
          SortByAsc: false,
        },
        projectId: projectId,
      });
      dispatchResetStateNotificationRequest();
    }
  }, [isReadNotification]);

  useEffect(() => {
    if (urlData.get('projectid')) {
      if (!!projects && !!projects.length) {
        let singleProjectDetails = projects.find(
          (el) => el.id === parseInt(urlData.get('projectid'))
        );
        let projectDetails = {
          id: singleProjectDetails?.id,
          name: singleProjectDetails?.name,
        };
        localStorage.setItem('selectedProject', JSON.stringify(projectDetails));
        setProjectName(singleProjectDetails.name);
      }
    }
  }, [urlData.get('projectid'), projects]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatchGetProjectRequest({dispatch});
    }
  }, []);

  useEffect(() => {
    if (projectId) {
      dispatchGetAllNotificationRequest({
        payload: {
          pageNumber: 1,
          pageSize: 10,
          SortByAsc: false,
        },
        projectId: projectId,
      });
      
    }
  }, [projectId]);

  useEffect(() => {
    setUserDetails(JSON.parse(getItem('userData')));
  }, [imageLink, isProfileUpdated]);

  const logoutClick = () => {
    dispatchSendLogoutRequest();
    dispatchResetState();
    deleteCookies();
    sessionStorage.removeItem('selectedProjectId'); 
    navigate('/');
  };
  const handleProfile = () => {
    setShowProfileModal(true);
  };

  useEffect(() => {
    if (Object.keys(instantCreatedProjectDetails).length !== 0) {
      dispatchSaveProjectIdRequest({
        id: instantCreatedProjectDetails.id,
        name: instantCreatedProjectDetails.name,
      });
      setProjectName(instantCreatedProjectDetails.name);
    }
  }, [instantCreatedProjectDetails]);

  const onGetNotification = () => {
    if (allNotifications.length === 0) {
      setNotificationToggal(!notificationToggal);
    }
  };
  useEffect(() => {
    if (!notificationToggal) {
      if (projectId) {
        dispatchGetAllTaskHistory({
          payload: {
            pageNumber: 1,
            pageSize: 10,
            SortByAsc: false,
          },
          projectId: projectId,
        });
      }
    }
  }, [notificationToggal]);

  useEffect(() => {
    if (!notificationToggal) {
      setNotificationToggal(true);
      setAllNotifications([]);
      dispatchResetStateNotificationRequest();
    }
  }, [projectId]);

  useEffect(() => {
    if (projectSettings) {
      setSettings(projectSettings);
    }
    if (menuLebels) {
      setLabelText(menuLebels);
    }
  }, [tasks, projectSettings]);

  const handleClick = (data) => {
    setProjectName(data.name);
    handleOnBlur();
    setProjectInfo(data.id,dispatch);
  };

  const handleOnBlur = () => {
    setShowDropdown(true);
  };
  const handleShowModal = (id) => {
    setModalId(id);
    setShowModal(true);
  };
  useEffect(() => {
    if (isTaskAdded) {
      successMsg(message);
    }
    if (isInvitesAdded) {
      successMsg(message);
    }
    setShowModal(false);
    dispatchResetState();
  }, [isTaskAdded, isInvitesAdded]);

  const handleCloseModal = (val) => {
    setShowModal(val);
  };

  const closeSelectProjectDropdown = (e) => {
    if (
      selectProjectRef.current &&
      !showDropdown &&
      !selectProjectRef.current.contains(e.target)
    ) {
      handleOnBlur();
    }
  };
  document.addEventListener('mousedown', (e) => closeSelectProjectDropdown(e));

  const getAllNotications = () => {
    navigate('/notifications');
  };

  const onClicktoView = ({ taskId, historyId }) => {
    dispatchSaveNotificationRequest({ taskHistoryId: historyId });
    navigate(`/task/${taskId}`);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchClose = () => {
    setSearchText('');
  };

  useEffect(()=>{
    if(isUnreadNotification){
      dispatchGetAllTaskHistory({
        payload: {
          pageNumber: 1,
          pageSize: 10,
          SortByAsc: false,
        },
        projectId: projectId,
      });
      dispatchResetStateNotificationRequest();
    }
  },[isUnreadNotification])

  const handleAllRead = () => {
    dispatchGetAllUnreadNotifications(projectId);
  };
  return (
    <>
      <div
        className={`inset-0 fixed block w-full customshadow ${
          location.pathname !== '/project-repo' ? '' : 'hidden'
        }`}
      ></div>
      <header
        className={`${
          location.pathname !== '/project-repo' ? '' : 'hidden'
        } shadow px-4 py-3 rounded`}
      >
        <div className="flex justify-between items-center mob-block">
          {/* drop down */}
          <div className="flex items-center justify-between custom-order">
            {location.pathname !== '/report' && (
              <>
                <div
                  ref={selectProjectRef}
                  className={`relative min-w-[18rem] max-w-[20rem] ${
                    isDropdownDisabled !== null ? 'pointer-events-none' : ''
                  }`}
                >
                  <div
                    className="bg-white flex items-center rounded-md"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <input
                      type="text"
                      defaultValue={projectName && projectName}
                      name="select"
                      id="select"
                      autoComplete="off"
                      className="text-gray-600 w-full text-2xl cursor-pointer appearance-none truncate text-[14px] px-2 caret-transparent hover:bg-gray-50 rounded-[4px]"
                    />
                  </div>
                  <input
                    type="checkbox"
                    name="show_more"
                    id="show_more"
                    className="hidden peer bg-red-400"
                  />
                  <div
                    className={`absolute rounded shadow bg-white max-h-[400px] overflow-y-auto z-[9] text-[14px] ${
                      showDropdown ? 'hidden' : 'peer-checked:flex'
                    } flex-col w-full mt-1 border border-gray-200`}
                  >
                    {!!projects &&
                      projects.map((item, idx) => {
                        return (
                          <div
                            className="cursor-pointer group border-t"
                            key={idx}
                            onClick={() =>
                              handleClick({
                                id: item.id,
                                name: item.name,
                              })
                            }
                          >
                            <p
                              className={`block p-2 border-transparent border-l-4 group-hover:border-[#e32135] ${
                                item.id
                                  ? 'border-[#e32135]'
                                  : ''
                              } group-hover:bg-gray-100`}
                            >
                              {item.name}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>

                {!urlPath.includes(location.pathname) && (
                  <div className="flex items-center mob-d-none border-l border-l-[#ccc]">
                    <button
                      className="plus-sign-btn flex items-center mt-0 mx-2"
                      id="header_add-project"
                      onClick={() => handleShowModal('Project')}
                    >
                      <i className="fa-solid fa-file-circle-plus"></i>
                    </button>
                    <Tooltip
                      className="tooltip"
                      anchorId="header_add-project"
                      content={labelText?.addProjects && labelText.addProjects}
                      place="top"
                    ></Tooltip>
                    {settings && settings.canInviteUser && (
                      <>
                        <button
                          className="plus-sign-btn flex items-center mt-0 mr-2"
                          id="header_invite"
                          onClick={() => handleShowModal('Invite')}
                        >
                          <i className="fa-solid fa-user-plus"></i>
                        </button>
                        <Tooltip
                          className="tooltip"
                          anchorId="header_invite"
                          content={
                            labelText?.projectInvite && labelText.projectInvite
                          }
                          place="top"
                        ></Tooltip>
                      </>
                    )}
                    {settings && settings.canAddTask && (
                      <>
                        <button
                          className="plus-sign-btn flex items-center mt-0"
                          id="header_add-task"
                          onClick={() => handleShowModal('Task')}
                        >
                          <i className="fa-solid fa-plus"></i>
                        </button>
                        <Tooltip
                          className="tooltip"
                          anchorId="header_add-task"
                          content={labelText?.addTask && labelText.addTask}
                          place="top"
                        ></Tooltip>
                      </>
                    )}
                  </div>
                )}

                {/* Mobile dropdown */}
                <div className="relative hidden mobile-dropdown">
                  <div
                    className="flex-items ml-3 cursor-pointer"
                    id="action-dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-plus w-[40px] h-[40px] flex items-center justify-center site-clr"></i>
                  </div>
                  <ul
                    className="dropdown-menu dropdown-menu-ul px-1"
                    aria-labelledby="action-dropdown"
                  >
                    <li className="cursor-pointer mb-2 text-[13px]">
                      {location.pathname !== '/' &&
                        location.pathname !== '/settings' && (
                        <div className="flex items-center">
                          <button
                            className="plus-sign-btn flex items-center mt-0 mx-2"
                            id="mobile_add-project"
                            onClick={() => handleShowModal('Project')}
                          >
                            <i className="fa-solid fa-file-circle-plus"></i>
                          </button>
                          <Tooltip
                            className="tooltip"
                            anchorId="mobile_add-project"
                            content="Add project"
                            place="top"
                          ></Tooltip>
                          {settings && settings.canInviteUser && (
                            <>
                              <button
                                className="plus-sign-btn flex items-center mt-0 mr-2"
                                id="mobile_invite"
                                onClick={() => handleShowModal('Invite')}
                              >
                                <i className="fa-solid fa-user-plus"></i>
                              </button>
                              <Tooltip
                                className="tooltip"
                                anchorId="mobile_invite"
                                content="Invites"
                                place="top"
                              ></Tooltip>
                            </>
                          )}
                          <button
                            className="plus-sign-btn flex items-center mt-0"
                            id="mobile_add-task"
                            onClick={() => handleShowModal('Task')}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </button>
                          <Tooltip
                            className="tooltip"
                            anchorId="mobile_add-task"
                            content="Add task"
                            place="top"
                          ></Tooltip>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
                {/* Mobile dropdown end*/}
              </>
            )}
          </div>

          <div className="flex-items custom-order">
            <div className="flex items-center">
              <img
                src="../../assets/img/bars.svg"
                alt=""
                className="menu-icon w-9 hidden cursor-pointer menu-bars"
                onClick={handleMobileMenu}
              />
            </div>
            <div className="flex">
              {!!projects && !!projects.length && (
                <div
                  className={`relative ${
                    location.pathname === '/task-list'
                      ? 'pointer-events-none'
                      : ''
                  }`}
                >
                  <input
                    type="search"
                    id="global-search"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="search-bar pr-[22px]"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                  />
                  <i
                    className={`fas fa-times absolute right-[11px] top-[13px] cursor-pointer site-clr hover:cursor-pointer ${
                      searchText ? 'block' : 'hidden'
                    }`}
                    onClick={() => handleSearchClose()}
                  ></i>
                    
                  {<GlobalSearchModal searchText={searchText} />}
                </div>
              )}

              <div className="relative">
                <div
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="cursor-pointer"
                  onClick={() => onGetNotification()}
                >
                  <span
                    className={`bg-red-500 notification ${
                      countDigit(allNotification) >= 3 &&
                      'h-[1.5rem] w-[1.5rem] !right-[-3px] !top-[-6px]'
                    }`}
                  >
                    <small
                      className={`${
                        allNotification
                          ? countDigit(allNotification) === 1
                            ? '!-top-[4px] !-right-[1px] px-[8px] py-[2px] '
                            : countDigit(allNotification) === 2
                              ? 'px-[8px] py-[5px] '
                              : '!-top-[0px] !-right-[2px] px-[4px] py-[4px]'
                          : '-top-[4px] -right-[1px] px-[7px] py-[2px]'
                      }`}
                    >
                      {allNotification ? allNotification : 0}
                    </small>
                  </span>
                  <img
                    src="../../assets/img/bell.svg"
                    alt=""
                    className="menu-icon w-9"
                  />
                </div>
                <div
                  className="dropdown-menu dropdown-menu-ul w-96"
                  aria-labelledby="action-dropdowns"
                >
                  <div>
                    <div className="flex justify-between border-b-2 p-4">
                      <p className="mb-0 font-bold">Notifications</p>
                      <span className="cursor-pointer" onClick={handleAllRead}>
                        Make All As Read
                      </span>
                    </div>

                    <div className="notification-max-h overflow-y-auto overflow-x-hidden">
                      {notificationLoading ? (
                        <Loader />
                      ) : allNotifications.length ? (
                        allNotifications.map((item, idx) => (
                          <div
                            key={idx}
                            className={`border-b-2 p-4 cursor-pointer ${
                              item.isRead ? '' : 'bg-[#feedf0]'
                            }`}
                            onClick={() =>
                              onClicktoView({
                                taskId: item.taskId,
                                historyId: item.id,
                              })
                            }
                          >
                            <div className="flex relative">
                              <div className="user absolute top-1">
                                {item.userId ? (
                                  <CustomAvatar
                                    id={item.userId}
                                    idPrefix={item.id}
                                    assigneeName={item.changedBy}
                                  />
                                ) : (
                                  <div className="user">
                                    <div className="avatar">
                                      <Avatar
                                        round={true}
                                        title="Unassigned"
                                        name="Un Assigned"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="ml-10">
                                <div className="md:flex items-center">
                                  <p
                                    className="mt-1 ml-2"
                                    dangerouslySetInnerHTML={{
                                      __html: item.changedValue,
                                    }}
                                  ></p>
                                </div>
                                <div>
                                  <b className="ml-2">
                                    <ReactTimeAgo
                                      date={Number(convertTime(item.createdAt))}
                                      locale="en-US"
                                    />
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-center py-4">
                          <i className="fas fa-bell-slash"></i>
                          <h2 className="font-bold mt-2">
                            No Notification yet!
                          </h2>
                        </div>
                      )}
                    </div>
                  </div>
                  {!!allNotifications.length &&
                    allNotifications.length >= 6 && (
                    <div className="bg-white w-full text-center p-2 border-t-2">
                      <button
                        className="bg-red-600 hover:bg-red-700 !text-white font-semibold py-2 px-32 border rounded-full shadow"
                        onClick={() => getAllNotications()}
                      >
                          Show More
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {userDetails && (
                <div className="relative profile-dropdown">
                  <div
                    className="flex-items ml-3 cursor-pointer current-usr"
                    id="action-dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="grid leading-tight">
                      <span className="font-semibold leading-none">
                        {userDetails.firstName + ' ' + userDetails.lastName}
                      </span>
                      <span className="text-right">
                        <small>{userDetails && userDetails.role}</small>
                      </span>
                    </div>
                    <div className="relative">
                      <img
                        src={`${
                          userDetails.profilePicUrl
                            ? userDetails.profilePicUrl
                            : '../../assets/img/user-png.png'
                        }`}
                        alt=""
                        className="rounded-full w-10 h-10 object-cover ml-2"
                      />
                      <span className="h-3 w-3 rounded-full bg-green-500 absolute border-2 border-solid border-white active-usr"></span>
                    </div>
                  </div>
                  <ul
                    className="dropdown-menu dropdown-menu-ul user-profile-dropdown"
                    aria-labelledby="action-dropdown"
                  >
                    <li className="cursor-pointer mb-2 text-[13px]">
                      <div className="text-center px-3" onClick={handleProfile}>
                        <span className="flex items-center justify-center">
                          <img
                            alt=""
                            src="../../assets/img/user-icon.svg"
                            className="w-4 h-4 mr-2"
                          />
                          Profile
                        </span>
                      </div>
                    </li>
                    <li className="cursor-pointer text-[13px]">
                      <div className="text-center px-3">
                        <span
                          className="flex items-center justify-center"
                          onClick={() => logoutClick()}
                        >
                          <img
                            alt=""
                            src="../../assets/img/logout.svg"
                            className="w-4 h-4 mr-2"
                          />
                          Logout
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      {showProfileModal && (
        <UserProfile
          showModal={showProfileModal}
          setShowModal={(val) => setShowProfileModal(val)}
          userDetails={userDetails}
        />
      )}
      {/* Show modal */}
      {showModal && (modalId === 'Task' || modalId === 'Tasks') && (
        <TaskModal
          modalId={modalId}
          showModal={showModal}
          setShowModal={(val) => handleCloseModal(val)}
        />
      )}
      {showModal && modalId === 'Project' && (
        <ProjectModal
          modalId={modalId}
          showModal={showModal}
          setShowModal={(val) => setShowModal(val)}
        />
      )}
      {showModal && modalId === 'Invite' && (
        <InvitesModal
          modalId={modalId}
          showModal={showModal}
          setShowModal={(val) => setShowModal(val)}
        />
      )}
    </>
  );
};
const mapDispatchToProps = {
  // dispatching
  dispatchSendLogoutRequest: sendLogoutRequest,
  dispatchSaveProjectIdRequest: saveProjectId,
  dispatchResetState: resetState,
  dispatchGetProjectRequest: getAllProjectRequest,
  dispatchGetAllTaskHistory: getAlltaskHistoryRequest,
  dispatchSaveNotificationRequest: saveNotificationRequest,
  dispatchGetAllNotificationRequest: getAllNotificationRequest,
  dispatchResetStateNotificationRequest: resetStateNotification,
  dispatchGetProjectSettingsRequest: getProjectSettingsRequest,
  dispatchGetProfilePicUrlRequest: getUserProfilePicUrlRequest,
  dispatchGetAllUnreadNotifications: getAllUnreadNotificationsRequest,
};
export default connect(null, mapDispatchToProps)(Header);
