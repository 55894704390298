import axios from 'axios';
import { deleteCookies } from './utils';
import { URLS } from './endPoints';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '../store/store';
import { useNavigate } from 'react-router-dom';
export const rootUrl = process.env.REACT_APP_BASE_API_URL;

const refresh = axios.create();
export const refreshAuthLogic = (failedRequest) =>
  refresh
    .post(`${rootUrl}/${URLS.REFRESH_TOKEN.url}`, {
      RefreshToken: localStorage.getItem('refreshToken'),
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem('token', tokenRefreshResponse.data.token);
      localStorage.setItem(
        'refreshToken',
        tokenRefreshResponse.data.refreshToken
      );
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.data.AuthToken;
      return Promise.resolve();
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403)
      ) {
        // setCallbackLinkRequest();
        localStorage.clear();
        deleteCookies();
      }
    });
// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic, { statusCodes: [403] });

function getAccessToken() {
  return localStorage.getItem('token');
}
axios.interceptors.request.use((request) => {
  request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  return request;
});

let selectedProjectId;
export class ApiService {
  static async getData(url, selectedProjectId) {
    let headers;
    if (selectedProjectId) {
      headers = {
        Authorization: `Bearer  ${getAccessToken()}`,
        ProjectId: selectedProjectId,
      };
    }
    const config = {
      headers,
    };
    const response = await axios.get(`${rootUrl}/${url}`, config);
    return response;
  }

  static async postData(url, data, headerProjectId) {
    let headers;
    selectedProjectId = store.getState().project.projectId;
    if (selectedProjectId && headerProjectId) {
      headers = {
        Authorization: `Bearer  ${getAccessToken()}`,
        ProjectId: selectedProjectId,
      };
    } else {
      headers = {
        Authorization: `Bearer  ${getAccessToken()}`,
      };
    }

    const config = {
      headers,
    };

    const response = await axios.post(`${rootUrl}/${url}`, data, config);
    return response;
  }

  static async postLoginData(url, data) {
    const config = {
      method: 'post',
      url: `${rootUrl}/${url}`,
      data: data,
    };

    const response = await axios(config);
    return response;
  }
}

const AxiosInterceptorsSetup = (navigate) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 404) {
        navigate('/not-found');
      }
      return Promise.reject(error);
    }
  );
};

export default function AxiosInterceptorNavigate() {
  let navigate = useNavigate();
  AxiosInterceptorsSetup(navigate);
  return <></>;
}
