import React, { useEffect} from 'react';
import {
  getProjectInvitationInfoRequest,
  projectSetInviteAcceptanceRequest,
} from '../../store/project/actions';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AcceptInvites = ({
  dispatchSetInviteAcceptanceStatus,
  dispatchGetInvitationInfo,
}) => {
  const {
    projectInviteInfo,
  } = useSelector((state) => state.project);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    dispatchGetInvitationInfo(token);
  }, []);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (projectInviteInfo.inviteStatus === 1 || projectInviteInfo.inviteStatus === 2) {
      navigate('/');
    }
  }, [projectInviteInfo]);

  const handleProjectInvites = (status) => {
    if (token) {
      dispatchSetInviteAcceptanceStatus({ token: token, status });
    }
  };
  useEffect(() => {
    if (projectInviteInfo.invitedEmail) {
      const loginUser = JSON.parse(localStorage.getItem('userData'));
      // Same person is not accepting the invite
      if (loginUser.email !== projectInviteInfo.invitedEmail) {
        navigate('/');
      }
    }
  }, [projectInviteInfo]);
  return (
    <>
      {/* invite-link */}
      <div className="p-5">
        <div className="invitation-box">
          {!projectInviteInfo.inviteStatus ? (
            <>
              <h1 className="text-[17px] mb-1 font-semibold site-clr">Hi,</h1>
              <p className="mb-2 text-gray-500">
                You are invited to work with the team on the project of{' '}
                <strong>{projectInviteInfo.projectName}</strong>
              </p>
            </>
          ) : (
            <p className="mb-2 text-gray-500 text-center">
              Already Accepted / Expired link!
            </p>
          )}

          {!projectInviteInfo.inviteStatus && (
            <div className="flex items-center">
              <button
                className="border rounded-lg btn-primary w-fit ml-auto mr-3"
                onClick={() => handleProjectInvites(1)}
              >
                Accept
              </button>
              <button
                className="border rounded-lg btn-primary btn-cancel w-fit mx-0"
                onClick={() => handleProjectInvites(2)}
              >
                Decline
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSetInviteAcceptanceStatus: projectSetInviteAcceptanceRequest,
  dispatchGetInvitationInfo: getProjectInvitationInfoRequest,
};
export default connect(null, mapDispatchToProps)(AcceptInvites);
