import * as Constants from './constants';

// Task Report
export const taskReportRequest = (payload) => {
  return {
    type: Constants.getTaskReportRequestActionType,
    payload,
  };
};

// Particular tracking status data
export const getSingleTrackingStatusDataRequest = (payload) => {
  return {
    type: Constants.getSingleTrackingStatusDataRequestActionType,
    payload,
  };
};
