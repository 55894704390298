import React, { useEffect, useState } from 'react';
import Loader from '../../../loader';
import { useSelector } from 'react-redux';
import UserProfileList from '../../../common/userList';
import CustomAvatar from '../../../common/customAvatar';
import Avatar from 'react-avatar';
import RemoveModal from '../modal/RemoveModal';
import {Link} from 'react-router-dom'

const SprintComponent = ({
  allTaskSprint,
  removeToSprintTask,
  handleRemoveTaskSprint,
  handleRemoveCheckBox,
  handleAddTaskToSprint,
  sprintId,
  isCompletedSprint,
  handleAllCheckbox,
}) => {
  const { loading, allSprint } = useSelector((state) => state.sprint);
  const { trackingStatusList } = useSelector((state) => state.project);
  const [selectedUser, setSelectedUser] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sprintList, setSprintList] = useState();
  const [showModal, setShowModal] = useState(false);
 
  useEffect(() => {
    if (!!allTaskSprint && !!allTaskSprint.length) {
      setFilteredTasks(allTaskSprint);
    }
  }, [allTaskSprint]);
  useEffect(() => {
    if (!!allSprint && !!allSprint.length) {
      let updatedSprints = allSprint.filter(
        (item) => item.isActiveSprint !== true && item.isComplete !== true
      );
      if (sprintId) {
        setSprintList(
          updatedSprints.filter((item) => item.id !== parseInt(sprintId))
        );
      } else {
        setSprintList(updatedSprints);
      }
    }
  }, [allSprint, sprintId]);

  useEffect(() => {
    let filterData;
    if (selectedUser.length) {
      let selectUserFilterData;
      filterData = selectUserFilterData = allTaskSprint.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      if (searchText) {
        filterData = selectUserFilterData.filter((item) => {
          return (
            item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        });
      }
    } else {
      if (searchText) {
        filterData = allTaskSprint.filter((item) => {
          return (
            item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        });
      } else {
        filterData = allTaskSprint;
      }
    }
    setFilteredTasks(filterData);
  }, [selectedUser]);

  const userFilterData = (id) => {
    const isUserAlreadySelected = selectedUser.includes(id);
    if (!isUserAlreadySelected) {
      setSelectedUser([...selectedUser, id]);
    } else {
      const updatedUserSelected = selectedUser.filter((us) => us !== id);
      setSelectedUser(updatedUserSelected);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const arr = [...allTaskSprint];
    if (selectedUser.length) {
      let userFilterData;
      userFilterData = arr.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      if (e.target.value) {
        userFilterData = userFilterData.filter((item) => {
          return (
            item.taskName
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) !== -1
          );
        });
      }
      setFilteredTasks(userFilterData);
    } else {
      let index;
      if (e.target.value) {
        index = arr.filter((item) => {
          return (
            item.taskName
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) !== -1
          );
        });
      } else {
        index = allTaskSprint;
      }
      setFilteredTasks(index);
    }
  };

  const handleSearchClose = () => {
    setSearchText('');
    if (selectedUser.length) {
      const userFilterData = allTaskSprint.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      setFilteredTasks(userFilterData);
    } else {
      setFilteredTasks(allTaskSprint);
    }
  };
  const getStatus = (statusId) => {
    if (!!trackingStatusList && !!trackingStatusList.length) {
      let d = trackingStatusList.filter((status) => {
        return parseInt(status.id) === parseInt(statusId);
      });
      if (d.length !== 0) {
        return d[0].name;
      }
    }
  };
  const handleAllCheckList = (e) => {
    handleAllCheckbox(e);
  };

  const handleSelectChange = (event) => {
  
    const arr = [...allTaskSprint];
    if (event !== 'null') {
      let filData;
      const searchData = arr.filter((item) => {
        return parseInt(item.trackingStatusId) === parseInt(event);
      });
      if (selectedUser.length) {
        const selectedData = searchData.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );

        if (searchText) {
          filData = selectedData.filter((item) => {
            return (
              item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1
            );
          });
        } else {
          filData = selectedData;
        }
      } else {
        const index = arr.filter((item) => {
          return parseInt(item.trackingStatusId) === parseInt(event);
        });
        if (searchText) {
          filData = index.filter((item) => {
            return (
              item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1
            );
          });
        } else {
          filData = index;
        }
      }
      setFilteredTasks(filData);
    } else {
      let filData;
      if (selectedUser.length) {
        const userFilterData = arr.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );
        if (searchText) {
          filData = userFilterData.filter((item) => {
            return (
              item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1
            );
          });
        } else {
          filData = userFilterData;
        }
      } else {
        if (searchText) {
          filData = arr.filter((item) => {
            return (
              item.taskName.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1
            );
          });
        } else {
          filData = arr;
        }
      }
      setFilteredTasks(filData);
    }
  };
  return (
    <>
      <div className="flex justify-between">
        <div className="-ml-[15px]">
          <UserProfileList
            selectedUser={selectedUser}
            userFilterData={userFilterData}
          />
        </div>
        <div className="accordion-body pb-0">
          <div className="ticket-heading justify-end mt-5">
            <div className="flex items-center mt-4">
              <div className="relative">
                <input
                  type="search"
                  className="search-bar pr-[22px]"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => handleSearch(e)}
                />
                <i
                  className={`fas fa-times absolute right-[11px] top-[13px] cursor-pointer site-clr hover:cursor-pointer ${
                    searchText ? 'block' : 'hidden'
                  }`}
                  onClick={() => handleSearchClose()}
                ></i>
              </div>
              <div className="flex border items-center ml-3 rounded-[0.5rem] h-[2.5rem] tasks-btn">
                <div className="relative">
                  <div className="arrow-down">
                    <i className="fas fa-chevron-down"></i>
                  </div>
                  <select
                    className="input-style select-none cursor-pointer"
                    onChange={(e) => {
                      handleSelectChange(e.target.value);
                    }}
                  >
                    <option value={'null'}>All</option>
                    {trackingStatusList.length &&
                      trackingStatusList.map((item, idx) => (
                        <option
                          key={idx}
                          value={item.id}
                          className="select-none"
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="border border-gray-200">
          <div className="flex justify-between pt-2 rounded-none border-0 bg-gray-50">
            <span className="mb-0 w-full items-center py-4 px-5 text-red-500 font-semibold">
              Sprint task
            </span>
            {!!removeToSprintTask.length && (
              <div className="dropdown inline-block relative mx-5 my-2">
                <img
                  src="../../assets/img/dots-vertical.svg"
                  alt=""
                  className="w-7 h-7 rotate-90 m-0 cursor-pointer"
                />

                <ul
                  id="dropdown-menu"
                  className="dropdown-menu absolute hidden pt-1 bg-white shadow rounded py-2 px-4 mb-2 whitespace-nowrap right-0 left-align"
                >
                  {!!sprintList &&
                    !!sprintList.length &&
                    sprintList.map((item, idx) => (
                      <li className="text-red-500" key={idx}>
                        <button
                          type="button"
                          onClick={() => handleAddTaskToSprint(item.id)}
                        >
                          Move to {item.name}
                        </button>
                      </li>
                    ))}
                  {isCompletedSprint ? (
                    ''
                  ) : (
                    <li className="text-red-500">
                      <button onClick={() => setShowModal(true)}>Remove</button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <table className="w-full text-sm text-left">
            <thead className="text-sm font-medium text-gray-900 bg-gray-50 sticky z-[99]">
              <tr className="border border-l-0 border-r-0">
                <th className="p-4">
                  <input
                    type="checkbox"
                    checked={removeToSprintTask.length === filteredTasks.length}
                    className="form-check-input cstm-ckeckbox"
                    onChange={(e) => handleAllCheckList(e)}
                  />
                </th>
                <th>Assgin to</th>
                <th scope="col" className="px-3 py-2">
                  Title
                </th>
                <th>Tracking Status</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td>
                    <Loader />
                  </td>
                </tr>
              ) : (
                filteredTasks.length ? (
                  filteredTasks.map((task, idx) => (
                    <tr className="mt-5 border-b" key={idx}>
                      <td className="p-4">
                        <input
                          type="checkbox"
                          checked={removeToSprintTask?.includes(task.taskId)}
                          className="form-check-input cstm-ckeckbox"
                          onChange={(e) => handleRemoveCheckBox(e, task.taskId)}
                        />
                      </td>
                      <td>
                        {task.assignedTo ? (
                          <CustomAvatar id={task.assignedTo} idPrefix={task.id} assigneeName={task.assigneeName}/>
                        ) : (
                          <div className="user">
                            <div className="avatar">
                              <Avatar
                                round={true}
                                title="Unassigned"
                                name="Un Assigned"
                              />
                            </div>
                          </div>
                        )}
                      </td>
                      <td>
                        <Link to={`/task/${task.taskId}`} target="_blank">
                          {task.taskName}
                        </Link>
                      </td>

                      <td>
                        {getStatus(task.trackingStatusId)
                          ? getStatus(task.trackingStatusId)
                          : 'N/A'}
                      </td>
                    </tr>
                  ))
                ):(
                  <tr>
                    <td colSpan={3}>
                      <div className="text-center p-5">
                        <i className="fas fa-bell-slash"></i>
                        <h2 className="font-bold mt-2">No Any Task yet!</h2>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <RemoveModal
          setShowModal={setShowModal}
          handleRemoveTaskSprint={handleRemoveTaskSprint}
        />
      )}
    </>
  );
};

export default SprintComponent;
