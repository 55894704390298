import React from 'react';
import { useState } from 'react';

const ImageModal = ({ imageSrc, onClose }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const handleWheel = (event) => {
    const newZoomLevel = zoomLevel + event.deltaY / 1000;

    if (newZoomLevel >= 1 && newZoomLevel <= 3) {
      setZoomLevel(newZoomLevel);
    }
  };
  return (
    <div className="fixed z-[9999] inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="bg-white rounded"
            onWheel={handleWheel}
            style={{
              transform: `scale(${zoomLevel})`,
            }}
          >
            <div className="sm:flex sm:items-start p-3">
              {imageSrc && (
                <div className="w-full relative">
                  <div
                    className="absolute -right-[10px] -top-[11px] close-icon bg-red-500 text-white rounded-full cursor-pointer"
                    onClick={onClose}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </div>
                  <img
                    src={imageSrc}
                    width="100%"
                    type="application/pdf"
                    className="max-w-full w-full h-full"
                  ></img>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
