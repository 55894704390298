import {
  EditorState,
} from 'draft-js';
import { convertFromHTML } from 'draft-convert';

// C O M P O N E N T S

const findSpanEntities = (contentBlock, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'SPAN'
    );
  });
};

export const htmlStringToEditorState = (textToConvert, isKeyNeeded) => {
  return EditorState.createWithContent(
    convertFromHTML({
      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'span') {
          const nodeAsLink = node;
          return createEntity('SPAN', 'MUTABLE', {
            id: nodeAsLink.id,
            text: nodeAsLink.innerText,
            key: isKeyNeeded ? nodeAsLink.id : null,
          });
        }
        return null;
      },
    })(textToConvert)
  );
};

export const customDecorator = [
  {
    strategy: findSpanEntities,
    component: '<span> Helllo </span>',
  },
];
