import React, { useEffect, useState } from 'react';
import FormInputField from '../../../formInputField';
import { connect, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { resetState } from '../../../../store/project/projectSlice';
import { updateTrackingStatus } from '../../../../store/project/actions';

const TrackingStatusCol = ({
  data,
  id,
  dispatchUpdateTrackingStatus,
  dispatchResetStateRequest,
  projectId,
  setDeleteShowModal,
  setDeletedData,
}) => {
  const [editMode, setEditMode] = useState(null);
  const [showBtn, setShowBtn] = useState(true);
  const [chooseStandardStatus, setChooseStandardStatus] = useState([]);
  const [standTrackingStatusId, setStandTrackingStatusId] = useState(0);
  const { flowStatus, isTrackingStatusUpdated } = useSelector(
    (state) => state.project
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (flowStatus) {
      setChooseStandardStatus(flowStatus);
    }
  }, [flowStatus]);

  useEffect(() => {
    if (isTrackingStatusUpdated) {
      dispatchResetStateRequest();
      onEdit();
    }
  }, [isTrackingStatusUpdated]);

  const onEdit = () => {
    setShowBtn(true);
    reset();
    setEditMode(null);
  };

  const handleStandTrackingStatusChange = (event) => {
    setStandTrackingStatusId(event.target.value);
  };

  const handleDataSubmit = (data) => {
    const finalData = {
      name: data.name,
      description: data.description,
      trackingId: data.id,
      projectId: projectId,
      flowStatusId: parseInt(data.flowStatusId),
    };
    dispatchUpdateTrackingStatus(finalData);
  };

  const onDelete = (task) => {
    setDeleteShowModal(true);
    setDeletedData(task);
  };

  return (
    <div
      key={id}
      className="hover:cursor-move grid grid-cols-3 justify-between bg-white border-b cursor-pointer select-none dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    >
      <div className="px-6 py-3 flex items-center pl-1">
        <>
          <img
            src="../../assets/img/icons/draggable.svg"
            className="w-5 h-4 mr-1"
          />
          {editMode === data.id ? (
            <div className="flex">
              <div className="w-3/5">
                <FormInputField
                  type={'text'}
                  fieldName={'name'}
                  register={register}
                  errors={errors}
                  placeholder={'Project-name'}
                />
              </div>
            </div>
          ) : (
            data.name
          )}
        </>
      </div>

      <div className="px-6 py-3">
        {editMode === data.id ? (
          <>
            <div className="flex">
              <div className="relative">
                <div className="arrow-down">
                  <i className="fas fa-chevron-down"></i>
                </div>
                <select
                  className="input-style"
                  {...register('flowStatusId', {
                    onChange: (e) => handleStandTrackingStatusChange(e),
                  })}
                  value={standTrackingStatusId}
                >
                  {!!chooseStandardStatus &&
                    !!chooseStandardStatus.length &&
                    chooseStandardStatus.map((status, idx) => (
                      <option key={idx} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-wrap items-center justify-end w-2/5">
                {!showBtn && (
                  <>
                    <button
                      className="mr-2 comment-edit-icon"
                      onClick={handleSubmit(handleDataSubmit)}
                    >
                      <i className="fa-solid fa-check"></i>
                    </button>
                    <button
                      className="dlt-comment-icon"
                      onClick={() => onEdit()}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        ) : data.flowStatusId === 0 ? (
          'None'
        ) : (
          !!chooseStandardStatus &&
          !!chooseStandardStatus.length &&
          chooseStandardStatus.find((item) => item.id === data.flowStatusId)
            .name
        )}
      </div>

      {showBtn ? (
        <div className="px-6 py-3 pl-1">
          <div className="text-left">
            <div className={'dropdown inline-block relative'}>
              <span
                className="social-link cursor-pointer"
                id="dropdownDefault"
                data-dropdown-toggle="dropdown dAction"
              >
                <img
                  src="../../assets/img/dots-vertical.svg"
                  className="w-5 h-5"
                />
              </span>
              <ul
                id="dropdown-menu"
                className="dropdown-menu absolute hidden pt-1 bg-white dark:bg-[#283046] shadow rounded py-2 px-4 mb-2 whitespace-nowrap"
              >
                <li className="mb-2">
                  <button
                    type="button"
                    onClick={() => {
                      setEditMode(data.id);
                      reset(data);
                      setShowBtn(false);
                    }}
                  >
                    <i
                      className="fa fa-pencil mr-2 cursor-pointer w-[18px]"
                      aria-hidden="true"
                    ></i>
                    Edit
                  </button>
                </li>

                <li className="text-red-500">
                  <button type="button" onClick={() => onDelete(data)}>
                    <i
                      className="fa fa-trash mr-2 cursor-pointer w-[18px]"
                      aria-hidden="true"
                    ></i>
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchResetStateRequest: resetState,
  dispatchUpdateTrackingStatus: updateTrackingStatus,
};
export default connect(null, mapDispatchToProps)(TrackingStatusCol);
