import React from 'react';
import { connect } from 'react-redux';
import { saveSubTask } from '../../../../../store/task/action';
import FormSelector from '../../../../formSelector';
import CreatableSelect from 'react-select/creatable';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import LinkIssuesTaskList from './LinkIssuesTaskList';

const LinkIssueTask = ({ singleTask, dispatchSaveSubTask, taskId }) => {
  const [showLinkIssueDiv, setShowLinkIssueDiv] = useState(false);
  const [subTaskSuggestions, setSubTaskSuggestions] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [addLinkTaskPermission, setAddLinkTaskPermission] = useState(true);

  const { tasks } = useSelector((state) => state.task);
  const { userData } = useSelector((state) => state.user);
  const loggedId = JSON.parse(localStorage.getItem('userData'));

  const {
    register,
    formState: { errors: relationTaskErrors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (userData && userData.length && loggedId) {
      const userRole = userData.find((uss) => {
        return uss.id === loggedId.id;
      });
      if (userRole.role === 4) {
        setAddLinkTaskPermission(false);
      } else {
        setAddLinkTaskPermission(true);
      }
    }
  }, [userData, loggedId]);

  useEffect(() => {
    if (tasks.length) {
      const suggestions = tasks.filter((task) => {
        if (task.id !== taskId) {
          return {
            value: task.id,
            label: task.title,
          };
        }
      });
      const updatedSuggestions = suggestions.map((item) => {
        return {
          value: item.id,
          label: item.title,
        };
      });
      setSubTaskSuggestions(updatedSuggestions);
    }
  }, [tasks]);

  const handleTaskId = (taskId) => {
    setSelectedTaskId(taskId);
  };

  const handleSubTask = (data) => {
    const finalPayload = {
      taskId: singleTask.id,
      relatedTaskId: selectedTaskId.value,
      relationTypeId: data.relationTypeId,
    };
    dispatchSaveSubTask(finalPayload);
    reset();
    setSelectedTaskId(null);
  };

  return (
    <>
      <LinkIssuesTaskList singleTask={singleTask} />
      {showLinkIssueDiv ? (
        <div>
          <form onSubmit={handleSubmit(handleSubTask)}>
            <div className="md:flex">
              <div className="lg:w-1/4 w-full md:mr-2">
                <div className="relative">
                  <FormSelector
                    label={'is blocked by'}
                    fieldName={'relationTypeId'}
                    register={register}
                    errors={relationTaskErrors}
                    isRequired={false}
                    data={[
                      { id: 1, name: 'ParentOf' },
                      { id: 2, name: 'Blocked By' },
                      { id: 3, name: 'Relation To' },
                    ]}
                    options="name"
                  />
                </div>
              </div>
              <div className="lg:w-3/4 w-full">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Tasks
                  </label>
                  {!!tasks && (
                    <CreatableSelect
                      isClearable
                      onChange={(e) => handleTaskId(e)}
                      options={subTaskSuggestions}
                      value={selectedTaskId}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-3">
              {selectedTaskId && (
                <div className="text-[13px]">
                  <button
                    type="submit"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0 mr-3"
                  >
                    <span>Link</span>
                  </button>
                </div>
              )}
              <div className="text-[13px] ml-3">
                <button
                  className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn btn-cancel"
                  onClick={() => setShowLinkIssueDiv(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex justify-start pt-2">
          {addLinkTaskPermission && (
            <button
              className="border rounded-lg mt-0 btn-primary w-fit ml-0"
              type="submit"
              onClick={() => setShowLinkIssueDiv(true)}
            >
              Link Issue
            </button>
          )}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSaveSubTask: saveSubTask,
};
export default connect(null, mapDispatchToProps)(LinkIssueTask);
