import React from 'react';

const FormFieldError = ({ message }) => {
  return (
    <span className="text-red-500 text-xs flex item-center error">
      <img src="../../assets/img/error.svg" className=" w-3" />
      {message}
    </span>
  );
};

export default FormFieldError;
