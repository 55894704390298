import React, { useRef, useState } from 'react';
import '../../comments/styles.scss';
import DeleteModal from '../../../../common/deleteModal';
import {
  deleteTaskComment,
  saveEditorImage,
  updateTaskComment,
} from '../../../../../store/task/action';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  convertTime,
  getItem,
  imageUpload,
  toolbar,
} from '../../../../../utilities/utils';
import ImageUploader from 'quill-image-uploader';
import Avatar from 'react-avatar';
import ReactTimeAgo from 'react-time-ago';
import CustomAvatar from '../../../../common/customAvatar';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ImageModal from '../../modal/ImageModal';

const CommentsContainer = ({
  data,
  dispatchDeleteTaskComment,
  dispatchUpdateTaskComment,
  settings,
}) => {
  const {
    reset,
  } = useForm();

  const [DeleteShowModal, setDeleteShowModal] = useState(false);
  const [deletedData, setDeletedData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editorsValue, setEditorsValue] = useState('');
  const [editId, setEditId] = useState(null);
  const [loginUserId, setLoginUserId] = useState(null);
  const quillRefEdit = useRef(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState('');
  const [allData, setAllData] = useState([]);
  const { projectUsers } = useSelector((state) => state.task);
  const { userData } = useSelector((state) => state.user);
  const atValues = [];

  useEffect(() => {
    if (data && data.length) {
      setAllData(data);
    }
  }, [data]);

  useEffect(() => {
    if (userData) {
      userData.map((user) =>
        atValues.push({
          id: user.id,
          value: user.firstName + ' ' + user.lastName,
        })
      );
    }
  }, [projectUsers, userData]);

  useEffect(() => {
    if (JSON.parse(getItem('userData'))) {
      setLoginUserId(JSON.parse(getItem('userData')).id);
    }
  }, [JSON.parse(getItem('userData'))]);

  const onDelete = (comment) => {
    setDeleteShowModal(true);
    setDeletedData(comment);
  };
  const handleDelete = () => {
    if (deletedData.id) {
      dispatchDeleteTaskComment(deletedData.id);
      setDeleteShowModal(false);
    }
  };
  const handleChange = (html) => {
    setEditorsValue(html);
    setEditMode(true);
  };
  Quill.register('modules/imageUploader', ImageUploader);
  const modules = React.useMemo(
    () => ({
      toolbar: toolbar,
      imageUploader: {
        upload: (image) => {
          return new Promise((resolve, reject) => {
            const imageUploadResponse = imageUpload(image);
            imageUploadResponse
              .then(function successValue(result) {
                // console.log(result);
                resolve(result.attachmentUrl);
              })
              .catch((error) => {
                reject('Upload File failed');
                console.error('Error:', error);
              });
          });
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@', '#'],
        source: function (searchTerm, renderItem, mentionChar) {
          let values;
          if (mentionChar === '@' || mentionChar === '#') {
            values = atValues;
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderItem(matches, searchTerm);
          }
        },
      },
    }),
    []
  );
  const OnEdit = (task) => {
    setEditMode(true);
    setEditId(task.id);
    reset(task);
    setEditorsValue(task.comment);
  };
  const handleUpdateSingleComment = () => {
    const finalData = {
      id: editId,
      data: [
        {
          path: '/comment',
          op: 'replace',
          value: editorsValue,
        },
      ],
    };
    dispatchUpdateTaskComment(finalData);
    setEditMode(false);
    setEditId('');
  };

  const handleDoubleClickImages = (event) => {
    if (event.target.tagName === 'IMG') {
      const imageSrc = event.target.getAttribute('src');
      setSelectedImageSrc(imageSrc);
      setShowImageModal(true);
    }
  };
  return (
    <>
      {!!allData &&
        !!allData.length &&
        allData.map((item, idx) => (
          <div className={`rounded-[5px] ${idx !== 0 ? 'mt-3' : ''}`} key={idx}>
            <div className="invisible-content">
              <div className="content bg-gray-50 hover:bg-gray-100 p-2 rounded-md comment-div">
                <div className="flex justify-between">
                  <div className="user">
                    {item.userId ? (
                      <CustomAvatar id={item.userId} idPrefix={item.id} assigneeName={item.userName} />
                    ) : (
                      <div className="user">
                        <div className="avatar">
                          <Avatar
                            round={true}
                            title="Unassigned"
                            name="Un Assigned"
                          />
                        </div>
                      </div>
                    )}
                    <div className="text-[12px]">
                      <h5>
                        <b>{item.userName}</b>
                        <span className="ml-3">
                          <ReactTimeAgo
                            date={Number(convertTime(item.createdAt))}
                            locale="en-US"
                          />
                        </span>
                      </h5>
                    </div>
                  </div>

                  {editMode && editId === item.id
                    ? ''
                    : loginUserId === item.userId &&
                      settings &&
                      settings.canAddComments && (
                      <div className="show-btn">
                        <button
                          className="text-blue-500"
                          onClick={() => OnEdit(item)}
                        >
                          <i
                            className="fa fa-pencil mr-2 comment-edit-icon"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <button
                          className="text-red-500 "
                          onClick={() => onDelete(item)}
                        >
                          <i
                            className="fa fa-trash dlt-comment-icon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    )}
                </div>
                {editId === item.id ? (
                  <div className="mt-4">
                    <ReactQuill
                      ref={quillRefEdit}
                      value={editorsValue}
                      onChange={handleChange}
                      modules={modules}
                    />

                    <div className="flex justify-end shodow-slate-300 mt-0">
                      <i
                        type="submit"
                        className="fa-solid fa-check mr-2 comment-edit-icon"
                        aria-hidden="true"
                        onClick={() => handleUpdateSingleComment()}
                      ></i>
                      <i
                        className="fa-solid fa-xmark dlt-comment-icon"
                        aria-hidden="true"
                        onClick={() => {
                          setEditMode(false);
                          setEditId('');
                        }}
                      ></i>
                    </div>
                  </div>
                ) : (
                  <div
                    className="mt-2"
                    dangerouslySetInnerHTML={{
                      __html: item.comment,
                    }}
                    onClick={(e) => handleDoubleClickImages(e)}
                  ></div>
                )}
              </div>
              {showImageModal && (
                <ImageModal
                  imageSrc={selectedImageSrc}
                  onClose={() => setShowImageModal(false)}
                />
              )}
            </div>
          </div>
        ))}

      {DeleteShowModal && (
        <DeleteModal
          DeleteShowModal={DeleteShowModal}
          setDeleteShowModal={(val) => setDeleteShowModal(val)}
          handleDelete={handleDelete}
          data={deletedData}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchUpdateTaskComment: updateTaskComment,
  dispatchDeleteTaskComment: deleteTaskComment,
  dispatchSaveEditorImageRequest: saveEditorImage,
};
export default connect(null, mapDispatchToProps)(CommentsContainer);
