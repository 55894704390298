import React, { useEffect, useState } from 'react';
import Task from './Task';
import {
  errorMsg,
  successMsg,
} from '../../../utilities/utils';

import {
  getScrum,
  deleteTaskRequest,
  updateTaskRequest,
  getTaskRequest,
  saveSprintTaskOrder,
  getKanban,
} from '../../../store/task/action';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import Loader from '../../loader';
import ToastMessage from '../../ToastContainer';
import TaskModal from './modal/TaskModal';
import ProjectModal from './modal/ProjectModal';
import InvitesModal from './modal/InvitesModal';
import { resetState } from '../../../store/task/taskSlice';
import DeleteModal from '../../common/deleteModal';
import TableView from './comments/TableView';
import UserProfileList from '../../common/userList';
import { resetStateRedirection } from '../../../store/common/commonSlice';
import { getUserProfilePicUrlRequest } from '../../../store/user/action';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
let sprintID;

const Scrum = ({
  dispatchUpdateTaskRequest,
  dispatchResetStateRequest,
  dispatchGetScrumRequest,
  dispatchDeleteTask,
  dispatchGetTaskRequest,
  dispatchSprintTaskOrderRequest,
  dispatchGetKanbanRequest,
  setTaskTimer,
  dispatchRedirectionState,
  dispatchGetProfilePicUrlRequest,
}) => {
  const {
    tasks,
    isTaskAdded,
    message,
    trackingStatuses,
    singleTask,
    isTaskComment,
    isAddCheckList,
    isUpdateTaskComment,
    isUpdateTask,
    isUpdateChecklist,
    isDeletedTask,
    isDeletedTaskComment,
    taskPriorities,
    isAddedTimeSpend,
    isDeletedTimeSpend,
    loading,
    isTaskRelationAdded,
    isTaskRelationDeleted,
    isSprintTaskOrdered,
    isAttachFileAdded,
    activeSprintName,
  } = useSelector((state) => state.task);
  const {
    projects,
    isProjectAdded,
    isRemoveProject,
    projectId,
    instantCreatedProjectDetails,
  } = useSelector((state) => state.project);
  const { isGetSingleTaskFailed } = useSelector((state) => state.common);
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let deletedId;
  const [allTasks, setAllTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectProjectId, setSelectProjectId] = useState();
  const [showList, setShowList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [oneTask, setOneTask] = useState();
  const [tasksPriorityId, setTasksPriorityId] = useState('');
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if (activeSprintName && Object.keys(activeSprintName).length) {
      sprintID = activeSprintName.id;
    }
  }, [activeSprintName]);
  useEffect(() => {
    if (isGetSingleTaskFailed) {
      errorMsg('settings', true);
    }
    dispatchRedirectionState();
  }, [isGetSingleTaskFailed]);

  useEffect(() => {
    if (!!tasks && !!tasks.length) {
      setAllTasks(tasks);
      setFilteredTasks(tasks);
    }
    setShowLoader(false);
  }, [tasks]);

  useEffect(() => {
    if (projectId) {
      setShowLoader(true);
      dispatchGetProfilePicUrlRequest(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (singleTask) {
      setOneTask(singleTask);
    }
  }, [singleTask]);

  useEffect(() => {
    let filterData;
    if (selectedUser.length) {
      const selectUserFilterData = tasks.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      if (searchText) {
        filterData = selectUserFilterData.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        });
      } else if (tasksPriorityId !== '' && tasksPriorityId !== 'null') {
        filterData = selectUserFilterData.filter((item) => {
          return parseInt(item.taskPriorityId) === parseInt(tasksPriorityId);
        });
      } else {
        filterData = selectUserFilterData;
      }
    } else {
      if (searchText) {
        filterData = allTasks.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        });
      } else if (tasksPriorityId !== '' && tasksPriorityId !== 'null') {
        filterData = allTasks.filter((item) => {
          return parseInt(item.taskPriorityId) === parseInt(tasksPriorityId);
        });
      } else {
        filterData = tasks;
      }
    }
    setFilteredTasks(filterData);
    setAllData(filterTaskFun(filterData));
  }, [selectedUser, tasks]);

  const filterTaskFun = (data) => {
    let arr = [];
    if (trackingStatuses && trackingStatuses.length) {
      trackingStatuses.forEach((status) => {
        arr.push({
          status: status.name,
          statsId: status.id,
          tasks: data.filter((task) => task.trackingStatusId === status.id),
        });
      });
    }
    return arr;
  };

  useEffect(() => {
    if (isRemoveProject) {
      successMsg('Deleting invitation is successfully!');
    }
  }, [isRemoveProject]);

  useEffect(() => {
    if (isProjectAdded) {
      successMsg('Added Successfully!');
    }
  }, [isProjectAdded]);

  useEffect(() => {
    if (isTaskAdded) {
      dispatchGetScrumRequest(selectProjectId);
      dispatchGetKanbanRequest(selectProjectId);
      dispatchResetStateRequest();
    }
  }, [isTaskAdded]);

  useEffect(() => {
    if (projectId) {
      setSelectProjectId(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (projects.length && selectProjectId) {
      dispatchGetScrumRequest(selectProjectId);
      dispatchGetKanbanRequest(selectProjectId);
    }
    if (selectProjectId) {
      setSelectedUser([]);
    }
  }, [selectProjectId]);
  useEffect(() => {
    if (Object.keys(instantCreatedProjectDetails).length !== 0) {
      let projectDetails = {
        id: instantCreatedProjectDetails.id,
        name: instantCreatedProjectDetails.name,
      };
      localStorage.setItem('selectedProject', JSON.stringify(projectDetails));
    }
  }, [instantCreatedProjectDetails]);

  const handleEditModal = (id) => {
    dispatchGetTaskRequest({ taskId: id, projectId });
    handleShowModal('Tasks');
    setTaskId(id);
  };

  const handleShowModal = (id) => {
    setModalId(id);
    setShowModal(true);
  };
  useEffect(() => {
    if (
      isTaskComment ||
      isAddCheckList ||
      isUpdateTaskComment ||
      isUpdateChecklist ||
      isDeletedTask ||
      isDeletedTaskComment ||
      isAddedTimeSpend ||
      isDeletedTimeSpend ||
      isUpdateTask ||
      isTaskRelationAdded ||
      isTaskRelationDeleted ||
      isSprintTaskOrdered ||
      isAttachFileAdded
    ) {
      successMsg(message);
    }
    dispatchResetStateRequest();
  }, [
    isTaskComment,
    isAddCheckList,
    isUpdateTaskComment,
    isUpdateChecklist,
    isDeletedTask,
    isDeletedTaskComment,
    isAddedTimeSpend,
    isDeletedTimeSpend,
    isUpdateTask,
    isTaskRelationAdded,
    isTaskRelationDeleted,
    isAttachFileAdded,
  ]);
  useEffect(() => {
    if (isUpdateTask) {
      dispatchGetKanbanRequest(projectId);
    }
  }, [isUpdateTask]);

  const handleCloseModal = (val) => {
    setShowModal(val);
    setTaskId(null);
    dispatchResetStateRequest();
  };

  const handleDelete = () => {
    if (deletedId) {
      dispatchDeleteTask(deletedId);
      setShowDeleteModal(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const arr = [...tasks];
    if (selectedUser.length) {
      const userFilterData = arr.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      let filData;
      const searchData = userFilterData.filter((item) => {
        return (
          item.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
        );
      });
      if (tasksPriorityId !== '' && tasksPriorityId !== 'null') {
        filData = searchData.filter((item) => {
          return parseInt(item.taskPriorityId) === parseInt(tasksPriorityId);
        });
      } else {
        filData = searchData;
      }
      setFilteredTasks(filData);
      setAllData(filterTaskFun(filData));
    } else {
      let index;
      if (e.target.value) {
        const searchData = arr.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
            -1
          );
        });
        if (tasksPriorityId !== '' && tasksPriorityId !== 'null') {
          index = searchData.filter((item) => {
            return parseInt(item.taskPriorityId) === parseInt(tasksPriorityId);
          });
        } else {
          index = searchData;
        }
      } else {
        index = arr;
      }
      setFilteredTasks(index);
      setAllData(filterTaskFun(index));
    }
  };

  const handleSelectTaskPriority = (event) => {
    setTasksPriorityId(event);
    const arr = [...tasks];
    if (event !== 'null' && event !== '') {
      let filData;
      const searchData = arr.filter((item) => {
        return parseInt(item.taskPriorityId) === parseInt(event);
      });
      if (selectedUser.length) {
        const selectedData = searchData.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );

        if (searchText) {
          filData = selectedData.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filData = selectedData;
        }
      } else {
        const index = arr.filter((item) => {
          return parseInt(item.taskPriorityId) === parseInt(event);
        });
        if (searchText) {
          filData = index.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filData = index;
        }
      }
      setFilteredTasks(filData);
      setAllData(filterTaskFun(filData));
    } else {
      let filData;
      if (selectedUser.length) {
        const userFilterData = arr.filter((item) =>
          selectedUser.includes(item.assignedTo)
        );
        if (searchText) {
          filData = userFilterData.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filData = userFilterData;
        }
      } else {
        if (searchText) {
          filData = arr.filter((item) => {
            return (
              item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filData = arr;
        }
      }
      setFilteredTasks(filData);
      setAllData(filterTaskFun(filData));
    }
  };

  const userFilterData = (id) => {
    const isUserAlreadySelected = selectedUser.includes(id);
    if (!isUserAlreadySelected) {
      setSelectedUser([...selectedUser, id]);
    } else {
      const updatedUserSelected = selectedUser.filter((us) => us !== id);
      setSelectedUser(updatedUserSelected);
    }
  };
  const handleSearchClose = () => {
    setSearchText('');
    if (selectedUser.length) {
      let userFilterData = tasks.filter((item) =>
        selectedUser.includes(item.assignedTo)
      );
      if (userFilterData && tasksPriorityId) {
        userFilterData = userFilterData.filter((item) => {
          return parseInt(item.taskPriorityId) === parseInt(tasksPriorityId);
        });
      }
      setFilteredTasks(userFilterData);
      setAllData(filterTaskFun(userFilterData));
    } else {
      setFilteredTasks(tasks);
      setAllData(filterTaskFun(tasks));
    }
  };

  const handleSelectChange = (data, isRemoveOperation) => {
    const finalPayload = {
      id: data.id,
      data: [
        {
          path: `/${data.fieldName.toLowerCase()}`,
          op: isRemoveOperation ? 'remove' : 'replace',
          value: data.fValue,
        },
      ],
    };
    dispatchUpdateTaskRequest(finalPayload);
    dispatchGetKanbanRequest(projectId);
  };

  // ---------------dnd function-------------------
  useEffect(() => {
    if (tasks && tasks.length && trackingStatuses.length) {
      let arr = [];
      trackingStatuses.forEach((status) => {
        arr.push({
          status: status.name,
          statsId: status.id,
          tasks: tasks.filter(
            (task) =>
              task.trackingStatusId === status.id &&
              (selectedUser.length === 0 ||
                selectedUser.includes(task.assignedTo))
          ),
        });
      });

      setAllData(arr);
    } else {
      setAllData([]);
    }
  }, [tasks, trackingStatuses]);

  const onDragEnd = (re) => {
    if (!re.destination) return;
    const id = Number(re.draggableId);
    const orderIndex = re.destination.index;
    const singelTaskData = tasks.find((task) => task.id === id);
    const source = re.source.droppableId.split(',');
    const dd = re.destination.droppableId.split(',');
    let newBoardData = allData;
    let dragItem = newBoardData[parseInt(source[0])].tasks[re.source.index];
    newBoardData[parseInt(source[0])].tasks.splice(re.source.index, 1);
    newBoardData[parseInt(dd[0])].tasks.splice(
      re.destination.index,
      0,
      dragItem
    );

    const filterTasks = allData.find((task) => task.statsId === Number(dd[1]));

    if (filterTasks.tasks && filterTasks.tasks.length) {
      let oIndex;
      if (orderIndex === 0) {
        if (filterTasks.tasks.length === 1) {
          oIndex = filterTasks.tasks[orderIndex].orderIndex;
        } else {
          if (filterTasks.tasks[orderIndex + 1].orderIndex === 0) {
            oIndex = 0;
          } else {
            oIndex = filterTasks.tasks[orderIndex + 1].orderIndex;
          }
        }
      } else {
        if (
          orderIndex === filterTasks.tasks.length - 1 &&
          source[1] !== dd[1]
        ) {
          oIndex = filterTasks.tasks[orderIndex - 1].orderIndex + 1;
        } else {
          oIndex = filterTasks.tasks[orderIndex - 1].orderIndex;
        }
      }
      setAllData(newBoardData);
      if (dd[0] === source[0]) {
        if (projects.find((project) => project.projectTypeId === 1)) {
          const payload = {
            trackingStatusId: singelTaskData.trackingStatusId,
            sprintId: sprintID,
            taskId: singelTaskData.id,
            orderIndex: oIndex,
          };
          if (dd[0] === source[0] && re.source.index !== re.destination.index) {
            dispatchSprintTaskOrderRequest(payload);
          }
        } else {
          const payload = {
            trackingStatusId: singelTaskData.trackingStatusId,
            taskId: singelTaskData.id,
            orderIndex: oIndex,
          };
          if (dd[0] === source[0] && re.source.index !== re.destination.index) {
            dispatchSprintTaskOrderRequest(payload);
          }
        }
      } else {
        const finalPayload = {
          trackingStatusId: Number(dd[1]),
          sprintId: sprintID,
          taskId: singelTaskData.id,
          orderIndex: oIndex,
        };
        dispatchSprintTaskOrderRequest(finalPayload);
      }
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <ToastMessage />
        <div className={`${trackingStatuses.length ? '' : 'custom-h'}`}>
          {!!projects.length && (<div className="accordion" id="accordionProject">
            <div className="accordion-item bg-white border-b-2 border-gray-200 rounded-none">
              <div className="accordion-header mb-0" id="headingMain">
                <div className="flex justify-between">
                  <div className="flex">
                    {!!activeSprintName &&
                      !!Object.keys(activeSprintName).length && (
                      <h4 className="mt-6 font-semibold">
                        {activeSprintName.name}
                      </h4>
                    )}
                    <UserProfileList
                      selectedUser={selectedUser}
                      userFilterData={userFilterData}
                    />
                  </div>
                  <div className="accordion-body p-4 pb-0">
                    <div className="ticket-heading justify-end pt-4">
                      <div className="flex items-center">
                        
                        <div className="flex items-center ml-3 rounded-[0.5rem] h-[2.5rem] tasks-btn">
                          <div className="relative">
                            <input
                              type="search"
                              className="search-bar pr-[22px]"
                              placeholder="Search"
                              value={searchText}
                              onChange={(e) => handleSearch(e)}
                            />
                            <i
                              className={`fas fa-times absolute right-[11px] top-[13px] cursor-pointer site-clr hover:cursor-pointer ${searchText ? 'block' : 'hidden'
                              }`}
                              onClick={() => handleSearchClose()}
                            ></i>
                          </div>
                          <div className="flex border items-center ml-3 rounded-[0.5rem] h-[2.5rem] tasks-btn">
                            <div className="relative">
                              <div className="arrow-down">
                                <i className="fas fa-chevron-down"></i>
                              </div>
                              <select
                                className="input-style select-none cursor-pointer"
                                onChange={(e) => {
                                  handleSelectTaskPriority(e.target.value);
                                }}
                              >
                                <option value={''}>Select Task Priority</option>
                                {!!taskPriorities &&
                                  !!taskPriorities.length &&
                                  taskPriorities.map((priority, idx) => (
                                    <option key={idx} value={priority.id}>
                                      {priority.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="flex border items-center ml-3 rounded-[0.5rem] h-[2.5rem] tasks-btn">
                            <div
                              className={`${showList ? '' : 'active'
                              } close-dropdown border-0 rounded-none rounded-l-[0.5rem] btn-primary`}
                              data-bs-toggle="collapse"
                              onClick={() => setShowList(false)}
                            >
                              <i className="fas fa-th-large"></i>
                            </div>
                            <div className="border border-white"></div>
                            <div
                              className={`${showList ? 'active' : ''
                              } close-dropdown border-0 rounded-none rounded-r-[0.5rem] btn-primary`}
                              data-bs-toggle="collapse"
                              onClick={() => setShowList(true)}
                            >
                              <i className="fa-solid fa-list"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}

          {!!projects && !!projects.length? (
            <div
              className={`${showList
                ? 'view-main'
                : 'w-full overflow-scroll mt-5 relative z-[1] rounded-[6px] bg-[#fbfbfb] h-calc view-main'
              }`}
            >
              <div className={`${showList ? '' : 'bg-[#fbfbfb] pb-2.5'}`}>
                {loading ? (
                  <Loader />
                ) : showList ? (
                  <div className="relative h-calc overflow-y-auto mt-5 list-view">
                    {showLoader ? (
                      <Loader />
                    ) : (
                      <TableView
                        filteredTasks={filteredTasks}
                        handleSelectChange={handleSelectChange}
                        handleEditModal={handleEditModal}
                      />
                    )}
                  </div>
                ) : showLoader ? (
                  <Loader />
                ) : (
                  <>
                    {allData.length ?
                      <div className="flex tasks-list h-full">
                        {
                          allData.map((status, idx) => {
                            return (
                              <Droppable
                                droppableId={`${idx},${status.statsId}`.toString()}
                                key={idx}
                              >
                                {(provided) => (
                                  <div
                                    key={idx}
                                    className="flex flex-col rounded-[5px]  "
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <div className="px-2 py-1 backdrop-blur-[2px] sticky top-0 z-10">
                                      <div className="ticket-head">
                                        <div className="text-[#e32135] self-center m-0">
                                          {status.status}
                                        </div>
                                        <div className="text-[#e32135] self-center m-0">
                                          {status.tasks.length}
                                        </div>
                                      </div>
                                    </div>
                                    {status.tasks.length >= 0 &&
                                      status.tasks.map((issue, index) => {
                                        return (
                                          <Task
                                            key={issue.id}
                                            index={index}
                                            id={issue.id}
                                            singleTask={issue}
                                            taskKey={index}
                                            issueTitle={issue.title}
                                            assignedTo={issue.assignedTo}
                                            tagId={issue.tagId}
                                            handleEditModal={handleEditModal}
                                            dueDate={issue.dueDate}
                                            setTaskTimer={setTaskTimer}
                                            assigneName={issue.assigneeName}
                                          />
                                        );
                                      })}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            );
                          })
                        }
                      </div> : (
                        <div className="flex justify-center">
                          <h1 className="text-2xl mt-5">No Any Task yet!</h1>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center text-center flex-col">
              <div className="">
                <img
                  src="../../assets/img/empty-folder.svg"
                  alt=""
                  className=" w-[180px] h-[180px] object-cover ml-2 inline"
                />
                <h1 className="text-2xl"> Hey admin!</h1>
                <p className="mt-1 text-[.9rem]">
                  Looks like you don't have access to any project. Click the button below to kick start.
                </p>
              </div>
              <div className="dropdown relative ml-0 mr-3 mt-2">
                <div
                  className="flex-items ml-3 cursor-pointer"
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div
                    className="btn-primary pt-2 flex items-center mt-01 justify-center w-[200px] h-10 cursor-pointer"
                    onClick={() => handleShowModal('Project')}
                  >
                    New project
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Show modal */}
          {showModal && (modalId === 'Task' || modalId === 'Tasks') && (
            <TaskModal
              modalId={modalId}
              showModal={showModal}
              setShowModal={(val) => {
                handleCloseModal(val);
                setOneTask({});
              }}
              singleTask={oneTask}
              taskId={taskId}
              setTaskTimer={setTaskTimer}
            />
          )}
          {showModal && modalId === 'Project' && (
            <ProjectModal
              modalId={modalId}
              showModal={showModal}
              setShowModal={(val) => setShowModal(val)}
            />
          )}
          {showModal && modalId === 'Invite' && (
            <InvitesModal
              modalId={modalId}
              showModal={showModal}
              setShowModal={(val) => setShowModal(val)}
            />
          )}
          {showDeleteModal && (
            <DeleteModal
              DeleteShowModal={showDeleteModal}
              setDeleteShowModal={(val) => setShowDeleteModal(val)}
              handleDelete={handleDelete}
              data={{ name: 'Task' }}
            />
          )}
        </div>
      </DragDropContext>
    </>
  );
};
const mapDispatchToProps = {
  // dispatching
  dispatchGetTaskRequest: getTaskRequest,
  dispatchUpdateTaskRequest: updateTaskRequest,
  dispatchResetStateRequest: resetState,
  dispatchGetScrumRequest: getScrum,
  dispatchGetKanbanRequest: getKanban,
  dispatchDeleteTask: deleteTaskRequest,
  dispatchSprintTaskOrderRequest: saveSprintTaskOrder,
  dispatchRedirectionState: resetStateRedirection,
  dispatchGetProfilePicUrlRequest: getUserProfilePicUrlRequest,
};
export default connect(null, mapDispatchToProps)(Scrum);
