import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import TimeSpendTable from '../../../../common/table/timeSpendTable';
import {
  getTaskTimeTracker,
  taskTimeTracker,
} from '../../../../../store/task/action';
import FormTextareaField from '../../../../formTextareField';

const TimeSpend = ({
  singleTask,
  dispatchTaskTimeTracker,
  dipatchGetTaskTimeTracker,
  isSortedByAsc
}) => {
  const {
    taskTimeTracker,
    isTaskTimeTracker,
    isTaskTimerEnd,
  } = useSelector((state) => state.task);
  const { projectId } = useSelector((state) => state.project);
  const [timeSpends, setTimeSpends] = useState([]);
  const [isShowTimeSpend, setIsShowTimeSpend] = useState(false);
  const showError = false;
  const {
    register,
    formState: { errors: errorsTimeSpend },
    handleSubmit,
    reset,
  } = useForm();

  const userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    if (taskTimeTracker && taskTimeTracker.length) {
      setTimeSpends(taskTimeTracker);
    }
  }, [taskTimeTracker]);

  useEffect(() => {
    if (isTaskTimerEnd) {
      const payload = { TaskId: singleTask.id, SortByAsc: !isSortedByAsc };
      dipatchGetTaskTimeTracker({ payload, projectId: projectId });
    }
  }, [isTaskTimerEnd]);

  useEffect(() => {
    if (Object.keys(singleTask).length !== 0 && projectId) {
      const payload = { TaskId: singleTask.id, SortByAsc: !isSortedByAsc };
      dipatchGetTaskTimeTracker({ payload, projectId: projectId });
    }
  }, [singleTask]);

  useEffect(() => {
    if (isTaskTimeTracker) {
      const payload = { TaskId: singleTask.id, SortByAsc: !isSortedByAsc };
      dipatchGetTaskTimeTracker({ payload, projectId: projectId });
    }
  }, [isTaskTimeTracker]);

  //  save Time Spend
  const handleTimeSpend = (data) => {
    const finalPayload = {
      userId: userData.id,
      taskId: singleTask?.id,
      startTime: new Date(data.startTime).toISOString(),
      endTime: new Date(data.endTime).toISOString(),
      details:data.comment
    };
    dispatchTaskTimeTracker(finalPayload);
    reset();
  };

  return (
    <>
      <div>
        {isShowTimeSpend && (
          <form onSubmit={handleSubmit(handleTimeSpend)} className="mt-2">
            {showError ? (
              <div className=" text-[#f24759] pb-1">
                Please select time less than 8 hours
              </div>
            ) : null}
            <div className="w-full md:flex">
              <div className="time-spent-items">
                <label className="block text-sm font-medium whitespace-nowrap">
                  Start Time
                </label>
                <input
                  type="datetime-local"
                  className="input-style w-20 px-2 mx-3 h-[36px]"
                  {...register('startTime', {
                    required: true,
                  })}
                  pattern="\d{1,2}"
                  maxLength="2"
                />
                <label>Mintues</label>
              </div>
              <div className="time-spent-items">
                <label className="block text-sm font-medium whitespace-nowrap">
                  End Time
                </label>
                <input
                  type="datetime-local"
                  className="input-style w-20 px-2 mx-3 h-[36px]"
                  {...register('endTime', {
                    required: true,
                  })}
                  pattern="\d{1,2}"
                  maxLength="2"
                />
              </div>
              <div className="flex items-end md:w-1/2 lg:w-4/6 md:ml-2">
                <div className="w-full mr-3 details-field">
                  <FormTextareaField
                    placeholder={'Details'}
                    fieldName={'comment'}
                    register={register}
                    errors={errorsTimeSpend}
                    isRequired={false}
                    rows={2}
                  />
                </div>

                <div className="mb-2">
                  <button className="border rounded-lg mt-0 btn-primary w-fit mr-0 ml-auto">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}

        <TimeSpendTable
          data={timeSpends}
          setIsShowTimeSpend={() => setIsShowTimeSpend(!isShowTimeSpend)}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchTaskTimeTracker: taskTimeTracker,
  dipatchGetTaskTimeTracker: getTaskTimeTracker,
};
export default connect(null, mapDispatchToProps)(TimeSpend);
