import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: true,
  success: false,
  error: true,
  message: '',
  isReadNotification: false,
  allTaskHistory: [],
  paginationMeta: {},
  allNotification: 0,
  notificationLoading: true,
  isUnreadNotification:false
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    saveNotificationRequestSuccess: (state, action) => {
      if (action.payload) {
        let allTaskHistory = JSON.parse(JSON.stringify(state.allTaskHistory));
        const allTaskHistoryToChange = allTaskHistory.find(
          (item) => item.id === action.payload.data.taskHistoryId
        );
        allTaskHistoryToChange.isRead = true;
        return {
          ...state,
          isReadNotification: true,
          allTaskHistory,
          loading: false,
        };
      }
      return state;
    },
    saveNotificationRequestFailure: (state) => {
      return {
        ...state,
        isReadNotification: false,
        loading: false,
        success: false,
      };
    },

    // get All task History
    getAllTaskHistoryRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allNotification: parseInt(action.payload.data[0].unreadCount),
          allTaskHistory: action.payload.data[0].taskHistoryModels,
          paginationMeta: action.payload.paginationMeta,
          loading: false,
          notificationLoading: false,
          
        };
      }
      return state;
    },
    getAllTaskHistoryRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
        notificationLoading: false,
      
      };
    },

    // Get All notification
    getAllNotificationRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allNotification: parseInt(action.payload.message),
          loading: false,
        };
      }
      return state;
    },
    getAllNotificationRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    },

    // Get All Unread notification
    getAllUnreadNotificationRequestSuccess: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allUnreadNotifications: action.payload.message,
          loading: false,
          isUnreadNotification:true
        };
      }
      return state;
    },
    getAllUnreadNotificationRequestFailure: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
        isUnreadNotification:false
      };
    },

    // reset state
    resetStateNotification: (state) => {
      return {
        ...state,
        message: '',
        isReadNotification: false,
        notificationLoading: true,
        isUnreadNotification:false
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveNotificationRequestSuccess,
  saveNotificationRequestFailure,
  // update
  getAllTaskHistoryRequestSuccess,
  getAllTaskHistoryRequestFailure,
  getAllNotificationRequestSuccess,
  getAllNotificationRequestFailure,
  getAllUnreadNotificationRequestSuccess,
  getAllUnreadNotificationRequestFailure,

  resetStateNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
