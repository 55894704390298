import * as Constants from './constants';

// Login Action
export const sendLoginRequest = (payload) => {
  return {
    type: Constants.sendLoginRequestActionType,
    payload,
  };
};

// Register
export const sendUserRequest = (payload) => {
  return {
    type: Constants.sendUserRequestActionType,
    payload,
  };
};

// Login with google
export const getLoginDetailsRequest = (payload) => {
  return {
    type: Constants.getLoginDetailsRequestActionType,
    payload,
  };
};
