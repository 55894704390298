import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { sendUserRequest } from '../../store/login/action';
import { removeItem, successMsg } from '../../utilities/utils';
import ToastMessage from '../ToastContainer';

const Register = ({ dispatchSendUserRequest }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    removeItem('token');
    removeItem('userData');
    removeItem('refreshToken');
  }, []);

  const handleSignUp = (data) => {
    dispatchSendUserRequest(data);
    reset();
  };
  const {isRegister, message } = useSelector(
    (state) => state.login
  );

  useEffect(() => {
    if (isRegister) {
      successMsg(message);
      const pageUrl = '/login';
      navigate(`${pageUrl}`);
    }
  }, [isRegister]);

  return (
    <>
      <ToastMessage />
      <div
        className="form-wrapper h-screen"
        style={{
          backgroundImage: 'url("../../assets/img/login-page-bg.png")',
        }}
      >
        <div className="flex justify-center relative h-screen items-center">
          <div>
            <div className="mb-7">
              <img
                src="../../assets/img/ensuesoft.jpg"
                className="w-38 h-38 mx-auto object-contain"
              />

              <h2 className="text-2xl text-center mb-0 text-black dark:text-[#ccc]">
                Welcome!
              </h2>
            </div>
            <div className="px-8 pt-4 pb-4 bg-white dark:bg-[#283046] rounded-lg shadow-card w-96">
              <form onSubmit={handleSubmit(handleSignUp)}>
                <div className="mt-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      First Name
                    </label>
                    <input
                      id="firstName"
                      {...register('firstName', {
                        required: true,
                        maxLength: 200,
                        pattern: /^[A-Za-z]/,
                      })}
                      type="text"
                      className="input-style"
                    />
                    {errors.userName?.type === 'required' && (
                      <FormFieldError message={AppConstants.requiredField} />
                    )}
                    {errors.userName?.type === 'pattern' && (
                      <FormFieldError message={AppConstants.notValidEmail} />
                    )}
                    {errors.userName?.type === 'maxLength' && (
                      <FormFieldError message={AppConstants.maxLengthReached} />
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium mb-1">
                      Last Name
                    </label>
                    <input
                      id="lastName"
                      {...register('lastName', {
                        required: true,
                        maxLength: 200,
                        pattern: /^[A-Za-z]/,
                      })}
                      type="text"
                      className="input-style"
                    />
                    {errors.userName?.type === 'required' && (
                      <FormFieldError message={AppConstants.requiredField} />
                    )}
                    {errors.userName?.type === 'pattern' && (
                      <FormFieldError message={AppConstants.notValidEmail} />
                    )}
                    {errors.userName?.type === 'maxLength' && (
                      <FormFieldError message={AppConstants.maxLengthReached} />
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium mb-1">
                      Email
                    </label>
                    <input
                      id="email"
                      {...register('email', {
                        required: true,
                        maxLength: 200,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      })}
                      type="text"
                      className="input-style"
                    />
                    {errors.userName?.type === 'required' && (
                      <FormFieldError message={AppConstants.requiredField} />
                    )}
                    {errors.userName?.type === 'pattern' && (
                      <FormFieldError message={AppConstants.notValidEmail} />
                    )}
                    {errors.userName?.type === 'maxLength' && (
                      <FormFieldError message={AppConstants.maxLengthReached} />
                    )}
                  </div>
                  <div className="mt-4">
                    <div className="flex-items mb-1">
                      <label className="block text-sm font-medium">
                        Password
                      </label>
                    </div>
                    <input
                      id="password"
                      {...register('password', { required: true })}
                      type="password"
                      className="input-style"
                    />
                    {errors.password?.type === 'required' && (
                      <FormFieldError message={AppConstants.requiredField} />
                    )}
                  </div>
                  {/* <div className="mt-4">
                    <div className="flex-items mb-1">
                      <label className="block text-sm font-semibold">
                        Gender
                      </label>
                      <div className="flex items-center">
                        <input
                          id="gender"
                          value={"male"}
                          {...register("gender", { required: true })}
                          type="radio"
                        />
                        <label className="flex items-center text-sm font-medium ml-3">
                          Male
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="gender"
                          value={"female"}
                          {...register("gender", { required: true })}
                          type="radio"
                        />
                        <label className="flex items-center text-sm font-medium ml-3">
                          Female
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="gender"
                          value={"others"}
                          {...register("gender", { required: true })}
                          type="radio"
                        />
                        <label className="flex items-center text-sm font-medium ml-3">
                          Others
                        </label>
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <button
                      type="submit"
                      className="px-6 py-2 mt-4 btn-primary ml-0"
                    >
                      Sign-up
                    </button>
                  </div>
                </div>
              </form>
              <h3 className="text-center mt-4 text-gray-600 dark:text-[#ccc]">
                Already a User?{' '}
                <Link to="/login" className="text-2xs site-clr hover:underline">
                  Sign In
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  // dispatching
  dispatchSendUserRequest: sendUserRequest,
};
export default connect(null, mapDispatchToProps)(Register);
