import { all, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { errorMsg, setItem } from '../../utilities/utils';
import { ApiService } from '../../utilities/api.service';
import { URLS } from '../../utilities/endPoints';
import {
  getUserProfilePicUrlRequestFailure,
  getUserProfilePicUrlRequestSuccess,
  passwordForgotRequestFailure,
  passwordForgotRequestSuccess,
  saveOTPRequestFailure,
  saveOTPRequestSuccess,
  updateUserPersonalDetailsRequestFailure,
  updateUserPersonalDetailsRequestSuccess,
  updateUserProfilePicRequestFailure,
  updateUserProfilePicRequestSuccess,
  verifyOTPRequestFailure,
  verifyOTPRequestSuccess,
} from './userSlice';
import * as AppConstants from '../../utilities/constants';

function* asyncUpdateProfilePic(data) {
  try {
    const response = yield ApiService.postData(
      URLS.PROFILE_PIC.url,
      data.payload
    );
    if (response.status === 200) {
      yield put(updateUserProfilePicRequestSuccess(response.data));
    }
    const userData = JSON.parse(localStorage.userData);
    setItem(
      'userData',
      JSON.stringify({
        ...userData,
        profilePicUrl: response.data.data,
      })
    );
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateUserProfilePicRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg(AppConstants.somethingWrong);
    }
  }
}

function* asyncUpdatePersonalDetails(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.PERSONAL_DETAILS.url}/${data.payload.id}`,
      data.payload.data
    );
    if (response.status === 200) {
      yield put(updateUserPersonalDetailsRequestSuccess(response.data));
    }
    const userData = JSON.parse(localStorage.userData);
    setItem(
      'userData',
      JSON.stringify({
        ...userData,
        firstName: response.data.data.firstName,
        lastName: response.data.data.lastName,
      })
    );
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(updateUserPersonalDetailsRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg(AppConstants.somethingWrong);
    }
  }
}

function* asyncGetUserProfilePicUrl(data) {
  try {
    const response = yield ApiService.getData(
      `${URLS.USER_PROFILE_PIC_URL.url}?ProjectId=${data.payload}`
    );
    if (response.status === 200) {
      yield put(getUserProfilePicUrlRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(getUserProfilePicUrlRequestFailure());
  }
}

function* asyncSavePasswordChange(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.FORGOT_PASSWORD.url}/update-password`,
      data.payload
    );
    if (response.status === 200) {
      yield put(passwordForgotRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(passwordForgotRequestFailure());
    if (err.response?.data.message) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg(AppConstants.somethingWrong);
    }
  }
}

function* asyncSaveOTP(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.FORGOT_PASSWORD.url}/code?email=${data.payload}`
    );
    if (response.status === 200) {
      yield put(saveOTPRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(saveOTPRequestFailure());
    if (err.response.data.statusCode === 404) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg(AppConstants.somethingWrong);
    }
  }
}

// verify otp
function* asyncVerifyOTP(data) {
  try {
    const response = yield ApiService.postData(
      `${URLS.FORGOT_PASSWORD.url}/verify-code`,
      data.payload
    );
    if (response.status === 200) {
      yield put(verifyOTPRequestSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    const err = error;
    yield put(verifyOTPRequestFailure());
    if (err.response.data.statusCode === 400) {
      errorMsg(err.response?.data.message);
    } else {
      errorMsg(AppConstants.somethingWrong);
    }
  }
}

export function* userSaga() {
  yield all([
    takeEvery(
      constants.updateUserProfilePicRequestActionType,
      asyncUpdateProfilePic
    ),
    takeEvery(
      constants.updatePersonalDetailsRequestActionType,
      asyncUpdatePersonalDetails
    ),
    takeEvery(
      constants.getUserProfilePicRequestActionType,
      asyncGetUserProfilePicUrl
    ),
    takeEvery(constants.savePasswordChangeRequestType, asyncSavePasswordChange),
    takeEvery(constants.saveOTPRequestType, asyncSaveOTP),
    takeEvery(constants.verifyOTPRequestType, asyncVerifyOTP),
  ]);
}
